import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import CommonSpinner from "../../components/common/CommonSpinner";
import ConfirmDialog from "../../components/common/ConfirmDialog";
import SysModels from "../../models";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import systemStore from "../../stores/SystemStore";
import toastStore from "../../stores/ToastStore";
import DocumentationDialog from "../dialogs/DocumentationDialog";
import DocumentationPreviewDialog from "../dialogs/DocumentationPreviewDialog";
import Pagination, { usePaging } from "../../components/common/Pagination";

function DocumentManagerDashboard(props: any) {
  const [publishedOnly, setPublishedOnly] = useState(false);
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const grid = useFetchHelper(
    async () =>
      SysServices.http.generalDocumentation.list(paging.page, paging.pageSize, {
        search: search.used,
        orderByEnum: SysModels.OrderByEnum.Ascending,
        onlyPublished: publishedOnly,
      }),
    "Documentation"
  );

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);

    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, publishedOnly]);

  //DELETE DOCUMENT
  const [delDoc, setDelDoc] =
    useState<SysModels.IGeneralDocumentationDisplayOutputDto>();
  const [docsBeingDeleted, setDocsBeingDeleted] = useState<
    SysModels.IGeneralDocumentationDisplayOutputDto[]
  >([]);
  const deleteData = async (id: string) => {
    await SysServices.http.generalDocumentation
      .delete(id)
      .then((data) => {
        toastStore.showToast("Document deleted.", "success");
        grid.getData();
      })
      .catch((error) => {
        toastStore.showError("Failed to Delete Document", error);
      })
      .finally(() => {
        setDocsBeingDeleted((list) => {
          return list.filter((d) => d.sqlId !== id);
        });
      });
  };

  //PUBLISH DOCUMENT
  const [pubDoc, setPubDoc] =
    useState<SysModels.IGeneralDocumentationDisplayOutputDto>();
  const [docsBeingPublished, setDocsBeingPublished] = useState<
    SysModels.IGeneralDocumentationDisplayOutputDto[]
  >([]);
  const publishData = async (id: string) => {
    await SysServices.http.generalDocumentation
      .publish(id, { active: true })
      .then((data) => {
        toastStore.showToast("Document Published.", "success");
        //grid.getData();
        if (grid.data) {
          grid.setData((prev) => {
            if (!prev) {
              return prev;
            }
            return {
              ...prev,
              generalDocumentationDisplayAdminDtos: (
                prev.generalDocumentationDisplayOutputDtos || []
              ).map((dto) => {
                if (id === dto.sqlId) {
                  return {
                    ...dto,
                    publishedOn: new Date(),
                  };
                }
                return dto;
              }),
            };
          });
        }
      })
      .catch((error) => {
        toastStore.showError("Failed to Publish Document", error);
      })
      .finally(() => {
        setDocsBeingPublished((list) => {
          return list.filter((d) => d.sqlId !== id);
        });
      });
  };

  const [showDialog, setShowDialog] = useState<{
    id?: string;
    show: boolean;
    duplicate: boolean;
  }>({
    show: false,
    duplicate: false,
  });

  const [showPreview, setShowPreview] = useState({
    show: false,
    print: false,
    data: {} as SysModels.IGeneralDocumentationDisplayAdminDto,
    published: false,
  });

  const openForEdit = (
    doc: SysModels.IGeneralDocumentationDisplayOutputDto
  ) => {
    if (
      !docsBeingDeleted.find((d) => doc.sqlId === d.sqlId) &&
      !docsBeingPublished.find((d) => doc.sqlId === d.sqlId)
    ) {
      setShowDialog({
        show: true,
        id: doc.sqlId,
        duplicate: false,
      });
    }
  };

  const [newSeq, setNewSeq] = useState<{ [key: string]: number }>({});
  const [seqHover, setSeqHover] = useState<{ [key: string]: boolean }>({});
  const [savingSeq, setSavingSeq] = useState<{ [key: string]: boolean }>({});
  const hasSeqChanges = (
    doc: SysModels.IGeneralDocumentationDisplayOutputDto
  ) => {
    return !!newSeq[doc.sqlId] && newSeq[doc.sqlId] !== doc.sequence;
  };
  const [preventSpinner, setPreventSpinner] = useState<string>();
  useEffect(() => {
    if (grid.status === FetchStatus.Complete) {
      if (preventSpinner) {
        const el = document.getElementById(preventSpinner);
        if (el && !commonService.elementIsVisibleInViewport(el, true)) {
          el.scrollIntoView();
        }
        if (!el) {
          toastStore.showToast(
            "The document is no longer visible on this page.",
            "warning"
          );
        }
      }
      setPreventSpinner(undefined);
    }
  }, [grid.status]);

  return (
    <>
      {showDialog.show && (
        <DocumentationDialog
          id={showDialog.id}
          duplicate={showDialog.duplicate}
          onClose={(saved) => {
            if (saved) {
              grid.getData();
            }
            setShowDialog({ id: undefined, show: false, duplicate: false });
          }}
        ></DocumentationDialog>
      )}

      {showPreview.show && (
        <DocumentationPreviewDialog
          id={showPreview.data.sqlId}
          title={showPreview.data.title}
          subject={showPreview.data.subjectDraft}
          content={showPreview.data.contentDraft}
          print={showPreview.print}
          published={showPreview.published}
          onClose={() => {
            setShowPreview({
              show: false,
              print: false,
              published: false,
              data: null as any,
            });
          }}
        ></DocumentationPreviewDialog>
      )}

      <ConfirmDialog
        show={!!delDoc}
        buttons="yesno"
        title="Delete Document"
        message={`Are you sure you want to delete this document?`}
        done={(rtn) => {
          if (rtn === "yes") {
            if (delDoc) {
              setDocsBeingDeleted((list) => {
                return [...list, { ...delDoc }];
              });
              deleteData(delDoc.sqlId);
            }
          }
          setDelDoc(undefined);
        }}
      ></ConfirmDialog>

      <ConfirmDialog
        show={!!pubDoc}
        buttons="yesno"
        title="Publish Document"
        message={`Are you sure you want to publish this document?`}
        done={(rtn) => {
          if (rtn === "yes") {
            if (pubDoc) {
              setDocsBeingPublished((list) => {
                return [...list, { ...pubDoc }];
              });
              publishData(pubDoc.sqlId);
            }
          }
          setPubDoc(undefined);
        }}
      ></ConfirmDialog>

      <div className="default-page-layout hide-on-print">
        <h4>Customer Site Documentation</h4>
        <div className="bg-white col-sm-12">
          {grid.status === FetchStatus.InProgress && !preventSpinner && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {(!!preventSpinner || grid.status === FetchStatus.Complete) && (
            <div>
              <div className="p-3 flex flex-wrap gap-10">
                <div className="flex-0" style={{ maxWidth: "100%" }}>
                  <div className="input-group search-box">
                    <input
                      autoFocus={true}
                      className="form-control"
                      type="text"
                      placeholder="Search"
                      value={search.typed}
                      onChange={(e) => {
                        setSearch((data) => {
                          return {
                            ...data,
                            typed: e.target.value,
                          };
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          e.stopPropagation();
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              grid.getData();
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }
                      }}
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={(e) => {
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              grid.getData();
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={(e) => {
                          setSearch((data) => {
                            return { typed: "", used: "" };
                          });
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <button
                    className="btn btn-outline-primary no-wrap me-2"
                    type="button"
                    onClick={(e) => {
                      setPublishedOnly(!publishedOnly);
                      pageChange(1, paging.pageSize);
                    }}
                  >
                    <i
                      className={`fa me-2 ${
                        publishedOnly ? "fa-check-square" : "fa-square-o"
                      }`}
                    ></i>
                    Published Only
                  </button>
                  <Button
                    variant="primary"
                    type="button"
                    onClick={() => {
                      setShowDialog({
                        id: undefined,
                        show: true,
                        duplicate: false,
                      });
                    }}
                  >
                    <span>
                      <i className="fa fa-plus"></i> Add Document
                    </span>
                  </Button>
                </div>
                <div className="flex-1"></div>
              </div>

              <table className="table table-hover pointer">
                <thead>
                  {isSmallScreen && (
                    <tr>
                      <th>Title / Description</th>
                      <th></th>
                    </tr>
                  )}
                  {!isSmallScreen && (
                    <tr>
                      <th>Sequence</th>
                      <th>Title / Description</th>
                      <th className="no-wrap">Published On</th>
                      <th></th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {grid.data?.generalDocumentationDisplayOutputDtos?.map(
                    (doc) => (
                      <tr key={doc.sqlId} className="break-word">
                        {isSmallScreen && (
                          <>
                            <td
                              onClick={() => {
                                openForEdit(doc);
                              }}
                            >
                              <strong>{doc.sequence}</strong> - {doc.title}
                              <div>
                                <small>{doc.subject}</small>
                              </div>
                              <div>
                                {doc.publishedOn ? (
                                  <small>
                                    <i className="fa fa-newspaper-o me-2"></i>
                                    {commonService.toLocalDate(
                                      doc.publishedOn,
                                      "full"
                                    )}
                                  </small>
                                ) : (
                                  <small>Unpublished</small>
                                )}
                              </div>
                            </td>
                          </>
                        )}
                        {!isSmallScreen && (
                          <>
                            <td
                              onClick={() => {
                                openForEdit(doc);
                              }}
                            >
                              {/* {doc.sequence} */}
                              <div className="flex flex-row flex-center">
                                {preventSpinner && (
                                  <div className="flex-0 text-right pb-3 pe-2">
                                    <i className="fa fa-spin fa-spinner"></i>
                                  </div>
                                )}
                                <div
                                  id={doc.sqlId}
                                  className={`flex-0 ${
                                    !!(
                                      seqHover[doc.sqlId] && hasSeqChanges(doc)
                                    )
                                      ? "show-sequence-tooltip"
                                      : ""
                                  } ${
                                    savingSeq[doc.sqlId]
                                      ? "saving-sequence"
                                      : ""
                                  }`}
                                >
                                  <input
                                    className="form-control mb-3 input-inline-edit"
                                    placeholder="Sequence"
                                    type="number"
                                    defaultValue={doc.sequence}
                                    readOnly={savingSeq[doc.sqlId]}
                                    style={{
                                      width: "150px",
                                      ...(hasSeqChanges(doc)
                                        ? {
                                            backgroundColor:
                                              "rgba(255, 0, 0, 0.15)",
                                          }
                                        : {}),
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                    onChange={(e) => {
                                      setNewSeq((prev) => {
                                        const data = { ...prev };
                                        data[doc.sqlId] = Number(
                                          e.target.value
                                        );
                                        return data;
                                      });
                                    }}
                                    onFocus={(e) => {
                                      setSeqHover((prev) => {
                                        const data = { ...prev };
                                        data[doc.sqlId] = true;
                                        return data;
                                      });
                                    }}
                                    onBlur={(e) => {
                                      setSeqHover((prev) => {
                                        const data = { ...prev };
                                        data[doc.sqlId] = false;
                                        return data;
                                      });
                                    }}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        if (newSeq[doc.sqlId] === undefined) {
                                          return;
                                        }
                                        if (newSeq[doc.sqlId] <= 0) {
                                          toastStore.showToast(
                                            "'Sequence' must be greater than '0'.",
                                            "warning"
                                          );
                                          return;
                                        }

                                        setSavingSeq((prev) => {
                                          const data = { ...prev };
                                          data[doc.sqlId] = true;
                                          return data;
                                        });
                                        SysServices.http.generalDocumentation
                                          .updateSequence(
                                            doc.sqlId,
                                            newSeq[doc.sqlId]
                                          )
                                          .then((data) => {
                                            toastStore.showToast(
                                              "Document Sequence Updated",
                                              "success"
                                            );
                                            setNewSeq((prev) => {
                                              const data = { ...prev };
                                              delete data[doc.sqlId];
                                              return data;
                                            });
                                            setPreventSpinner(doc.sqlId);
                                            grid.getData();
                                          })
                                          .catch((err) => {
                                            toastStore.showError(
                                              "Failed Updating Document Sequence",
                                              err
                                            );
                                          })
                                          .finally(() => {
                                            setSavingSeq((prev) => {
                                              const data = { ...prev };
                                              data[doc.sqlId] = false;
                                              return data;
                                            });
                                          });
                                      }
                                    }}
                                  ></input>
                                </div>
                              </div>
                            </td>
                            <td
                              onClick={() => {
                                openForEdit(doc);
                              }}
                            >
                              {doc.title}
                              <div>
                                <small>{doc.subject}</small>
                              </div>
                            </td>
                            <td
                              className="no-wrap"
                              onClick={() => {
                                openForEdit(doc);
                              }}
                            >
                              {doc.publishedOn ? (
                                <small>
                                  {commonService.toLocalDate(
                                    doc.publishedOn,
                                    "date"
                                  )}
                                  <br />
                                  {commonService.toLocalDate(
                                    doc.publishedOn,
                                    "time"
                                  )}
                                </small>
                              ) : (
                                <small>Unpublished</small>
                              )}
                            </td>
                          </>
                        )}
                        <td className="text-right no-wrap">
                          {!docsBeingDeleted.find(
                            (d) => doc.sqlId === d.sqlId
                          ) &&
                            !docsBeingPublished.find(
                              (d) => doc.sqlId === d.sqlId
                            ) && (
                              <>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    size="sm"
                                    id={`dropdownDoc${doc.sqlId}`}
                                    variant="outline-primary"
                                  >
                                    <i className="fa fa-bars"></i>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu align="right">
                                    <Dropdown.Item
                                      onClick={() => {
                                        setDelDoc(doc);
                                      }}
                                    >
                                      <i className="fa fa-trash me-2"></i>{" "}
                                      Delete
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setShowDialog({
                                          id: doc.sqlId,
                                          show: true,
                                          duplicate: true,
                                        });
                                      }}
                                    >
                                      <i className="fa fa-copy me-2"></i>{" "}
                                      Duplicate
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setPubDoc(doc);
                                      }}
                                    >
                                      <i className="fa fa-newspaper-o me-2"></i>
                                      Publish
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      disabled={!doc.publishedOn}
                                      onClick={() => {
                                        setShowPreview({
                                          show: true,
                                          print: false,
                                          data: doc as any,
                                          published: true,
                                        });
                                      }}
                                    >
                                      <i className="fa fa-television me-2"></i>
                                      Preview Published
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setShowPreview({
                                          show: true,
                                          print: false,
                                          data: doc as any,
                                          published: false,
                                        });
                                      }}
                                    >
                                      <i className="fa fa-television me-2"></i>
                                      Preview Draft
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      disabled={!doc.publishedOn}
                                      onClick={() => {
                                        setShowPreview({
                                          show: true,
                                          print: true,
                                          data: doc as any,
                                          published: true,
                                        });
                                      }}
                                    >
                                      <i className="fa fa-print me-2"></i> Print
                                      Published
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setShowPreview({
                                          show: true,
                                          print: true,
                                          data: doc as any,
                                          published: false,
                                        });
                                      }}
                                    >
                                      <i className="fa fa-print me-2"></i> Print
                                      Draft
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </>
                            )}
                          {!!docsBeingDeleted.find(
                            (d) => doc.sqlId === d.sqlId
                          ) && (
                            <Button variant="default" type="button">
                              <Spinner
                                animation="border"
                                variant="danger"
                                size="sm"
                              ></Spinner>
                            </Button>
                          )}
                          {!!docsBeingPublished.find(
                            (d) => doc.sqlId === d.sqlId
                          ) && (
                            <Button variant="default" type="button">
                              <Spinner
                                animation="border"
                                variant="success"
                                size="sm"
                              ></Spinner>
                            </Button>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>

              <div className="p-3 pt-0">
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="documents"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          )}
        </div>

        {/* <div className="mt-4">
          <Button
            variant="primary"
            type="button"
            onClick={() => {
              setShowDialog({ id: undefined, show: true, duplicate: false });
            }}
          >
            <span>
              <i className="fa fa-plus"></i> Add Document
            </span>
          </Button>
        </div> */}
      </div>
    </>
  );
}

export default DocumentManagerDashboard;
