import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Button, ModalBody, ModalFooter } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../../components/common/CommonSpinner";
import FormModal from "../../components/common/FormModal";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import userStore from "../../stores/UserStore";
import UserRolesDisplayComponent from "../common/UserRolesDisplayComponent";

function AddUserDialog(props: { onClose: (refresh?: boolean) => void }) {
  const navigate = useNavigate();
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });
  const grid = useFetchHelper(async () => {
    return SysServices.http.user.findUserInActiveDirectory({
      searchString: search.used,
    });
  }, "Users");

  useEffect(() => {
    document.getElementById("searchUserFromAAD")?.focus();

    if (search.used.trim() !== "") {
      grid.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.used]);

  return (
    <div>
      <FormModal
        title="Add User from Active Directory"
        isOpen={true}
        close={() => {
          props.onClose();
        }}
        size="lg"
        customBodyFooter={true}
      >
        <ModalBody>
          <div className="bg-white">
            {grid.status === FetchStatus.InProgress && (
              <div className="p-3">
                <CommonSpinner></CommonSpinner>
              </div>
            )}
            <div>
              {grid.status !== FetchStatus.InProgress && (
                <div className="p-1 flex flex-wrap gap-10">
                  <div className="flex-0" style={{ maxWidth: "100%" }}>
                    <div className="input-group search-box">
                      <input
                        id="searchUserFromAAD"
                        className="form-control"
                        type="text"
                        placeholder="Search"
                        value={search.typed}
                        onChange={(e) => {
                          setSearch((data) => {
                            return {
                              ...data,
                              typed: e.target.value,
                            };
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            e.stopPropagation();
                            setSearch((data) => {
                              if (data.used === data.typed) {
                                grid.getData();
                                return data;
                              }
                              return {
                                ...data,
                                used: data.typed,
                              };
                            });
                          }
                        }}
                      ></input>
                      <div className="input-group-append">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={(e) => {
                            setSearch((data) => {
                              if (data.used === data.typed) {
                                grid.getData();
                                return data;
                              }
                              return {
                                ...data,
                                used: data.typed,
                              };
                            });
                          }}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={(e) => {
                            setSearch((data) => {
                              return { typed: "", used: "" };
                            });
                          }}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {grid.status === FetchStatus.Complete && (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name / Email / Roles / Last Modified</th>
                    </tr>
                  </thead>
                  <tbody>
                    {grid.data?.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <div>
                            <strong>
                              {item.firstName} {item.lastName}
                            </strong>
                          </div>
                          <a
                            href={`mailto:${item.email}`}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              window.open(`mailto:${item.email}`);
                            }}
                          >
                            {item.email || "No Email"}
                          </a>
                          {!!item.dateUpdated && (
                            <>
                              <div>
                                Roles:{" "}
                                <UserRolesDisplayComponent
                                  roles={item.userRoleDto}
                                ></UserRolesDisplayComponent>
                              </div>
                              <div className="flex flex-center gap-10">
                                <div>
                                  {item.isActive && (
                                    <span className="chip-green">Active</span>
                                  )}
                                  {!item.isActive && (
                                    <span className="chip-red">Inactive</span>
                                  )}
                                </div>
                                <small>
                                  {!item.dateUpdated ||
                                  dayjs(item.dateUpdated).format(
                                    "YYYY-MM-DD"
                                  ) === "1-01-01"
                                    ? ""
                                    : commonService.toLocalDate(
                                        item.dateUpdated,
                                        "full"
                                      )}
                                </small>
                              </div>
                            </>
                          )}
                        </td>
                        <td>
                          <Button
                            size="sm"
                            className="no-wrap"
                            variant={
                              !item.dateUpdated ? "primary" : "outline-primary"
                            }
                            onClick={(e) => {
                              userStore.setUserToEdit({ ...item });
                              navigate(`/users/${item.id}`);
                            }}
                          >
                            {!item.dateUpdated ? (
                              <>
                                <i className="fa fa-plus"></i> Add
                              </>
                            ) : (
                              <>
                                <i className="fa fa-pencil"></i> Edit
                              </>
                            )}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={() => {
              props.onClose();
            }}
            variant="secondary"
            size="sm"
          >
            Close
          </Button>
        </ModalFooter>
      </FormModal>
    </div>
  );
}

export default AddUserDialog;
