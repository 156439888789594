import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import toastStore from "../stores/ToastStore";
import CustomerLinkComponent from "./common/CustomerLinkComponent";

export const TopCustomerDatePeriodEnum = [
  {
    label: "This Month",
    value: SysModels.DatePeriodEnum.ThisMonth,
  },
  {
    label: "This Week",
    value: SysModels.DatePeriodEnum.ThisWeek,
  },
  {
    label: "Today",
    value: SysModels.DatePeriodEnum.Today,
  },
];

function TopCustomers(props: any) {
  const grid = useFetchHelper(
    async () =>
      SysServices.http.customer.listTop(paging.page, paging.pageSize, filter),
    "Top Customers"
  );

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    grid.getData();
  }, [calledOnce, grid]);

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [filter, setFilter] = useState(SysModels.DatePeriodEnum.ThisMonth);

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, filter]);

  const [isSwitching, setIsSwitching] = useState(false);
  const [selectedCustomer, setSelectedCustomer] =
    useState<SysModels.ITopCustomerOutputDto>();
  useEffect(() => {
    if (selectedCustomer) {
      setIsSwitching(true);
      SysServices.http.customer
        .get(selectedCustomer?.erpCustomerNumber)
        .then((data) => {
          systemStore.setCurrentCustomer({
            name: selectedCustomer.customerName,
            number: selectedCustomer.erpCustomerNumber,
            id: selectedCustomer.customerSqlId,
            data: data,
          });
          setIsSwitching(false);
        })
        .catch((error) => {
          toastStore.showError("Failed Getting Customer", error);
          setIsSwitching(false);
        });
    }
  }, [selectedCustomer]);

  return (
    <>
      {isSwitching && (
        <CommonSpinner
          overlay={true}
          message={`Switching to Customer: ${selectedCustomer?.customerName}`}
        ></CommonSpinner>
      )}
      <div className="default-page-layout">
        <h4 className="hide-on-print">
          Top Customers For{" "}
          {TopCustomerDatePeriodEnum.find((o) => o.value === filter)?.label ||
            "This Week"}
        </h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div>
              <div className="p-3 flex gap-10">
                {/* <div className="flex-1"></div> */}
                <div className="flex-1">
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-primary">
                      {TopCustomerDatePeriodEnum.find((o) => o.value === filter)
                        ?.label || "This Week"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="left">
                      {TopCustomerDatePeriodEnum.map((opt) => (
                        <Dropdown.Item
                          key={opt.value}
                          onClick={() => {
                            setFilter(opt.value);
                          }}
                          active={opt.value === filter}
                        >
                          <span>{opt.label}</span>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Customer Name</th>
                    <th className="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {grid.data?.topCustomerOutputDtos?.map((customer) => (
                    <tr
                      key={customer.customerSqlId}
                      onClick={(e) => {
                        setSelectedCustomer(customer);
                      }}
                    >
                      <td className="pointer">
                        <CustomerLinkComponent
                          name={customer.customerName}
                          number={customer.erpCustomerNumber}
                        ></CustomerLinkComponent>
                      </td>
                      <td className="text-right">
                        {commonService.toMoney(customer.amount)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="p-3 pt-0">
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="entries"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TopCustomers;
