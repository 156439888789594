import React, { useEffect } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import { useParams } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import PurchaseOrders from "./PurchaseOrders";
import commonService from "../services/CommonService";

function VendorDetailPage(props: any) {
  const { id } = useParams();
  const detail = useFetchHelper(
    async () => SysServices.http.vendor.get(id as any),
    "Vendor Detail"
  );

  useEffect(() => {
    detail.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="default-page-layout">
      <h4 className="hide-on-print">Vendor Detail</h4>

      <div className="alert alert-sm alert-light">
        {detail.status === FetchStatus.InProgress && (
          <div className="p-3">
            <CommonSpinner></CommonSpinner>
          </div>
        )}
        {detail.data && detail.status === FetchStatus.Complete && (
          <div>
            <h5 className="mb-1">
              {detail.data.vendorName} <small>({detail.data.vendorId})</small>
            </h5>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th style={{ width: "100px" }}></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Address: </td>
                  <td>
                    {[
                      detail.data.address1,
                      detail.data.address2,
                      detail.data.address3,
                      detail.data.city,
                      detail.data.state,
                      detail.data.postalCode,
                    ]
                      .filter((x) => !commonService.isNullOrEmpty(x))
                      .join(", ")}
                  </td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>
                    {detail.data.email && (
                      <a href={`mailto:${detail.data.email}`}>
                        {detail.data.email}
                      </a>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Phone:</td>
                  <td>
                    {detail.data.telephone && (
                      <a href={`tel:${detail.data.telephone}`}>
                        {detail.data.telephone}
                      </a>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Fax:</td>
                  <td>{detail.data.fax}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>

      <h4 className="pt-2">Purchase Orders</h4>
      <div className="bg-white">
        <div>{id && <PurchaseOrders vendorId={id}></PurchaseOrders>}</div>
      </div>
    </div>
  );
}

export default VendorDetailPage;
