import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import TableRow from "../components/common/TableRow";
import SysModels from "../models";
import OrderDetailDialog from "../pages/orders/OrderDetailDialog";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import CustomerLinkComponent from "./common/CustomerLinkComponent";

function SalesRepSalesOrder(props: any) {
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const grid = useFetchHelper(async () => {
    return SysServices.http.sales.listOrders(paging.page, paging.pageSize, {
      salesOrderInvoiceTime: filter,
      orderBy: SysModels.OrderByEnum.Descending,
      search: search.used,
    });
  }, "Orders");

  const timeEnums = useFetchHelper(
    async () =>
      SysServices.http.genericEnumLookup.get("SalesOrderInvoiceTimeEnum"),
    "Invoice Time"
  );

  const pageOptions = [10, 25, 50, 100];
  const [selectedOrder, setSelectedOrder] =
    useState<SysModels.ISalesOrderInvoiceGridItemDto>();

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
  }, []);

  const [filter, setFilter] = useState(
    SysModels.SalesOrderInvoiceTimeEnum.ThisMonth
  );
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  useEffect(() => {
    if (grid.status !== FetchStatus.InProgress) {
      grid.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, filter]);

  useEffect(() => {
    timeEnums.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {selectedOrder && (
        <OrderDetailDialog
          order={selectedOrder as any}
          onClose={() => setSelectedOrder(undefined)}
          salesRepOrder={{
            orderId: selectedOrder.sqlId,
            customerId: selectedOrder.customerNumber,
          }}
        ></OrderDetailDialog>
      )}

      <div className="default-page-layout">
        <h4>Sales Orders</h4>
      </div>
      {grid.status === FetchStatus.InProgress && (
        <div>
          <CommonSpinner message="Getting Orders"></CommonSpinner>
        </div>
      )}
      {grid.status === FetchStatus.Failed && (
        <div className="bg-white p-3 text-danger">
          <i className="fa fa-warning"></i> Failed getting orders.
        </div>
      )}
      {grid.status === FetchStatus.Complete && (
        <div className="bg-white">
          <div className="p-3 flex flex-wrap gap-10">
            <div className="flex-0 w-100">
              <div className="input-group search-box">
                <input
                  className="form-control form-control-sm"
                  type="text"
                  placeholder="Search"
                  value={search.typed}
                  onChange={(e) => {
                    setSearch((data) => {
                      return {
                        ...data,
                        typed: e.target.value,
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          grid.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                    }
                  }}
                ></input>
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          grid.getData();
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={(e) => {
                      setSearch((data) => {
                        return { typed: "", used: "" };
                      });
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <Dropdown>
                <Dropdown.Toggle variant="outline-primary">
                  {timeEnums.status === FetchStatus.Complete ? (
                    <>
                      {(timeEnums.data || []).find((o) => o.value === filter)
                        ?.label || "This Month"}
                    </>
                  ) : (
                    <>Loading...</>
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu align="right">
                  {(timeEnums.data || [])
                    .sort(commonService.sortByNumericProperty("value"))
                    .map((opt) => (
                      <Dropdown.Item
                        key={opt.value}
                        onClick={() => {
                          setFilter(opt.value);
                        }}
                        active={opt.value === filter}
                      >
                        <span>{opt.label}</span>
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="overflow-auto">
            <table className="table w-100 bg-white my-orders-table">
              <thead>
                <tr>
                  {isSmallScreen && (
                    <>
                      <th></th>
                    </>
                  )}
                  {!isSmallScreen && (
                    <>
                      <th>Customer</th>
                      <th>MWF Order No.</th>
                      <th>Delivery On</th>
                      <th className="text-right">Total $</th>
                      <th>BG Order No.</th>
                      <th className="text-center"></th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {grid.data?.salesOrderInvoiceGridItemDtos &&
                  grid.data.salesOrderInvoiceGridItemDtos.map((item, i) => (
                    <tr key={`${i}-${item.webOrderNumber}`}>
                      {isSmallScreen && (
                        <td>
                          <table className="table-label-desc">
                            <tbody>
                              <TableRow label="Customer:">
                                <CustomerLinkComponent
                                  name={item.customerName}
                                  number={item.customerNumber}
                                ></CustomerLinkComponent>
                              </TableRow>
                              <TableRow label="MWF Order ID:">
                                {item.tranId}
                              </TableRow>
                              <TableRow label="Delivery On:">
                                {commonService.toLocalDate(item.tranDate)}
                              </TableRow>
                              <TableRow label="Total $:">
                                {commonService.toMoney(item.tranAmount)}
                              </TableRow>
                              <TableRow label="BG Order No.:">
                                {item.webOrderNumber}
                              </TableRow>
                            </tbody>
                          </table>
                        </td>
                      )}
                      {!isSmallScreen && (
                        <>
                          <td>
                            <CustomerLinkComponent
                              name={item.customerName}
                              number={item.customerNumber}
                            ></CustomerLinkComponent>
                          </td>
                          <td>{item.tranId}</td>
                          <td>{commonService.toLocalDate(item.tranDate)}</td>
                          <td className="text-right">
                            {commonService.toMoney(item.tranAmount)}
                          </td>
                          <td>{item.webOrderNumber}</td>
                          <td className="text-center">
                            <div>
                              <i
                                className="fa fa-eye pointer text-primary"
                                onClick={(e) => {
                                  setSelectedOrder(item);
                                }}
                              ></i>
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>

            <div className="p-3 pt-0">
              <Pagination
                length={grid.data?.totalRecords || 0}
                page={paging.page}
                pageSize={paging.pageSize}
                pageChange={pageChange}
                showingOfWhatLabel="entries"
                sizes={pageOptions}
              ></Pagination>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SalesRepSalesOrder;
