import React, { useEffect, useState } from "react";
import CommonSpinner from "../../components/common/CommonSpinner";
import ConfirmDialog from "../../components/common/ConfirmDialog";
import FormModal from "../../components/common/FormModal";
import SwitchButton from "../../components/common/SwitchButton";
import SysModels from "../../models";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import toastStore from "../../stores/ToastStore";

function ReportDialog(props: { id?: any; onClose: (done?: boolean) => void }) {
  const [model, setModel] = useState({
    isActive: true,
    reportRoleDto: {},
  } as SysModels.IReportMaintenanceOutputDto);

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const focusTitle = () => {
    setTimeout(() => {
      document.getElementById("reportTitle")?.focus();
    }, 200);
  };

  useEffect(() => {
    if (props.id) {
      SysServices.http.report
        .get(props.id)
        .then((data) => {
          setModel(data);
          setLoading(false);
          focusTitle();
        })
        .catch((error) => {
          toastStore.showError("Failed Getting Report", error);
          props.onClose();
        });
    } else {
      setLoading(false);
      focusTitle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canSave = () => {
    return (
      !commonService.isNullOrWhitespace(model.title) &&
      !commonService.isNullOrWhitespace(model.description) &&
      !commonService.isNullOrWhitespace(model.pageToDisplay)
    );
  };

  const [roleList, setRoleList] = useState(
    [] as {
      label: string;
      value: number;
      checked?: boolean;
    }[]
  );

  const checkSelected = (role: SysModels.UserRolesEnum) => {
    return roleList.find((r) => r.value === role)?.checked || false;
  };

  const saveReport = async () => {
    setSaving(true);
    const data = {
      ...model,
      reportRoleDto: {
        isAdmin: checkSelected(SysModels.UserRolesEnum.Admin),
        isApplicationAdmin: checkSelected(
          SysModels.UserRolesEnum.ApplicationAdmin
        ),
        isCustomerService: checkSelected(
          SysModels.UserRolesEnum.CustomerService
        ),
        isExecutive: checkSelected(SysModels.UserRolesEnum.Executive),
        isOeManager: checkSelected(SysModels.UserRolesEnum.OeManager),
        isSales: checkSelected(SysModels.UserRolesEnum.Sales),
        isSalesManager: checkSelected(SysModels.UserRolesEnum.SalesManager),
        isDocumentManager: checkSelected(
          SysModels.UserRolesEnum.DocumentManager
        ),
        isPurchasing: checkSelected(SysModels.UserRolesEnum.Purchasing),
        isLoginAsSalesRep: checkSelected(
          SysModels.UserRolesEnum.LoginAsSalesRep
        ),
        none: checkSelected(-1 as any),
      },
    };
    const req = props.id
      ? SysServices.http.report.update(model.id, data)
      : SysServices.http.report.create(data);
    await req
      .then((data) => {
        toastStore.showToast("Report saved.", "success");
        props.onClose(true);
      })
      .catch((error) => {
        toastStore.showError("Failed Saving Report", error);
        setSaving(false);
      });
  };

  const [showDel, setShowDel] = useState(false);
  const deleteReport = async () => {
    setDeleting(true);
    await SysServices.http.report
      .delete(model.id)
      .then((data) => {
        toastStore.showToast("Report deleted.", "success");
        props.onClose(true);
      })
      .catch((error) => {
        toastStore.showError("Failed Deleting Report", error);
        setDeleting(false);
      });
  };

  const roles = useFetchHelper(
    async () => SysServices.http.genericEnumLookup.get("UserRolesEnum"),
    "Roles"
  );

  const isRoleSelected = (role: SysModels.UserRolesEnum) => {
    if (!model?.reportRoleDto) {
      return false;
    }
    if (role === SysModels.UserRolesEnum.Admin) {
      return model?.reportRoleDto?.isAdmin || false;
    }
    if (role === SysModels.UserRolesEnum.ApplicationAdmin) {
      return model?.reportRoleDto?.isApplicationAdmin || false;
    }
    if (role === SysModels.UserRolesEnum.CustomerService) {
      return model?.reportRoleDto?.isCustomerService || false;
    }
    if (role === SysModels.UserRolesEnum.Executive) {
      return model?.reportRoleDto?.isExecutive || false;
    }
    if (role === SysModels.UserRolesEnum.OeManager) {
      return model?.reportRoleDto?.isOeManager || false;
    }
    if (role === SysModels.UserRolesEnum.Sales) {
      return model?.reportRoleDto?.isSales || false;
    }
    if (role === SysModels.UserRolesEnum.SalesManager) {
      return model?.reportRoleDto?.isSalesManager || false;
    }
    if (role === SysModels.UserRolesEnum.DocumentManager) {
      return model?.reportRoleDto?.isDocumentManager || false;
    }
    if (role === SysModels.UserRolesEnum.Purchasing) {
      return model?.reportRoleDto?.isPurchasing || false;
    }
    if (role === SysModels.UserRolesEnum.LoginAsSalesRep) {
      return model?.reportRoleDto?.isLoginAsSalesRep || false;
    }
    return true;
  };

  useEffect(() => {
    if (roles.status === FetchStatus.Complete) {
      const list = [
        ...(roles.data || []),
        {
          value: -1,
          label: "None",
        },
      ];
      list.forEach((role) => {
        (role as any).checked = isRoleSelected(role.value);
      });
      list.forEach((role) => {
        if (role.value === -1) {
          (role as any).checked = !list.find(
            (r) => r.value !== -1 && (r as any).checked
          );
        }
      });
      setRoleList(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles.status, model.reportRoleDto]);

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (!calledOnce) {
      roles.getData();
      setCalledOnce(true);
    }
  }, [calledOnce, roles]);

  return (
    <>
      {showDel && (
        <ConfirmDialog
          title="Delete Report"
          message="Do you really want to delete this report?"
          buttons="yesno"
          done={(rtn) => {
            if (rtn === "yes") {
              deleteReport();
            }
            setShowDel(false);
          }}
          show={true}
        ></ConfirmDialog>
      )}
      <FormModal
        title={props.id ? "Edit Report" : "Add Report"}
        isOpen={true}
        close={() => {
          props.onClose();
        }}
        submitButtonLabel={saving ? "Saving..." : "Save"}
        deleteButtonLabel={deleting ? "Deleting..." : "Delete"}
        disableSubmit={!canSave() || saving || deleting || loading}
        disabledDeleteButton={saving || deleting || loading}
        showDeleteButton={!!props.id}
        submit={() => {
          saveReport();
        }}
        deleteAction={() => {
          setShowDel(true);
        }}
        moveBehind={showDel}
      >
        {loading && <CommonSpinner message="Loading..."></CommonSpinner>}
        {(saving || deleting) && <CommonSpinner overlay={true}></CommonSpinner>}
        {!loading && (
          <>
            <div className="row">
              <div className="col-sm-12 col-md-7">
                <div>
                  <label className="mb-2">Title</label>
                  <input
                    id="reportTitle"
                    className="form-control"
                    placeholder="Title"
                    value={model.title || ""}
                    onChange={(e) => {
                      setModel((p) => {
                        if (!p) return p;
                        return {
                          ...p,
                          title: e.target.value,
                        };
                      });
                    }}
                    maxLength={100}
                  ></input>
                </div>
                <div className="pt-3">
                  <label className="mb-2">Description</label>
                  <textarea
                    className="form-control"
                    placeholder="Description"
                    value={model.description || ""}
                    onChange={(e) => {
                      setModel((p) => {
                        if (!p) return p;
                        return {
                          ...p,
                          description: e.target.value,
                        };
                      });
                    }}
                    rows={3}
                  ></textarea>
                </div>
                <div className="pt-3">
                  <label className="mb-2">Page to Display</label>
                  <input
                    className="form-control"
                    placeholder="Page to Display"
                    value={model.pageToDisplay || ""}
                    onChange={(e) => {
                      setModel((p) => {
                        if (!p) return p;
                        return {
                          ...p,
                          pageToDisplay: e.target.value,
                        };
                      });
                    }}
                    maxLength={50}
                  ></input>
                </div>
                <div className="pt-3">
                  <SwitchButton
                    checkedLabel="Active"
                    uncheckedLabel="Inactive"
                    checked={model.isActive}
                    onChange={(data) => {
                      setModel((p) => {
                        if (!p) return p;
                        return {
                          ...p,
                          isActive: data,
                        };
                      });
                    }}
                  ></SwitchButton>
                </div>
              </div>
              <div className="col-sm-12 col-md-5">
                <div>
                  <label className="mb-2">Roles</label>
                  {calledOnce && model && (
                    <div>
                      <div className="role-list">
                        {roleList.map((role) => (
                          <div key={role.value} className="flex flex-center">
                            <input
                              className="me-2 pointer"
                              type="checkbox"
                              id={`roleId${role.value}`}
                              name="role"
                              value={role.label}
                              checked={role.checked}
                              onChange={(e) => {
                                setRoleList((prev) => {
                                  return prev.map((p) => {
                                    if (e.target.checked && role.value === -1) {
                                      if (p.value === role.value) {
                                        return { ...p, checked: true };
                                      } else {
                                        return { ...p, checked: false };
                                      }
                                    }

                                    if (e.target.checked && role.value !== -1) {
                                      if (p.value === -1) {
                                        return { ...p, checked: false };
                                      }
                                    }

                                    if (p.value !== role.value) {
                                      return p;
                                    }
                                    return {
                                      ...p,
                                      checked: e.target.checked,
                                    };
                                  });
                                });
                              }}
                            ></input>
                            <label
                              htmlFor={`roleId${role.value}`}
                              className="pointer"
                            >
                              {role.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </FormModal>
    </>
  );
}

export default ReportDialog;
