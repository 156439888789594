import SysModels from "../../models";
import fetcher from "../Fetcher";

class PortalMonitorService {
  getAllRules = async () => {
    const url = `/PortalMonitor/AllRules`;
    return fetcher.get<SysModels.IPortalMonitorRuleOutputDto[]>(url);
  };

  getRule = async (ruleRegimeId: number, systemActivityId: number) => {
    const url = `/PortalMonitor/Rule/${ruleRegimeId}/${systemActivityId}`;
    return fetcher.get<SysModels.IPortalMonitorRuleOutputDto>(url);
  };

  createRule = async (data: SysModels.IPortalMonitorRuleInsertDto) => {
    const url = `/PortalMonitor/CreateRule`;
    return fetcher.post<SysModels.IPortalMonitorRuleOutputDto>(url, data);
  };

  updateRule = async (
    ruleRegimeId: number,
    systemActivityId: number,
    data: SysModels.IPortalMonitorRuleUpdateDto
  ) => {
    const url = `/PortalMonitor/UpdateRule/${ruleRegimeId}/${systemActivityId}`;
    return fetcher.post<SysModels.IPortalMonitorRuleOutputDto>(url, data);
  };

  deleteRule = async (ruleRegimeId: number, systemActivityId: number) => {
    const url = `/PortalMonitor/DeleteRule/${ruleRegimeId}/${systemActivityId}`;
    return fetcher.delete<SysModels.IPortalMonitorRuleOutputDto>(url);
  };

  getAllSystemBatchActivities = async () => {
    const url = `/PortalMonitor/AllSystemBatchActivities`;
    return fetcher.get<SysModels.ISystemBatchActivityOutputDto[]>(url);
  };

  listErrors = async (page: number, pageSize: number) => {
    const url = `/PortalMonitor/ListErrors/${page}/${pageSize}`;
    return fetcher.get<SysModels.IErrorTableGridOutputDto>(url);
  };

  getError = async (partitionKey: string, rowKey: string) => {
    const url = `/PortalMonitor/Error/${partitionKey}/${rowKey}`;
    return fetcher.get<SysModels.IErrorTableOutputDto>(url);
  };

  deleteError = async (partitionKey: string, rowKey: string) => {
    const url = `/PortalMonitor/DeleteError/${partitionKey}/${rowKey}`;
    return fetcher.delete<SysModels.IErrorTableOutputDto>(url);
  };

  deleteManyErrors = async (data: SysModels.IErrorDeleteManyInputDto) => {
    const url = `/PortalMonitor/DeleteManyErrors`;
    return fetcher.post<any>(url, data);
  };

  deleteAllErrors = async () => {
    const url = `/PortalMonitor/DeleteAllErrors`;
    return fetcher.delete<any>(url);
  };
}

const portalMonitor = new PortalMonitorService();
export default portalMonitor;
