import { reaction } from "mobx";
import React, { useEffect, useMemo, useState } from "react";
import {
  Route,
  BrowserRouter,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";

import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { b2cPolicies } from "./AuthConfig";
import { EventType } from "@azure/msal-browser";

import Toast from "./components/toast/Toast";
import systemStore, {
  useActiveRole,
  useSalesAccountViewing,
  useSiteDown,
} from "./stores/SystemStore";

import "./App.scss";
import Layout from "./components/Layout";
import Login from "./pages/Login";
import LoggedOut from "./pages/LoggedOut";
import Dashboard from "./pages-employee/Dashboard";

import menuActions, { IMenuAction } from "./layout/MenuActions";
import commonService from "./services/CommonService";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  Title,
  LinearScale,
} from "chart.js";
import GlobalSearchResults from "./pages/GlobalSearchResults";
import GlobalSearchItemViewer from "./pages/GlobalSearchItemViewer";
import SysServices from "./services";
import SiteDownPage from "./pages/SiteDownPage";
import SysModels from "./models";
import packageInfo from "../package.json";
import NewVersion from "./components/NewVersion";
ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

function App({ instance }: { instance: IPublicClientApplication }) {
  useEffect(() => {
    window.addEventListener("resize", () => {
      systemStore.updateWindowSize();
    });
    systemStore.updateWindowSize();
    commonService.isEmployeeSite = true;
  }, []);

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted.
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  useEffect(() => {
    const callbackId = instance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (
          event.error &&
          event.error.errorMessage.indexOf("AADB2C90118") > -1
        ) {
          instance.loginPopup(b2cPolicies.authorities.forgotPassword as any);
          // if (event.interactionType === InteractionType.Redirect) {
          //   instance.loginRedirect(
          //     b2cPolicies.authorities.forgotPassword as any
          //   );
          // } else if (event.interactionType === InteractionType.Popup) {
          //   instance
          //     .loginPopup(b2cPolicies.authorities.forgotPassword as any)
          //     .catch((e) => {
          //       return;
          //     });
          // }
        }
      }

      // if (event.error) {
      //   systemStore.setLastMsalError(event.error);
      // }

      if (
        event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      ) {
        //event.payload.account.idTokenClaims.extension_website
        if (event?.payload) {
          /**
           * We need to reject id tokens that were not issued with the default sign-in policy.
           * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
           * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (
            event.payload.idTokenClaims["acr"] ===
            b2cPolicies.names.forgotPassword
          ) {
            window.alert(
              "Password has been reset successfully. \nPlease sign-in with your new password."
            );
            return instance.logout();
          } else if (
            event.payload.idTokenClaims["acr"] === b2cPolicies.names.editProfile
          ) {
            window.alert(
              "Profile has been edited successfully. \nPlease sign-in again."
            );
            return instance.logout();
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  //const [sideMenuReady, setSideMenuReady] = useState(systemStore.sideMenuReady);
  const [mwfRole, setMwfRole] = useState(systemStore.extension_MWFRole);
  const [currentCustomer, setCurrentCustomer] = useState(
    systemStore.currentCustomer
  );
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.extension_MWFRole,
      (n, p, r) => {
        setMwfRole(n);
      }
    );
    const disposer2 = reaction(
      () => systemStore.currentCustomer,
      (n, p, r) => {
        setCurrentCustomer(n);
      }
    );
    const disposer3 = reaction(
      () => systemStore.tmoSystemMessage,
      (val, prevVal, r) => {
        setTmoSystemMessage(val);
      }
    );
    // const disposer4 = reaction(
    //   () => systemStore.sideMenuReady,
    //   (val, prevVal, r) => {
    //     setSideMenuReady(val);
    //   }
    // );
    return () => {
      disposer();
      disposer2();
      disposer3();
      //disposer4();
    };
  }, []);

  const [ready, setReady] = useState(false);
  const siteDown = useSiteDown();
  const [tmoSystemMessage, setTmoSystemMessage] = useState(0);
  const getSiteDown = async () => {
    await SysServices.http.system
      .getSiteDown()
      .then((data) => {
        systemStore.setSiteDown(data);
        setReady(true);
        if (data.isVisible) {
          systemStore.setActiveRole(0 as any);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSiteDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmoSystemMessage]);

  const [version, setVersion] = useState<SysModels.ISiteVersionOutputDto>();
  const getSiteVersion = async () => {
    if (showVersionDialog) {
      //do nothing...
      return;
    }
    await SysServices.http.system
      .getSiteVersion()
      .then((data) => {
        //console.log(data);
        setVersion(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const showVersionDialog = useMemo(() => {
    if (version) {
      if (version.versionNumber === packageInfo.version) {
        return false;
      }
      return version.forceReload || false;
    }
    return undefined;
  }, [version]);

  useEffect(() => {
    let tmi: any;
    if (ready) {
      getSiteVersion();
      tmi = setInterval(() => {
        getSiteVersion();
      }, 15000);
    }
    return () => {
      clearInterval(tmi);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, siteDown]);

  const salesAccountViewing = useSalesAccountViewing();

  return (
    <>
      {showVersionDialog && (
        <NewVersion version={version?.versionNumber || ""}></NewVersion>
      )}
      <Toast />
      <BrowserRouter>
        <Routes>
          {ready && showVersionDialog === false && (
            <>
              {siteDown?.isVisible ? (
                <>
                  <Route path="*">
                    <Navigate to="/sitedown" />
                  </Route>
                  <Route path="/sitedown" element={<SiteDownPage />}></Route>
                </>
              ) : (
                <Route
                  path="*"
                  element={
                    <MsalProvider instance={instance}>
                      <UnauthenticatedTemplate>
                        <Routes>
                          <Route
                            path="/sitedown"
                            element={<Navigate to="/" />}
                          ></Route>
                          <Route
                            path="/logout"
                            element={<LoggedOut></LoggedOut>}
                          ></Route>
                          <Route path="*" element={<Login></Login>}></Route>
                        </Routes>
                      </UnauthenticatedTemplate>
                      <AuthenticatedTemplate>
                        <Layout>
                          <GlobalSearchItemViewer></GlobalSearchItemViewer>
                          <Routes>
                            <Route
                              path="/sitedown"
                              element={<Navigate to="/" />}
                            ></Route>
                            <Route
                              path="/"
                              element={<Dashboard></Dashboard>}
                            ></Route>
                            <Route
                              path="/dashboard"
                              element={<Navigate to="/" />}
                            ></Route>
                            <Route
                              path="/search/:query?/:types?"
                              element={<GlobalSearchResults />}
                            ></Route>
                            {menuActions.actions.map((ma) => (
                              <Route
                                path={ma.url}
                                key={`${ma.role}-${ma.url}-${
                                  salesAccountViewing?.email || "x"
                                }`}
                                element={
                                  <MenuActionComponent
                                    ma={ma}
                                    mwfRole={mwfRole}
                                    currentCustomer={currentCustomer}
                                  />
                                }
                              />
                            ))}
                            <Route
                              path="*"
                              element={<div>Page Not Found</div>}
                            ></Route>
                          </Routes>
                        </Layout>
                      </AuthenticatedTemplate>
                    </MsalProvider>
                  }
                ></Route>
              )}
            </>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

const AccessDenied = ({
  message,
  goToDefaultDashboard,
}: {
  message: string;
  goToDefaultDashboard: () => void;
}) => {
  useEffect(() => {
    goToDefaultDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <strong>Loading...</strong>
      {/* <div className="text-danger">
        <h4>
          <i className="fa fa-warning"></i> Access Denied
        </h4>
      </div>
      {message && <div>{message}</div>} */}
    </>
  );
};

const MenuActionComponent = ({
  ma,
  mwfRole,
  currentCustomer,
}: {
  ma: IMenuAction;
  mwfRole: number;
  currentCustomer: any;
}) => {
  let actionRole = ma.role;
  if (
    systemStore.salesAccountViewing &&
    actionRole & SysModels.UserRolesEnum.Sales &&
    mwfRole & SysModels.UserRolesEnum.LoginAsSalesRep
  ) {
    actionRole = actionRole + SysModels.UserRolesEnum.LoginAsSalesRep;
  }

  let hasAccess =
    !!(mwfRole & actionRole) &&
    (!ma.canAccess || (ma.canAccess && ma.canAccess()));
  let msg = "";
  if (hasAccess && !!ma.asCustomer) {
    hasAccess = !!currentCustomer;
    msg = "Please select a customer to access this page.";
  }

  const navigate = useNavigate();
  const activeRole = useActiveRole();
  const [ready, setReady] = useState(false);

  const goToDefaultDashboard = () => {
    const dashboards = menuActions.actions.filter(
      (db) => db.dashboard && db.role & mwfRole
    );
    const activeDbrd = dashboards.find(
      (db) => db.role === systemStore.activeRole
    );
    if (activeDbrd) {
      navigate(activeDbrd.url);
    } else {
      navigate(dashboards[0] ? dashboards[0].url : "/");
    }
  };

  useEffect(() => {
    if (!hasAccess) {
      //This checks if the current route is for Customer Portal but no current customer selected
      if (ma.asCustomer && !systemStore.currentCustomer) {
        setTimeout(goToDefaultDashboard, 1000);
        //return <strong>Loading...</strong>;
      }

      //This checks if page is for Sales Rep but user only has LoginAsSalesRep role
      // if (
      //   ma.role & SysModels.UserRolesEnum.Sales &&
      //   mwfRole & SysModels.UserRolesEnum.LoginAsSalesRep
      // ) {
      //   navigate(
      //     menuActions.getDashboardUrl(SysModels.UserRolesEnum.LoginAsSalesRep)
      //   );
      //   return <strong>Loading...</strong>;
      // }
    }

    setReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      activeRole === SysModels.UserRolesEnum.LoginAsSalesRep &&
      hasAccess &&
      !systemStore.salesAccountViewing &&
      ma.role & SysModels.UserRolesEnum.Sales &&
      !(ma.role & SysModels.UserRolesEnum.LoginAsSalesRep) &&
      mwfRole & SysModels.UserRolesEnum.LoginAsSalesRep
    ) {
      navigate(
        menuActions.getDashboardUrl(SysModels.UserRolesEnum.LoginAsSalesRep)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRole]);

  return ready ? (
    <>
      {hasAccess ? (
        ma.component ? (
          React.createElement(ma.component, {})
        ) : (
          <div>
            <strong>{ma.label}</strong> Page Not Implemented
          </div>
        )
      ) : (
        activeRole > 0 && (
          <AccessDenied
            message={msg}
            goToDefaultDashboard={goToDefaultDashboard}
          />
        )
      )}
    </>
  ) : (
    <strong>Loading...</strong>
  );
};

export default App;
