import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../components/common/CommonSpinner";
import ConfirmDialog from "../components/common/ConfirmDialog";
import DateRangePicker, {
  DatePeriod,
} from "../components/common/DateRangePicker";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import toastStore from "../stores/ToastStore";

function CustomerDashboardList(props: any) {
  const navigate = useNavigate();

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });
  const [period, setPeriod] = useState<{
    from: string | null;
    to: string | null;
  }>({ from: null, to: null });

  const [filter, setFilter] =
    useState<SysModels.IDashboardAnnouncementSearchDto>({
      search: "",
      dashboardAnnouncementActiveSearchEnum:
        SysModels.DashboardAnnouncementActiveSearchEnum.Active,
      orderByEnum: SysModels.OrderByEnum.Ascending,
      showFromDate: period.from as any,
      showToDate: period.to as any,
    });

  const grid = useFetchHelper(async () => {
    return SysServices.http.dashboardAnnouncement.list(
      paging.page,
      paging.pageSize,
      {
        ...filter,
        showFromDate: period.from as any,
        showToDate: period.to as any,
        search: search.used,
      }
    );
  }, "Dashboard Announcements");

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, filter, period]);

  const statusFilter = [
    {
      label: "All",
      value: SysModels.DashboardAnnouncementActiveSearchEnum.All,
    },
    {
      label: "Active",
      value: SysModels.DashboardAnnouncementActiveSearchEnum.Active,
    },
    {
      label: "Inactive",
      value: SysModels.DashboardAnnouncementActiveSearchEnum.Inactive,
    },
  ];

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [itemToDel, setItemToDel] = useState<string>();
  const [deleting, setDeleting] = useState(false);
  const deleteItem = async (itemId: any) => {
    if (deleting) return;
    setDeleting(true);
    await SysServices.http.dashboardAnnouncement
      .delete(itemId)
      .then((data) => {
        toastStore.showToast("Dashboard Announcement Deleted.", "success");
        grid.getData();
      })
      .catch((error) => {
        toastStore.showError("Failed Deleting Dashboard Announcement", error);
        setDeleting(false);
      });
  };

  return (
    <>
      {!!itemToDel && (
        <ConfirmDialog
          show={true}
          title="Confirm Delete"
          message="Do you really want to delete this Dashboard Announcement?"
          done={(rtn) => {
            if (rtn === "yes") {
              deleteItem(itemToDel);
            }
            setItemToDel(undefined);
          }}
          buttons="yesno"
        ></ConfirmDialog>
      )}
      <div className="default-page-layout">
        <h4 className="hide-on-print">Manage Customer Dashboard</h4>
        <div className="bg-white">
          <div>
            <div className="p-3 flex flex-wrap gap-10">
              <div className="flex-0" style={{ maxWidth: "100%" }}>
                <div className="input-group search-box">
                  <input
                    autoFocus={true}
                    className="form-control"
                    type="text"
                    placeholder="Search"
                    value={search.typed}
                    onChange={(e) => {
                      setSearch((data) => {
                        return {
                          ...data,
                          typed: e.target.value,
                        };
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                        pageChange(1, paging.pageSize);
                        setSearch((data) => {
                          if (data.used === data.typed) {
                            return data;
                          }
                          return {
                            ...data,
                            used: data.typed,
                          };
                        });
                      }
                    }}
                  ></input>
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={(e) => {
                        pageChange(1, paging.pageSize);
                        setSearch((data) => {
                          if (data.used === data.typed) {
                            return data;
                          }
                          return {
                            ...data,
                            used: data.typed,
                          };
                        });
                      }}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={(e) => {
                        pageChange(1, paging.pageSize);
                        setSearch((data) => {
                          return { typed: "", used: "" };
                        });
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex-0">
                <Dropdown>
                  <Dropdown.Toggle variant="outline-primary">
                    <i className="fa fa-filter me-2"></i>{" "}
                    {
                      statusFilter.find(
                        (opt) =>
                          opt.value ===
                          filter.dashboardAnnouncementActiveSearchEnum
                      )?.label
                    }
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="left">
                    {statusFilter.map((opt) => (
                      <Dropdown.Item
                        key={opt.value}
                        onClick={(e) => {
                          setFilter((prev) => {
                            return {
                              ...prev,
                              dashboardAnnouncementActiveSearchEnum: opt.value,
                            };
                          });
                        }}
                        active={
                          opt.value ===
                          filter.dashboardAnnouncementActiveSearchEnum
                        }
                      >
                        {opt.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div>
                <DateRangePicker
                  from={period.from}
                  to={period.to}
                  onChanged={(from, to) => {
                    setPeriod({ from: from, to: to });
                  }}
                  periods={[
                    DatePeriod.All,
                    DatePeriod.Today,
                    DatePeriod.Tomorrow,
                    DatePeriod.ThisWeek,
                    DatePeriod.NextWeek,
                    DatePeriod.ThisMonth,
                    DatePeriod.Yesterday,
                    DatePeriod.Custom,
                  ]}
                ></DateRangePicker>
              </div>
              <div className="flex-1">
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    navigate(`/managedashboard/new`);
                  }}
                >
                  <i className="zmdi zmdi-view-dashboard me-2"></i>Add
                </Button>
              </div>
            </div>
            {grid.status === FetchStatus.InProgress && (
              <div className="p-3">
                <CommonSpinner></CommonSpinner>
              </div>
            )}
            {grid.status === FetchStatus.Complete && (
              <>
                <table className="table table-hover pointer">
                  <thead>
                    {isSmallScreen && (
                      <tr>
                        <th>Title / Active Dates / Status</th>
                        <th></th>
                      </tr>
                    )}
                    {!isSmallScreen && (
                      <tr>
                        <th>Title</th>
                        <th>Active Dates</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {grid.data?.dashboardAnnouncementDisplayAdminDtos?.map(
                      (item) => (
                        <tr
                          key={item.sqlId}
                          className="break-word"
                          onClick={(e) => {
                            navigate(`/managedashboard/${item.sqlId}`);
                          }}
                        >
                          {isSmallScreen && (
                            <td>
                              <div>{item.title}</div>
                              <div>
                                {commonService.toLocalDate(
                                  item.showFromDate,
                                  "MMM DD, YYYY"
                                )}{" "}
                                -{" "}
                                {commonService.toLocalDate(
                                  item.showToDate,
                                  "MMM DD, YYYY"
                                )}
                              </div>
                              <div className="no-wrap">
                                {item.activeYn && (
                                  <span className="chip-green">Active</span>
                                )}
                                {!item.activeYn && (
                                  <span className="chip-red">Inactive</span>
                                )}
                              </div>
                            </td>
                          )}
                          {!isSmallScreen && (
                            <>
                              <td>{item.title}</td>
                              <td>
                                {commonService.toLocalDate(
                                  item.showFromDate,
                                  "MMM DD, YYYY"
                                )}{" "}
                                -{" "}
                                {commonService.toLocalDate(
                                  item.showToDate,
                                  "MMM DD, YYYY"
                                )}
                              </td>
                              <td>
                                <div className="no-wrap">
                                  {item.activeYn && (
                                    <span className="chip-green">Active</span>
                                  )}
                                  {!item.activeYn && (
                                    <span className="chip-red">Inactive</span>
                                  )}
                                </div>
                              </td>
                            </>
                          )}
                          <td className="text-right no-wrap">
                            <i className="fa fa-pencil text-primary pointer me-2"></i>
                            <i
                              className="fa fa-trash text-danger pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setItemToDel(item.sqlId);
                              }}
                            ></i>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <div className="p-3 pt-0">
                  <Pagination
                    length={grid.data?.totalRecords || 0}
                    page={paging.page}
                    pageSize={paging.pageSize}
                    pageChange={pageChange}
                    showingOfWhatLabel="users"
                    sizes={[10, 25, 50, 100]}
                  ></Pagination>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerDashboardList;
