import SysModels from "../../models";
import fetcher from "../Fetcher";

class ReportViewService {
  activeOnLineCustomers = async (data: SysModels.IReportViewDateRageDto) => {
    const url = `/ReportView/ActiveOnLineCustomers`;
    return fetcher.post<SysModels.IReportViewActiveOnlineCustomersDto[]>(
      url,
      data
    );
  };

  activeOnLineCustomersCsv = async (data: SysModels.IReportViewDateRageDto) => {
    const url = `/ReportView/ActiveOnLineCustomersCsv`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  inActiveOnLineCustomers = async (data: SysModels.IReportViewDateRageDto) => {
    const url = `/ReportView/InactiveOnLineCustomers`;
    return fetcher.post<SysModels.IReportViewInactiveOnlineCustomersDto[]>(
      url,
      data
    );
  };

  inactiveOnLineCustomersCsv = async (
    data: SysModels.IReportViewDateRageDto
  ) => {
    const url = `/ReportView/InactiveOnLineCustomersCsv`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  localSpendBySource = async (
    data: SysModels.IReportLocalSpendBySourceParameterDto
  ) => {
    const url = `/ReportView/LocalSpendBySource`;
    return fetcher.post<SysModels.IReportLocalSpendBySourceDto>(url, data);
  };

  localSpendBySourceCsv = async (
    data: SysModels.IReportLocalSpendBySourceParameterDto
  ) => {
    const url = `/ReportView/LocalSpendBySourceCsv`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  localSpendBySourceSummary = async (
    data: SysModels.IReportLocalSpendBySourceParameterDto
  ) => {
    const url = `/ReportView/LocalSpendBySourceSummary`;
    return fetcher.post<SysModels.IReportLocalSpendBySourceSummaryDto[]>(
      url,
      data
    );
  };

  localSpendBySourceSummaryCsv = async (
    data: SysModels.IReportLocalSpendBySourceParameterDto
  ) => {
    const url = `/ReportView/LocalSpendBySourceSummaryCsv`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  totalSpendBySource = async (
    data: SysModels.IReportLocalSpendBySourceParameterDto
  ) => {
    const url = `/ReportView/TotalSpendBySource`;
    return fetcher.post<SysModels.IReportLocalSpendBySourceDto>(url, data);
  };

  totalSpendBySourceCsv = async (
    data: SysModels.IReportLocalSpendBySourceParameterDto
  ) => {
    const url = `/ReportView/TotalSpendBySourceCsv`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  totalSpendBySourceSummary = async (
    data: SysModels.IReportLocalSpendBySourceParameterDto
  ) => {
    const url = `/ReportView/TotalSpendBySourceSummary`;
    return fetcher.post<SysModels.IReportLocalSpendBySourceSummaryDto[]>(
      url,
      data
    );
  };

  totalSpendBySourceSummaryCsv = async (
    data: SysModels.IReportLocalSpendBySourceParameterDto
  ) => {
    const url = `/ReportView/TotalSpendBySourceSummaryCsv`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  openOrdersCategories = async () => {
    const url = `/ReportView/OpenOrdersCategories`;
    return fetcher.get<SysModels.IReportOpenOrderCategoriesDto>(url);
  };

  openOrders = async (data: SysModels.IReportOpenOrdersParameterDto) => {
    const url = `/ReportView/OpenOrders`;
    return fetcher.post<SysModels.IReportOpenOrdersOutputDto[]>(url, data);
  };

  openOrdersCsv = async (data: SysModels.IReportOpenOrdersParameterDto) => {
    const url = `/ReportView/OpenOrdersCsv`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };
}

const reportView = new ReportViewService();
export default reportView;
