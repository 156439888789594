import { makeAutoObservable } from "mobx";
import SysModels from "../models";

class UserStore {
  private _userToEdit?: SysModels.IUserOutputDto;

  get userToEdit() {
    return this._userToEdit;
  }

  constructor() {
    makeAutoObservable(this);
  }

  setUserToEdit(user?: SysModels.IUserOutputDto) {
    this._userToEdit = user;
  }
}
const userStore = new UserStore();
export default userStore;
