import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import SysServices from "../../services";
import SysModels from "../../models";
import CommonSpinner from "../../components/common/CommonSpinner";
import commonService from "../../services/CommonService";
import { reaction } from "mobx";
import systemStore from "../../stores/SystemStore";
import PurchaseOrderDialog from "../PurchaseOrderDialog";

function LatestPurchaseOrdersWidget(props: any) {
  const navigate = useNavigate();
  const grid = useFetchHelper(
    () =>
      SysServices.http.purchaseOrder.list({
        page: 1,
        pageSize: 5,
        isSummary: true,
        orderBy: SysModels.OrderByEnum.Descending,
        dateRange: SysModels.PurchaseOrderSummaryDateRangeEnum.All,
        start: null as any,
        end: null as any,
        search: null as any,
        vendorId: null as any,
      }),
    "Purchase Orders"
  );

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tranId, setTranId] = useState<string>();

  return (
    <>
      {!!tranId && (
        <PurchaseOrderDialog
          tranId={tranId}
          onClose={() => setTranId(undefined)}
        ></PurchaseOrderDialog>
      )}
      <div className="hide-on-print">
        <div className="bg-white mb-4">
          <div className="p-3 bg-success txt-light">
            <h5 className="m-0 flex flex-center gap-10">
              <span className="flex-1">
                <span className="flex flex-center">
                  <em className="zmdi zmdi-receipt me-2"></em>
                  <span>Latest Purchase Orders</span>
                </span>
              </span>
              <div className="flex-0">
                <i
                  className="fa fa-refresh pointer"
                  onClick={(e) => {
                    if (grid.status !== FetchStatus.InProgress) {
                      grid.getData();
                    }
                  }}
                ></i>
              </div>
            </h5>
          </div>
          <div className="p-3 overflow-auto min-height-100">
            {grid.status === FetchStatus.InProgress && (
              <CommonSpinner></CommonSpinner>
            )}
            {grid.status === FetchStatus.Complete && (
              <div>
                <table className="table table-sm table-hover">
                  <thead>
                    {isSmallScreen && (
                      <tr>
                        <th>Transactions</th>
                      </tr>
                    )}
                    {!isSmallScreen && (
                      <tr>
                        <th>Tran. ID</th>
                        <th>Tran. Date</th>
                        <th>Expected Date</th>
                        <th>Prepared By</th>
                        <th>Vendor ID</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {grid.data?.purchaseOrderOutputDtos?.map((row) => (
                      <tr
                        key={row.tranId}
                        className="pointer"
                        onClick={(e) => setTranId(row.tranId)}
                      >
                        {isSmallScreen && (
                          <>
                            <td>
                              <div className="flex flex-center gap-10">
                                <div>
                                  <small>Tran. ID:</small> {row.tranId}
                                </div>
                                <div>
                                  <small>Tran. Date:</small>{" "}
                                  {commonService.toLocalDate(
                                    row.tranDate,
                                    "MMM DD, YYYY"
                                  )}
                                </div>
                              </div>
                              <div>
                                <small>Expected Date:</small>{" "}
                                {commonService.toLocalDate(
                                  row.expectedDate,
                                  "MMM DD, YYYY"
                                )}
                              </div>
                              <div>
                                <small>Prepared By:</small> {row.preparedBy}
                              </div>
                              <div>
                                <small>Vendor ID:</small>{" "}
                                <a
                                  href={`/vendors/${row.erpVendorInternalAccountNo}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    navigate(
                                      `/vendors/${row.erpVendorInternalAccountNo}`
                                    );
                                  }}
                                >
                                  {row.vendorAccountNo}
                                </a>
                              </div>
                            </td>
                          </>
                        )}
                        {!isSmallScreen && (
                          <>
                            <td>{row.tranId}</td>
                            <td>
                              {commonService.toLocalDate(
                                row.tranDate,
                                "MMM DD, YYYY"
                              )}
                            </td>
                            <td>
                              {commonService.toLocalDate(
                                row.expectedDate,
                                "MMM DD, YYYY"
                              )}
                            </td>
                            <td>{row.preparedBy}</td>
                            <td>
                              <a
                                href={`/vendors/${row.erpVendorInternalAccountNo}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  navigate(
                                    `/vendors/${row.erpVendorInternalAccountNo}`
                                  );
                                }}
                              >
                                {row.vendorAccountNo}
                              </a>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            <div className="text-right">
              <small>
                <a
                  className="mail-link"
                  href="/purchaseorders"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate("/purchaseorders");
                  }}
                >
                  View All...
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LatestPurchaseOrdersWidget;
