import React, { useState } from "react";
import { Button, ModalBody, ModalFooter } from "react-bootstrap";
import FormModal from "../../components/common/FormModal";
import SysModels from "../../models";
import IncomingOrderDetail from "../common/IncomingOrderDetail";

function IncomingOrderDialog(props: {
  detail: SysModels.IOrderHeaderOutputDto;
  print: boolean;
  onClose: () => void;
}) {
  const [detail] = useState<SysModels.IOrderHeaderOutputDto>(props.detail);
  return (
    <FormModal
      title="Incoming Order Details"
      isOpen={true}
      close={() => {
        props.onClose();
      }}
      customBodyFooter={true}
    >
      <ModalBody>
        <IncomingOrderDetail
          id={detail.sqlId}
          customerId={detail.customerAccountNo}
          onReady={() => {
            if (props.print) {
              window.print();
            }
          }}
        ></IncomingOrderDetail>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={() => {
            window.print();
          }}
          variant="primary"
          size="sm"
        >
          Print
        </Button>

        <Button
          type="button"
          onClick={() => {
            props.onClose();
          }}
          variant="secondary"
          size="sm"
        >
          Close
        </Button>
      </ModalFooter>
    </FormModal>
  );
}

export default IncomingOrderDialog;
