import React from "react";

function ReportTestPage(props: any) {
  return (
    <div>
      <h5>Report Test Page</h5>
      <div>For Testing Only...</div>
    </div>
  );
}

export default ReportTestPage;
