import SysModels from "../../models";
import fetcher from "../Fetcher";

class SiteManagementService {
  listIncomingOrders = async (
    page: number,
    pageSize: number,
    data: SysModels.IOrderSearchInputDto
  ) => {
    const url = `/SiteManagement/IncomingOrders/${page}/${pageSize}`;
    return fetcher.post<SysModels.IOrderGridOutputDto>(url, data);
  };

  getIncomingOrder = async (customerNumber: string, sqlId: number) => {
    const url = `/SiteManagement/IncomingOrder/${customerNumber}/${sqlId}`;
    return fetcher.get<SysModels.IOrderOutputDto>(url);
  };

  getListOrdersInError = async (
    page: number,
    pageSize: number,
    data: SysModels.IOrderInErrorSearchInputDto
  ) => {
    const url = `/SiteManagement/OrdersInError/${page}/${pageSize}`;
    return fetcher.post<SysModels.IOrderGridOutputDto>(url, data);
  };

  getOrderInErrorDetail = async (sqlId: number) => {
    const url = `/SiteManagement/OrderInErrorDetail/${sqlId}`;
    return fetcher.get<SysModels.IOrderInErrorOutputDto>(url);
  };

  batchResubmitInvoices = async (data: SysModels.IResubmitInvoicesDto) => {
    const url = `/SiteManagement/BatchResubmitInvoices`;
    return fetcher.post<SysModels.IOrderGridOutputDto>(url, data);
  };

  batchReRequestInvoicesFromNetsuite = async (
    data: SysModels.IBatchResubmitInvoiceInputDto
  ) => {
    const url = `/SiteManagement/BatchReRequestInvoicesFromNetsuite`;
    return fetcher.post<any>(url, data);
  };

  batchReRequestSalesOrdersFromNetsuite = async (
    data: SysModels.IBatchResubmitInvoiceInputDto
  ) => {
    const url = `/SiteManagement/BatchReRequestSalesOrdersFromNetsuite`;
    return fetcher.post<any>(url, data);
  };

  batchReRequestCreditMemosFromNetsuite = async (
    data: SysModels.IBatchResubmitInvoiceInputDto
  ) => {
    const url = `/SiteManagement/BatchReRequestCreditMemosFromNetsuite`;
    return fetcher.post<any>(url, data);
  };

  resubmitInvoice = async (data: SysModels.IResubmitInvoiceDto) => {
    const url = `/SiteManagement/ResubmitInvoice`;
    return fetcher.post<any>(url, data);
  };

  getSystemActivities = async () => {
    const url = `/SiteManagement/SystemActivities`;
    return fetcher.get<SysModels.ISystemLastActivityDto[]>(url);
  };

  getCurrentSystemActivity = async () => {
    const url = `/SiteManagement/CurrentSystemActivity`;
    return fetcher.get<SysModels.ICurrentSystemActivityDto[]>(url);
  };

  getMwfGlobalSettings = async () => {
    const url = `/SiteManagement/MwfGlobalSettings`;
    return fetcher.get<SysModels.IMwfGlobalSettingsBindingOutputDto>(url);
  };

  updateMwfGlobalSettings = async (
    data: SysModels.IMwfGlobalSettingsBindingUpdateDto
  ) => {
    const url = `/SiteManagement/UpdateMwfGlobalSettings`;
    return fetcher.put<SysModels.IMwfGlobalSettingsBindingOutputDto>(url, data);
  };

  getSiteMessage = async (siteId: "Employee" | "MyOrders") => {
    const url = `/SiteManagement/SiteMessage/${siteId}`;
    return fetcher.get<SysModels.ISiteMessageOutputDto>(url);
  };

  getSiteDown = async (siteId: "Employee" | "MyOrders") => {
    const url = `/SiteManagement/SiteDown/${siteId}`;
    return fetcher.get<SysModels.ISiteDownOutputDto>(url);
  };

  updateSiteMessage = async (data: SysModels.ISiteMessageUpdateDto) => {
    const url = `/SiteManagement/UpdateSiteMessage`;
    return fetcher.put<SysModels.ISiteMessageOutputDto>(url, data);
  };

  updateSiteDown = async (data: SysModels.ISiteDownUpdateDto) => {
    const url = `/SiteManagement/UpdateSiteDown`;
    return fetcher.put<SysModels.ISiteDownOutputDto>(url, data);
  };

  getNonDeliveryDates = async () => {
    const url = `/SiteManagement/NonDeliveryDates`;
    return fetcher.get<SysModels.INonDeliveryDateOutputDto[]>(url);
  };

  createNonDeliveryDate = async (data: SysModels.INonDeliveryDateInsertDto) => {
    const url = `/SiteManagement/CreateNonDeliveryDate`;
    return fetcher.post<any>(url, data);
  };

  deleteNonDeliveryDate = async (id: number) => {
    const url = `/SiteManagement/DeleteNonDeliveryDate/${id}`;
    return fetcher.delete<any>(url, {});
  };

  getInventoryItem = async (
    itemId: string = "null",
    internalItemId: string = "null"
  ) => {
    const url = `/SiteManagement/InventoryItem/${itemId}/${internalItemId}`;
    return fetcher.get<SysModels.IErpInventoryItemOutputDto>(url);
  };

  activateInventoryItem = async (id: number) => {
    const url = `/SiteManagement/ActivateInventoryItem/${id}`;
    return fetcher.put<SysModels.IErpInventoryItemOutputDto>(url, {});
  };

  deActivateInventoryItem = async (id: number) => {
    const url = `/SiteManagement/DeActivateInventoryItem/${id}`;
    return fetcher.put<SysModels.IErpInventoryItemOutputDto>(url, {});
  };

  listPortalInvoices = async (
    page: number,
    pageSize: number,
    data: SysModels.IPortalInvoicesSearchDto
  ) => {
    const url = `/SiteManagement/PortalInvoices/${page}/${pageSize}`;
    return fetcher.post<SysModels.IPortalInvoiceGridOutputDto>(url, data);
  };

  viewInvoiceDetail = async (id: number) => {
    const url = `/SiteManagement/ViewInvoiceDetail/${id}`;
    return fetcher.get<SysModels.IPortalInvoiceOutputDto>(url);
  };

  markInErrorOrderAsFixed = async (id: number) => {
    const url = `/SiteManagement/MarkInErrorOrderAsFixed/${id}`;
    return fetcher.put<any>(url, {});
  };

  resubmitOrderToNetSuite = async (id: number) => {
    const url = `/SiteManagement/ResubmitOrderToNetSuite/${id}`;
    return fetcher.put<any>(url, {});
  };

  reRequestInvoiceFromNetsuite = async (
    data: SysModels.IResubmitInvoiceInputDto
  ) => {
    const url = `/SiteManagement/ReRequestInvoiceFromNetsuite`;
    return fetcher.post<any>(url, data);
  };

  getAllBuyingGroups = async () => {
    const url = `/SiteManagement/AllBuyingGroups`;
    return fetcher.get<SysModels.IBuyingGroupOutputDto[]>(url);
  };

  listBatchReRequestInvoices = async (page: number, pageSize: number) => {
    const url = `/SiteManagement/ListBatchReRequestInvoices/${page}/${pageSize}`;
    return fetcher.get<SysModels.IBatchReRequestInvoiceGridOutputDto>(url);
  };

  listBatchReRequestCreditMemos = async (page: number, pageSize: number) => {
    const url = `/SiteManagement/ListBatchReRequestCreditMemos/${page}/${pageSize}`;
    return fetcher.get<SysModels.IBatchReRequestCreditMemoGridOutputDto>(url);
  };
}

const siteManagement = new SiteManagementService();
export default siteManagement;
