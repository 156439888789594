import React, { useEffect, useMemo, useState } from "react";
import FormModal from "../components/common/FormModal";
import CommonSpinner from "../components/common/CommonSpinner";
import SysModels from "../models";
import commonService from "../services/CommonService";
import SwitchButton from "../components/common/SwitchButton";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";

function SkuMappingDialog(props: {
  id?: number;
  buyingGroup: {
    id: number;
    name: string;
  };
  fromSearch?: SysModels.IErpInventoryItemOutputDto;
  onClose: (done?: boolean) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [model, setModel] = useState<SysModels.IBuyingGroupMappingInputDto>({
    netSuiteItemId: props.fromSearch?.itemId || "",
    buyingGroupItemId: "", //props.fromSearch?.erpInternalId || "",
    buyingGroupId: props.buyingGroup.id,
    isMappingActive: true,
  });

  const canSave = useMemo(() => {
    if (
      commonService.isNullOrWhitespace(model.buyingGroupItemId) ||
      commonService.isNullOrWhitespace(model.netSuiteItemId)
    ) {
      return false;
    }
    return true;
  }, [model]);

  useEffect(() => {
    if (props.id) {
      setLoading(true);
      SysServices.http.buyingGroupMapping
        .get(props.id)
        .then((data) => {
          setModel({
            ...model,
            ...data,
          });
          setLoading(false);
          commonService.focusInput("netSuiteItemId", 500);
        })
        .catch((err) => {
          toastStore.showError("Failed Getting Item Mapping", err);
          props.onClose();
        });
    } else {
      commonService.focusInput("netSuiteItemId");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      title={props.id ? "Edit Item Mapping" : "Add Item Mapping"}
      isOpen={true}
      close={() => {
        props.onClose();
      }}
      size="md"
      submitButtonLabel={saving ? "Saving..." : "Save"}
      disableSubmit={loading || saving || !canSave}
      submit={() => {
        if (!canSave) return;
        setSaving(true);
        if (!props.id) {
          SysServices.http.buyingGroupMapping
            .create({
              ...model,
            })
            .then((data) => {
              toastStore.showToast("Item Mapping Saved", "success");
              props.onClose(true);
            })
            .catch((err) => {
              toastStore.showError("Failed Saving Item Mapping", err);
            })
            .finally(() => {
              setSaving(false);
            });
        } else {
          SysServices.http.buyingGroupMapping
            .update(props.id, {
              isMappingActive: model.isMappingActive,
            })
            .then((data) => {
              toastStore.showToast("Item Mapping Saved", "success");
              props.onClose(true);
            })
            .catch((err) => {
              toastStore.showError("Failed Saving Item Mapping", err);
            })
            .finally(() => {
              setSaving(false);
            });
        }
      }}
    >
      {loading && (
        <div className="p-4">
          <CommonSpinner overlay={true}></CommonSpinner>
        </div>
      )}
      <div className={loading ? "display-none" : ""}>
        <label className="mb-2">Buying Group</label>
        <input
          id="buyingGroup"
          className="form-control mb-3"
          placeholder="Buying Group Item ID"
          value={props.buyingGroup.name}
          readOnly={true}
        ></input>

        <label className="mb-2 required-label">NetSuite Item ID</label>
        <input
          id="netSuiteItemId"
          className="form-control mb-3"
          placeholder="NetSuite Item ID"
          value={model.netSuiteItemId}
          readOnly={saving || !!props.id}
          onChange={(e) => {
            setModel((p) => {
              return {
                ...p,
                netSuiteItemId: e.target.value,
              };
            });
          }}
        ></input>

        <label className="mb-2 required-label">Buying Group Item ID</label>
        <input
          id="buyingGroupItemId"
          className="form-control mb-3"
          placeholder="Buying Group Item ID"
          value={model.buyingGroupItemId}
          readOnly={saving || !!props.id}
          onChange={(e) => {
            setModel((p) => {
              return {
                ...p,
                buyingGroupItemId: e.target.value,
              };
            });
          }}
        ></input>

        <div>
          <div>
            <SwitchButton
              checked={model.isMappingActive}
              disabled={saving}
              checkedLabel="Active"
              onChange={(val) => {
                setModel((data) => {
                  return {
                    ...data,
                    isMappingActive: val,
                  };
                });
              }}
            ></SwitchButton>
          </div>
        </div>
      </div>
    </FormModal>
  );
}

export default SkuMappingDialog;
