import SysModels from "../../models";
import fetcher from "../Fetcher";

class MyAccountService {
  getLastDashboard = async () => {
    const url = `/MyAccount/LastDashboard`;
    return fetcher.get<string>(url);
  };

  saveDashboardUsed = async (data: SysModels.ISaveDashboardInputDto) => {
    const url = `/MyAccount/SaveDashboardUsed`;
    return fetcher.post<any>(url, data);
  };
}

const myAccount = new MyAccountService();
export default myAccount;
