import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import Pagination, { usePaging } from "../components/common/Pagination";
import CommonSpinner from "./../components/common/CommonSpinner";
import ConfirmDialog from "./../components/common/ConfirmDialog";
import SysModels from "./../models";
import SysServices from "./../services";
import commonService from "./../services/CommonService";
import { FetchStatus, useFetchHelper } from "./../services/FetchHelper";
import systemStore from "./../stores/SystemStore";
import toastStore from "./../stores/ToastStore";
import ReleaseNoteDialog from "./dialogs/ReleaseNoteDialog";
import ReleaseNotePreviewDialog from "./dialogs/ReleaseNotePreviewDialog";

function ReleaseNotes(props: any) {
  const [paging, setPaging] = usePaging(1, 50);
  const grid = useFetchHelper(
    async () =>
      SysServices.http.siteDocumentation.getItems({
        page: paging.page,
        pageSize: paging.pageSize,
        search: search.used,
        orderByEnum: SysModels.OrderByEnum.Ascending,
      }),
    "Release Notes"
  );

  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used]);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //DELETE DOCUMENT
  const [delDoc, setDelDoc] =
    useState<SysModels.ISiteDocumentationDisplayAdminDto>();
  const [docsBeingDeleted, setDocsBeingDeleted] = useState<
    SysModels.ISiteDocumentationDisplayAdminDto[]
  >([]);
  const deleteData = async (id: string) => {
    await SysServices.http.siteDocumentation
      .delete(id)
      .then((data) => {
        toastStore.showToast("Release Note deleted.", "success");
        grid.getData();
      })
      .catch((error) => {
        toastStore.showError("Failed to Delete Release Note", error);
      })
      .finally(() => {
        setDocsBeingDeleted((list) => {
          return list.filter((d) => d.sqlId !== id);
        });
      });
  };

  //PUBLISH DOCUMENT
  const [pubDoc, setPubDoc] =
    useState<SysModels.ISiteDocumentationDisplayAdminDto>();
  const [docsBeingPublished, setDocsBeingPublished] = useState<
    SysModels.ISiteDocumentationDisplayAdminDto[]
  >([]);
  const publishData = async (id: string) => {
    await SysServices.http.siteDocumentation
      .publish(id, { active: true })
      .then((data) => {
        toastStore.showToast("Release Note Published.", "success");
        //grid.getData();
        if (grid.data) {
          grid.setData((prev) => {
            if (!prev) {
              return prev;
            }
            return {
              ...prev,
              siteDocumentationDisplayAdminDtos: (
                prev.siteDocumentationDisplayAdminDtos || []
              ).map((dto) => {
                if (id === dto.sqlId) {
                  return {
                    ...dto,
                    publishedOn: new Date(),
                  };
                }
                return dto;
              }),
            };
          });
        }
      })
      .catch((error) => {
        toastStore.showError("Failed to Publish Release Note", error);
      })
      .finally(() => {
        setDocsBeingPublished((list) => {
          return list.filter((d) => d.sqlId !== id);
        });
      });
  };

  const [showDialog, setShowDialog] = useState<{
    id?: string;
    show: boolean;
    duplicate: boolean;
  }>({
    show: false,
    duplicate: false,
  });

  const [showPreview, setShowPreview] = useState({
    show: false,
    print: false,
    data: {} as SysModels.ISiteDocumentationDisplayAdminDto,
    published: false,
  });

  const openForEdit = (doc: SysModels.ISiteDocumentationDisplayAdminDto) => {
    if (
      !docsBeingDeleted.find((d) => doc.sqlId === d.sqlId) &&
      !docsBeingPublished.find((d) => doc.sqlId === d.sqlId)
    ) {
      setShowDialog({
        show: true,
        id: doc.sqlId,
        duplicate: false,
      });
    }
  };

  const getTypeName = (type: SysModels.SiteDocumentationTypeEnum) => {
    if (type === SysModels.SiteDocumentationTypeEnum.Employee) {
      return "Employee";
    }
    if (type === SysModels.SiteDocumentationTypeEnum.Customer) {
      return "Customer";
    }
    return "Portal Documentation";
  };

  return (
    <>
      {showDialog.show && (
        <ReleaseNoteDialog
          id={showDialog.id}
          duplicate={showDialog.duplicate}
          onClose={(saved) => {
            if (saved) {
              grid.getData();
            }
            setShowDialog({ id: undefined, show: false, duplicate: false });
          }}
        ></ReleaseNoteDialog>
      )}

      {showPreview.show && (
        <ReleaseNotePreviewDialog
          id={showPreview.data.sqlId}
          title={showPreview.data.title}
          subject={showPreview.data.subjectDraft}
          content={showPreview.data.contentDraft}
          print={showPreview.print}
          published={showPreview.published}
          onClose={() => {
            setShowPreview({
              show: false,
              print: false,
              published: false,
              data: null as any,
            });
          }}
        ></ReleaseNotePreviewDialog>
      )}

      <ConfirmDialog
        show={!!delDoc}
        buttons="yesno"
        title="Delete Release Note"
        message={`Are you sure you want to delete this Release Note?`}
        done={(rtn) => {
          if (rtn === "yes") {
            if (delDoc) {
              setDocsBeingDeleted((list) => {
                return [...list, { ...delDoc }];
              });
              deleteData(delDoc.sqlId);
            }
          }
          setDelDoc(undefined);
        }}
      ></ConfirmDialog>

      <ConfirmDialog
        show={!!pubDoc}
        buttons="yesno"
        title="Publish Release Note"
        message={`Are you sure you want to publish this Release Note?`}
        done={(rtn) => {
          if (rtn === "yes") {
            if (pubDoc) {
              setDocsBeingPublished((list) => {
                return [...list, { ...pubDoc }];
              });
              publishData(pubDoc.sqlId);
            }
          }
          setPubDoc(undefined);
        }}
      ></ConfirmDialog>

      <div className="default-page-layout hide-on-print">
        <h4>Release Notes</h4>

        <div className="bg-white col-sm-12">
          <div className="p-3 flex flex-wrap gap-10">
            <div className="flex-0" style={{ maxWidth: "100%" }}>
              <div className="input-group search-box">
                <input
                  autoFocus={true}
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={search.typed}
                  onChange={(e) => {
                    setSearch((data) => {
                      return {
                        ...data,
                        typed: e.target.value,
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearch((data) => {
                        pageChange(1, paging.pageSize);
                        if (data.used === data.typed) {
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                    }
                  }}
                ></input>
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      pageChange(1, paging.pageSize);
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={(e) => {
                      pageChange(1, paging.pageSize);
                      setSearch((data) => {
                        return { typed: "", used: "" };
                      });
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-1">
              <Button
                variant="outline-primary"
                type="button"
                onClick={() => {
                  setShowDialog({
                    id: undefined,
                    show: true,
                    duplicate: false,
                  });
                }}
              >
                <span>
                  <i className="fa fa-plus"></i> Add Release Note
                </span>
              </Button>
            </div>
          </div>

          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div>
              <table className="table table-hover pointer">
                <thead>
                  {isSmallScreen && (
                    <tr>
                      <th>Title / Description</th>
                      <th></th>
                    </tr>
                  )}
                  {!isSmallScreen && (
                    <tr>
                      <th>Sequence</th>
                      <th>Type</th>
                      <th>Title / Description</th>
                      <th className="no-wrap">Published On</th>
                      <th></th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {grid.data?.siteDocumentationDisplayAdminDtos?.map((doc) => (
                    <tr key={doc.sqlId} className="break-word">
                      {isSmallScreen && (
                        <>
                          <td
                            onClick={() => {
                              openForEdit(doc);
                            }}
                          >
                            <strong>{doc.sequence}</strong> -{" "}
                            <strong>
                              {!!doc.siteDocumentationType &&
                                getTypeName(doc.siteDocumentationType)}
                            </strong>{" "}
                            - {doc.title}
                            <div>
                              <small>{doc.subjectDraft}</small>
                            </div>
                            <div>
                              {doc.publishedOn ? (
                                <small>
                                  <i className="fa fa-newspaper-o me-2"></i>
                                  {commonService.toLocalDate(
                                    doc.publishedOn,
                                    "full"
                                  )}
                                </small>
                              ) : (
                                <small>Unpublished</small>
                              )}
                            </div>
                          </td>
                        </>
                      )}
                      {!isSmallScreen && (
                        <>
                          <td
                            onClick={() => {
                              openForEdit(doc);
                            }}
                          >
                            {doc.sequence}
                          </td>
                          <td
                            onClick={() => {
                              openForEdit(doc);
                            }}
                          >
                            {!!doc.siteDocumentationType &&
                              getTypeName(doc.siteDocumentationType)}
                          </td>
                          <td
                            onClick={() => {
                              openForEdit(doc);
                            }}
                          >
                            {doc.title}
                            <div>
                              <small>{doc.subjectDraft}</small>
                            </div>
                          </td>
                          <td
                            className="no-wrap"
                            onClick={() => {
                              openForEdit(doc);
                            }}
                          >
                            {doc.publishedOn ? (
                              <small>
                                {commonService.toLocalDate(
                                  doc.publishedOn,
                                  "date"
                                )}
                                <br />
                                {commonService.toLocalDate(
                                  doc.publishedOn,
                                  "time"
                                )}
                              </small>
                            ) : (
                              <small>Unpublished</small>
                            )}
                          </td>
                        </>
                      )}
                      <td className="text-right no-wrap">
                        {!docsBeingDeleted.find((d) => doc.sqlId === d.sqlId) &&
                          !docsBeingPublished.find(
                            (d) => doc.sqlId === d.sqlId
                          ) && (
                            <>
                              <Dropdown>
                                <Dropdown.Toggle
                                  size="sm"
                                  id={`dropdownDoc${doc.sqlId}`}
                                  variant="outline-primary"
                                >
                                  <i className="fa fa-bars"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="right">
                                  <Dropdown.Item
                                    onClick={() => {
                                      setDelDoc(doc);
                                    }}
                                  >
                                    <i className="fa fa-trash me-2"></i> Delete
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setShowDialog({
                                        id: doc.sqlId,
                                        show: true,
                                        duplicate: true,
                                      });
                                    }}
                                  >
                                    <i className="fa fa-copy me-2"></i>{" "}
                                    Duplicate
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setPubDoc(doc);
                                    }}
                                  >
                                    <i className="fa fa-newspaper-o me-2"></i>
                                    Publish
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    disabled={!doc.publishedOn}
                                    onClick={() => {
                                      setShowPreview({
                                        show: true,
                                        print: false,
                                        data: doc,
                                        published: true,
                                      });
                                    }}
                                  >
                                    <i className="fa fa-television me-2"></i>
                                    Preview Published
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setShowPreview({
                                        show: true,
                                        print: false,
                                        data: doc,
                                        published: false,
                                      });
                                    }}
                                  >
                                    <i className="fa fa-television me-2"></i>
                                    Preview Draft
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    disabled={!doc.publishedOn}
                                    onClick={() => {
                                      setShowPreview({
                                        show: true,
                                        print: true,
                                        data: doc,
                                        published: true,
                                      });
                                    }}
                                  >
                                    <i className="fa fa-print me-2"></i> Print
                                    Published
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setShowPreview({
                                        show: true,
                                        print: true,
                                        data: doc,
                                        published: false,
                                      });
                                    }}
                                  >
                                    <i className="fa fa-print me-2"></i> Print
                                    Draft
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </>
                          )}
                        {!!docsBeingDeleted.find(
                          (d) => doc.sqlId === d.sqlId
                        ) && (
                          <Button variant="default" type="button">
                            <Spinner
                              animation="border"
                              variant="danger"
                              size="sm"
                            ></Spinner>
                          </Button>
                        )}
                        {!!docsBeingPublished.find(
                          (d) => doc.sqlId === d.sqlId
                        ) && (
                          <Button variant="default" type="button">
                            <Spinner
                              animation="border"
                              variant="success"
                              size="sm"
                            ></Spinner>
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div
            className={`p-3 pt-0 ${
              grid.status === FetchStatus.InProgress ? "display-none" : ""
            }`}
          >
            <Pagination
              length={grid.data?.totalRecords || 0}
              page={paging.page}
              pageSize={paging.pageSize}
              pageChange={pageChange}
              showingOfWhatLabel="documents"
              sizes={[10, 25, 50, 100]}
            ></Pagination>
          </div>
        </div>

        {/* <div className="mt-4">
          <Button
            variant="primary"
            type="button"
            onClick={() => {
              setShowDialog({ id: undefined, show: true, duplicate: false });
            }}
          >
            <span>
              <i className="fa fa-plus"></i> Add Release Note
            </span>
          </Button>
        </div> */}
      </div>
    </>
  );
}

export default ReleaseNotes;
