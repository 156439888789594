import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import ConfirmDialog from "../components/common/ConfirmDialog";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import toastStore from "../stores/ToastStore";
import MonitorRuleDialog from "./MonitorRuleDialog";

function MonitorRuleList(props: any) {
  const grid = useFetchHelper(async () => {
    return SysServices.http.portalMonitor.getAllRules();
  }, "Rules");

  const ruleRegimeList = useFetchHelper(async () => {
    return SysServices.http.genericEnumLookup.get("RuleRegimeEnum");
  }, "Rule Regime");

  useEffect(() => {
    grid.getData();
    ruleRegimeList.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [deleting, setDeleting] = useState(false);
  const [ruleToDelete, setRuleToDelete] = useState<
    | {
        ruleRegimeId?: number;
        systemActivityId?: number;
      }
    | undefined
  >();
  const deleteRule = async (ruleRegimeId: number, systemActivityId: number) => {
    setDeleting(true);
    await SysServices.http.portalMonitor
      .deleteRule(ruleRegimeId, systemActivityId)
      .then((data) => {
        toastStore.showToast("Rule deleted successfully.", "success");
        setDeleting(false);
        grid.getData();
      })
      .catch((error) => {
        toastStore.showError("Failed Deleting Rule", error);
        setDeleting(false);
      });
  };

  const [showDialog, setShowDialog] = useState<
    | {
        ruleRegimeId?: number;
        systemActivityId?: number;
      }
    | undefined
  >();

  return (
    <>
      {deleting && (
        <CommonSpinner overlay={true} message="Deleting..."></CommonSpinner>
      )}
      {!!ruleToDelete && (
        <ConfirmDialog
          show={true}
          title="Delete Rule"
          message={`Do you really want to delete this rule?`}
          buttons="yesno"
          done={(rtn) => {
            if (
              rtn === "yes" &&
              ruleToDelete.ruleRegimeId !== undefined &&
              ruleToDelete.systemActivityId !== undefined
            ) {
              deleteRule(
                ruleToDelete.ruleRegimeId,
                ruleToDelete.systemActivityId
              );
            }
            setRuleToDelete(undefined);
          }}
        ></ConfirmDialog>
      )}
      {!!showDialog && (
        <MonitorRuleDialog
          ruleRegimeId={showDialog.ruleRegimeId}
          systemActivityId={showDialog.systemActivityId}
          onClose={(done) => {
            if (done) {
              grid.getData();
            }
            setShowDialog(undefined);
          }}
        ></MonitorRuleDialog>
      )}
      <div className="default-page-layout">
        <h4 className="hide-on-print">Manage Monitor Rules</h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div>
              <div className="p-3 flex flex-wrap gap-10">
                <div className="flex-1">
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setShowDialog({});
                    }}
                  >
                    <i className="fa fa-plus me-2"></i>Add
                  </Button>
                </div>
              </div>
              <table className="table table-hover pointer">
                <thead>
                  {isSmallScreen && (
                    <tr>
                      <th>Name / Email / Roles</th>
                      <th></th>
                    </tr>
                  )}
                  {!isSmallScreen && (
                    <tr>
                      <th>Process Name</th>
                      <th>System Activity ID</th>
                      <th>Rules Regime</th>
                      <th>Problem Minutes</th>
                      <th>Recheck Minutes</th>
                      <th>Active</th>
                      <th></th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {grid.data?.map((item, i) => (
                    <tr
                      key={i}
                      className="break-word"
                      onClick={(e) => {
                        setShowDialog({
                          systemActivityId: item.systemActivityId,
                          ruleRegimeId: item.ruleRegimeId,
                        });
                      }}
                    >
                      {isSmallScreen && (
                        <td>
                          <div>{item.processName}</div>
                        </td>
                      )}
                      {!isSmallScreen && (
                        <>
                          <td>
                            <div>{item.processName}</div>
                          </td>
                          <td>{item.systemActivityId}</td>
                          <td>
                            {ruleRegimeList.data?.find(
                              (r) => r.value === item.ruleRegimeId
                            )?.label || "Unknown"}
                          </td>
                          <td>{item.problemMinutes}</td>
                          <td>{item.recheckMinutes}</td>
                          <td>
                            {item.active && (
                              <span className="chip-green">Active</span>
                            )}
                            {!item.active && (
                              <span className="chip-red">Inactive</span>
                            )}
                          </td>
                        </>
                      )}
                      <td
                        className="text-center no-wrap"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setRuleToDelete({
                            ruleRegimeId: item.ruleRegimeId,
                            systemActivityId: item.systemActivityId,
                          });
                        }}
                      >
                        <i className="fa fa-trash text-danger pointer"></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default MonitorRuleList;
