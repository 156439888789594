import SysModels from "../../models";
import fetcher from "../Fetcher";

class BuyingGroupStorageService {
  getStorageAreas = async () => {
    const url = `/BuyingGroupStorage/StorageAreas`;
    return fetcher.get<SysModels.IBuyingGroupStorageAreaOutputDto[]>(url);
  };

  listFiles = async (data: SysModels.IBuyingGroupFileListInputDto) => {
    const url = `/BuyingGroupStorage/Files/List`;
    return fetcher.post<SysModels.IBuyingGroupFileListOutputDto>(url, data);
  };

  downloadFile = async (areaId: string, fileName: string) => {
    const url = `/BuyingGroupStorage/Download/${areaId}/${fileName}`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };
}

const buyingGroupStorage = new BuyingGroupStorageService();
export default buyingGroupStorage;
