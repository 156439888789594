import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";
import SysModels from "../../models";

function BatchRequestInvoicesWidget(props: {
  title: string;
  object: string;
  action: (data: {
    invoiceNumbers: string[];
    buyingGroupSqlIds: number[];
  }) => Promise<any>;
  groups: SysModels.IBuyingGroupOutputDto[];
  viewStatus?: () => void;
  autoFocus?: boolean;
}) {
  const [selGroups, setSelGroups] = useState<SysModels.IBuyingGroupOutputDto[]>(
    []
  );
  useEffect(() => {
    setSelGroups(props.groups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [list, setList] = useState<string[]>([]);
  const updateInvoiceList = (invoiceString: string) => {
    const newIds: string[] = [];
    invoiceString.split(" ").forEach((inv) => {
      if (inv.trim() !== "") {
        inv
          .trim()
          .split(",")
          .forEach((inv2) => {
            if (inv2.trim() !== "") {
              inv2
                .trim()
                .split(/(?:\r\n|\r|\n)/g)
                .forEach((inv3) => {
                  if (inv3.trim() !== "") {
                    newIds.push(inv3.trim());
                  }
                });
            }
          });
      }
    });
    setList(newIds);
  };

  const [sending, setSending] = useState(false);
  const send = async () => {
    setSending(true);
    await props
      .action({
        invoiceNumbers: [...list],
        buyingGroupSqlIds:
          selGroups.length === props.groups.length
            ? []
            : selGroups.map((x) => x.sqlId),
      })
      .then((data) => {
        setSending(false);
        toastStore.showToast("Request has been sent.", "success");
      })
      .catch((error) => {
        setSending(false);
        toastStore.showError("Failed Sending Request", error);
      });
  };

  const cannotSubmit = () => {
    return (
      sending ||
      list.length === 0 ||
      !!list.find((inv) => !commonService.validations.numberOnly(inv)) ||
      !!list.find((inv) => list.filter((i) => i === inv).length > 1)
    );
  };

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className="hide-on-print">
      <div className="bg-white mb-4">
        <div className="p-3 bg-blue txt-light">
          <h5 className="m-0">
            <em className="zmdi zmdi-collection-bookmark me-2"></em>
            {props.title}
          </h5>
        </div>
        <div className="p-3 min-height-100">
          <div className="mb-4">
            <label className="mb-2 text-primary">
              <strong>
                Enter {props.object} (numbers only) separated by commas or
                spaces
              </strong>
            </label>
            <textarea
              autoFocus={props.autoFocus || false}
              className="form-control"
              placeholder={`${props.object} string`}
              onChange={(e) => {
                updateInvoiceList(e.target.value || "");
              }}
              disabled={sending}
              rows={5}
            ></textarea>
            <div className="mt-2">
              {list
                .filter(
                  (inv) =>
                    !commonService.validations.numberOnly(inv) ||
                    list.filter((i) => i === inv).length > 1
                )
                .filter((value, index, self) => {
                  return self.indexOf(value) === index;
                })
                .map((inv) => {
                  if (!commonService.validations.numberOnly(inv)) {
                    return (
                      <span
                        key={inv}
                        className="chip-red me-1"
                        title="Numbers Only"
                      >
                        {inv}
                      </span>
                    );
                  }
                  if (list.filter((i) => i === inv).length > 1) {
                    return (
                      <span
                        key={inv}
                        className="chip-orange me-1"
                        title={`Entered ${
                          list.filter((i) => i === inv).length
                        } times`}
                      >
                        {inv}
                      </span>
                    );
                  }
                  return "";
                })}
            </div>
          </div>
          <div className="text-right">
            <div className="flex flex-row">
              <div className="flex-1 pe-2">
                {props.groups.length > 0 && (
                  <Dropdown
                    show={showDropdown}
                    onToggle={(isOpen, e, metaData) => {
                      if (showDropdown) {
                        if (metaData.source === "select") {
                          return;
                        }
                      }
                      setShowDropdown(!showDropdown);
                    }}
                  >
                    <Dropdown.Toggle size="sm" variant="outline-primary">
                      Buying Groups (
                      {selGroups.length === 0 ||
                      selGroups.length === props.groups.length
                        ? "All"
                        : selGroups.length}
                      )
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="right">
                      <Dropdown.Item
                        onClick={(e) => {
                          if (selGroups.length === props.groups.length) {
                            setSelGroups([]);
                          } else {
                            setSelGroups(props.groups);
                          }
                        }}
                      >
                        {selGroups.length === props.groups.length ? (
                          <i className="fa fa-check-square me-3"></i>
                        ) : (
                          <i className="fa fa-square-o me-3"></i>
                        )}
                        <span>Select All</span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      {props.groups.map((grp) => (
                        <Dropdown.Item
                          key={grp.sqlId}
                          onClick={() => {
                            setShowDropdown(true);
                            if (
                              selGroups.find((sg) => grp.sqlId === sg.sqlId)
                            ) {
                              setSelGroups((prev) => {
                                return prev.filter(
                                  (sg) => sg.sqlId !== grp.sqlId
                                );
                              });
                            } else {
                              setSelGroups((prev) => {
                                return [
                                  ...prev.filter(
                                    (sg) => sg.sqlId !== grp.sqlId
                                  ),
                                  grp,
                                ];
                              });
                            }
                          }}
                        >
                          {selGroups.find((sg) => grp.sqlId === sg.sqlId) ? (
                            <i className="fa fa-check-square me-3"></i>
                          ) : (
                            <i className="fa fa-square-o me-3"></i>
                          )}
                          <span>{grp.buyingGroupName}</span>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
              <div>
                {props.groups.length > 0 && (
                  <Button
                    variant="primary"
                    type="button"
                    size="sm"
                    className="me-2"
                    onClick={() => {
                      props.viewStatus && props.viewStatus();
                    }}
                  >
                    Show Progress
                  </Button>
                )}
                <Button
                  variant="success"
                  type="button"
                  size="sm"
                  disabled={cannotSubmit()}
                  onClick={() => send()}
                >
                  {sending ? "Submitting..." : "Submit"} ({list.length})
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BatchRequestInvoicesWidget;
