import React, { useEffect, useState } from "react";
import CommonSpinner from "../../components/common/CommonSpinner";
import SysServices from "../../services";
import systemStore from "../../stores/SystemStore";
import toastStore from "../../stores/ToastStore";

function CustomerLinkComponent(props: {
  name: string;
  number: string;
  id?: number;
  numberPartOfName?: boolean;
  switchOnLoad?: boolean;
}) {
  const [isSwitching, setIsSwitching] = useState(false);

  const switchNow = async () => {
    setIsSwitching(true);
    await SysServices.http.customer
      .get(props.number)
      .then((data) => {
        systemStore.setCurrentCustomer({
          name: data.customerName,
          number: data.erpCustomerNumber,
          id: data.sqlId,
          data: data,
        });
        //setIsSwitching(false);
      })
      .catch((error) => {
        toastStore.showError("Failed Getting Customer", error);
        setIsSwitching(false);
      });
  };

  useEffect(() => {
    if (props.switchOnLoad) {
      switchNow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isSwitching && (
        <CommonSpinner
          overlay={true}
          message={`Switching to Customer: ${props?.name}`}
        ></CommonSpinner>
      )}
      {!props.switchOnLoad && (
        <a
          href="/"
          className="mail-link"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            switchNow();
          }}
        >
          {props.numberPartOfName
            ? `${props.name}`
            : `${props.name} (${props.number})`}
        </a>
      )}
    </>
  );
}

export default CustomerLinkComponent;
