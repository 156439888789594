import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import CommonSpinner from "../../components/common/CommonSpinner";
import DateRangePicker, {
  DatePeriod,
} from "../../components/common/DateRangePicker";
import SysModels from "../../models";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import CSROrderSummaryDialog from "../dialogs/CSROrderSummaryDialog";

function CSROrderSummaryWidget(props: any) {
  const [period, setPeriod] = useState<{
    from: string | null;
    to: string | null;
  }>({
    from: dayjs().add(-12, "month").format("YYYY-MM-DD"),
    to: dayjs().format("YYYY-MM-DD"),
  });

  const csrOrderSummary = useFetchHelper(
    async () =>
      SysServices.http.order.getCsrOrderSummary(
        period.from || "2015-06-01", //dayjs().add(-4, "year").format("YYYY-MM-DD"),
        period.to || dayjs().format("YYYY-MM-DD")
      ),
    "CSR Order Summary"
  );
  useEffect(() => {
    csrOrderSummary.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  const [selectedOrder, setSelectedOrder] =
    useState<SysModels.ICsrTotalSummaryOutputDto>();

  return (
    <>
      {!!selectedOrder && (
        <CSROrderSummaryDialog
          name={`${selectedOrder.firstName} ${selectedOrder.lastName}`}
          userId={selectedOrder.userName}
          email={selectedOrder.email}
          onClose={() => {
            setSelectedOrder(undefined);
          }}
        ></CSROrderSummaryDialog>
      )}
      <div className="hide-on-print">
        <div className="bg-white mb-4">
          <div className="p-3 py-2 bg-blue txt-light">
            <h5 className="m-0 flex flex-center flex-wrap">
              <div className="flex-1">
                <em className="zmdi zmdi-shopping-cart me-2 my-2"></em>CSR Order
                Summary
              </div>
              <div className="flex-0">
                <DateRangePicker
                  from={period.from}
                  to={period.to}
                  onChanged={(from, to) => {
                    setPeriod({ from: from, to: to });
                  }}
                  defaultPeriod={DatePeriod.Custom}
                  periods={[
                    DatePeriod.All,
                    DatePeriod.Today,
                    DatePeriod.Yesterday,
                    DatePeriod.ThisWeek,
                    DatePeriod.LastWeek,
                    DatePeriod.ThisMonth,
                    DatePeriod.LastMonth,
                    DatePeriod.Custom,
                  ]}
                ></DateRangePicker>
              </div>
            </h5>
          </div>
          <div className="p-3 overflow-auto min-height-100">
            <div className="pb-3 text-danger">
              Based on incoming orders. Amounts might change after OE processes
              the orders.
            </div>
            {csrOrderSummary.status === FetchStatus.InProgress && (
              <CommonSpinner></CommonSpinner>
            )}
            {csrOrderSummary.status === FetchStatus.Complete && (
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th className="text-right">#</th>
                    <th className="text-right">Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {csrOrderSummary?.data?.map((summary) => (
                    <tr key={summary.userName}>
                      <td>
                        {summary.firstName} {summary.lastName}
                        {/* <div>
                        <small>
                          <a href={`mailto:${summary.email}`}>
                            {summary.email}
                          </a>
                        </small>
                      </div> */}
                      </td>
                      <td>
                        <small>
                          <a href={`mailto:${summary.email}`}>
                            {summary.email}
                          </a>
                        </small>
                      </td>
                      <td className="text-right">{summary.totalOrdersNo}</td>
                      <td className="text-right">
                        {commonService.toMoney(summary.totalAmount)}
                      </td>
                      <td className="text-center">
                        <i
                          className="fa fa-eye txt-primary pointer"
                          onClick={() => {
                            setSelectedOrder(summary);
                          }}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CSROrderSummaryWidget;
