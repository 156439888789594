import SysModels from "../../models";
import fetcher from "../Fetcher";

class ReportQueryService {
  create = async (data: SysModels.IReportSavedQueryInputDto) => {
    const url = `/ReportQuery/Create`;
    return fetcher.post<any>(url, data);
  };

  update = async (id: string, data: SysModels.IReportSavedQueryInputDto) => {
    const url = `/ReportQuery/Update/${id}`;
    return fetcher.post<any>(url, data);
  };

  delete = async (id: string, reportId: string) => {
    const url = `/ReportQuery/Delete/${id}/${reportId}`;
    return fetcher.delete<any>(url, {});
  };

  list = async (page: number, pageSize: number, reportId: string) => {
    const url = `/ReportQuery/${page}/${pageSize}/${reportId}`;
    return fetcher.get<SysModels.IReportSavedQueryGridOutputDto>(url);
  };
}

const reportQuery = new ReportQueryService();
export default reportQuery;
