import React, { useState } from "react";
import { Button, ModalBody, ModalFooter } from "react-bootstrap";
import CommonSpinner from "../../components/common/CommonSpinner";
import FormModal from "../../components/common/FormModal";
import SysModels from "../../models";
import SysServices from "../../services";
import toastStore from "../../stores/ToastStore";
import IncomingOrderDetail from "../common/IncomingOrderDetail";

function IncomingOrderInErrorDialog(props: {
  onClose: () => void;
  onRefresh: () => void;
  detail: SysModels.IOrderInErrorOutputDto;
  print: boolean;
}) {
  const [doingAction, setDoingAction] = useState(0); //0 default, 1 marking, 2 resubmit
  const [print, setPrint] = useState(props.print);
  const [detail, setDetail] = useState<SysModels.IOrderInErrorOutputDto>(
    props.detail
  );

  const doFixInNetSuite = (sqlId: number) => {
    setDoingAction(1);
    SysServices.http.siteManagement
      .markInErrorOrderAsFixed(sqlId)
      .then(() => {
        setDoingAction(0);
        toastStore.showToast(
          "In Error order was marked as fixed in NetSuite",
          "success"
        );
        props.onRefresh();
        props.onClose();
      })
      .catch((error) => {
        setDoingAction(0);
        toastStore.showError("Failed marking order as fixed.", error);
      });
  };

  const doResubmit = (sqlId: number) => {
    setDoingAction(2);
    SysServices.http.siteManagement
      .resubmitOrderToNetSuite(sqlId)
      .then(() => {
        setDoingAction(0);
        toastStore.showToast(
          "In Error order was submitted to NetSuite",
          "success"
        );
        props.onRefresh();
        props.onClose();
      })
      .catch((error) => {
        setDoingAction(0);
        toastStore.showError("Failed Resubmitting Order.", error);
      });
  };

  return (
    <>
      <FormModal
        title="Incoming Order Details"
        isOpen={true}
        close={() => {
          setPrint(false);
          props.onClose();
        }}
        customBodyFooter={true}
      >
        {doingAction > 0 && (
          <CommonSpinner
            overlay={true}
            message={
              doingAction === 1
                ? "Marking Order as Fixed in NetSuite"
                : "Resubmitting Order"
            }
          ></CommonSpinner>
        )}
        <ModalBody>
          <IncomingOrderDetail
            id={detail.sqlId}
            isError={true}
            customerId={detail.erpCustomerNumber}
            onReady={(data) => {
              setDetail((prev) => {
                return {
                  ...prev,
                  ...data,
                };
              });
              if (print) {
                window.print();
              }
            }}
          ></IncomingOrderDetail>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={() => {
              window.print();
            }}
            variant="primary"
            size="sm"
          >
            Print
          </Button>

          {!detail?.errorRead && (
            <Button
              type="button"
              onClick={() => {
                setPrint(false);
                doFixInNetSuite(detail.sqlId);
              }}
              variant="primary"
              size="sm"
            >
              Fixed in NetSuite
            </Button>
          )}

          {detail?.recordStatus === 16 && (
            <Button
              type="button"
              onClick={() => {
                setPrint(false);
                doResubmit(detail.sqlId);
              }}
              variant="primary"
              size="sm"
            >
              Resubmit
            </Button>
          )}

          <Button
            type="button"
            onClick={() => {
              setPrint(false);
              props.onClose();
            }}
            variant="secondary"
            size="sm"
          >
            Close
          </Button>
        </ModalFooter>
      </FormModal>
    </>
  );
}

export default IncomingOrderInErrorDialog;
