import SysModels from "../../models";
import fetcher from "../Fetcher";

class PurchaseOrderService {
  list = async (data: SysModels.IPurchaseOrderSearchDto) => {
    let url = `/PurchaseOrder/List`;
    return fetcher.post<SysModels.IPurchaseOrderGridOutputDto>(url, data);
  };

  detail = async (tranId: string) => {
    let url = `/PurchaseOrder/Detail/${tranId}`;
    return fetcher.get<SysModels.IPurchaseOrderOutputDto>(url);
  };

  summary = async (data: SysModels.IPurchaseOrderSummarySearchDto) => {
    let url = `/PurchaseOrder/Summary`;
    return fetcher.post<SysModels.IPurchaseOrderSummaryOutputDto>(url, data);
  };
}

const purchaseOrder = new PurchaseOrderService();
export default purchaseOrder;
