import dayjs from "dayjs";
import { reaction } from "mobx";
import React, { useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import SysServices from "../../services";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import systemStore from "../../stores/SystemStore";

function SystemActivityWidget(props: any) {
  //const navigate = useNavigate();

  const sysActivity = useFetchHelper(
    async () => SysServices.http.siteManagement.getCurrentSystemActivity(),
    "System Activities"
  );
  useEffect(() => {
    sysActivity.getData();

    const disposer = reaction(
      () => systemStore.tmo10sec,
      (n, p, r) => {
        if (sysActivity.status !== FetchStatus.InProgress) {
          sysActivity.getData();
        }
      }
    );

    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="hide-on-print">
      <div className="bg-white mb-4">
        <div className="p-3 bg-success txt-light">
          <h5 className="m-0">
            <em className="fa fa-tasks me-2"></em>System Activity
          </h5>
        </div>
        <div className="p-3 overflow-auto min-height-100">
          <div className="mb-3 txt-primary">
            <strong>
              Total Background Tasks Running: {sysActivity?.data?.length || 0}
              {sysActivity.status === FetchStatus.InProgress && (
                <i className="fa fa-spin fa-spinner mx-1"></i>
              )}
            </strong>
          </div>
          <table className="table table-sm">
            <thead>
              <tr>
                <th>Activity</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {sysActivity?.data?.map((summary, i) => (
                <React.Fragment key={`${i}-${summary.action}`}>
                  <tr>
                    <td style={summary.max > 0 ? { border: "none" } : {}}>
                      {summary.action}
                    </td>
                    <td style={summary.max > 0 ? { border: "none" } : {}}>
                      {dayjs(summary.createdOn).format("MMM DD, YYYY hh:mm A")}
                    </td>
                  </tr>
                  {summary.max > 0 && (
                    <tr>
                      <td colSpan={2}>
                        <div className="mb-2">
                          <ProgressBar
                            animated
                            now={(summary.soFar / summary.max) * 100}
                            variant="primary"
                            label={`${Math.ceil(
                              (summary.soFar / summary.max) * 100
                            )}%`}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          {/* <div className="text-right">
            <small>
              <a
                className="mail-link"
                href="/systemactivity"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate("/systemactivity");
                }}
              >
                View All Activity
              </a>
            </small>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default SystemActivityWidget;
