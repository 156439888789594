import { reaction } from "mobx";
import React, { useEffect, useMemo, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import ConfirmDialog from "../components/common/ConfirmDialog";
import FormModal from "../components/common/FormModal";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import toastStore from "../stores/ToastStore";

function BidSheetOrder(props: any) {
  const grid = useFetchHelper(
    async () =>
      SysServices.http.customer.listBidSheetOrderItems(
        paging.page,
        paging.pageSize
      ),
    "Bid Sheet Orders"
  );

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showDialog, setShowDialog] = useState<{
    show: boolean;
    item?: SysModels.ICustomerBidSheetOrderOutputDto;
  }>({ show: false });
  const [saving, setSaving] = useState(false);
  const canSave = useMemo(() => {
    return (
      !saving &&
      !commonService.isNullOrWhitespace(showDialog.item?.itemCode || "") &&
      Number(showDialog.item?.sequence || 0) > 0
    );
  }, [saving, showDialog]);

  const [itemToDelete, setItemToDelete] =
    useState<SysModels.ICustomerBidSheetOrderOutputDto>();
  const [idsBeingDeleted, setIdsBeingDeleted] = useState<number[]>([]);

  return (
    <>
      {itemToDelete && (
        <ConfirmDialog
          show={true}
          title="Delete Item"
          message={`Do you really want to delete this item (${itemToDelete.itemCode})?`}
          buttons="yesno"
          done={(rtn) => {
            if (rtn === "yes") {
              setIdsBeingDeleted((d) => [...d, itemToDelete.sqlId]);
              SysServices.http.customer
                .deleteBidSheetOrderItem(itemToDelete.sqlId)
                .then((e) => {
                  toastStore.showToast(
                    "Bid Sheet Order Item Deleted",
                    "success"
                  );
                  grid.getData();
                })
                .catch((error) => {
                  toastStore.showError(
                    "Failed to Delete Bid Sheet Order Item",
                    error
                  );
                })
                .finally(() => {
                  setItemToDelete(undefined);
                });
            }
            setItemToDelete(undefined);
          }}
        ></ConfirmDialog>
      )}
      <FormModal
        title="Bid Sheet Order Item"
        isOpen={showDialog.show}
        close={() => {
          if (saving) return;
          setShowDialog({
            show: false,
            item: undefined,
          });
        }}
        submit={async () => {
          if (canSave && showDialog.item && systemStore.currentCustomer) {
            setSaving(true);
            await (showDialog.item?.sqlId
              ? SysServices.http.customer.updateBidSheetOrderItem(
                  showDialog.item?.sqlId,
                  {
                    customerNumber: systemStore.currentCustomer.number,
                    ...showDialog.item,
                  }
                )
              : SysServices.http.customer.createBidSheetOrderItem({
                  customerNumber: systemStore.currentCustomer.number,
                  ...showDialog.item,
                })
            )
              .then((e) => {
                toastStore.showToast("Bid Sheet Order Item Saved", "success");
                grid.getData();
                setShowDialog({
                  show: false,
                  item: undefined,
                });
              })
              .catch((error) => {
                toastStore.showError(
                  "Failed Saving Bid Sheet Order Item",
                  commonService.getErrorStatusCode(error) === 404
                    ? "Item Not Found"
                    : error
                );
              })
              .finally(() => {
                setSaving(false);
              });
          }
        }}
        size="sm"
        disableSubmit={!canSave}
        submitButtonLabel={saving ? "Saving..." : "Save Item"}
      >
        <div>
          <div className="form-group mb-2">
            <label className="required-label">Item Code</label>
            <input
              className="form-control"
              value={showDialog.item?.itemCode || ""}
              disabled={saving || !!showDialog.item?.sqlId}
              onChange={(e) => {
                setShowDialog((data) => {
                  return {
                    ...data,
                    item: {
                      ...(data.item || ({} as any)),
                      itemCode: e.target.value,
                    },
                  };
                });
              }}
            />
          </div>
          <div className="form-group">
            <label className="required-label">Sequence</label>
            <input
              className="form-control"
              type="number"
              step="1"
              min={1}
              max={9999999}
              value={showDialog.item?.sequence || ""}
              disabled={saving}
              onChange={(e) => {
                setShowDialog((data) => {
                  return {
                    ...data,
                    item: {
                      ...(data.item || ({} as any)),
                      sequence: Number(e.target.value),
                    },
                  };
                });
              }}
            />
          </div>
        </div>
      </FormModal>
      <div className="default-page-layout">
        <h4 className="hide-on-print">Bid Sheet Order</h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div>
              <table className="table">
                <thead>
                  {isSmallScreen && (
                    <tr>
                      <th>Item / Description</th>
                      <th></th>
                    </tr>
                  )}
                  {!isSmallScreen && (
                    <tr>
                      <th>Item</th>
                      <th>Description</th>
                      <th>Sequence</th>
                      <th className="text-right">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          onClick={(e) => {
                            setShowDialog({
                              show: true,
                              item: undefined,
                            });
                          }}
                        >
                          Add
                        </button>
                      </th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {grid.data?.customerBidSheetOrderOutputDtos?.map(
                    (item, i) => (
                      <tr key={`${item.itemCode}-${i}`}>
                        {isSmallScreen && (
                          <>
                            <td>
                              <strong>{item.itemCode}</strong>
                              <div>{item.itemDescription}</div>
                              <div>Sequence: {item.sequence}</div>
                            </td>
                          </>
                        )}
                        {!isSmallScreen && (
                          <>
                            <td>{item.itemCode}</td>
                            <td>{item.itemDescription}</td>
                            <td>{item.sequence}</td>
                          </>
                        )}
                        <td className="text-right">
                          {idsBeingDeleted.find((i) => i === item.sqlId) ? (
                            <i className="fa fa-spinner fa-spin text-danger"></i>
                          ) : (
                            <>
                              <i
                                className="fa fa-pencil me-2 pointer text-primary"
                                onClick={(e) => {
                                  setShowDialog({
                                    show: true,
                                    item: {
                                      ...item,
                                    },
                                  });
                                }}
                              ></i>
                              <i
                                className="fa fa-trash pointer text-danger"
                                onClick={(e) => {
                                  setItemToDelete(item);
                                }}
                              ></i>
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
              <div className="p-3 pt-0">
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="entries"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default BidSheetOrder;
