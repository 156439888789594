import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import CustomerLinkComponent from "./common/CustomerLinkComponent";
import DateRangePicker, {
  DatePeriod,
  GetPeriodValues,
} from "../components/common/DateRangePicker";
import { useParams } from "react-router-dom";
import TwoItemBarChart, { BarChar_Colors } from "./common/TwoItemBarChart";

function SalesRepSalesProjection(props: any) {
  const { worder, wperiod } = useParams();
  const [order, setOrder] = useState(
    commonService.isNullOrEmpty(wperiod || "")
      ? SysModels.OrderByEnum.Ascending
      : Number(worder)
  );
  const [period, setPeriod] = useState<{
    from: string | null;
    to: string | null;
    period?: DatePeriod;
  }>({
    from: GetPeriodValues(DatePeriod.ThisWeek).from,
    to: GetPeriodValues(DatePeriod.ThisWeek).to,
    period: commonService.isNullOrEmpty(wperiod || "")
      ? DatePeriod.ThisWeek
      : Number(wperiod),
  });

  const getPeriod = () => {
    if (period.period === DatePeriod.ThisMonth) {
      return SysModels.SalesRepProjectionDatePeriodEnum.ThisMonth;
    }
    if (period.period === DatePeriod.ThisQuarter) {
      return SysModels.SalesRepProjectionDatePeriodEnum.ThisQuarter;
    }
    return SysModels.SalesRepProjectionDatePeriodEnum.ThisWeek;
  };

  const grid = useFetchHelper(
    async () =>
      SysServices.http.sales.salesRepProjection({
        page: paging.page,
        pageSize: paging.pageSize,
        isSummary: false,
        orderBy: order,
        datePeriod: getPeriod(),
      }),
    "Sales Projection"
  );

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, period, order]);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="default-page-layout">
        <h4 className="hide-on-print">Sales Projection</h4>
        <div className="bg-white">
          <div>
            <div
              className={`p-3 flex flex-center py-2 txt-light ${
                order === SysModels.OrderByEnum.Ascending
                  ? "bg-danger"
                  : "bg-success"
              }`}
            >
              <div className="me-2 bg-white">
                <button
                  className="btn btn-outline-primary px-3"
                  type="button"
                  onClick={(e) => {
                    setOrder(
                      order === SysModels.OrderByEnum.Ascending
                        ? SysModels.OrderByEnum.Descending
                        : SysModels.OrderByEnum.Ascending
                    );
                  }}
                  disabled={grid.status === FetchStatus.InProgress}
                >
                  {order === SysModels.OrderByEnum.Ascending ? (
                    <>
                      <i className="fa fa-long-arrow-down"></i> Under Performing
                    </>
                  ) : (
                    <>
                      <i className="fa fa-long-arrow-up"></i> Tracking Well
                    </>
                  )}
                </button>
              </div>
              <div className="flex-0">
                <DateRangePicker
                  align="right"
                  from={period.from}
                  to={period.to}
                  onChanged={(from, to, period) => {
                    setPeriod({ from: from, to: to, period: period });
                  }}
                  defaultPeriod={
                    commonService.isNullOrEmpty(wperiod || "")
                      ? DatePeriod.ThisWeek
                      : Number(wperiod)
                  }
                  hideCustom={true}
                  periods={[
                    DatePeriod.ThisWeek,
                    DatePeriod.ThisMonth,
                    DatePeriod.ThisQuarter,
                  ]}
                  disabled={grid.status === FetchStatus.InProgress}
                ></DateRangePicker>
              </div>
              <div className="flex-1"></div>
            </div>
            {grid.status === FetchStatus.InProgress && (
              <div className="p-3">
                <CommonSpinner></CommonSpinner>
              </div>
            )}
            <div
              className={`bg-white ${
                grid.status === FetchStatus.Complete ? "" : "display-none"
              }`}
            >
              <table className="table">
                <thead>
                  {isSmallScreen && (
                    <tr>
                      <th className="flex flex-center">
                        <div className="flex-1">Customer</div>
                        <div>
                          <i
                            className="fa fa-square"
                            style={{
                              color: BarChar_Colors[0],
                            }}
                          ></i>{" "}
                          <span className="me-2">This Period</span>
                          <i
                            className="fa fa-square"
                            style={{
                              color: BarChar_Colors[1],
                            }}
                          ></i>{" "}
                          Last Period
                        </div>
                      </th>
                    </tr>
                  )}
                  {!isSmallScreen && (
                    <tr>
                      <th>Customer</th>
                      <th className="text-right">Projected Percentage</th>
                      <th className="text-center px-2">
                        <i
                          className="fa fa-square"
                          style={{
                            color: BarChar_Colors[0],
                          }}
                        ></i>{" "}
                        <span className="me-2">This Period</span>
                        <i
                          className="fa fa-square"
                          style={{
                            color: BarChar_Colors[1],
                          }}
                        ></i>{" "}
                        Last Period
                      </th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {grid.data?.salesRepProjectionOutputDtos?.map((customer) => (
                    <tr key={customer.customerNumber}>
                      {isSmallScreen && (
                        <>
                          <td>
                            <CustomerLinkComponent
                              name={customer.customerName}
                              number={customer.customerNumber}
                            ></CustomerLinkComponent>
                            <div className="flex flex-center">
                              <div className="pe-2">Projected Percentage:</div>
                              <div className="flex-1">
                                {!commonService.isNumberNullOrMax(
                                  customer.percentage
                                ) && (
                                  <strong>
                                    {commonService.toNumberWithComma(
                                      customer.percentage
                                    )}
                                    %
                                  </strong>
                                )}
                              </div>
                            </div>
                            <div className="flex flex-center">
                              <TwoItemBarChart
                                data={[
                                  {
                                    value: customer.thisPeriodAmount,
                                    label: "This Period",
                                  },
                                  {
                                    value: customer.lastPeriodAmount,
                                    label: "Last Period",
                                  },
                                ]}
                              ></TwoItemBarChart>
                            </div>
                          </td>
                        </>
                      )}
                      {!isSmallScreen && (
                        <>
                          <td>
                            <CustomerLinkComponent
                              name={customer.customerName}
                              number={customer.customerNumber}
                            ></CustomerLinkComponent>
                          </td>
                          <td className="text-right">
                            {!commonService.isNumberNullOrMax(
                              customer.percentage
                            ) && (
                              <>
                                {commonService.toNumberWithComma(
                                  customer.percentage
                                )}
                                %
                              </>
                            )}
                          </td>
                          <td>
                            <TwoItemBarChart
                              data={[
                                {
                                  value: customer.thisPeriodAmount,
                                  label: "This Period",
                                },
                                {
                                  value: customer.lastPeriodAmount,
                                  label: "Last Period",
                                },
                              ]}
                            ></TwoItemBarChart>
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="p-3 pt-0">
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="entries"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SalesRepSalesProjection;
