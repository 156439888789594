import React from "react";
import SysModels from "../../models";

function UserRolesDisplayComponent({
  roles,
}: {
  roles: SysModels.IUserRoleDto;
}) {
  return (
    <>
      {[
        { label: "Admin", show: roles.isAdmin },
        {
          label: "Application Admin",
          show: roles.isApplicationAdmin,
        },
        {
          label: "Customer Service",
          show: roles.isCustomerService,
        },
        {
          label: "Document Manager",
          show: roles.isDocumentManager,
        },
        {
          label: "Executive",
          show: roles.isExecutive,
        },
        {
          label: "OE Manager",
          show: roles.isOeManager,
        },
        { label: "Sales", show: roles.isSales },
        {
          label: "Sales Manager",
          show: roles.isSalesManager,
        },
        { label: "Purchasing", show: roles.isPurchasing },
        { label: "Login As Sales Rep", show: roles.isLoginAsSalesRep },
        { label: "None", show: roles.none },
      ]
        .filter((r) => r.show)
        .map((r) => r.label)
        .join(", ")}
    </>
  );
}

export default UserRolesDisplayComponent;
