import dayjs from "dayjs";
import { reaction } from "mobx";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Dropdown,
  ModalBody,
  ModalFooter,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import CommonSpinner from "../components/common/CommonSpinner";
import FormModal from "../components/common/FormModal";
import Pagination, { usePaging } from "../components/common/Pagination";
import TableRow from "../components/common/TableRow";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import CustomerLinkComponent from "./common/CustomerLinkComponent";
import DateRangePicker, {
  DatePeriod,
  GetPeriodValues,
} from "../components/common/DateRangePicker";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ColumnHeaderSortComponent from "./common/ColumnHeaderSortComponent";

interface IOption {
  id: string;
  name: string;
}

const getInitialDateRange = () => {
  if (dayjs().get("date") >= 15) {
    return {
      ...GetPeriodValues(DatePeriod.ThisMonth),
      period: DatePeriod.ThisMonth,
    };
  } else {
    return {
      ...GetPeriodValues(DatePeriod.LastMonth),
      period: DatePeriod.LastMonth,
    };
  }
};

const getSurveyPeriod = (period?: DatePeriod) => {
  if (period === DatePeriod.ThisMonth) {
    return SysModels.SurveyDatePeriodEnum.ThisMonth;
  }
  if (period === DatePeriod.LastMonth) {
    return SysModels.SurveyDatePeriodEnum.LastMonth;
  }
  if (period === DatePeriod.ThisQuarter) {
    return SysModels.SurveyDatePeriodEnum.ThisQuarter;
  }
  if (period === DatePeriod.LastQuarter) {
    return SysModels.SurveyDatePeriodEnum.LastQuarter;
  }
  if (period === DatePeriod.ThisYear) {
    return SysModels.SurveyDatePeriodEnum.ThisYear;
  }
  if (period === DatePeriod.LastYear) {
    return SysModels.SurveyDatePeriodEnum.LastYear;
  }
  return SysModels.SurveyDatePeriodEnum.DateRange;
};

function CustomerSurveys(props: any) {
  const navigate = useNavigate();

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [period, setPeriod] = useState<{
    from: string | null;
    to: string | null;
    period?: DatePeriod;
  }>({
    from: getInitialDateRange().from,
    to: getInitialDateRange().to,
    period: getInitialDateRange().period,
  });
  const [filter, setFilter] = useState<{ name: string; id: string }>();

  const grid = useFetchHelper(async () => {
    return SysServices.http.survey.list(1, 4000, {
      customerNumber: filter?.id || (null as any),
      surveyDatePeriod: getSurveyPeriod(period.period),
      start: period.period === DatePeriod.Custom ? period.from : (null as any),
      end: period.period === DatePeriod.Custom ? period.to : (null as any),
    });
  }, "Surveys");

  const chart = useFetchHelper(async () => {
    return SysServices.http.survey.surveySummary({
      customerNumber: filter?.id || (null as any),
      surveyDatePeriod: getSurveyPeriod(period.period),
      start: period.period === DatePeriod.Custom ? period.from : (null as any),
      end: period.period === DatePeriod.Custom ? period.to : (null as any),
    });
  }, "Surveys");

  useEffect(() => {
    chart.getData();
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, filter]);

  const ActionsCellComponent = (data: any) => {
    return (
      <>
        <Dropdown>
          {isSmallScreen && (
            <Dropdown.Toggle size="sm" variant="outline-primary">
              <i className="fa fa-bars"></i>
            </Dropdown.Toggle>
          )}
          {!isSmallScreen && (
            <Dropdown.Toggle size="sm" variant="primary">
              Actions
            </Dropdown.Toggle>
          )}

          <Dropdown.Menu align="right">
            <Dropdown.Item
              onClick={() => {
                setDetail(data);
              }}
            >
              <i className="fa fa-eye me-3"></i>
              <span>View Details</span>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setPrint(true);
                setDetail(data);
              }}
            >
              <i className="fa fa-print me-3"></i>
              <span>Print Survey</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const [print, setPrint] = useState(false);
  const [detail, setDetail] = useState<SysModels.ISurveyItemOutputDto>();
  const surveyDetail = useFetchHelper(
    async () => SysServices.http.survey.get(detail?.surveyToken || ""),
    "Survey"
  );

  const { token } = useParams();
  useEffect(() => {
    if (token) {
      setDetail({ surveyToken: token } as any);
    } else {
      setDetail(undefined);
      setPrint(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (detail && surveyDetail.status !== FetchStatus.InProgress) {
      surveyDetail.getData();
      navigate(`/listsurveys/${detail.surveyToken}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  useEffect(() => {
    if (
      print &&
      surveyDetail.data &&
      surveyDetail.status === FetchStatus.Complete
    ) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
    if (surveyDetail.status === FetchStatus.Failed) {
      setDetail(undefined);
      setPrint(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyDetail.status]);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typeaheadRef = useRef(null);
  const [users, setUsers] = useState([] as IOption[]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (query: string) => {
    if ((query || "").trim() === "") {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    await SysServices.http.customer
      .typeAhead({ search: query })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: i.label,
        }));

        setUsers(options);
        setIsLoading(false);
      })
      .catch((err) => {
        setUsers([]);
        setIsLoading(false);
      });
  };

  const [activeTab, setActiveTab] = useState("SurveySummary");
  const [orderBy, setOrderBy] = useState(SysModels.OrderByEnum.Ascending);
  const [sortBy, setSortBy] = useState("key");

  const getSortedData = () => {
    if (sortBy === "customerName" || sortBy === "receivedOn") {
      if (orderBy === 0) {
        return grid.data?.surveyGridItemDtos?.sort(
          commonService.sortByStringProperty(sortBy)
        );
      }
      return grid.data?.surveyGridItemDtos
        ?.sort(commonService.sortByStringProperty(sortBy))
        ?.reverse();
    }
    if (orderBy === 0) {
      return grid.data?.surveyGridItemDtos?.sort(
        commonService.sortByNumericProperty(sortBy)
      );
    }
    return grid.data?.surveyGridItemDtos
      ?.sort(commonService.sortByNumericProperty(sortBy))
      ?.reverse();
  };

  const sendEmail = () => {
    if (surveyDetail.status === FetchStatus.Complete && surveyDetail.data) {
      let val = `mailto:${surveyDetail.data?.email.join(",")}`;
      val += `?subject=${encodeURIComponent(
        `Survey Details for: ${surveyDetail.data?.customerName}`
      )}`;

      let body = "";
      document
        .getElementById("surveyEmail")
        ?.querySelectorAll("tr")
        ?.forEach((row) => {
          row.querySelectorAll("td")?.forEach((col, i) => {
            body += col.textContent;
            if (i === 0) {
              body += ": ";
            }
          });
          body += " \n";
        });
      body += " \n\n\n";
      val += `&body=${encodeURIComponent(body)}`;
      window.open(val, "_blank");
    }
  };

  return (
    <>
      {!!detail && (
        <FormModal
          title={`Survey Details for: ${
            `<span class="text-success">${
              surveyDetail.status === FetchStatus.Complete
                ? surveyDetail?.data?.customerName
                : "..."
            }</span>` || "..."
          }`}
          isOpen={true}
          close={() => {
            setDetail(undefined);
            setPrint(false);
            navigate(`/listsurveys`);
          }}
          submitButtonLabel="Print"
          submit={() => {
            window.print();
          }}
          customBodyFooter={true}
        >
          <ModalBody>
            <div className="p-3">
              {surveyDetail.status === FetchStatus.InProgress && (
                <div>
                  <CommonSpinner></CommonSpinner>
                </div>
              )}
              {surveyDetail.data &&
                surveyDetail.status === FetchStatus.Complete && (
                  <div>
                    <div id="surveyEmail" className="display-none">
                      <SurveyDetail data={surveyDetail.data}></SurveyDetail>
                    </div>
                    <div className="show-on-print pb-2">
                      <h5>
                        Survey Details for: {surveyDetail?.data?.customerName}
                      </h5>
                    </div>
                    <div>
                      <table className="table table-sm">
                        <tbody>
                          <tr>
                            <td className="no-wrap">
                              <strong>Date Sent: </strong>
                              {dayjs
                                .utc(surveyDetail.data.sentOn)
                                .local()
                                .format("MMM DD, YYYY")}
                            </td>
                            <td className="no-wrap">
                              <strong>Date Received: </strong>
                              {dayjs
                                .utc(surveyDetail.data.receivedOn)
                                .local()
                                .format("MMM DD, YYYY")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <Bar
                        data={{
                          labels: [
                            `Delivery`,
                            `Product Quality`,
                            `Customer Service`,
                            `Online Ordering`,
                            `Sales Rep`,
                            `Overall Company`,
                          ],
                          datasets: [
                            {
                              label: "Ratings",
                              data: [
                                surveyDetail.data.deliveryRating,
                                surveyDetail.data.productQualityRating,
                                surveyDetail.data.customerServiceRating,
                                surveyDetail.data.onlineOrderingRating,
                                surveyDetail.data.salesRepRating,
                                surveyDetail.data.overallCompanyRating,
                              ],
                              backgroundColor: [
                                "#f0c541",
                                "#f3a464",
                                "#c4996d",
                                "#f1a1c7",
                                "#ed6f56",
                                "#2ecd99",
                              ],
                              borderWidth: 0,
                            },
                          ],
                        }}
                        plugins={[ChartDataLabels as any]}
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                        }}
                      />
                    </div>
                    <div className="pt-2">
                      <hr />
                      <div>
                        <strong>Comment</strong>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: surveyDetail.data.comment,
                        }}
                      ></div>
                    </div>
                  </div>
                )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              onClick={(e) => {
                setDetail(undefined);
                setPrint(false);
                navigate(`/listsurveys`);
              }}
            >
              Close
            </Button>
            <Button
              variant="outline-primary"
              disabled={surveyDetail.status !== FetchStatus.Complete}
              onClick={(e) => {
                sendEmail();
              }}
            >
              Send Email
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                window.print();
              }}
            >
              Print
            </Button>
          </ModalFooter>
        </FormModal>
      )}

      <div className="default-page-layout hide-on-print">
        <h4>Surveys Received</h4>
        <div className="">
          <div className="bg-white p-3 flex flex-wrap gap-10 mb-2">
            <div>
              <DateRangePicker
                from={period.from}
                to={period.to}
                onChanged={(from, to, period) => {
                  setPeriod({ from: from, to: to, period: period });
                }}
                defaultPeriod={getInitialDateRange().period}
                periods={[
                  DatePeriod.ThisMonth,
                  DatePeriod.LastMonth,
                  DatePeriod.ThisQuarter,
                  DatePeriod.LastQuarter,
                  DatePeriod.ThisYear,
                  DatePeriod.LastYear,
                  DatePeriod.Custom,
                ]}
              ></DateRangePicker>
            </div>
            <div className="flex-1">
              <div className="input-group">
                <div>
                  <AsyncTypeahead
                    id="customerTypeAhead"
                    labelKey="name"
                    onSearch={handleSearch}
                    onChange={(data: any) => {
                      if (data[0]) {
                        setFilter({
                          id: data[0].id,
                          name: data[0].name,
                        });
                        (typeaheadRef?.current as any)?.clear();
                      }
                    }}
                    searchText={"Searching..."}
                    isLoading={isLoading}
                    options={users}
                    placeholder="Search Customer..."
                    minLength={1}
                    delay={500}
                    useCache={false}
                    filterBy={() => true}
                    style={{ width: "280px" }}
                    ref={typeaheadRef}
                  />
                </div>
                {!!filter && (
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      style={{ borderRadius: 0 }}
                    >
                      <div
                        style={{
                          maxWidth: "230px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          marginRight: "10px",
                        }}
                      >
                        {filter?.name}
                      </div>
                      <i
                        className="fa fa-times text-danger pointer"
                        onClick={(e) => {
                          setFilter(undefined);
                        }}
                      ></i>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-sm-12">
            <Tabs
              transition={false}
              onSelect={(tab) => {
                tab && setActiveTab(tab);
              }}
            >
              <Tab eventKey="SurveySummary" title="Survey Summary">
                <div className="bg-white p-4">
                  {chart.status === FetchStatus.InProgress && (
                    <div className="p-3">
                      <CommonSpinner></CommonSpinner>
                    </div>
                  )}
                  {chart?.status === FetchStatus.Complete &&
                    !!chart.data &&
                    activeTab === "SurveySummary" && (
                      <div className="row">
                        <SurveyChart
                          title="Delivery"
                          data={chart.data.deliveryChartData}
                        ></SurveyChart>
                        <SurveyChart
                          title="Customer Service"
                          data={chart.data.customerServiceChartData}
                        ></SurveyChart>
                        <SurveyChart
                          title="Sales Representative"
                          data={chart.data.salesRepChartData}
                        ></SurveyChart>
                        <SurveyChart
                          title="Product Quality"
                          data={chart.data.productChartData}
                        ></SurveyChart>
                        <SurveyChart
                          title="Online Ordering"
                          data={chart.data.onlineChartData}
                        ></SurveyChart>
                        <SurveyChart
                          title="Overall Company Performance"
                          data={chart.data.overallCompanyChartData}
                        ></SurveyChart>
                      </div>
                    )}
                </div>
              </Tab>
              <Tab eventKey="SurveyDetail" title="Survey Detail">
                <div className="bg-white">
                  {grid.status === FetchStatus.InProgress && (
                    <div className="p-3">
                      <CommonSpinner></CommonSpinner>
                    </div>
                  )}
                  {grid?.status === FetchStatus.Complete && !!grid.data && (
                    <>
                      <table className="table">
                        <thead>
                          {isSmallScreen && (
                            <tr>
                              <th>Customer / Date Received / Ratings</th>
                            </tr>
                          )}
                          {!isSmallScreen && (
                            <tr>
                              <th>
                                <ColumnHeaderSortComponent
                                  name="Customer"
                                  mode={
                                    sortBy === "customerName" ? orderBy : null
                                  }
                                  onClick={(mode) => {
                                    if (mode === null) {
                                      setSortBy("customerName");
                                    } else {
                                      setOrderBy(mode ? 0 : 1);
                                    }
                                  }}
                                ></ColumnHeaderSortComponent>
                              </th>
                              <th className="no-wrap">
                                <ColumnHeaderSortComponent
                                  name="Date Received"
                                  mode={
                                    sortBy === "receivedOn" ? orderBy : null
                                  }
                                  onClick={(mode) => {
                                    if (mode === null) {
                                      setSortBy("receivedOn");
                                    } else {
                                      setOrderBy(mode ? 0 : 1);
                                    }
                                  }}
                                ></ColumnHeaderSortComponent>
                              </th>
                              <th>
                                <ColumnHeaderSortComponent
                                  name="Delivery"
                                  mode={
                                    sortBy === "deliveryRating" ? orderBy : null
                                  }
                                  onClick={(mode) => {
                                    if (mode === null) {
                                      setSortBy("deliveryRating");
                                    } else {
                                      setOrderBy(mode ? 0 : 1);
                                    }
                                  }}
                                ></ColumnHeaderSortComponent>
                              </th>
                              <th>
                                <ColumnHeaderSortComponent
                                  name="Product"
                                  mode={
                                    sortBy === "productQualityRating"
                                      ? orderBy
                                      : null
                                  }
                                  onClick={(mode) => {
                                    if (mode === null) {
                                      setSortBy("productQualityRating");
                                    } else {
                                      setOrderBy(mode ? 0 : 1);
                                    }
                                  }}
                                ></ColumnHeaderSortComponent>
                              </th>
                              <th className="no-wrap">
                                <ColumnHeaderSortComponent
                                  name="Customer Service"
                                  mode={
                                    sortBy === "customerServiceRating"
                                      ? orderBy
                                      : null
                                  }
                                  onClick={(mode) => {
                                    if (mode === null) {
                                      setSortBy("customerServiceRating");
                                    } else {
                                      setOrderBy(mode ? 0 : 1);
                                    }
                                  }}
                                ></ColumnHeaderSortComponent>
                              </th>
                              <th>
                                <ColumnHeaderSortComponent
                                  name="Online"
                                  mode={
                                    sortBy === "onlineOrderingRating"
                                      ? orderBy
                                      : null
                                  }
                                  onClick={(mode) => {
                                    if (mode === null) {
                                      setSortBy("onlineOrderingRating");
                                    } else {
                                      setOrderBy(mode ? 0 : 1);
                                    }
                                  }}
                                ></ColumnHeaderSortComponent>
                              </th>
                              <th className="no-wrap">
                                <ColumnHeaderSortComponent
                                  name="Sales Rep"
                                  mode={
                                    sortBy === "salesRepRating" ? orderBy : null
                                  }
                                  onClick={(mode) => {
                                    if (mode === null) {
                                      setSortBy("salesRepRating");
                                    } else {
                                      setOrderBy(mode ? 0 : 1);
                                    }
                                  }}
                                ></ColumnHeaderSortComponent>
                              </th>
                              <th className="no-wrap">
                                <ColumnHeaderSortComponent
                                  name="Overall Company"
                                  mode={
                                    sortBy === "overallCompanyRating"
                                      ? orderBy
                                      : null
                                  }
                                  onClick={(mode) => {
                                    if (mode === null) {
                                      setSortBy("overallCompanyRating");
                                    } else {
                                      setOrderBy(mode ? 0 : 1);
                                    }
                                  }}
                                ></ColumnHeaderSortComponent>
                              </th>
                              <th></th>
                            </tr>
                          )}
                        </thead>
                        <tbody key={`${sortBy}-${setOrderBy}`}>
                          {getSortedData()
                            ?.filter((s, i) => {
                              const start = (paging.page - 1) * paging.pageSize;
                              const end = paging.page * paging.pageSize - 1;
                              return i >= start && i <= end;
                            })
                            ?.map((survey) => (
                              <tr key={survey.surveyToken}>
                                {isSmallScreen && (
                                  <>
                                    <td>
                                      <div className="flex gap-10">
                                        <div className="flex-1">
                                          <CustomerLinkComponent
                                            name={survey.customerName}
                                            number={survey.customerNumber}
                                            numberPartOfName={true}
                                          ></CustomerLinkComponent>
                                        </div>
                                        <div>
                                          <ActionsCellComponent
                                            {...survey}
                                          ></ActionsCellComponent>
                                        </div>
                                      </div>
                                      <table className="table-label-desc">
                                        <tbody>
                                          <TableRow label="Date Received:">
                                            {dayjs(survey.receivedOn).format(
                                              "MMM DD, YYYY"
                                            )}
                                          </TableRow>
                                          <TableRow label="Delivery:">
                                            {survey.deliveryRating}
                                          </TableRow>
                                          <TableRow label="Product:">
                                            {survey.productQualityRating}
                                          </TableRow>
                                          <TableRow label="Customer Service	:">
                                            {survey.customerServiceRating}
                                          </TableRow>
                                          <TableRow label="Online:">
                                            {survey.onlineOrderingRating}
                                          </TableRow>
                                          <TableRow label="Sales Rep:">
                                            {survey.salesRepRating}
                                          </TableRow>
                                          <TableRow label="Overall Company:">
                                            {survey.overallCompanyRating}
                                          </TableRow>
                                        </tbody>
                                      </table>
                                    </td>
                                  </>
                                )}
                                {!isSmallScreen && (
                                  <>
                                    <td>
                                      <CustomerLinkComponent
                                        name={survey.customerName}
                                        number={survey.customerNumber}
                                        numberPartOfName={true}
                                      ></CustomerLinkComponent>
                                    </td>
                                    <td className="no-wrap">
                                      {dayjs(survey.receivedOn).format(
                                        "MMM DD, YYYY"
                                      )}
                                    </td>
                                    <td>{survey.deliveryRating}</td>
                                    <td>{survey.productQualityRating}</td>
                                    <td>{survey.customerServiceRating}</td>
                                    <td>{survey.onlineOrderingRating}</td>
                                    <td>{survey.salesRepRating}</td>
                                    <td>{survey.overallCompanyRating}</td>
                                    <td>
                                      <ActionsCellComponent
                                        {...survey}
                                      ></ActionsCellComponent>
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <div className="p-3 pt-0">
                        <Pagination
                          length={grid.data?.totalRecords || 0}
                          page={paging.page}
                          pageSize={paging.pageSize}
                          pageChange={pageChange}
                          showingOfWhatLabel="entries"
                          sizes={[10, 25, 50, 100]}
                        ></Pagination>
                      </div>
                    </>
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

const SurveyChart = ({
  data,
  title,
}: {
  title: string;
  data: SysModels.ISurveyChartDataOutputDto;
}) => {
  const totals = data.fives + data.fours + data.threes + data.twos + data.ones;
  return (
    <div className="col col-xs-12 col-sm-6 col-md-4 text-center">
      <h5 className="">{title}</h5>
      <div className="pb-4">
        <div
          style={{ maxWidth: "350px", margin: "auto" }}
          className="chart-doughnut"
        >
          <Pie
            data={{
              labels: [
                `5 Stars: ${commonService.toNumberWithComma(data?.fives || 0)}`,
                `4 Stars: ${commonService.toNumberWithComma(data?.fours || 0)}`,
                `3 Stars: ${commonService.toNumberWithComma(
                  data?.threes || 0
                )}`,
                `2 Stars: ${commonService.toNumberWithComma(data?.twos || 0)}`,
                `1 Star: ${commonService.toNumberWithComma(data?.ones || 0)}`,
              ],
              datasets: [
                {
                  label: "Ratings",
                  data: [
                    data?.fives || 0,
                    data?.fours || 0,
                    data?.threes || 0,
                    data?.twos || 0,
                    data?.ones || 0,
                    totals === 0 ? 1 : 0,
                  ],
                  backgroundColor: [
                    "#f0c541",
                    "#f3a464",
                    "#c4996d",
                    "#f1a1c7",
                    "#ed6f56",
                    "#ddd",
                  ],
                  borderWidth: 0,
                },
              ],
            }}
            plugins={[ChartDataLabels as any]}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              plugins: {
                datalabels: {
                  display: true,
                  color: "white",
                  formatter: (val, context) => {
                    if (!Number(val || 0)) {
                      return "";
                    }
                    if (!totals) {
                      return "";
                    }
                    const perc = Number(val || 0) / totals;
                    return `${commonService.toNumberWithComma(perc * 100)}%`;
                  },
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      if (!context.label) {
                        return "No Data";
                      }
                      const perc = Number(context.raw || 0) / totals;
                      return ` ${
                        context.label
                      }: ${commonService.toNumberWithComma(perc * 100)}%`;
                    },
                  },
                },
              },
            }}
          ></Pie>
        </div>
      </div>
    </div>
  );
};

const SurveyDetail = (surveyDetail: {
  data: SysModels.ISurveyItemOutputDto;
}) => {
  return (
    <table className="table table-sm mt-4">
      <tbody>
        {/* <tr className="show-on-print">
          <td colSpan={2} className="pb-4">
            <h5>Survey Details for: {surveyDetail?.data?.customerName}</h5>
          </td>
        </tr> */}
        <tr>
          <td className="no-wrap">
            <strong>Date Sent</strong>
          </td>
          <td>
            {dayjs.utc(surveyDetail.data.sentOn).local().format("MMM DD, YYYY")}
          </td>
        </tr>
        <tr>
          <td className="no-wrap">
            <strong>Date Received</strong>
          </td>
          <td>
            {dayjs
              .utc(surveyDetail.data.receivedOn)
              .local()
              .format("MMM DD, YYYY")}
          </td>
        </tr>
        <tr>
          <td className="no-wrap">
            <strong>Delivery</strong>
          </td>
          <td>{surveyDetail.data.deliveryRating}</td>
        </tr>

        <tr>
          <td className="no-wrap">
            <strong>Product Quality</strong>
          </td>
          <td>{surveyDetail.data.productQualityRating}</td>
        </tr>
        <tr>
          <td className="no-wrap">
            <strong>Customer Service</strong>
          </td>
          <td>{surveyDetail.data.customerServiceRating}</td>
        </tr>
        <tr>
          <td className="no-wrap">
            <strong>Online Ordering</strong>
          </td>
          <td>{surveyDetail.data.onlineOrderingRating}</td>
        </tr>
        <tr>
          <td className="no-wrap">
            <strong>Sales Rep</strong>
          </td>
          <td>{surveyDetail.data.salesRepRating}</td>
        </tr>
        <tr>
          <td className="no-wrap">
            <strong>Overall Company</strong>
          </td>
          <td>{surveyDetail.data.overallCompanyRating}</td>
        </tr>
        <tr>
          <td>
            <strong>Comment</strong>
          </td>
          <td
            dangerouslySetInnerHTML={{
              __html: surveyDetail.data.comment,
            }}
          ></td>
        </tr>
      </tbody>
    </table>
  );
};

export default CustomerSurveys;
