import React, { useEffect } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SalesSummaryTableComponent from "./common/SalesSummaryTableComponent";

function SalesRepSummary(props: any) {
  const grid = useFetchHelper(
    async () => SysServices.http.sales.getAllSalesRepSummary(),
    "Sales Rep Summary"
  );

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="default-page-layout">
        <h4 className="hide-on-print">Sales Rep Summary</h4>
        <div
          className="mb-4 alert alert-danger p-1 px-2"
          style={{ display: "inline-block" }}
        >
          <small>
            THESE AMOUNTS DO NOT INCLUDE CREDITS ISSUED FOR THE MONTH.
          </small>
        </div>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <>
              <SalesSummaryTableComponent
                allReps={true}
                rows={[...(grid.data || [])].map((row) => {
                  return {
                    ...row,
                    idKey: row.email,
                  };
                })}
              ></SalesSummaryTableComponent>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SalesRepSummary;
