import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import DateRangePicker, {
  DatePeriod,
} from "../components/common/DateRangePicker";
import ColumnHeaderSortComponent from "./common/ColumnHeaderSortComponent";
import PurchaseOrderDialog from "./PurchaseOrderDialog";
import { useNavigate } from "react-router-dom";

const SortTransDate = (props: {
  mode: SysModels.OrderByEnum;
  onToggle: (mode: SysModels.OrderByEnum | null) => void;
}) => {
  return (
    <ColumnHeaderSortComponent
      name="Tran. Date"
      mode={props.mode}
      onClick={(mode) => {
        props.onToggle(mode);
      }}
    ></ColumnHeaderSortComponent>
  );
};

function PurchaseOrders(props: { vendorId?: string }) {
  const navigate = useNavigate();
  const [order, setOrder] = useState(SysModels.OrderByEnum.Descending);
  const [period, setPeriod] = useState<{
    from: string | null;
    to: string | null;
    period?: SysModels.PurchaseOrderSummaryDateRangeEnum;
  }>({
    from: null as any,
    to: null as any,
    period: SysModels.PurchaseOrderSummaryDateRangeEnum.All,
  });

  const dateRange = useFetchHelper(
    () =>
      SysServices.http.genericEnumLookup.get(
        "PurchaseOrderSummaryDateRangeEnum"
      ),
    "Date Range"
  );

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const grid = useFetchHelper(
    () =>
      SysServices.http.purchaseOrder.list({
        page: paging.page,
        pageSize: paging.pageSize,
        isSummary: false,
        orderBy: order,
        dateRange: period.period as any,
        start: period.from || (null as any),
        end: period.to || (null as any),
        search: search.used,
        vendorId: props.vendorId || (null as any),
      }),
    "Purchase Orders"
  );

  useEffect(() => {
    dateRange.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dateRange.status === FetchStatus.Complete) {
      grid.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, period, dateRange.status, paging, search.used]);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tranId, setTranId] = useState<string>();

  return (
    <div className="default-page-layout">
      {!!tranId && (
        <PurchaseOrderDialog
          tranId={tranId}
          onClose={() => setTranId(undefined)}
        ></PurchaseOrderDialog>
      )}

      {!props.vendorId && <h4 className="hide-on-print">Purchase Orders</h4>}
      <div className="bg-white">
        {(dateRange.status === FetchStatus.InProgress ||
          grid.status === FetchStatus.InProgress) && (
          <div className="p-3">
            <CommonSpinner></CommonSpinner>
          </div>
        )}

        <div
          className={grid.status === FetchStatus.Complete ? "" : "display-none"}
        >
          <div className="p-3 flex flex-wrap gap-10 hide-on-print">
            <div className="flex-0">
              <div className="input-group search-box">
                <input
                  autoFocus={true}
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={search.typed}
                  onChange={(e) => {
                    setSearch((data) => {
                      return {
                        ...data,
                        typed: e.target.value,
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearch((data) => {
                        pageChange(1, paging.pageSize);
                        if (data.used === data.typed) {
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                    }
                  }}
                ></input>
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      pageChange(1, paging.pageSize);
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={(e) => {
                      pageChange(1, paging.pageSize);
                      setSearch((data) => {
                        return { typed: "", used: "" };
                      });
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-0">
              {dateRange.status === FetchStatus.Complete && (
                <DateRangePicker
                  disabled={grid.status === FetchStatus.InProgress}
                  from={period.from}
                  to={period.to}
                  align="right"
                  onChanged={(from, to, period) => {
                    setPeriod({
                      from: from,
                      to: to,
                      period: dateRange.data?.find((x) => x.value === period)
                        ?.value,
                    });
                  }}
                  defaultPeriod={
                    SysModels.PurchaseOrderSummaryDateRangeEnum.All as any
                  }
                  periods={[DatePeriod.Custom]}
                  customPeriods={[
                    ...(dateRange.data || [])
                      .sort(commonService.sortByNumericProperty("value"))
                      .filter((x) => x.label !== "Custom"),
                  ]}
                ></DateRangePicker>
              )}
            </div>
          </div>

          <table className="table table-hover">
            <thead>
              {isSmallScreen && (
                <tr>
                  <th>
                    Transactions /{" "}
                    <SortTransDate
                      mode={order}
                      onToggle={(mode) => {
                        setOrder(
                          (mode || 0) === SysModels.OrderByEnum.Descending
                            ? SysModels.OrderByEnum.Ascending
                            : SysModels.OrderByEnum.Descending
                        );
                      }}
                    ></SortTransDate>
                  </th>
                </tr>
              )}
              {!isSmallScreen && (
                <tr>
                  <th>Tran. ID</th>
                  <th>
                    <SortTransDate
                      mode={order}
                      onToggle={(mode) => {
                        setOrder(
                          (mode || 0) === SysModels.OrderByEnum.Descending
                            ? SysModels.OrderByEnum.Ascending
                            : SysModels.OrderByEnum.Descending
                        );
                      }}
                    ></SortTransDate>
                  </th>
                  <th>Expected Date</th>
                  <th>Prepared By</th>
                  <th>Vendor ID</th>
                  <th>Delivery Truck</th>
                </tr>
              )}
            </thead>
            <tbody>
              {grid.data?.purchaseOrderOutputDtos?.map((row) => (
                <tr
                  key={row.tranId}
                  className="pointer"
                  onClick={(e) => setTranId(row.tranId)}
                >
                  {isSmallScreen && (
                    <>
                      <td>
                        <div className="flex flex-center gap-10">
                          <div>
                            <small>Tran. ID:</small> {row.tranId}
                          </div>
                          <div>
                            <small>Tran. Date:</small>{" "}
                            {commonService.toLocalDate(
                              row.tranDate,
                              "MMM DD, YYYY"
                            )}
                          </div>
                        </div>
                        <div>
                          <small>Expected Date:</small>{" "}
                          {commonService.toLocalDate(
                            row.expectedDate,
                            "MMM DD, YYYY"
                          )}
                        </div>
                        <div>
                          <small>Prepared By:</small> {row.preparedBy}
                        </div>
                        <div>
                          <small>Vendor ID:</small>{" "}
                          {props.vendorId ? (
                            <>{row.vendorAccountNo}</>
                          ) : (
                            <>
                              <a
                                href={`/vendors/${row.erpVendorInternalAccountNo}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  navigate(
                                    `/vendors/${row.erpVendorInternalAccountNo}`
                                  );
                                }}
                              >
                                {row.vendorAccountNo}
                              </a>
                            </>
                          )}
                        </div>
                        <div>
                          <small>Delivery Truck:</small> {row.deliveryTruck}
                        </div>
                      </td>
                    </>
                  )}
                  {!isSmallScreen && (
                    <>
                      <td>{row.tranId}</td>
                      <td className="no-wrap">
                        {commonService.toLocalDate(
                          row.tranDate,
                          "MMM DD, YYYY"
                        )}
                      </td>
                      <td className="no-wrap">
                        {commonService.toLocalDate(
                          row.expectedDate,
                          "MMM DD, YYYY"
                        )}
                      </td>
                      <td>{row.preparedBy}</td>
                      <td>
                        {props.vendorId ? (
                          <>{row.vendorAccountNo}</>
                        ) : (
                          <>
                            <a
                              href={`/vendors/${row.erpVendorInternalAccountNo}`}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigate(
                                  `/vendors/${row.erpVendorInternalAccountNo}`
                                );
                              }}
                            >
                              {row.vendorAccountNo}
                            </a>
                          </>
                        )}
                      </td>
                      <td>{row.deliveryTruck}</td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="p-3 pt-0 hide-on-print">
            <Pagination
              length={grid.data?.totalRecords || 0}
              page={paging.page}
              pageSize={paging.pageSize}
              pageChange={pageChange}
              showingOfWhatLabel="entries"
              sizes={[10, 25, 50, 100]}
            ></Pagination>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchaseOrders;
