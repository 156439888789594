import React, { useEffect, useState } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import ConfirmDialog from "../components/common/ConfirmDialog";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import SysServices from "../services";
import { useWindowSize } from "../services/CommonHooks";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import toastStore from "../stores/ToastStore";
import ReportDialog from "./dialogs/ReportDialog";

function ReportMaintenance(props: any) {
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [filter, setFilter] =
    useState<SysModels.IReportMaintenanceGridSearchDto>({
      search: "",
      activeStatusEnum: SysModels.ReportMaintenanceActiveStatusEnum.Active,
      orderByEnum: SysModels.OrderByEnum.Ascending,
    });
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const grid = useFetchHelper(async () => {
    return SysServices.http.report.listMaintenance(
      paging.page,
      paging.pageSize,
      {
        ...filter,
        search: search.used,
      }
    );
  }, "Users");

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, filter]);

  const statusFilter = [
    {
      label: "All",
      value: SysModels.ReportMaintenanceActiveStatusEnum.All,
    },
    {
      label: "Active",
      value: SysModels.ReportMaintenanceActiveStatusEnum.Active,
    },
    {
      label: "Inactive",
      value: SysModels.ReportMaintenanceActiveStatusEnum.Inactive,
    },
  ];

  const { isSmallScreen } = useWindowSize();

  const [showReportDialog, setShowReportDialog] = useState<{
    id?: any;
    show: boolean;
  }>();
  const [showDelReport, setShowDelReport] = useState<{
    id?: any;
    show: boolean;
  }>();
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
  const deleteReport = async (idToDel: any) => {
    setIdsToDelete((list) => {
      return [...list.filter((id) => id !== idToDel), idToDel];
    });
    await SysServices.http.report
      .delete(idToDel)
      .then((data) => {
        toastStore.showToast("Report deleted.", "success");
        grid.getData();
      })
      .catch((error) => {
        toastStore.showError("Failed Deleting Report", error);
      })
      .finally(() => {
        setIdsToDelete((list) => {
          return [...list.filter((id) => id !== idToDel)];
        });
      });
  };

  return (
    <>
      {showDelReport?.show && (
        <ConfirmDialog
          title="Delete Report"
          message="Do you really want to delete this report?"
          buttons="yesno"
          done={(rtn) => {
            if (rtn === "yes" && showDelReport?.id) {
              deleteReport(showDelReport.id);
            }
            setShowDelReport(undefined);
          }}
          show={true}
        ></ConfirmDialog>
      )}
      {showReportDialog?.show && (
        <ReportDialog
          id={showReportDialog.id}
          onClose={(done) => {
            if (done) {
              grid.getData();
            }
            setShowReportDialog(undefined);
          }}
        ></ReportDialog>
      )}
      <div className="default-page-layout">
        <h4 className="hide-on-print">Report Maintenance</h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div>
              <div className="p-3 flex flex-wrap gap-10">
                <div className="flex-0" style={{ maxWidth: "100%" }}>
                  <div className="input-group search-box">
                    <input
                      autoFocus={true}
                      className="form-control"
                      type="text"
                      placeholder="Search"
                      value={search.typed}
                      onChange={(e) => {
                        setSearch((data) => {
                          return {
                            ...data,
                            typed: e.target.value,
                          };
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          e.stopPropagation();
                          setSearch((data) => {
                            pageChange(1, paging.pageSize);
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }
                      }}
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            return { typed: "", used: "" };
                          });
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex-0">
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-primary">
                      <i className="fa fa-filter me-2"></i>{" "}
                      {
                        statusFilter.find(
                          (opt) => opt.value === filter.activeStatusEnum
                        )?.label
                      }
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="left">
                      {statusFilter.map((opt) => (
                        <Dropdown.Item
                          key={opt.value}
                          onClick={(e) => {
                            setFilter((prev) => {
                              return {
                                ...prev,
                                activeStatusEnum: opt.value,
                              };
                            });
                          }}
                          active={opt.value === filter.activeStatusEnum}
                        >
                          {opt.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="flex-1">
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setShowReportDialog({ show: true });
                    }}
                  >
                    <i className="fa fa-plus me-2"></i>Add
                  </Button>
                </div>
              </div>
              <table className="table table-hover pointer">
                <thead>
                  {isSmallScreen && (
                    <tr>
                      <th>Title / Description / Status</th>
                      <th></th>
                    </tr>
                  )}
                  {!isSmallScreen && (
                    <tr>
                      <th style={{ minWidth: "150px" }}>Title</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {grid.data?.reportGridMaintenanceItemDtos?.map((item) => (
                    <tr
                      key={item.id}
                      className="break-word"
                      onClick={(e) => {
                        if (!idsToDelete.includes(item.id)) {
                          setShowReportDialog({ show: true, id: item.id });
                        }
                      }}
                    >
                      {isSmallScreen && (
                        <td>
                          <div>{item.title}</div>
                          <div>
                            <small>{item.description}</small>
                          </div>
                          <div className="no-wrap">
                            {item.isActive && (
                              <span className="chip-green">Active</span>
                            )}
                            {!item.isActive && (
                              <span className="chip-red">Inactive</span>
                            )}
                          </div>
                        </td>
                      )}
                      {!isSmallScreen && (
                        <>
                          <td>{item.title}</td>
                          <td>{item.description}</td>
                          <td>
                            <div className="no-wrap">
                              {item.isActive && (
                                <span className="chip-green">Active</span>
                              )}
                              {!item.isActive && (
                                <span className="chip-red">Inactive</span>
                              )}
                            </div>
                          </td>
                        </>
                      )}
                      <td className="text-right no-wrap">
                        {idsToDelete.includes(item.id) ? (
                          <Spinner
                            variant="danger"
                            animation="border"
                            size="sm"
                          ></Spinner>
                        ) : (
                          <>
                            <i className="fa fa-pencil text-primary pointer me-3"></i>
                            <i
                              className="fa fa-trash text-danger pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowDelReport({ id: item.id, show: true });
                              }}
                            ></i>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="p-3 pt-0">
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="reports"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ReportMaintenance;
