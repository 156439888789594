import commonService from "../../services/CommonService";
import toastStore from "../../stores/ToastStore";

function FileUpload(props: {
  label?: string;
  onChange?: (files: FileList) => void;
  disabled?: boolean;
  accepts?: {
    extensions: string;
    pattern: RegExp;
  };
}) {
  //const [files, setFiles] = useState<FileList>();
  const inputId = `FileInput${commonService.getUniqueId()}`;

  return (
    <div style={{ display: "inline-block" }}>
      <div className="form-group" style={{ display: "inline-block" }}>
        <input
          hidden
          type="file"
          multiple
          onChange={(event) => {
            const fileList = event.target.files;
            if (fileList) {
              if (props.accepts) {
                let cnt = 0;
                while (cnt < fileList.length) {
                  const file = fileList[cnt];
                  if (!props.accepts.pattern.exec(file.name)) {
                    toastStore.showToast(
                      `Invalid file type. Please select files with the following extension(s): <strong>${props.accepts.extensions}</strong>`,
                      "warning"
                    );
                    return;
                  }
                  cnt++;
                }
              }
              //setFiles(fileList);
              props?.onChange && props.onChange(fileList);
            }
          }}
          id={inputId}
          accept={props?.accepts?.extensions || undefined}
          value={""}
        />
        <button
          type="button"
          className="btn btn-sm btn-outline-primary"
          disabled={props?.disabled || false}
          onClick={() => {
            document.getElementById(inputId)?.click();
          }}
        >
          <i className="fa fa-upload me-1"></i> {props?.label || "Upload"}
          {/* {files ? ` (${files.length})` : ""} */}
        </button>
      </div>
    </div>
  );
}

export default FileUpload;
