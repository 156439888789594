import SysModels from "../../models";
import fetcher from "../Fetcher";

class BatchScheduleManagerService {
  getOccurrences = async (rowKey: string) => {
    const url = `/BatchScheduleManager/Occurrences/${rowKey}`;
    return fetcher.get<SysModels.IBatchJobScheduleItemOutputDto[]>(url);
  };

  getOccurrencesFromExpression = async (
    data: SysModels.IBatchJobExpressionInputDto
  ) => {
    const url = `/BatchScheduleManager/OccurrencesFromExpression`;
    return fetcher.post<SysModels.IBatchJobScheduleItemOutputDto>(url, data);
  };

  getAllJobs = async () => {
    const url = `/BatchScheduleManager/AllJobs`;
    return fetcher.get<SysModels.IBatchJobsScheduleEntityOutputDto[]>(url);
  };

  create = async (data: SysModels.IBatchJobScheduleInsertDto) => {
    const url = `/BatchScheduleManager`;
    return fetcher.post<SysModels.IBatchJobsScheduleEntityOutputDto>(url, data);
  };

  delete = async (type: number, rowKey: string) => {
    const url = `/BatchScheduleManager/${type}/${rowKey}`;
    return fetcher.delete<any>(url);
  };

  get = async (type: number, rowKey: string) => {
    const url = `/BatchScheduleManager/${type}/${rowKey}`;
    return fetcher.get<SysModels.IBatchJobsScheduleEntityGetOutputDto>(url);
  };

  update = async (
    type: number,
    rowKey: string,
    data: SysModels.IBatchJobScheduleUpdateDto
  ) => {
    const url = `/BatchScheduleManager/${type}/${rowKey}`;
    return fetcher.put<SysModels.IBatchJobsScheduleEntityGetOutputDto>(
      url,
      data
    );
  };

  startFunction = async (rowKey: string) => {
    const url = `/BatchScheduleManager/StartFunction/${rowKey}`;
    return fetcher.post<any>(url);
  };

  allStatusOfDurable = async (rowKey: string, days: number) => {
    const url = `/BatchScheduleManager/AllStatusOfDurable/${rowKey}/${days}`;
    return fetcher.get<SysModels.IDurableFunctionStatusDto[]>(url);
  };

  singleStatusOfDurable = async (rowKey: string, instanceId: string) => {
    const url = `/BatchScheduleManager/SingleStatusOfDurable/${rowKey}/${instanceId}`;
    return fetcher.get<SysModels.IDurableFunctionStatusDto>(url);
  };

  terminateInstanceOfDurable = async (
    rowKey: string,
    instanceId: string,
    reason: string
  ) => {
    const url = `/BatchScheduleManager/TerminateInstanceOfDurable/${rowKey}/${instanceId}/${encodeURIComponent(
      reason
    )}`;
    return fetcher.post<any>(url);
  };
}

const batchScheduleManager = new BatchScheduleManagerService();
export default batchScheduleManager;
