import React, { useEffect } from "react";
import FormModal from "../../components/common/FormModal";
import { Button, ModalBody, ModalFooter } from "react-bootstrap";
import CommonSpinner from "../../components/common/CommonSpinner";
import Pagination, { usePaging } from "../../components/common/Pagination";
import SysServices from "../../services";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import commonService from "../../services/CommonService";

function BatchRequestCreditMemoDialog(props: { onClose: () => void }) {
  const [paging, setPaging] = usePaging(1, 25);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const grid = useFetchHelper(
    async () =>
      SysServices.http.siteManagement.listBatchReRequestCreditMemos(
        paging.page,
        paging.pageSize
      ),
    "Batch Re-request Credit Memos"
  );

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  return (
    <FormModal
      title="'Rerequest Credit Memos from NetSuite' Progress"
      isOpen={true}
      customBodyFooter={true}
      close={() => {
        props.onClose();
      }}
      size="xl"
    >
      <ModalBody>
        {grid.status === FetchStatus.InProgress && (
          <div className="mt-4">
            <CommonSpinner message="loading..."></CommonSpinner>
          </div>
        )}
        <div>
          {grid.status === FetchStatus.Complete && (
            <div>
              <table className="table table-sm table-striped">
                <thead>
                  <tr>
                    <th className="no-wrap">Credit Memo #</th>
                    <th className="no-wrap">Buying Groups</th>
                    <th className="no-wrap">Requested On</th>
                    <th className="no-wrap">Processed On</th>
                    <th>Error</th>
                  </tr>
                </thead>
                <tbody>
                  {grid.data?.batchReRequestCreditMemoOutputDtos.map((item) => (
                    <tr key={item.creditMemoNumber}>
                      <td>{item.creditMemoNumber}</td>
                      <td>{item.buyingGroups || "All"}</td>
                      <td>
                        {item.error && <div className="pb-2">{item.error}</div>}
                        {item.firstName && (
                          <>
                            By {item.firstName} {item.lastName} on{" "}
                          </>
                        )}
                        <span className="no-wrap">
                          {commonService.toLocalDate(item.requestedOn, "full")}
                        </span>
                      </td>
                      <td>
                        {item.processedOn && (
                          <span className="no-wrap">
                            {commonService.toLocalDate(
                              item.processedOn,
                              "full"
                            )}
                          </span>
                        )}
                      </td>
                      <td>{item.error}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="p-3 pt-0">
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="entries"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={() => {
            props.onClose();
          }}
          variant="secondary"
          size="sm"
        >
          Close
        </Button>
      </ModalFooter>
    </FormModal>
  );
}

export default BatchRequestCreditMemoDialog;
