import React, { useEffect, useState } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import CommonSpinner from "../components/common/CommonSpinner";
import { useWindowSize } from "../stores/SystemStore";
import SysModels from "../models";
import dayjs from "dayjs";
import commonService from "../services/CommonService";
import FileSaver from "file-saver";
import toastStore from "../stores/ToastStore";

const PAGE_SIZE = 100;

function BuyingGroupStorage(props: any) {
  const list = useFetchHelper(
    SysServices.http.buyingGroupStorage.getStorageAreas,
    "Storage Areas"
  );
  const [tokens, setTokens] = useState<string[]>([]);
  const [areaSel, setAreaSel] =
    useState<SysModels.IBuyingGroupStorageAreaOutputDto>();

  const files = useFetchHelper(
    async () =>
      SysServices.http.buyingGroupStorage.listFiles({
        areaId: areaSel?.containerName || (null as any),
        continuationToken: tokens[tokens.length - 1] || (null as any),
      }),
    "Storage Areas"
  );

  const windowSize = useWindowSize();

  useEffect(() => {
    list.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (list.status === FetchStatus.Complete) {
      list.data?.length && setAreaSel(list.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.status]);

  useEffect(() => {
    if (areaSel?.containerName) {
      files.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaSel, tokens]);

  const [page, setPage] = useState(1);
  const numOfPages = () => {
    if (files.data?.buyingGroupFileDisplayOutputDtos) {
      const pages = Math.ceil(
        files.data.buyingGroupFileDisplayOutputDtos.length / PAGE_SIZE
      );
      return pages;
    }
    return 1;
  };
  const isLastPage = () => {
    return page === numOfPages();
  };

  useEffect(() => {
    if (files.status === FetchStatus.Complete) {
      if (page === -1) {
        setPage(numOfPages());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files.status]);

  return (
    <div className="default-page-layout h-100 flex flex-column">
      <h4 className="hide-on-print">Buying Group Storage</h4>
      <div className="bg-white col-sm-12 flex-1 mb-4">
        {list.status === FetchStatus.InProgress && (
          <div className="p-3">
            <CommonSpinner></CommonSpinner>
          </div>
        )}
        {list.status === FetchStatus.Complete && (
          <div className="h-100 flex flex-column">
            <div className="customer-documentation flex-1">
              <div className={`hide-on-print`}>
                <div className="p-3 flex flex-wrap gap-10">
                  <div className="flex-0" style={{ maxWidth: "100%" }}></div>
                </div>

                <table className="table table-hover pointer m-0">
                  <thead>
                    <tr>
                      <th>Storage Areas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.data?.map((area) => (
                      <tr
                        key={area.sqlId}
                        onClick={(e) => {
                          var anchor =
                            document.querySelector("#document-preview");
                          anchor?.scrollIntoView();
                          setAreaSel(area);
                          setTokens([]);
                          setPage(1);
                        }}
                        className={`${
                          area.sqlId === areaSel?.sqlId
                            ? "selected-document"
                            : ""
                        }`}
                      >
                        <td>
                          <div className="flex">
                            <div className="flex-1">{area.buyingGroupName}</div>
                            <div>{/* - */}</div>
                          </div>
                          <div>
                            <small>{area.descriptionOfStorage}</small>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {!list.data?.length && (
                  <div className="text-center py-4">
                    <small>No Record(s) Found</small>
                  </div>
                )}
              </div>
              <div className="flex flex-column">
                <div id="document-preview"></div>
                {files.status === FetchStatus.InProgress ? (
                  <div className="p-4">
                    <CommonSpinner></CommonSpinner>
                  </div>
                ) : (
                  <div className="p-4 flex-1">
                    {!!areaSel && (
                      <h5 className="pb-3">
                        <strong>{areaSel?.buyingGroupName}</strong> /{" "}
                        <small>{areaSel?.descriptionOfStorage}</small>
                      </h5>
                    )}
                    {!!areaSel &&
                      !files.data?.buyingGroupFileDisplayOutputDtos?.length && (
                        <div>No File(s) Found</div>
                      )}
                    {!!files.data?.buyingGroupFileDisplayOutputDtos?.length && (
                      <table className="table table-bordered">
                        <tbody>
                          {files.data?.buyingGroupFileDisplayOutputDtos
                            ?.filter((g, i) => {
                              return (
                                i >= (page - 1) * PAGE_SIZE &&
                                i < page * PAGE_SIZE
                              );
                            })
                            ?.map((file) => (
                              <tr key={file.filename}>
                                {windowSize.isSmall ? (
                                  <>
                                    <td>
                                      {file.filename}
                                      <div>
                                        <small className="flex-1">
                                          {dayjs(file.lastModifiedOn).format(
                                            "MMM DD, YYYY hh:mm A"
                                          )}
                                        </small>
                                      </div>
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>{file.filename}</td>
                                    <td>
                                      <small className="flex-1">
                                        {dayjs(file.lastModifiedOn).format(
                                          "MMM DD, YYYY hh:mm A"
                                        )}
                                      </small>
                                    </td>
                                  </>
                                )}

                                <td className="text-center">
                                  <i
                                    className="fa fa-download text-primary pointer"
                                    onClick={(e) => {
                                      areaSel &&
                                        SysServices.http.buyingGroupStorage
                                          .downloadFile(
                                            areaSel.containerName,
                                            file.filename
                                          )
                                          .then((data) => {
                                            const file =
                                              commonService.b64toBlob(
                                                data.fileContents,
                                                "text/plain"
                                              );
                                            FileSaver.saveAs(
                                              file,
                                              data.fileDownloadName
                                            );
                                          })
                                          .catch((error) => {
                                            toastStore.showError(
                                              "Failed to Download File",
                                              error
                                            );
                                          })
                                          .finally(() => {});
                                    }}
                                  ></i>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}

                {files.status === FetchStatus.Complete &&
                  (tokens.length > 0 ||
                    !!files.data?.continuationToken ||
                    numOfPages() > 1) &&
                  !!files.data?.buyingGroupFileDisplayOutputDtos?.length && (
                    <div className="p-4 pt-0 text-right">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary px-3 me-2"
                        disabled={!tokens.length && page === 1}
                        onClick={(e) => {
                          setTokens([]);
                          setPage(1);
                        }}
                      >
                        <i className="fa fa-angle-double-left me-2"></i>
                        First
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary px-3 me-2"
                        disabled={!tokens.length && page === 1}
                        onClick={(e) => {
                          if (page > 1) {
                            setPage(page - 1);
                            return;
                          }
                          setPage(-1);
                          setTokens((tks) => {
                            return tks.filter((t, i) => {
                              return i < tokens.length - 1;
                            });
                          });
                        }}
                      >
                        <i className="fa fa-angle-left me-2"></i>
                        Prev
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-outline-secondary px-3"
                        disabled={
                          !files.data?.continuationToken && isLastPage()
                        }
                        onClick={(e) => {
                          if (files.data?.buyingGroupFileDisplayOutputDtos) {
                            if (page < numOfPages()) {
                              setPage(page + 1);
                              return;
                            }
                          }

                          setPage(1);
                          setTokens((tks) => {
                            if (files.data?.continuationToken) {
                              return [...tks, files.data.continuationToken];
                            }
                            return tks;
                          });
                        }}
                      >
                        <span className="me-2">Next</span>
                        <i className="fa fa-angle-right"></i>
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BuyingGroupStorage;
