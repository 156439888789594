import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import TableRow from "../components/common/TableRow";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import toastStore from "../stores/ToastStore";
import CustomerLinkComponent from "./common/CustomerLinkComponent";
import IncomingOrderInErrorDialog from "./dialogs/IncomingOrderInErrorDialog";
import { useParams } from "react-router-dom";

export const OrdersInErrorFilterDisplay = [
  {
    label: "Not Fixed",
    value: SysModels.OrdersInErrorFilterByEnum.NotRead,
  },
  {
    label: "Fixed",
    value: SysModels.OrdersInErrorFilterByEnum.Read,
  },
  {
    label: "All",
    value: SysModels.OrdersInErrorFilterByEnum.All,
  },
];

function IncomingOrdersInError(props: any) {
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [filter, setFilter] = useState(
    SysModels.OrdersInErrorFilterByEnum.NotRead
  );
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });
  const grid = useFetchHelper(
    async () =>
      SysServices.http.siteManagement.getListOrdersInError(
        paging.page,
        paging.pageSize,
        {
          search: search.used,
          filterBy: filter,
        }
      ),
    "Incoming Orders In Error"
  );

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, filter]);

  const [doingAction, setDoingAction] = useState(0); //0 default, 1 marking, 2 resubmit

  const doFixInNetSuite = (sqlId: number) => {
    setDoingAction(1);
    SysServices.http.siteManagement
      .markInErrorOrderAsFixed(sqlId)
      .then(() => {
        setDoingAction(0);
        toastStore.showToast(
          "In Error order was marked as fixed in NetSuite",
          "success"
        );
        grid.getData();
      })
      .catch((error) => {
        setDoingAction(0);
        toastStore.showError("Failed marking order as fixed.", error);
      });
  };

  const doResubmit = (sqlId: number) => {
    setDoingAction(2);
    SysServices.http.siteManagement
      .resubmitOrderToNetSuite(sqlId)
      .then(() => {
        setDoingAction(0);
        toastStore.showToast(
          "In Error order was submitted to NetSuite",
          "success"
        );
        grid.getData();
      })
      .catch((error) => {
        setDoingAction(0);
        toastStore.showError("Failed Resubmitting Order.", error);
      });
  };

  const ActionsCellComponent = (data: SysModels.IOrderHeaderOutputDto) => {
    return (
      <>
        <Dropdown>
          {isSmallScreen && (
            <Dropdown.Toggle size="sm" variant="outline-primary">
              <i className="fa fa-bars"></i>
            </Dropdown.Toggle>
          )}
          {!isSmallScreen && (
            <Dropdown.Toggle size="sm" variant="primary">
              Actions
            </Dropdown.Toggle>
          )}

          <Dropdown.Menu align="right">
            <Dropdown.Item
              onClick={() => {
                setDetail(data as any);
              }}
            >
              <i className="fa fa-eye me-3"></i>
              <span>View Details</span>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setPrint(true);
                setDetail(data as any);
              }}
            >
              <i className="fa fa-print me-3"></i>
              <span>Print Order</span>
            </Dropdown.Item>

            {!data.errorRead && (
              <Dropdown.Item
                onClick={() => {
                  doFixInNetSuite(data.sqlId);
                }}
              >
                <i className="fa fa-check-square-o me-3"></i>
                <span>Fixed in NetSuite</span>
              </Dropdown.Item>
            )}

            {data.recordStatus === 16 && (
              <Dropdown.Item
                onClick={() => {
                  doResubmit(data.sqlId);
                }}
              >
                <i className="fa fa-share-square-o me-3"></i>
                <span>Resubmit Order</span>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const getIncomingOrderFilter = (status: number) => {
    if (status === 1) {
      return "Pending";
    }
    if (status === 2) {
      return "Awaiting Re-Submission";
    }
    if (status === 8) {
      return "Not all items sent";
    }
    if (status === 16) {
      return "Rejected by ERP";
    }
    return "Unkown";
  };

  const [print, setPrint] = useState(false);
  const [detail, setDetail] = useState<SysModels.IOrderInErrorOutputDto>();
  const { id } = useParams();

  useEffect(() => {
    const token = id;
    if (token) {
      setDetail((prev) => {
        if (!prev)
          return {
            sqlId: token,
          } as any;
        return {
          ...prev,
          sqlId: token,
        };
      });
    } else {
      setDetail(undefined);
      setPrint(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {doingAction > 0 && (
        <CommonSpinner
          overlay={true}
          message={
            doingAction === 1
              ? "Marking Order as Fixed in NetSuite"
              : "Resubmitting Order"
          }
        ></CommonSpinner>
      )}

      {!!detail && (
        <IncomingOrderInErrorDialog
          detail={detail}
          onClose={() => {
            setDetail(undefined);
            setPrint(false);
          }}
          onRefresh={() => {
            grid.getData();
          }}
          print={print}
        ></IncomingOrderInErrorDialog>
      )}

      <div className="default-page-layout hide-on-print">
        <h4 className="hide-on-print">Incoming Orders In Error</h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <>
              <div className="p-3 flex flex-wrap gap-10">
                <div className="flex-0 w-100">
                  <div className="input-group search-box">
                    <input
                      autoFocus={true}
                      className="form-control form-control-sm"
                      type="text"
                      placeholder="Search"
                      value={search.typed}
                      onChange={(e) => {
                        setSearch((data) => {
                          return {
                            ...data,
                            typed: e.target.value,
                          };
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          e.stopPropagation();
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }
                      }}
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            return { typed: "", used: "" };
                          });
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-primary">
                      {OrdersInErrorFilterDisplay.find(
                        (o) => o.value === filter
                      )?.label || "All"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="left">
                      {OrdersInErrorFilterDisplay.map((opt) => (
                        <Dropdown.Item
                          key={opt.value}
                          onClick={() => {
                            setFilter(opt.value);
                          }}
                          active={opt.value === filter}
                        >
                          <span>{opt.label}</span>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-sm-12">
                <table className="table">
                  <thead>
                    {isSmallScreen && (
                      <tr>
                        <td></td>
                      </tr>
                    )}
                    {!isSmallScreen && (
                      <tr>
                        <th>Customer</th>
                        <th className="no-wrap">BG Order No.</th>
                        <th>Placed On</th>
                        <th>Req. For</th>
                        <th className="no-wrap">ERP Order No.</th>
                        <th className="text-right">Total</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {grid.data?.orderHeaderOutputDtos?.map((order) => (
                      <tr key={order.sqlId}>
                        {isSmallScreen && (
                          <>
                            <td>
                              <div className="flex gap-10">
                                <div className="flex-1">
                                  <CustomerLinkComponent
                                    name={order.customerName}
                                    number={order.customerAccountNo}
                                    numberPartOfName={true}
                                  ></CustomerLinkComponent>
                                </div>
                                <div>
                                  <ActionsCellComponent
                                    {...order}
                                  ></ActionsCellComponent>
                                </div>
                              </div>
                              <div className="mb-1">{order.buyingGroup}</div>
                              <table className="table-label-desc">
                                <tbody>
                                  <TableRow label="BG Order No.:">
                                    <a
                                      href="/"
                                      className="mail-link"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setDetail({
                                          sqlId: order.sqlId,
                                        } as any);
                                      }}
                                    >
                                      {order.externalOrderNo}
                                    </a>
                                  </TableRow>
                                  <TableRow label="Placed On:">
                                    {commonService.toLocalDate(
                                      order.receivedOn,
                                      "MMM DD, YYYY"
                                    )}
                                  </TableRow>
                                  <TableRow label="Req. For:">
                                    {commonService.toLocalDate(
                                      order.deliveryDate,
                                      "MMM DD, YYYY"
                                    )}
                                  </TableRow>
                                  <TableRow label="ERP Order No.:">
                                    {order.erpOrderNumber}
                                  </TableRow>
                                  <TableRow label="Total:">
                                    {commonService.toMoney(order.orderAmount)}
                                  </TableRow>
                                  <TableRow label="Status:">
                                    {getIncomingOrderFilter(order.recordStatus)}
                                  </TableRow>
                                </tbody>
                              </table>
                            </td>
                          </>
                        )}
                        {!isSmallScreen && (
                          <>
                            <td>
                              <CustomerLinkComponent
                                name={order.customerName}
                                number={order.customerAccountNo}
                                numberPartOfName={true}
                              ></CustomerLinkComponent>
                              <div>{order.buyingGroup}</div>
                            </td>
                            <td>
                              <a
                                href="/"
                                className="mail-link"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setDetail({
                                    sqlId: order.sqlId,
                                  } as any);
                                }}
                              >
                                {order.externalOrderNo}
                              </a>
                            </td>
                            <td className="no-wrap">
                              {commonService.toLocalDate(
                                order.receivedOn,
                                "MMM DD, YYYY"
                              )}
                            </td>
                            <td className="no-wrap">
                              {commonService.toLocalDate(
                                order.deliveryDate,
                                "MMM DD, YYYY"
                              )}
                            </td>
                            <td>{order.erpOrderNumber}</td>
                            <td className="text-right">
                              {commonService.toMoney(order.orderAmount)}
                            </td>
                            <td>
                              {getIncomingOrderFilter(order.recordStatus)}
                            </td>
                            <td>
                              <ActionsCellComponent
                                {...order}
                              ></ActionsCellComponent>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="p-3 pt-0">
                  <Pagination
                    length={grid.data?.totalRecords || 0}
                    page={paging.page}
                    pageSize={paging.pageSize}
                    pageChange={pageChange}
                    showingOfWhatLabel="entries"
                    sizes={[10, 25, 50, 100]}
                  ></Pagination>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default IncomingOrdersInError;
