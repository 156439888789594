import dayjs from "dayjs";
import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableRow from "../../components/common/TableRow";
import commonService from "../../services/CommonService";
import systemStore from "../../stores/SystemStore";
import CustomerLinkComponent from "./CustomerLinkComponent";

interface ISummaryRow {
  idKey: any;
  email: string;
  thisMonthAmount: number;
  lastMonthAmount: number;
  threeMonthAmount: number;
  fourMonthAmount: number;
  fiveMonthAmount: number;
  sixMonthAmount: number;
  personName?: string;
  customerName?: string;
  erpCustomerNumber?: string;
}

function SalesSummaryTableComponent({
  allReps,
  rows,
}: {
  allReps?: boolean;
  rows: ISummaryRow[];
}) {
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GoToDetail = (item: ISummaryRow) => {
    return (
      <a
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          navigate(`/salesrepdetail/${item.email}`);
        }}
        href={`/salesrepdetail/${item.email}`}
      >
        {item.personName}
      </a>
    );
  };

  return (
    <div className="overflow-auto">
      <table className="table">
        <thead>
          <tr>
            <th>{!allReps ? "Customer" : "Rep's Name"}</th>
            {!isSmallScreen && (
              <>
                {allReps && <th>Email</th>}
                <th className="text-right">
                  Current ({dayjs().format("MMMM")})
                </th>
                <th className="text-right">
                  Previous ({dayjs().add(-1, "month").format("MMMM")})
                </th>
                <th className="text-right">
                  {dayjs().add(-2, "month").format("MMM YYYY")}
                </th>
                <th className="text-right">
                  {dayjs().add(-3, "month").format("MMM YYYY")}
                </th>
                <th className="text-right">
                  {dayjs().add(-4, "month").format("MMM YYYY")}
                </th>
                <th className="text-right">
                  {dayjs().add(-5, "month").format("MMM YYYY")}
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {rows?.map((summary, i) => (
            <tr key={`${summary.idKey}-${i}`}>
              {allReps && !isSmallScreen && (
                <td>
                  <GoToDetail {...summary}></GoToDetail>
                </td>
              )}
              <td>
                {!allReps && (
                  <>
                    {!!summary.customerName && !!summary.erpCustomerNumber && (
                      <CustomerLinkComponent
                        name={summary.customerName}
                        number={summary.erpCustomerNumber}
                      ></CustomerLinkComponent>
                    )}
                  </>
                )}
                {!!allReps && (
                  <>
                    {isSmallScreen && (
                      <div>
                        <GoToDetail {...summary}></GoToDetail>
                      </div>
                    )}
                    {!!summary.email && (
                      <a href={`mailto:${summary.email}`}>{summary.email}</a>
                    )}
                  </>
                )}
                {isSmallScreen && (
                  <table className="table-label-desc">
                    <tbody>
                      <TableRow label={`Current (${dayjs().format("MMMM")})`}>
                        {commonService.toMoney(summary.thisMonthAmount)}
                      </TableRow>
                      <TableRow
                        label={`Previous (${dayjs()
                          .add(-1, "month")
                          .format("MMMM")})`}
                      >
                        {commonService.toMoney(summary.lastMonthAmount)}
                      </TableRow>
                      <TableRow
                        label={dayjs().add(-2, "month").format("MMMM YYYY")}
                      >
                        {commonService.toMoney(summary.threeMonthAmount)}
                      </TableRow>
                      <TableRow
                        label={dayjs().add(-3, "month").format("MMMM YYYY")}
                      >
                        {commonService.toMoney(summary.fourMonthAmount)}
                      </TableRow>
                      <TableRow
                        label={dayjs().add(-4, "month").format("MMMM YYYY")}
                      >
                        {commonService.toMoney(summary.fiveMonthAmount)}
                      </TableRow>
                      <TableRow
                        label={dayjs().add(-5, "month").format("MMMM YYYY")}
                      >
                        {commonService.toMoney(summary.sixMonthAmount)}
                      </TableRow>
                    </tbody>
                  </table>
                )}
              </td>
              {!isSmallScreen && (
                <>
                  <td className="text-right">
                    {commonService.toMoney(summary.thisMonthAmount)}
                  </td>
                  <td className="text-right">
                    {commonService.toMoney(summary.lastMonthAmount)}
                  </td>
                  <td className="text-right">
                    {commonService.toMoney(summary.threeMonthAmount)}
                  </td>
                  <td className="text-right">
                    {commonService.toMoney(summary.fourMonthAmount)}
                  </td>
                  <td className="text-right">
                    {commonService.toMoney(summary.fiveMonthAmount)}
                  </td>
                  <td className="text-right">
                    {commonService.toMoney(summary.sixMonthAmount)}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SalesSummaryTableComponent;
