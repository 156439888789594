import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import CommonSpinner from "../components/common/CommonSpinner";
import SwitchButton from "../components/common/SwitchButton";
import SysModels from "../models";
import SysServices from "../services";
import { useUserRole } from "../services/CommonHooks";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import toastStore from "../stores/ToastStore";
import userStore from "../stores/UserStore";
import Pagination, { usePaging } from "../components/common/Pagination";
import commonService from "../services/CommonService";

function UserEdit(props: any) {
  const navigate = useNavigate();

  const userRole = useUserRole();
  const [user, setUser] = useState<SysModels.IUserOutputDto | undefined>(
    userStore.userToEdit
  );
  const roles = useFetchHelper(
    async () => SysServices.http.genericEnumLookup.get("UserRolesEnum"),
    "Roles"
  );

  const [isInitiallySalesManager, setIsInitiallySalesManager] = useState(false);

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (!calledOnce) {
      if (!userStore.userToEdit) {
        navigate(`/users`);
      } else {
        setUser(userStore.userToEdit);
        setIsInitiallySalesManager(
          userStore.userToEdit.userRoleDto.isSalesManager
        );
      }
      roles.getData();
      setCalledOnce(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calledOnce, roles]);

  const isRoleSelected = (role: SysModels.UserRolesEnum) => {
    if (!user?.userRoleDto) {
      return false;
    }
    if (role === SysModels.UserRolesEnum.Admin) {
      return user?.userRoleDto?.isAdmin || false;
    }
    if (role === SysModels.UserRolesEnum.ApplicationAdmin) {
      return user?.userRoleDto?.isApplicationAdmin || false;
    }
    if (role === SysModels.UserRolesEnum.CustomerService) {
      return user?.userRoleDto?.isCustomerService || false;
    }
    if (role === SysModels.UserRolesEnum.Executive) {
      return user?.userRoleDto?.isExecutive || false;
    }
    if (role === SysModels.UserRolesEnum.OeManager) {
      return user?.userRoleDto?.isOeManager || false;
    }
    if (role === SysModels.UserRolesEnum.Sales) {
      return user?.userRoleDto?.isSales || false;
    }
    if (role === SysModels.UserRolesEnum.SalesManager) {
      return user?.userRoleDto?.isSalesManager || false;
    }
    if (role === SysModels.UserRolesEnum.DocumentManager) {
      return user?.userRoleDto?.isDocumentManager || false;
    }
    if (role === SysModels.UserRolesEnum.Purchasing) {
      return user?.userRoleDto?.isPurchasing || false;
    }
    if (role === SysModels.UserRolesEnum.LoginAsSalesRep) {
      return user?.userRoleDto?.isLoginAsSalesRep || false;
    }
    return true;
  };

  const checkSelected = (role: SysModels.UserRolesEnum) => {
    return roleList.find((r) => r.value === role)?.checked || false;
  };

  const [saving, setSaving] = useState(false);
  const [active, setActive] = useState(false);
  const submit = async () => {
    if (user) {
      setSaving(true);
      await SysServices.http.user
        .update({
          userId: user.id,
          isActive: active,
          firstName: user.firstName,
          lastName: user.lastName,
          userRoleDto: {
            isAdmin: checkSelected(SysModels.UserRolesEnum.Admin),
            isApplicationAdmin: checkSelected(
              SysModels.UserRolesEnum.ApplicationAdmin
            ),
            isCustomerService: checkSelected(
              SysModels.UserRolesEnum.CustomerService
            ),
            isExecutive: checkSelected(SysModels.UserRolesEnum.Executive),
            isOeManager: checkSelected(SysModels.UserRolesEnum.OeManager),
            isSales: checkSelected(SysModels.UserRolesEnum.Sales),
            isSalesManager: checkSelected(SysModels.UserRolesEnum.SalesManager),
            isDocumentManager: checkSelected(
              SysModels.UserRolesEnum.DocumentManager
            ),
            isPurchasing: checkSelected(SysModels.UserRolesEnum.Purchasing),
            isLoginAsSalesRep: checkSelected(
              SysModels.UserRolesEnum.LoginAsSalesRep
            ),
            none: checkSelected(-1 as any),
          },
        })
        .then((data) => {
          toastStore.showToast("User updated successfully.", "success");
          navigate(`/users`);
        })
        .catch((error) => {
          toastStore.showError("Failed Saving User", error);
          setSaving(false);
        });
    }
  };

  const [roleList, setRoleList] = useState(
    [] as {
      label: string;
      value: number;
      checked?: boolean;
    }[]
  );

  useEffect(() => {
    if (roles.status === FetchStatus.Complete) {
      const list = [
        ...(roles.data || []),
        {
          value: -1,
          label: "None",
        },
      ];
      list.forEach((role) => {
        (role as any).checked = isRoleSelected(role.value);
      });
      list.forEach((role) => {
        if (role.value === -1) {
          (role as any).checked = !list.find(
            (r) => r.value !== -1 && (r as any).checked
          );
        }
      });
      setRoleList(list);
      setActive(user?.isActive || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles.status, user?.userRoleDto]);

  const canEditNames = () => {
    return (
      (userRole & SysModels.UserRolesEnum.Admin) > 0 ||
      (userRole & SysModels.UserRolesEnum.ApplicationAdmin) > 0
    );
  };

  const [paging, setPaging] = usePaging(1, 25);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const availableSalesRep = useFetchHelper(async () => {
    return SysServices.http.user.listSalesReps(paging.page, paging.pageSize, {
      searchCriteria: search.used,
    });
  }, "Sales Reps");

  useEffect(() => {
    if (!isInitiallySalesManager) {
      return;
    }
    const tmo = setTimeout(() => {
      availableSalesRep.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, isInitiallySalesManager]);

  const currentSalesReps = useFetchHelper(async () => {
    return SysServices.http.user.salesRepsForSalesManager(user?.id || "");
  }, "Sales Reps");

  useEffect(() => {
    if (!isInitiallySalesManager) {
      return;
    }

    if (currentSalesReps.status === FetchStatus.Default && user?.id) {
      currentSalesReps.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isInitiallySalesManager]);

  const [salesReps, setSalesReps] = useState<
    SysModels.IUserSalesRepsForSalesManagerDetailDto[]
  >([]);

  useEffect(() => {
    if (currentSalesReps.status === FetchStatus.Complete) {
      setSalesReps([...(currentSalesReps.data || [])]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSalesReps.data, currentSalesReps.status]);

  // const hasSalesRepChanges = useMemo(() => {
  //   return (
  //     JSON.stringify(
  //       salesReps.sort(commonService.sortByStringProperty("personName"))
  //     ) !==
  //     JSON.stringify(
  //       (currentSalesReps.data || []).sort(
  //         commonService.sortByStringProperty("personName")
  //       )
  //     )
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [salesReps, currentSalesReps.data]);

  const [savingSalesReps, setSavingSalesRep] = useState(false);

  const saveSalesReps = async (emails: string[]) => {
    setSavingSalesRep(true);
    await SysServices.http.user
      .addSalesReps(user?.id || "", {
        saleRepEmails: emails,
      })
      .then((data) => {
        if (emails.length > (currentSalesReps.data?.length || 0)) {
          toastStore.showToast("Sales Rep Added", "success");
        } else {
          toastStore.showToast("Sales Rep Removed", "success");
        }
        currentSalesReps.getData();
      })
      .catch((err: any) => {
        toastStore.showError("Failed Saving Sales Reps", err);
      })
      .finally(() => {
        setSavingSalesRep(false);
      });
  };

  return (
    <>
      {saving && (
        <CommonSpinner overlay={true} message="Saving..."></CommonSpinner>
      )}
      {!user && <CommonSpinner message="Loading..."></CommonSpinner>}
      {!!user && (
        <div className="default-page-layout">
          <h4 className="flex flex-wrap flex-center mb-2">
            <span className="flex-1 mb-2">
              {!user.dateUpdated ? "Add User" : "Edit User"}
            </span>
          </h4>
          <div className="row">
            {/* style={{ background: "#fff" }} */}
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                  <h6>
                    <strong>User Information</strong>
                  </h6>

                  <div className="pt-3">
                    <label className="mb-2">First Name</label>
                    <input
                      className="form-control"
                      placeholder="First Name"
                      value={user?.firstName || ""}
                      readOnly={!canEditNames()}
                      onChange={(e) => {
                        setUser((p) => {
                          if (!p) return p;
                          return {
                            ...p,
                            firstName: e.target.value,
                          };
                        });
                      }}
                    ></input>
                  </div>

                  <div className="pt-3">
                    <label className="mb-2">Last Name</label>
                    <input
                      className="form-control"
                      placeholder="Last Name"
                      value={user?.lastName || ""}
                      readOnly={!canEditNames()}
                      onChange={(e) => {
                        setUser((p) => {
                          if (!p) return p;
                          return {
                            ...p,
                            lastName: e.target.value,
                          };
                        });
                      }}
                    ></input>
                  </div>

                  <div className="pt-3">
                    <label className="mb-2">Email</label>
                    <input
                      className="form-control"
                      placeholder="Email"
                      readOnly={true}
                      value={user?.email || ""}
                    ></input>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <h6>
                    <strong>Access Permissions</strong>
                  </h6>

                  <div className="pt-3 flex flex-wrap flex-center">
                    <div className="flex-1 mb-3">
                      <SwitchButton
                        checkedLabel="Active"
                        uncheckedLabel="Inactive"
                        checked={active || false}
                        onChange={(checked) => {
                          setActive(checked);
                        }}
                      ></SwitchButton>
                    </div>
                  </div>
                  {calledOnce && user && (
                    <div className="pt-3">
                      <label className="mb-2">
                        <strong>Roles</strong>
                      </label>
                      <div className="role-list">
                        {roleList
                          .filter((r) => {
                            if (
                              r.value ===
                              SysModels.UserRolesEnum.ApplicationAdmin
                            ) {
                              return (
                                userRole &&
                                !!(
                                  userRole &
                                  SysModels.UserRolesEnum.ApplicationAdmin
                                )
                              );
                            }
                            if (
                              r.value === SysModels.UserRolesEnum.Admin ||
                              r.value ===
                                SysModels.UserRolesEnum.LoginAsSalesRep
                            ) {
                              return (
                                userRole &&
                                !!(
                                  userRole &
                                  (SysModels.UserRolesEnum.ApplicationAdmin +
                                    SysModels.UserRolesEnum.Admin)
                                )
                              );
                            }
                            return true;
                          })
                          .map((role) => (
                            <div key={role.value} className="flex flex-center">
                              <input
                                className="me-2 pointer"
                                type="checkbox"
                                id={`roleId${role.value}`}
                                name="role"
                                value={role.label}
                                checked={role.checked}
                                onChange={(e) => {
                                  setRoleList((prev) => {
                                    return prev.map((p) => {
                                      if (
                                        e.target.checked &&
                                        role.value === -1
                                      ) {
                                        if (p.value === role.value) {
                                          return { ...p, checked: true };
                                        } else {
                                          return { ...p, checked: false };
                                        }
                                      }

                                      if (
                                        e.target.checked &&
                                        role.value !== -1
                                      ) {
                                        if (p.value === -1) {
                                          return { ...p, checked: false };
                                        }
                                      }

                                      if (p.value !== role.value) {
                                        return p;
                                      }
                                      return {
                                        ...p,
                                        checked: e.target.checked,
                                      };
                                    });
                                  });
                                }}
                              ></input>
                              <label
                                htmlFor={`roleId${role.value}`}
                                className="pointer"
                              >
                                {role.label}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-sm-12 mt-4">
              <span>
                <Button
                  variant="outline-secondary"
                  className="mb-2 me-2"
                  onClick={() => {
                    navigate(`/users`);
                  }}
                  disabled={saving}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="mb-2"
                  onClick={submit}
                  disabled={saving}
                >
                  Submit
                </Button>
              </span>
            </div>
          </div>

          {isInitiallySalesManager && (
            <div
              className={`row mt-4 mb-2 ${
                roleList.find(
                  (x) =>
                    x.value === SysModels.UserRolesEnum.SalesManager &&
                    x.checked
                )
                  ? ""
                  : "display-none"
              }`}
            >
              <div className="col-sm-12">
                <h5>Sales Manager's Sales Reps</h5>
              </div>
              <div className="col col-sm-6">
                <table className="table table-sm table-striped">
                  <thead>
                    <tr>
                      <th colSpan={2}>Current Sales Reps</th>
                    </tr>
                  </thead>
                  {!!(
                    !currentSalesReps.data &&
                    currentSalesReps.status === FetchStatus.InProgress
                  ) ? (
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <CommonSpinner></CommonSpinner>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody className="bg-white">
                      {salesReps
                        .sort(commonService.sortByStringProperty("personName"))
                        .map((row, i) => (
                          <tr key={`${row.email}-${i}`}>
                            <td>
                              <div>
                                {commonService.isNullOrWhitespace(
                                  row.personName
                                )
                                  ? row.email
                                  : row.personName}{" "}
                                (<a className="no-wrap">{row.email}</a>)
                              </div>
                            </td>
                            <td style={{ width: "100px" }}>
                              <button
                                type="button"
                                className="btn btn-sm btn-danger px-2"
                                disabled={savingSalesReps}
                                onClick={(e) => {
                                  setSalesReps((list) => {
                                    return list.filter(
                                      (x) => x.email !== row.email
                                    );
                                  });
                                  saveSalesReps(
                                    salesReps
                                      .filter((x) => x.email !== row.email)
                                      .map((x) => x.email)
                                  );
                                }}
                              >
                                {!currentSalesReps?.data?.find(
                                  (x) => row.email === x.email
                                ) ? (
                                  <>
                                    Saving...{" "}
                                    <i className="fa fa-spin fa-spinner"></i>
                                  </>
                                ) : (
                                  <>
                                    Remove <i className="fa fa-arrow-right"></i>
                                  </>
                                )}
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </table>
                {/* <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!hasSalesRepChanges || savingSalesReps}
                    onClick={(e) => {
                      setSavingSalesRep(true);
                      SysServices.http.user
                        .addSalesReps(user?.id, {
                          saleRepEmails: salesReps.map((x) => x.email),
                        })
                        .then((data) => {
                          toastStore.showToast("Sales Rep(s) Saved", "success");
                          currentSalesReps.getData();
                        })
                        .catch((err: any) => {
                          toastStore.showError("Failed Saving Sales Reps", err);
                        })
                        .finally(() => {
                          setSavingSalesRep(false);
                        });
                    }}
                  >
                    {savingSalesReps
                      ? "Saving Sales Rep(s)..."
                      : "Save Sales Rep(s)"}
                  </button>
                </div> */}
              </div>
              <div className="col col-sm-6">
                <table className="table table-sm table-striped mb-0">
                  <thead>
                    <tr>
                      <th colSpan={2}>Available Sales Reps</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <div className="flex-0" style={{ maxWidth: "100%" }}>
                          <div className="input-group search-box">
                            <input
                              autoFocus={true}
                              className="form-control"
                              type="text"
                              placeholder="Search"
                              value={search.typed}
                              onChange={(e) => {
                                setSearch((data) => {
                                  return {
                                    ...data,
                                    typed: e.target.value,
                                  };
                                });
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  pageChange(1, paging.pageSize);
                                  setSearch((data) => {
                                    if (data.used === data.typed) {
                                      return data;
                                    }
                                    return {
                                      ...data,
                                      used: data.typed,
                                    };
                                  });
                                }
                              }}
                            ></input>
                            <div className="input-group-append">
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={(e) => {
                                  pageChange(1, paging.pageSize);
                                  setSearch((data) => {
                                    if (data.used === data.typed) {
                                      return data;
                                    }
                                    return {
                                      ...data,
                                      used: data.typed,
                                    };
                                  });
                                }}
                              >
                                <i className="fa fa-search"></i>
                              </button>
                              <button
                                className="btn btn-secondary"
                                type="button"
                                onClick={(e) => {
                                  pageChange(1, paging.pageSize);
                                  setSearch((data) => {
                                    return { typed: "", used: "" };
                                  });
                                }}
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  {availableSalesRep.status === FetchStatus.InProgress ? (
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <CommonSpinner></CommonSpinner>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody className="bg-white">
                      {!availableSalesRep.data?.asSalesRepOutputDtos
                        ?.length && (
                        <tr>
                          <td colSpan={2} className="text-center p-2">
                            No Sales Rep(s) Found
                          </td>
                        </tr>
                      )}
                      {availableSalesRep.data?.asSalesRepOutputDtos?.map(
                        (row) => (
                          <tr key={row.email}>
                            <td style={{ width: "120px" }}>
                              <button
                                type="button"
                                className="btn btn-sm btn-success"
                                onClick={(e) => {
                                  setSalesReps((list) => {
                                    return [...list, row];
                                  });
                                  saveSalesReps(
                                    [...salesReps, row].map((x) => x.email)
                                  );
                                }}
                                disabled={
                                  savingSalesReps ||
                                  !!salesReps.find((x) => x.email === row.email)
                                }
                                style={{ width: "100px" }}
                              >
                                {salesReps.find(
                                  (x) => x.email === row.email
                                ) ? (
                                  <>
                                    <i className="fa fa-check me-1"></i> Added
                                  </>
                                ) : (
                                  <>
                                    <i className="fa fa-arrow-left me-1"></i>{" "}
                                    Add
                                  </>
                                )}
                              </button>
                            </td>
                            <td>
                              <div>
                                {row.personName} (
                                <a className="no-wrap">{row.email}</a>)
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  )}
                </table>
                <div className="p-0">
                  <Pagination
                    length={availableSalesRep.data?.totalRecords || 0}
                    page={paging.page}
                    pageSize={paging.pageSize}
                    pageChange={pageChange}
                    showingOfWhatLabel="users"
                    sizes={[10, 25, 50, 100]}
                  ></Pagination>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default UserEdit;
