import React, { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import CommonSpinner from "../../../components/common/CommonSpinner";
import ConfirmDialog from "../../../components/common/ConfirmDialog";
import SysServices from "../../../services";
import { FetchStatus, useFetchHelper } from "../../../services/FetchHelper";
import toastStore from "../../../stores/ToastStore";

export interface ISelectedCategoryModel {
  id: any;
  name: string;
}
export interface IQueryModel {
  id: any;
  name: string;
  items: ISelectedCategoryModel[];
}

function CategoryQueryFilters(props: {
  reportId: string;
  reportName: string;
  selectedCategory: ISelectedCategoryModel[];
  onSelectedCategoryChanged: (list: ISelectedCategoryModel[]) => void;
}) {
  const [showQueries, setShowQueries] = useState(false);
  const [queries, setQueries] = useState<IQueryModel[]>([]);
  const [query, setQuery] = useState<IQueryModel>({
    id: 0,
    name: "",
    items: [],
  });
  const [queryToDel, setQueryToDel] = useState<IQueryModel>();
  const [selectedCategory, setSelectedCategory] = useState<
    ISelectedCategoryModel[]
  >([]);
  const hasChanges = useMemo(() => {
    const match = queries.find((q) => query.id === q.id);
    if (!match) {
      return true;
    }
    return (
      JSON.stringify({
        ...query,
        items: selectedCategory,
      }) !== JSON.stringify(match)
    );
  }, [query, queries, selectedCategory]);

  const toggleCategory = (id: any, name: string) => {
    setSelectedCategory((list) => {
      if (list.find((c) => c.id === id)) {
        return list.filter((c) => c.id !== id);
      }
      return [...list, { id, name }];
    });
  };

  useEffect(() => {
    const list = props.selectedCategory || [];
    setSelectedCategory(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedCategory]);

  useEffect(() => {
    const list = props.selectedCategory || [];
    if (JSON.stringify(list) === JSON.stringify(selectedCategory)) {
      return; //do nothing
    }
    props.onSelectedCategoryChanged(selectedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    if (query.items.length) {
      props.onSelectedCategoryChanged(query.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.items]);

  const [loading, setLoading] = useState(false);
  const loadQueries = async (afterSave = false) => {
    setLoading(true);
    await SysServices.http.reportQuery
      .list(1, 10, props.reportId)
      .then((rtn) => {
        const data: IQueryModel[] = [];
        if (rtn && rtn.reportQueryGridItemDtos) {
          rtn.reportQueryGridItemDtos.forEach((item) => {
            data.push({
              id: item.id,
              name: item.name,
              items: JSON.parse(item.json || "[]"),
            });
          });
        }

        setQueries(data);
        setLoading(false);

        if (afterSave && query.name.trim()) {
          const match = data.find((q) => q.name.trim() === query.name.trim());
          if (match) {
            setQuery(match);
          }
        }
      })
      .catch((err) => {
        toastStore.showError("Failed getting Queries.", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadQueries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [saving, setSaving] = useState(false);
  const saveQuery = async () => {
    if (
      queries.find(
        (q) => q.name.trim() === query.name.trim() && q.id !== query.id
      )
    ) {
      toastStore.showToast("Query name already exists.", "warning");
      return;
    }
    if (queries.length >= 10) {
      toastStore.showToast(
        "Only a maximum of 10 queries can saved.",
        "warning"
      );
      return;
    }
    if (selectedCategory.length < 1) {
      toastStore.showToast("Please select at least one category", "warning");
      return;
    }

    const data = [...queries];
    let isNew = false;
    if (data.find((d) => d.id === query.id)) {
      data.forEach((d) => {
        if (d.id === query.id) {
          d.name = query.name;
          d.items = selectedCategory;
        }
      });
    } else {
      isNew = true;
      data.push({
        ...query,
        items: selectedCategory,
      });
    }

    setSaving(true);

    const dto = {
      reportId: props.reportId,
      name: query.name.trim(),
      json: JSON.stringify(selectedCategory),
    };

    if (isNew) {
      await SysServices.http.reportQuery
        .create(dto)
        .then(() => {
          toastStore.showToast("Query saved.", "success");
          localStorage.setItem(props.reportName, JSON.stringify(data));
          setQueries(data);
          setSaving(false);
          loadQueries(true);
        })
        .catch((err) => {
          toastStore.showError("Failed saving Query.", err);
          setSaving(false);
        });
    } else {
      await SysServices.http.reportQuery
        .update(query.id, dto)
        .then(() => {
          toastStore.showToast("Query saved.", "success");
          localStorage.setItem(props.reportName, JSON.stringify(data));
          setQueries(data);
          setSaving(false);
        })
        .catch((err) => {
          toastStore.showError("Failed saving Query.", err);
          setSaving(false);
        });
    }
  };

  const [idsToDel, setIdsToDel] = useState<string[]>([]);
  const delQuery = async (id: string) => {
    if (id === query.id) {
      removeFilter();
    }

    setIdsToDel((list) => [...list, id]);
    await SysServices.http.reportQuery
      .delete(id, props.reportId)
      .then(() => {
        toastStore.showToast("Query deleted.", "success");
        const data = queries.filter((d) => d.id !== id);
        localStorage.setItem(props.reportName, JSON.stringify(data));
        setQueries(data);
        setIdsToDel((list) => list.filter((i) => i !== id));
      })
      .catch((err) => {
        toastStore.showError("Failed deleting Query.", err);
        setIdsToDel((list) => list.filter((i) => i !== id));
      });
  };

  const removeFilter = () => {
    setQuery({
      id: 0,
      name: "",
      items: [],
    });
    props.onSelectedCategoryChanged([]);
  };

  const categories = useFetchHelper(
    async () => SysServices.http.reportView.openOrdersCategories(),
    "Category"
  );

  useEffect(() => {
    categories.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //0-none, 1-some, 2-all
  const pageSelection = () => {
    if (
      categories.data &&
      categories.data.catalogCategoryOutputDtos?.length > 0
    ) {
      const cnt = categories.data.catalogCategoryOutputDtos.filter((i) =>
        selectedCategory.find((c) => c.id === i.id)
      ).length;
      if (cnt === 0) {
        return 0;
      }
      return categories.data.catalogCategoryOutputDtos.length === cnt ? 2 : 1;
    }
    return 0;
  };

  return (
    <>
      {!!queryToDel && (
        <ConfirmDialog
          show={true}
          title="Delete Query Filter"
          message="Do you really want to delete this query filter?"
          buttons="yesno"
          done={(rtn) => {
            if (rtn === "yes") {
              delQuery(queryToDel.id);
            }
            setQueryToDel(undefined);
          }}
        ></ConfirmDialog>
      )}

      {categories.status === FetchStatus.InProgress && (
        <div className="py-4">
          <CommonSpinner></CommonSpinner>
        </div>
      )}

      <table
        className={`table table-hover bg-white ${
          categories.status === FetchStatus.Complete ? "" : "display-none"
        }`}
      >
        <thead>
          <tr>
            <th className="no-wrap">
              <div className="flex flex-center gap-10">
                <span className="flex-0">Category</span>
                <div className="flex-1">
                  <div className="flex flex-1 flex-center gap-10">
                    <div
                      className="flex-1 px-2"
                      style={{ position: "relative" }}
                    >
                      {!!query.name.trim() && (
                        <div
                          className="flex flex-center alert alert-sm alert-danger p-0 m-0 no-wrap"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            maxWidth: "100%",
                            transform: "translateY(-50%)",
                          }}
                          title={query.name}
                        >
                          <div
                            className="no-wrap px-2"
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {query.name}
                          </div>
                          <i
                            className="fa fa-times text-danger pointer p-1 px-2"
                            style={{
                              backgroundColor: "#f5c2c7",
                            }}
                            title="Remove Filter"
                            onClick={(e) => {
                              removeFilter();
                            }}
                          ></i>
                        </div>
                      )}
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="p-0 border-none text-primary"
                        variant="default"
                        size="sm"
                        style={{ fontSize: "12px" }}
                      >
                        <strong>{selectedCategory.length} Selected</strong>
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ minWidth: "280px" }}>
                        <div className="p-2 mb-2">
                          {showQueries && (
                            <div className="flex flex-center gap-10">
                              <button
                                type="button"
                                className="btn btn-sm btn-danger"
                                onClick={(e) => setShowQueries(false)}
                              >
                                <i className="fa fa-angle-left"></i> Back
                              </button>
                              <div className="flex-1">
                                <strong>Query List</strong>
                              </div>
                            </div>
                          )}
                          {!showQueries && (
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="new-password"
                                value={query.name || ""}
                                onChange={(e) => {
                                  setQuery((p) => {
                                    return {
                                      ...p,
                                      name: e.target.value || "",
                                    };
                                  });
                                }}
                                placeholder="Enter Query Name"
                                maxLength={50}
                                disabled={query.id === -1 || query.id === -2}
                              ></input>
                              <div className="input-group-append">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary"
                                  title="Save"
                                  onClick={(e) => {
                                    saveQuery();
                                  }}
                                  disabled={
                                    saving ||
                                    !query.name.trim() ||
                                    !hasChanges ||
                                    query.id === -1 ||
                                    query.id === -2
                                  }
                                >
                                  {saving ? (
                                    <i className="fa fa-spin fa-spinner"></i>
                                  ) : (
                                    <i className="fa fa-save"></i>
                                  )}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-secondary"
                                  title="Query List"
                                  onClick={(e) => setShowQueries(true)}
                                >
                                  List <i className="fa fa-angle-right"></i>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>

                        {showQueries && loading && (
                          <Dropdown.Header>Loading...</Dropdown.Header>
                        )}
                        {/* {showQueries && !loading && queries.length === 0 && (
                          <Dropdown.Header>No Queries Saved</Dropdown.Header>
                        )} */}
                        {showQueries && !loading && (
                          <>
                            {[
                              {
                                id: -1,
                                name: "Default 1",
                                items: [
                                  ...(
                                    categories.data?.defaultOneDtos || []
                                  )?.map((c) => {
                                    return {
                                      id: c.id,
                                      name: `${c.displayDescription}`,
                                    };
                                  }),
                                ],
                              },
                              {
                                id: -2,
                                name: "Default 2",
                                items: [
                                  ...(
                                    categories.data?.defaultTwoDtos || []
                                  )?.map((c) => {
                                    return {
                                      id: c.id,
                                      name: `${c.displayDescription}`,
                                    };
                                  }),
                                ],
                              },
                              ...queries,
                            ].map((q) => (
                              <Dropdown.Item key={q.id}>
                                <div
                                  className="flex gap-5 flex-center"
                                  style={{
                                    maxWidth: "350px",
                                    whiteSpace: "normal",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setQuery(q);
                                    setSelectedCategory([...q.items]);
                                    setShowQueries(false);
                                  }}
                                >
                                  <small className="flex-1">{q.name}</small>
                                  {idsToDel.find((i) => i === q.id) ? (
                                    <i className="fa fa-spin fa-spinner text-danger"></i>
                                  ) : q.id < 0 ? (
                                    <></>
                                  ) : (
                                    <i
                                      className="fa fa-trash text-danger pointer"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setQueryToDel(q);
                                      }}
                                    ></i>
                                  )}
                                </div>
                              </Dropdown.Item>
                            ))}
                          </>
                        )}
                        {!showQueries &&
                          selectedCategory.length > 0 &&
                          !(query.id === -1 || query.id === -2) && (
                            <>
                              <Dropdown.Item
                                className="text-danger"
                                onClick={(e) => {
                                  //setSelectedCategory([]);
                                  removeFilter();
                                }}
                              >
                                <div className="flex gap-5 flex-center">
                                  <span className="flex-1">Clear All</span>
                                  <i className="fa fa-trash"></i>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Divider></Dropdown.Divider>
                            </>
                          )}
                        {!showQueries && selectedCategory.length === 0 && (
                          <Dropdown.Header>
                            No Category Selected
                          </Dropdown.Header>
                        )}
                        {!showQueries && (
                          <div
                            style={{
                              minWidth: "300px",
                              maxHeight: "500px",
                              overflow: "auto",
                            }}
                          >
                            {selectedCategory.map((c) => (
                              <Dropdown.Item key={c.id}>
                                <div
                                  className="flex gap-5 flex-center"
                                  style={{
                                    maxWidth: "350px",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  <small className="flex-1">
                                    {c.name} ({c.id})
                                  </small>

                                  {!(query.id === -1 || query.id === -2) && (
                                    <i
                                      className="fa fa-trash text-danger pointer"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        toggleCategory(c.id, c.name);
                                      }}
                                    ></i>
                                  )}
                                </div>
                              </Dropdown.Item>
                            ))}
                          </div>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </th>
            <th className="text-right" style={{ width: "15px" }}>
              <input
                type="checkbox"
                checked={pageSelection() === 2}
                onChange={(e) => {
                  if (
                    categories.data &&
                    categories.data.catalogCategoryOutputDtos
                  ) {
                    if (pageSelection() !== 2) {
                      setSelectedCategory((list) => {
                        const newList = [
                          ...list.filter(
                            (s) =>
                              !categories.data?.catalogCategoryOutputDtos?.find(
                                (e) => e.id === s.id
                              )
                          ),
                          ...(
                            categories.data?.catalogCategoryOutputDtos || []
                          ).map((e) => {
                            return {
                              id: e.id,
                              name: e.displayDescription,
                            };
                          }),
                        ];
                        return newList;
                      });
                    } else {
                      setSelectedCategory((list) => {
                        const newList = [
                          ...list.filter(
                            (s) =>
                              !categories.data?.catalogCategoryOutputDtos?.find(
                                (e) => e.id === s.id
                              )
                          ),
                        ];
                        return newList;
                      });
                    }
                  }
                }}
              ></input>
            </th>
          </tr>
        </thead>
        <tbody>
          {categories?.data?.catalogCategoryOutputDtos?.map((item, idx) => (
            <tr
              key={`${item.id}-${idx}`}
              className="pointer"
              onClick={(e) => {
                toggleCategory(item.id, item.displayDescription);
              }}
            >
              <td>
                <small>
                  {item.displayDescription} ({item.displayId})
                </small>
              </td>
              <td className="text-right">
                <input
                  type="checkbox"
                  checked={!!selectedCategory.find((c) => c.id === item.id)}
                  onChange={(e) => {
                    //do nothing...
                  }}
                ></input>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className={`p-3 px-0 pt-0 ${
          categories.data &&
          categories.data?.catalogCategoryOutputDtos?.length > 0 &&
          categories.status === FetchStatus.Complete
            ? ""
            : "display-none"
        }`}
      ></div>

      {categories.data &&
        !categories.data?.catalogCategoryOutputDtos?.length &&
        categories.status === FetchStatus.Complete && (
          <div className="py-4 text-center">No Categories Found</div>
        )}
    </>
  );
}

export default CategoryQueryFilters;
