import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SalesWeeklySummaryTableComponent from "./common/SalesWeeklySummaryTableComponent";
import { Tab, Tabs } from "react-bootstrap";
import SalesSummaryTableComponent from "./common/SalesSummaryTableComponent";
import commonService from "../services/CommonService";
import CustomerLinkComponent from "./common/CustomerLinkComponent";
import dayjs from "dayjs";
import toastStore from "../stores/ToastStore";
import FileSaver from "file-saver";

function MySalesSummary(props: any) {
  //WEEKLY
  const [weekyLoaded, setWeekyLoaded] = useState(true);
  const gridWeekly = useFetchHelper(
    async () => SysServices.http.sales.getWeeklySalesSummary(),
    "Weekly Sales Summary"
  );
  const [pagingWeekly, setPagingWeekly] = usePaging(1, 50);
  const pageChangeWeekly = (page: number, pageSize: number) => {
    setPagingWeekly({ ...pagingWeekly, page: page, pageSize: pageSize });
  };

  useEffect(() => {
    if (weekyLoaded) {
      gridWeekly.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekyLoaded]); //pagingWeekly,

  //MONTHLY
  const [monthlyLoaded, setMonthlyLoaded] = useState(false);
  const gridMonthly = useFetchHelper(
    async () => SysServices.http.sales.getMyCustomerSalesSummary(),
    "Monthly Sales Summary"
  );
  const [pagingMonthly, setPagingMonthly] = usePaging(1, 50);
  const pageChangeMonthly = (page: number, pageSize: number) => {
    setPagingMonthly({ ...pagingMonthly, page: page, pageSize: pageSize });
  };

  useEffect(() => {
    if (monthlyLoaded) {
      gridMonthly.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyLoaded]);

  //YEARLY
  const [yearlyLoaded, setYearlyLoaded] = useState(false);
  const gridYearly = useFetchHelper(
    async () => SysServices.http.sales.yearlySalesSummary(),
    "Annual Sales Summary"
  );
  const [pagingYearly, setPagingYearly] = usePaging(1, 50);
  const pageChangeYearly = (page: number, pageSize: number) => {
    setPagingYearly({ ...pagingYearly, page: page, pageSize: pageSize });
  };

  useEffect(() => {
    if (yearlyLoaded) {
      gridYearly.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearlyLoaded]);

  const [selTab, setSelTab] = useState("WeeklySummary");
  const [downloading, setDownloading] = useState<{ [key: string]: boolean }>(
    {}
  );

  const downloadCsv = async (tabName: string, req: Promise<any>) => {
    if (downloading[tabName]) return;
    setDownloading((prev) => {
      const data = { ...prev };
      data[tabName] = true;
      return data;
    });
    req
      .then((data) => {
        const file = commonService.b64toBlob(data.fileContents, "text/csv");
        FileSaver.saveAs(file, `${data.fileDownloadName}.csv`);
      })
      .catch((err) => {
        toastStore.showError("Download Failed", err);
      })
      .finally(() => {
        setDownloading((prev) => {
          const data = { ...prev };
          data[tabName] = false;
          return data;
        });
      });
  };

  return (
    <>
      <div className="default-page-layout">
        <h4 className="hide-on-print">My Sales Summary</h4>
        <div
          className="mb-4 alert alert-danger p-1 px-2"
          style={{ display: "inline-block" }}
        >
          <small>
            THESE AMOUNTS DO NOT INCLUDE CREDITS ISSUED FOR THE MONTH.
          </small>
        </div>
        <Tabs
          transition={false}
          onSelect={(tab) => {
            tab && setSelTab(tab);
            if (tab === "WeeklySummary") {
              !weekyLoaded && setWeekyLoaded(true);
            }
            if (tab === "MonthlySummary") {
              !monthlyLoaded && setMonthlyLoaded(true);
            }
            if (tab === "YearlySummary") {
              !yearlyLoaded && setYearlyLoaded(true);
            }
          }}
        >
          <Tab
            eventKey="WeeklySummary"
            title={
              <div>
                <span className="me-2">Weekly Summary</span>{" "}
                {selTab === "WeeklySummary" && (
                  <>
                    <i
                      title="Refresh"
                      className={`fa text-primary pointer me-2 ${
                        gridWeekly.status === FetchStatus.InProgress
                          ? "fa-spin fa-circle-o-notch"
                          : "fa-refresh"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (gridWeekly.status !== FetchStatus.InProgress) {
                          pageChangeWeekly(1, pagingWeekly.pageSize);
                          gridWeekly.getData();
                        }
                      }}
                    ></i>
                    <i
                      title="Download"
                      className={`fa text-primary pointer ${
                        downloading[selTab]
                          ? "fa-spin fa-circle-o-notch"
                          : "fa-download"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        downloadCsv(
                          selTab,
                          SysServices.http.sales.downloadWeeklySalesSummaryCsv()
                        );
                      }}
                    ></i>
                  </>
                )}
              </div>
            }
          >
            <>
              <div className="bg-white">
                {gridWeekly.status === FetchStatus.InProgress && (
                  <div className="p-3">
                    <CommonSpinner></CommonSpinner>
                  </div>
                )}
                {gridWeekly.status === FetchStatus.Complete && (
                  <>
                    <SalesWeeklySummaryTableComponent
                      rows={[
                        ...(gridWeekly.data
                          ?.weeklyCustomerSalesSummaryBySalesRepOutputDtos ||
                          []),
                      ]
                        .map((row) => {
                          return {
                            ...row,
                            idKey: row.erpCustomerNumber,
                          };
                        })
                        .filter((row, i) => {
                          return (
                            (pagingWeekly.page - 1) * pagingWeekly.pageSize <=
                              i && pagingWeekly.page * pagingWeekly.pageSize > i
                          );
                        })}
                    ></SalesWeeklySummaryTableComponent>
                    <div className="p-3 pt-0">
                      <Pagination
                        length={gridWeekly.data?.totalRecords || 0}
                        page={pagingWeekly.page}
                        pageSize={pagingWeekly.pageSize}
                        pageChange={pageChangeWeekly}
                        showingOfWhatLabel="entries"
                        sizes={[10, 25, 50, 100]}
                      ></Pagination>
                    </div>
                  </>
                )}
              </div>
            </>
          </Tab>
          <Tab
            eventKey="MonthlySummary"
            title={
              <div>
                <span className="me-2">Monthly Summary</span>{" "}
                {selTab === "MonthlySummary" && (
                  <>
                    <i
                      title="Refresh"
                      className={`fa text-primary pointer me-2 ${
                        gridMonthly.status === FetchStatus.InProgress
                          ? "fa-spin fa-circle-o-notch"
                          : "fa-refresh"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (gridMonthly.status !== FetchStatus.InProgress) {
                          pageChangeMonthly(1, pagingMonthly.pageSize);
                          gridMonthly.getData();
                        }
                      }}
                    ></i>
                    <i
                      title="Download"
                      className={`fa text-primary pointer ${
                        downloading[selTab]
                          ? "fa-spin fa-circle-o-notch"
                          : "fa-download"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        downloadCsv(
                          selTab,
                          SysServices.http.sales.downloadMonthlySalesSummaryCsv()
                        );
                      }}
                    ></i>
                  </>
                )}
              </div>
            }
          >
            <>
              <div className="bg-white">
                {gridMonthly.status === FetchStatus.InProgress && (
                  <div className="p-3">
                    <CommonSpinner></CommonSpinner>
                  </div>
                )}
                {gridMonthly.status === FetchStatus.Complete && (
                  <>
                    <SalesSummaryTableComponent
                      rows={[
                        ...(gridMonthly.data
                          ?.customerSalesSummaryBySalesRepOutputDtos || []),
                      ]
                        .map((row) => {
                          return {
                            ...row,
                            idKey: row.erpCustomerNumber,
                          };
                        })
                        .filter((row, i) => {
                          return (
                            (pagingMonthly.page - 1) * pagingMonthly.pageSize <=
                              i &&
                            pagingMonthly.page * pagingMonthly.pageSize > i
                          );
                        })}
                    ></SalesSummaryTableComponent>
                    <div className="p-3 pt-0">
                      <Pagination
                        length={gridMonthly.data?.totalRecords || 0}
                        page={pagingMonthly.page}
                        pageSize={pagingMonthly.pageSize}
                        pageChange={pageChangeMonthly}
                        showingOfWhatLabel="entries"
                        sizes={[10, 25, 50, 100]}
                      ></Pagination>
                    </div>
                  </>
                )}
              </div>
            </>
          </Tab>

          <Tab
            eventKey="YearlySummary"
            title={
              <div>
                <span className="me-2">Annual Summary</span>{" "}
                {selTab === "YearlySummary" && (
                  <>
                    <i
                      title="Refresh"
                      className={`fa text-primary pointer me-2 ${
                        gridYearly.status === FetchStatus.InProgress
                          ? "fa-spin fa-circle-o-notch"
                          : "fa-refresh"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (gridYearly.status !== FetchStatus.InProgress) {
                          pageChangeYearly(1, pagingYearly.pageSize);
                          gridYearly.getData();
                        }
                      }}
                    ></i>
                    <i
                      title="Download"
                      className={`fa text-primary pointer ${
                        downloading[selTab]
                          ? "fa-spin fa-circle-o-notch"
                          : "fa-download"
                      }`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        downloadCsv(
                          selTab,
                          SysServices.http.sales.downloadYearlySalesSummaryCsv()
                        );
                      }}
                    ></i>
                  </>
                )}
              </div>
            }
          >
            <>
              <div className="bg-white">
                {gridYearly.status === FetchStatus.InProgress && (
                  <div className="p-3">
                    <CommonSpinner></CommonSpinner>
                  </div>
                )}
                {gridYearly.status === FetchStatus.Complete && (
                  <>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Customer</th>
                          <th>Current ({dayjs().format("YYYY")})</th>
                          <th>
                            Previous ({dayjs().add(-1, "year").format("YYYY")})
                          </th>
                          <th>{dayjs().add(-2, "year").format("YYYY")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gridYearly.data?.yearlyCustomerSalesSummaryBySalesRepOutputDtos
                          ?.filter((row, i) => {
                            return (
                              (pagingYearly.page - 1) * pagingYearly.pageSize <=
                                i &&
                              pagingYearly.page * pagingYearly.pageSize > i
                            );
                          })
                          ?.map((row) => (
                            <tr key={row.erpCustomerNumber}>
                              <td>
                                <CustomerLinkComponent
                                  name={row.customerName}
                                  number={row.erpCustomerNumber}
                                ></CustomerLinkComponent>
                              </td>
                              <td>
                                {commonService.toMoney(row.thisYearAmount)}
                              </td>
                              <td>
                                {commonService.toMoney(row.lastYearAmount)}
                              </td>
                              <td>
                                {commonService.toMoney(row.threeYearAmount)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="p-3 pt-0">
                      <Pagination
                        length={gridYearly.data?.totalRecords || 0}
                        page={pagingYearly.page}
                        pageSize={pagingYearly.pageSize}
                        pageChange={pageChangeYearly}
                        showingOfWhatLabel="entries"
                        sizes={[10, 25, 50, 100]}
                      ></Pagination>
                    </div>
                  </>
                )}
              </div>
            </>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default MySalesSummary;
