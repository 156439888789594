import dayjs from "dayjs";
import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import DateRangePicker, {
  DatePeriod,
} from "../components/common/DateRangePicker";
import Pagination, { usePaging } from "../components/common/Pagination";
import TableRow from "../components/common/TableRow";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import ColumnHeaderSortComponent from "./common/ColumnHeaderSortComponent";
import CustomerLinkComponent from "./common/CustomerLinkComponent";
import IncomingOrderDialog from "./dialogs/IncomingOrderDialog";

function IncomingOrders(props: any) {
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [filter, setFilter] = useState(SysModels.IncomingOrderFilterByEnum.All);
  const [orderBy, setOrderBy] = useState(SysModels.OrderByEnum.Descending);
  const [sortBy, setSortBy] = useState(
    SysModels.IncomingOrderSortByEnum.PlacedOn
  );
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });
  const [period, setPeriod] = useState<{
    from: string | null;
    to: string | null;
  }>({ from: null, to: null });

  const grid = useFetchHelper(
    async () =>
      SysServices.http.siteManagement.listIncomingOrders(
        paging.page,
        paging.pageSize,
        {
          search: search.used,
          filterBy: filter,
          sortBy: sortBy,
          orderBy: orderBy,
          startDate: period.from as any,
          endDate: period.to as any,
        }
      ),
    "Incoming Orders"
  );

  const statuses = useFetchHelper(
    async () =>
      SysServices.http.genericEnumLookup.get("IncomingOrderFilterByEnum"),
    "Statuses"
  );

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, filter, orderBy, sortBy, period]);

  const ActionsCellComponent = (data: any) => {
    return (
      <>
        <Dropdown>
          {isSmallScreen && (
            <Dropdown.Toggle size="sm" variant="outline-primary">
              <i className="fa fa-bars"></i>
            </Dropdown.Toggle>
          )}
          {!isSmallScreen && (
            <Dropdown.Toggle size="sm" variant="primary">
              Actions
            </Dropdown.Toggle>
          )}

          <Dropdown.Menu align="right">
            <Dropdown.Item
              onClick={() => {
                setDetail(data);
              }}
            >
              <i className="fa fa-eye me-3"></i>
              <span>View Details</span>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setPrint(true);
                setDetail(data);
              }}
            >
              <i className="fa fa-print me-3"></i>
              <span>Print Order</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const [print, setPrint] = useState(false);
  const [detail, setDetail] = useState<SysModels.IOrderHeaderOutputDto>();

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    statuses.getData();

    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SortPlacedOn = () => {
    return (
      <ColumnHeaderSortComponent
        name="Placed On"
        mode={
          sortBy === SysModels.IncomingOrderSortByEnum.PlacedOn ? orderBy : null
        }
        onClick={(mode) => {
          if (mode === null) {
            setSortBy(SysModels.IncomingOrderSortByEnum.PlacedOn);
          } else {
            setOrderBy(mode ? 0 : 1);
          }
        }}
      ></ColumnHeaderSortComponent>
    );
  };

  const SortReqFor = () => {
    return (
      <ColumnHeaderSortComponent
        name="Req. For"
        mode={
          sortBy === SysModels.IncomingOrderSortByEnum.RequestedFor
            ? orderBy
            : null
        }
        onClick={(mode) => {
          if (mode === null) {
            setSortBy(SysModels.IncomingOrderSortByEnum.RequestedFor);
          } else {
            setOrderBy(mode ? 0 : 1);
          }
        }}
      ></ColumnHeaderSortComponent>
    );
  };

  return (
    <>
      {!!detail && (
        <IncomingOrderDialog
          detail={detail}
          print={print}
          onClose={() => {
            setDetail(undefined);
            setPrint(false);
          }}
        ></IncomingOrderDialog>
      )}

      <div className="default-page-layout hide-on-print">
        <h4 className="hide-on-print">Incoming Orders</h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}

          {grid.status === FetchStatus.Complete && (
            <div
            // className={
            //   grid.status === FetchStatus.Complete ? "" : "display-none"
            // }
            >
              <div className="p-3 flex flex-wrap gap-10">
                <div className="flex-0 w-100">
                  <div className="input-group search-box">
                    <input
                      autoFocus={true}
                      className="form-control"
                      type="text"
                      placeholder="Search"
                      value={search.typed}
                      onChange={(e) => {
                        setSearch((data) => {
                          return {
                            ...data,
                            typed: e.target.value,
                          };
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          e.stopPropagation();
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }
                      }}
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            return { typed: "", used: "" };
                          });
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex-0">
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-primary">
                      <i className="fa fa-filter me-2"></i>{" "}
                      {statuses?.data?.find((o) => o.value === filter)?.label ||
                        "All"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="left">
                      {statuses?.data
                        ?.sort(commonService.sortByNumericProperty("value"))
                        ?.map((opt) => (
                          <Dropdown.Item
                            key={opt.value}
                            onClick={() => {
                              setFilter(opt.value);
                            }}
                            active={opt.value === filter}
                          >
                            <span>{opt.label}</span>
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  <DateRangePicker
                    from={period.from}
                    to={period.to}
                    onChanged={(from, to) => {
                      setPeriod({ from: from, to: to });
                    }}
                    periods={[
                      DatePeriod.All,
                      DatePeriod.Today,
                      DatePeriod.Tomorrow,
                      DatePeriod.ThisWeek,
                      DatePeriod.NextWeek,
                      DatePeriod.ThisMonth,
                      DatePeriod.Yesterday,
                      DatePeriod.Custom,
                    ]}
                  ></DateRangePicker>
                </div>
              </div>
              <div className="col-sm-12">
                <table className="table">
                  <thead>
                    <tr>
                      {!isSmallScreen && (
                        <>
                          <th>Customer</th>
                          <th className="no-wrap">BG Order No.</th>
                        </>
                      )}
                      <th>
                        <span>
                          <SortPlacedOn></SortPlacedOn>
                        </span>
                        {isSmallScreen && (
                          <span>
                            <span className="mx-4">|</span>
                            <SortReqFor></SortReqFor>
                          </span>
                        )}
                      </th>
                      {!isSmallScreen && (
                        <>
                          <th className="no-wrap">
                            <SortReqFor></SortReqFor>
                          </th>
                          <th className="no-wrap">ERP Order No.</th>
                          <th className="text-right">Total</th>
                          <th>Status</th>
                          <th></th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {grid.data?.orderHeaderOutputDtos?.map((order) => (
                      <tr key={order.sqlId}>
                        {isSmallScreen && (
                          <>
                            <td>
                              <div className="flex gap-10">
                                <div className="flex-1">
                                  <CustomerLinkComponent
                                    name={order.customerName}
                                    number={order.customerAccountNo}
                                    numberPartOfName={true}
                                  ></CustomerLinkComponent>
                                </div>
                                <div>
                                  <ActionsCellComponent
                                    {...order}
                                  ></ActionsCellComponent>
                                </div>
                              </div>
                              <div className="mb-1">{order.buyingGroup}</div>
                              <table className="table-label-desc">
                                <tbody>
                                  <TableRow label="BG Order No.:">
                                    <a
                                      href="/"
                                      className="mail-link"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setDetail(order);
                                      }}
                                    >
                                      {order.externalOrderNo}
                                    </a>
                                  </TableRow>
                                  <TableRow label="Placed On:">
                                    {dayjs(order.receivedOn).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </TableRow>
                                  <TableRow label="Req. For:">
                                    {dayjs(order.deliveryDate).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </TableRow>
                                  <TableRow label="ERP Order No.:">
                                    {order.erpOrderNumber}
                                  </TableRow>
                                  <TableRow label="Total:">
                                    {commonService.toMoney(order.orderAmount)}
                                  </TableRow>
                                  <TableRow label="Status:">
                                    {
                                      statuses?.data?.find(
                                        (o) => o.value === order.recordStatus
                                      )?.label
                                    }
                                  </TableRow>
                                </tbody>
                              </table>
                            </td>
                          </>
                        )}
                        {!isSmallScreen && (
                          <>
                            <td>
                              <CustomerLinkComponent
                                name={order.customerName}
                                number={order.customerAccountNo}
                                numberPartOfName={true}
                              ></CustomerLinkComponent>
                              <div>{order.buyingGroup}</div>
                            </td>
                            <td>
                              <a
                                href="/"
                                className="mail-link"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setDetail(order);
                                }}
                              >
                                {order.externalOrderNo}
                              </a>
                            </td>
                            <td className="no-wrap">
                              {dayjs(order.receivedOn).format("MMM DD, YYYY")}
                            </td>
                            <td className="no-wrap">
                              {dayjs(order.deliveryDate).format("MMM DD, YYYY")}
                            </td>
                            <td>{order.erpOrderNumber}</td>
                            <td className="text-right">
                              {commonService.toMoney(order.orderAmount)}
                            </td>
                            <td>
                              {
                                statuses?.data?.find(
                                  (o) => o.value === order.recordStatus
                                )?.label
                              }
                            </td>
                            <td>
                              <ActionsCellComponent
                                {...order}
                              ></ActionsCellComponent>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="p-3 pt-0">
                  <Pagination
                    length={grid.data?.totalRecords || 0}
                    page={paging.page}
                    pageSize={paging.pageSize}
                    pageChange={pageChange}
                    showingOfWhatLabel="entries"
                    sizes={[10, 25, 50, 100]}
                  ></Pagination>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default IncomingOrders;
