import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../../components/common/CommonSpinner";
import SysServices from "../../services";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import { useWindowSize } from "../../stores/SystemStore";
import { AsyncTypeahead } from "react-bootstrap-typeahead"; // ES2015
import commonService from "../../services/CommonService";

interface IOption {
  id: string;
  name: string;
}

function RecentlyViewedVendorsWdiget(props: any) {
  const navigate = useNavigate();

  const grid = useFetchHelper(
    async () => SysServices.http.vendor.listRecentlyViewed(5),
    "Recently Viewed Vendors"
  );

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [vendors, setVendors] = useState([] as IOption[]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (query: string) => {
    if ((query || "").trim() === "") {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    await SysServices.http.vendor
      .list(1, 10, { search: query })
      .then((items) => {
        const options = items.vendorOutputDtos.map((i) => ({
          id: i.id,
          name: i.vendorName,
        }));

        setVendors(options);
        setIsLoading(false);
      })
      .catch((err) => {
        setVendors([]);
        setIsLoading(false);
      });
  };

  const windowSize = useWindowSize();

  return (
    <>
      <div className="hide-on-print">
        <div className="bg-white mb-4">
          <div className="px-3 py-2 bg-primary txt-light">
            <h5 className="m-0 flex flex-wrap gap-10">
              <span className="flex-1 py-2">
                <span className="flex flex-center">
                  <em className="zmdi zmdi-store me-2"></em>
                  <span>Recently Viewed Vendors</span>
                </span>
              </span>
              <AsyncTypeahead
                id="basic-typeahead-single"
                labelKey="name"
                onSearch={handleSearch}
                onChange={(data: any) => {
                  if (data[0]) {
                    //({ id: data[0].id, name: data[0].name });
                    navigate(`/vendors/${data[0].id}`);
                  }
                }}
                searchText={"Searching..."}
                isLoading={isLoading}
                options={vendors}
                placeholder="Search..."
                minLength={1}
                delay={500}
                useCache={false}
                filterBy={() => true}
                style={{ width: "300px" }}
              />
            </h5>
          </div>
          <div className="p-3 overflow-auto min-height-100">
            {grid.status === FetchStatus.InProgress && (
              <CommonSpinner></CommonSpinner>
            )}
            {grid.status === FetchStatus.Complete && (
              <table className="table table-sm table-hover">
                <thead>
                  {windowSize.isSmall && (
                    <tr>
                      <td>Vendor / Last Viewed</td>
                    </tr>
                  )}
                  {!windowSize.isSmall && (
                    <tr>
                      <th style={{ width: "25px" }}></th>
                      <th>Vendor</th>
                      <th>Last Viewed</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {grid?.data?.map((vendor) => (
                    <tr
                      key={vendor.sqlId}
                      className="pointer"
                      onClick={(e) => {
                        navigate(`/vendors/${vendor.erpInternalVendorId}`);
                      }}
                    >
                      {windowSize.isSmall && (
                        <td>
                          <div>{vendor.vendorName}</div>
                          <div>
                            {commonService.toLocalDate(
                              vendor.dateAdded,
                              "MMM DD, YYYY hh:mm A"
                            )}
                          </div>
                        </td>
                      )}
                      {!windowSize.isSmall && (
                        <>
                          <td>
                            <i className="zmdi zmdi-store text-primary"></i>
                          </td>
                          <td>{vendor.vendorName}</td>
                          <td>
                            {commonService.toLocalDate(
                              vendor.dateAdded,
                              "MMM DD, YYYY hh:mm A"
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <div className="text-right">
              <small>
                <a
                  className="mail-link"
                  href="/vendors"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate("/vendors");
                  }}
                >
                  View All Vendors
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecentlyViewedVendorsWdiget;
