import SysModels from "../../models";
import fetcher from "../Fetcher";

class ApiIntegrationService {
  get = async (id: string) => {
    const url = `/ApiIntegration/${id}`;
    return fetcher.get<SysModels.IApiIntegrationOutputDto>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    data: SysModels.ISearchInputBaseDto
  ) => {
    const url = `/ApiIntegration/Integrations/${page}/${pageSize}`;
    return fetcher.post<SysModels.IApiIntegrationGridOutputDto>(url, data);
  };

  create = async (data: SysModels.IApiIntegrationInputDto) => {
    const url = `/ApiIntegration`;
    return fetcher.post<SysModels.IApiIntegrationOutputDto>(url, data);
  };

  update = async (id: string, data: SysModels.IApiIntegrationInputDto) => {
    const url = `/ApiIntegration/${id}`;
    return fetcher.put<SysModels.IApiIntegrationOutputDto>(url, data);
  };

  delete = async (id: string) => {
    const url = `/ApiIntegration/${id}`;
    return fetcher.delete<any>(url);
  };

  customerTypeAhead = async (data: SysModels.ITypeAheadInputDto) => {
    const url = `/ApiIntegration/CustomerTypeAhead`;
    return fetcher.post<SysModels.ITypeAheadOutputDto[]>(url, data);
  };

  regenerateKey = async (id: string) => {
    const url = `/ApiIntegration/RegenerateKey/${id}`;
    return fetcher.get<SysModels.IApiIntegrationOutputDto>(url);
  };

  email = async (data: SysModels.IApiIntegrationEmailInputDto) => {
    const url = `/ApiIntegration/Email`;
    return fetcher.post<any>(url, data);
  };
}

const apiIntegration = new ApiIntegrationService();
export default apiIntegration;
