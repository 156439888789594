import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import { useNavigate } from "react-router-dom";
import { useActiveRole, useSalesAccountViewing } from "../stores/SystemStore";
import menuActions from "../layout/MenuActions";

function ReportsByRole(props: any) {
  const navigate = useNavigate();
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [filter, setFilter] = useState<SysModels.IReportGridSearchDto>();

  const activeRole = useActiveRole();
  const salesAccountViewing = useSalesAccountViewing();
  const [roleName, setRoleName] = useState("");
  useEffect(() => {
    if (activeRole > 0) {
      let role = activeRole;

      if (
        //Check if LoginAsSalesRep mode, then change role to SalesRep
        activeRole === SysModels.UserRolesEnum.LoginAsSalesRep &&
        salesAccountViewing
      ) {
        role = SysModels.UserRolesEnum.Sales;
      }

      menuActions.actions.forEach((a) => {
        if (a.dashboard && a.role === role) {
          setRoleName(a.label);
        }
      });

      setFilter({
        search: "",
        orderByEnum: SysModels.OrderByEnum.Ascending,
        reportRoleDto: {
          isAdmin: role === SysModels.UserRolesEnum.Admin,
          isApplicationAdmin: role === SysModels.UserRolesEnum.ApplicationAdmin,
          isCustomerService: role === SysModels.UserRolesEnum.CustomerService,
          isDocumentManager: role === SysModels.UserRolesEnum.DocumentManager,
          isExecutive: role === SysModels.UserRolesEnum.Executive,
          isOeManager: role === SysModels.UserRolesEnum.OeManager,
          isSales: role === SysModels.UserRolesEnum.Sales,
          isSalesManager: role === SysModels.UserRolesEnum.SalesManager,
          isPurchasing: role === SysModels.UserRolesEnum.Purchasing,
          isLoginAsSalesRep: role === SysModels.UserRolesEnum.LoginAsSalesRep,
          none: false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRole]);

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const grid = useFetchHelper(async () => {
    return SysServices.http.report.list(paging.page, paging.pageSize, {
      ...(filter || ({} as any)),
      search: search.used,
    });
  }, "Users");

  useEffect(() => {
    if (filter && filter.reportRoleDto && activeRole > 0) {
      grid.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, filter]);

  return (
    <div className="default-page-layout">
      <h4 className="hide-on-print">
        {roleName ? `Reports for ${roleName}` : "Reports"}
      </h4>
      <div className="bg-white">
        {grid.status === FetchStatus.InProgress && (
          <div className="p-3">
            <CommonSpinner></CommonSpinner>
          </div>
        )}
        {grid.status === FetchStatus.Complete && (
          <div>
            <div className="p-3 flex flex-wrap gap-10">
              <div className="flex-0" style={{ maxWidth: "100%" }}>
                <div className="input-group search-box">
                  <input
                    autoFocus={true}
                    className="form-control"
                    type="text"
                    placeholder="Search"
                    value={search.typed}
                    onChange={(e) => {
                      setSearch((data) => {
                        return {
                          ...data,
                          typed: e.target.value,
                        };
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                        setSearch((data) => {
                          if (data.used === data.typed) {
                            grid.getData();
                            return data;
                          }
                          return {
                            ...data,
                            used: data.typed,
                          };
                        });
                      }
                    }}
                  ></input>
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={(e) => {
                        setSearch((data) => {
                          if (data.used === data.typed) {
                            grid.getData();
                            return data;
                          }
                          return {
                            ...data,
                            used: data.typed,
                          };
                        });
                      }}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={(e) => {
                        setSearch((data) => {
                          return { typed: "", used: "" };
                        });
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex-0"></div>
              <div className="flex-1"></div>
            </div>

            <div className="row p-3 py-0">
              {grid.data?.reportGridItemDtos.map((item) => (
                <div
                  key={item.pageToDisplay}
                  className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4"
                >
                  <div
                    className="card report-card flex h-100 w-100"
                    onClick={(e) => {
                      navigate(`/reportview/${item.pageToDisplay}/${item.id}`);
                    }}
                  >
                    <div className="card-body flex-1">
                      <h5 className="card-title">{item.title}</h5>
                      <p className="card-text ">{item.description}</p>
                    </div>
                    <div className="text-right pb-3 px-4 text-primary">
                      <i className="fa fa-eye me-2"></i>
                      <small>View Report</small>
                    </div>
                  </div>
                </div>
              ))}
              {grid.data?.totalRecords === 0 && (
                <div className="py-3">No Report(s) Found</div>
              )}
            </div>

            <div className="p-3 pt-0">
              <Pagination
                length={grid.data?.totalRecords || 0}
                page={paging.page}
                pageSize={paging.pageSize}
                pageChange={pageChange}
                showingOfWhatLabel="reports"
                sizes={[10, 25, 50, 100]}
              ></Pagination>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ReportsByRole;
