import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import CommonSpinner from "../../components/common/CommonSpinner";
import SysServices from "../../services";
import { useTimer } from "../../services/CommonHooks";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";

function LastSystemActivitiesWidget(props: any) {
  const sysActivity = useFetchHelper(
    async () => SysServices.http.siteManagement.getSystemActivities(),
    "Last System Activities"
  );
  const tmo = useTimer();

  useEffect(() => {
    sysActivity.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmo]);

  return (
    <div className="hide-on-print">
      <div className="bg-white mb-4">
        <div className="p-3 bg-primary txt-light">
          <h5 className="m-0 flex flex-center">
            <em className="fa fa-terminal me-2"></em>
            <span className="flex-1">Last System Activities</span>

            {!!sysActivity.data && (
              <>
                {sysActivity.status !== FetchStatus.InProgress && (
                  <i
                    className="fa fa-refresh pointer"
                    onClick={(e) => {
                      sysActivity.getData();
                    }}
                  ></i>
                )}
                {sysActivity.status === FetchStatus.InProgress && (
                  <Spinner
                    animation="border"
                    size="sm"
                    variant="light"
                  ></Spinner>
                )}
              </>
            )}
          </h5>
        </div>
        <div className="p-3 overflow-auto min-height-100 position-relative">
          {!sysActivity.data &&
            sysActivity.status === FetchStatus.InProgress && (
              <CommonSpinner overlay={true}></CommonSpinner>
            )}
          {sysActivity.status !== FetchStatus.Failed && (
            <>
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Activity</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {sysActivity?.data?.map((summary, i) => (
                    <tr
                      key={i}
                      className={summary.displayInError ? "bg-light-red" : ""}
                    >
                      <td>{summary.activity}</td>
                      <td>
                        {commonService.toLocalDate(
                          summary.lastTimeProcessed,
                          "full"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LastSystemActivitiesWidget;
