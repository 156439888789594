import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Pagination, { usePaging } from "../../components/common/Pagination";
import CommonSpinner from "../../components/common/CommonSpinner";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import commonService from "../../services/CommonService";
import systemStore from "../../stores/SystemStore";
import SysServices from "../../services";

function LoginAsSalesRepDashboard(props: any) {
  const navigate = useNavigate();
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const grid = useFetchHelper(async () => {
    return SysServices.http.asSalesRepRole.list(paging.page, paging.pageSize, {
      searchCriteria: search.used,
    });
  }, "Users");

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used]);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [switching, setSwitching] = useState(false);

  return (
    <>
      <div className="default-page-layout">
        {switching && (
          <CommonSpinner
            message="Switching to Sales Rep View"
            overlay={true}
          ></CommonSpinner>
        )}
        <h4 className="hide-on-print">Sales Reps/Managers</h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div>
              <div className="p-3 flex flex-wrap gap-10">
                <div className="flex-0" style={{ maxWidth: "100%" }}>
                  <div className="input-group search-box">
                    <input
                      autoFocus={true}
                      className="form-control"
                      type="text"
                      placeholder="Search"
                      value={search.typed}
                      onChange={(e) => {
                        setSearch((data) => {
                          return {
                            ...data,
                            typed: e.target.value,
                          };
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          e.stopPropagation();
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }
                      }}
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            return { typed: "", used: "" };
                          });
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table table-hover pointer">
                <thead>
                  {isSmallScreen && (
                    <tr>
                      <th>Name / Email / Roles / Last Modified</th>
                      <th></th>
                    </tr>
                  )}
                  {!isSmallScreen && (
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {grid.data?.asSalesRepOutputDtos?.map((item) => (
                    <tr
                      key={item.email}
                      className="break-word"
                      onClick={(e) => {
                        setSwitching(true);
                        systemStore.setSalesAccountViewing({
                          email: item.email,
                          role: item.isSalesPerson
                            ? "Sales Rep"
                            : "Sales Manager",
                        });
                        setTimeout(() => {
                          navigate("/salesdashboard");
                        }, 1000);
                      }}
                    >
                      {isSmallScreen && (
                        <td>
                          <div>
                            {commonService.isNullOrWhitespace(
                              item.personName
                            ) ? (
                              <i>( Unknown )</i>
                            ) : (
                              item.personName
                            )}
                          </div>
                          <div>
                            <a href={`mailto:${item.email}`}>{item.email}</a>
                          </div>
                          <small>
                            {item.isSalesPerson ? "Sales Rep" : "Sales Manager"}
                          </small>
                        </td>
                      )}
                      {!isSmallScreen && (
                        <>
                          <td>
                            {commonService.isNullOrWhitespace(
                              item.personName
                            ) ? (
                              <i>( Unknown )</i>
                            ) : (
                              item.personName
                            )}
                          </td>
                          <td>
                            <a
                              href={`mailto:${item.email}`}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open(`mailto:${item.email}`);
                              }}
                            >
                              {item.email}
                            </a>
                          </td>
                          <td>
                            {item.isSalesPerson ? "Sales Rep" : "Sales Manager"}
                          </td>
                        </>
                      )}
                      <td className="text-right">
                        <i className="fa fa-eye text-primary pointer"></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="p-3 pt-0">
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="users"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default LoginAsSalesRepDashboard;
