import { GeneralDocumentationService } from "../GeneralDocumentationService";
import SysModels from "../../../models";
import { fetcher } from "../_services";

class ExtendedGeneralDocumentationService extends GeneralDocumentationService {
  getItems = async () => {
    const url = `/GeneralDocumentation/Items`;
    return fetcher.get<SysModels.IGeneralDocumentationDisplayAdminGridDto>(url);
  };

  getItem = async (id: string) => {
    const url = `/GeneralDocumentation/${id}`;
    return fetcher.get<SysModels.IGeneralDocumentationAdminOutputDto>(url);
  };

  getContent = async (id: string) => {
    const url = `/GeneralDocumentation/GetContent/${id}`;
    return fetcher.get<string>(url);
  };

  getContentPublished = async (id: string) => {
    const url = `/GeneralDocumentation/GetContentPublished/${id}`;
    return fetcher.get<string>(url);
  };

  create = async (data: SysModels.IGeneralDocumentationInputDto) => {
    const url = `/GeneralDocumentation`;
    return fetcher.post<SysModels.IGeneralDocumentationAdminOutputDto>(
      url,
      data
    );
  };

  update = async (
    id: string,
    data: SysModels.IGeneralDocumentationInputDto
  ) => {
    const url = `/GeneralDocumentation/${id}`;
    return fetcher.put<SysModels.IGeneralDocumentationAdminOutputDto>(
      url,
      data
    );
  };

  updateSequence = async (id: string, sequence: number) => {
    const url = `/GeneralDocumentation/UpdateSequence/${id}/${sequence}`;
    return fetcher.put<any>(url, {});
  };

  delete = async (id: string) => {
    const url = `/GeneralDocumentation/${id}`;
    return fetcher.delete<any>(url, {});
  };

  setActiveStatus = async (
    id: string,
    data: SysModels.IGeneralDocumentationActiveStatusDto
  ) => {
    const url = `/GeneralDocumentation/SetActiveStatus/${id}`;
    return fetcher.put<SysModels.IGeneralDocumentationAdminOutputDto>(
      url,
      data
    );
  };

  publish = async (
    id: string,
    data: SysModels.IGeneralDocumentationActiveStatusDto
  ) => {
    const url = `/GeneralDocumentation/Publish/${id}`;
    return fetcher.put<any>(url, data);
  };
}

const extendedGeneralDocumentation = new ExtendedGeneralDocumentationService();

export default extendedGeneralDocumentation;
