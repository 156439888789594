import SysModels from "../../models";
import fetcher from "../Fetcher";

class ReportService {
  listMaintenance = async (
    page: number,
    pageSize: number,
    data: SysModels.IReportMaintenanceGridSearchDto
  ) => {
    const url = `/Report/Maintenances/${page}/${pageSize}`;
    return fetcher.post<SysModels.IReportMaintenanceGridDto>(url, data);
  };

  create = async (data: SysModels.IReportInsertDto) => {
    const url = `/Report`;
    return fetcher.post<any>(url, data);
  };

  update = async (id: string, data: SysModels.IReportUpdateDto) => {
    const url = `/Report/${id}`;
    return fetcher.put<any>(url, data);
  };

  delete = async (id: string) => {
    const url = `/Report/${id}`;
    return fetcher.delete<any>(url, {});
  };

  get = async (id: string) => {
    const url = `/Report/Maintenance/${id}`;
    return fetcher.get<SysModels.IReportMaintenanceOutputDto>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    data: SysModels.IReportGridSearchDto
  ) => {
    const url = `/Report/${page}/${pageSize}`;
    return fetcher.post<SysModels.IReportGridDto>(url, data);
  };
}

const report = new ReportService();
export default report;
