import SysModels from "../../models";
import fetcher from "../Fetcher";

class UserService {
  list = async (
    page: number,
    pageSize: number,
    data: SysModels.IUserListSearchInputDto
  ) => {
    const url = `/User/${page}/${pageSize}`;
    return fetcher.post<SysModels.IUserOutputGridDto>(url, data);
  };

  update = async (data: SysModels.IUserUpdateDto) => {
    const url = `/User`;
    return fetcher.post<SysModels.IUserOutputDto>(url, data);
  };

  findUserInActiveDirectory = async (
    data: SysModels.IUserActiveDirectorySearchInputDto
  ) => {
    const url = `/User/FindUserInActiveDirectory`;
    return fetcher.post<SysModels.IUserOutputDto[]>(url, data);
  };

  listInitial = async (
    page: number,
    pageSize: number,
    data: SysModels.IUserInitialSearchDto
  ) => {
    const url = `/User/ListInitial/${page}/${pageSize}`;
    return fetcher.post<SysModels.IUserInitialGridDto>(url, data);
  };

  getInitial = async (email: string) => {
    const url = `/User/Initial/${email}`;
    return fetcher.get<SysModels.IUserInitialOutputDto>(url);
  };

  createInitial = async (data: SysModels.IUserInitialInsertDto) => {
    const url = `/User/CreateInitial`;
    return fetcher.post<SysModels.IUserInitialOutputDto>(url, data);
  };

  updateInitial = async (
    email: string,
    data: SysModels.IUserInitialUpdateDto
  ) => {
    const url = `/User/UpdateInitial/${email}`;
    return fetcher.put<SysModels.IUserInitialOutputDto>(url, data);
  };

  deleteInitial = async (email: string) => {
    const url = `/User/DeleteInitial/${email}`;
    return fetcher.delete<SysModels.IUserInitialOutputDto>(url, {});
  };

  addSalesReps = async (
    salesMangerUserId: string,
    data: SysModels.IUserSalesManagerAddSalesRepsDto
  ) => {
    const url = `/User/AddSalesReps/${salesMangerUserId}`;
    return fetcher.post<any>(url, data);
  };

  listSalesReps = async (
    page: number,
    pageSize: number,
    data: SysModels.IAsSalesRepSearchInputDto
  ) => {
    const url = `/User/ListSalesReps/${page}/${pageSize}`;
    return fetcher.post<SysModels.IAsSalesRepGridDto>(url, data);
  };

  salesRepsForSalesManager = async (salesManagerUserId: string) => {
    const url = `/User/SalesRepsForSalesManager/${salesManagerUserId}`;
    return fetcher.get<SysModels.IUserSalesRepsForSalesManagerDetailDto[]>(url);
  };
}

const user = new UserService();
export default user;
