import React, { useEffect, useMemo, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import ConfirmDialog from "../components/common/ConfirmDialog";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import toastStore from "../stores/ToastStore";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import FormModal from "../components/common/FormModal";
import { ModalBody, ModalFooter } from "react-bootstrap";

function ManagePortalErrors(props: any) {
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const grid = useFetchHelper(async () => {
    return SysServices.http.portalMonitor.listErrors(
      paging.page,
      paging.pageSize
    );
  }, "Errors");

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  const [deleting, setDeleting] = useState(false);
  const [errorToDelete, setErrorToDelete] = useState<
    | {
        partitionKey?: string;
        rowKey?: string;
        which?: "single" | "selected" | "all";
      }
    | undefined
  >();

  const [selected, setSelected] = useState<
    { partitionKey: string; rowKey: string }[]
  >([]);

  const [showDialog, setShowDialog] =
    useState<SysModels.IErrorTableOutputDto>();

  const deleteError = async (partitionKey: string, rowKey: string) => {
    setDeleting(true);
    await SysServices.http.portalMonitor
      .deleteError(partitionKey, rowKey)
      .then((data) => {
        toastStore.showToast("Error deleted successfully.", "success");
        setDeleting(false);
        setSelected((p) => {
          return p.filter(
            (s) => !(s.partitionKey === partitionKey && s.rowKey === rowKey)
          );
        });
        grid.getData();
      })
      .catch((error) => {
        toastStore.showError("Failed Deleting Error", error);
        setDeleting(false);
      });
  };

  const deleteAllErrors = async () => {
    setDeleting(true);
    await SysServices.http.portalMonitor
      .deleteAllErrors()
      .then((data) => {
        toastStore.showToast(`All Errors deleted successfully.`, "success");
        setDeleting(false);
        setSelected([]);
        grid.getData();
      })
      .catch((error) => {
        toastStore.showError("Failed Deleting All Errors", error);
        setDeleting(false);
      });
  };

  const deleteManyErrors = async () => {
    setDeleting(true);
    await SysServices.http.portalMonitor
      .deleteManyErrors({
        rowPartitionKeysList: selected,
      })
      .then((data) => {
        toastStore.showToast(
          `Selected Errors (${selected.length}) deleted successfully.`,
          "success"
        );
        setDeleting(false);
        setSelected([]);
        grid.getData();
      })
      .catch((error) => {
        toastStore.showError("Failed Deleting Errors", error);
        setDeleting(false);
      });
  };

  const selectedAll = useMemo(() => {
    return (
      !!grid?.data?.errorTableOutputDtos?.length &&
      grid?.data?.errorTableOutputDtos?.filter((x) =>
        selected.find(
          (s) => x.partitionKey === s.partitionKey && x.rowKey === s.rowKey
        )
      )?.length === grid.data?.errorTableOutputDtos?.length
    );
  }, [grid.data, selected]);

  return (
    <>
      {!!showDialog && (
        <FormModal
          title="Error Details"
          isOpen={true}
          close={() => {
            setShowDialog(undefined);
          }}
          size="lg"
          customBodyFooter={true}
          moveBehind={!!errorToDelete}
        >
          <ModalBody>
            <div>
              <h5>{showDialog.subject}</h5>
              <div className="mb-2">
                {commonService.toLocalDate(
                  showDialog.dateTime,
                  "MMMM DD, YYYY HH:mm A"
                )}
              </div>
            </div>
            <div
              className="alert alert-sm alert-light"
              style={{
                backgroundColor: "#efefef",
                wordBreak: "break-word",
                minHeight: "200px",
                maxHeight: "60vh",
                overflow: "auto",
              }}
            >
              <div>{showDialog?.body}</div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-secondary me-2"
              type="button"
              onClick={(e) => {
                setShowDialog(undefined);
              }}
            >
              Close
            </button>
            <button
              className="btn btn-danger"
              type="button"
              onClick={(e) => {
                showDialog &&
                  setErrorToDelete({
                    partitionKey: showDialog.partitionKey,
                    rowKey: showDialog.rowKey,
                    which: "single",
                  });
              }}
            >
              Delete
            </button>
          </ModalFooter>
        </FormModal>
      )}

      {deleting && (
        <CommonSpinner overlay={true} message="Deleting..."></CommonSpinner>
      )}
      {!!errorToDelete && (
        <ConfirmDialog
          show={true}
          title={
            errorToDelete.which === "single"
              ? "Delete Error"
              : errorToDelete.which === "selected"
              ? "Delete Selected Errors"
              : "Delete All Errors"
          }
          message={
            errorToDelete.which === "single"
              ? `Do you really want to delete this Error?`
              : errorToDelete.which === "selected"
              ? `Do you really want to delete the selected (${selected.length}) errors?`
              : `Do you really want to delete all errors?`
          }
          buttons="yesno"
          done={(rtn) => {
            if (
              rtn === "yes" &&
              errorToDelete.partitionKey !== undefined &&
              errorToDelete.rowKey !== undefined
            ) {
              if (errorToDelete.which === "all") {
                deleteAllErrors();
              } else if (errorToDelete.which === "selected") {
                deleteManyErrors();
              } else {
                deleteError(errorToDelete.partitionKey, errorToDelete.rowKey);
              }
              setShowDialog(undefined);
            }
            setErrorToDelete(undefined);
          }}
        ></ConfirmDialog>
      )}
      <div className="default-page-layout">
        <h4 className="hide-on-print">Manage Portal Errors</h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div className="table-container">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        className="mt-2 mx-1 pointer"
                        checked={selectedAll}
                        onChange={(e) => {
                          if (selectedAll) {
                            setSelected([]);
                          } else {
                            setSelected(
                              (grid?.data?.errorTableOutputDtos || []).map(
                                (x) => {
                                  return {
                                    partitionKey: x.partitionKey,
                                    rowKey: x.rowKey,
                                  };
                                }
                              )
                            );
                          }
                        }}
                      />
                    </th>
                    <th colSpan={2}>
                      <div className="flex flex-row">
                        <div className="flex-1 pt-2">
                          <div>Subject / Date / Details</div>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-primary me-2 pointer"
                            onClick={(e) => {
                              setSelected([]);
                              pageChange(1, paging.pageSize);
                            }}
                          >
                            <i className="fa fa-refresh text-primary mx-2"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-danger me-2"
                            disabled={!selected.length}
                            onClick={(e) => {
                              setErrorToDelete({
                                partitionKey: "",
                                rowKey: "",
                                which: "selected",
                              });
                            }}
                          >
                            Delete Selected{" "}
                            {selected.length ? `(${selected.length})` : ``}
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-danger"
                            disabled={!grid.data?.totalRecords}
                            onClick={(e) => {
                              setErrorToDelete({
                                partitionKey: "",
                                rowKey: "",
                                which: "all",
                              });
                            }}
                          >
                            Delete All
                          </button>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!grid.data?.totalRecords && (
                    <tr>
                      <td colSpan={3} className="text-center">
                        <div className="p-2">No Record(s) Found</div>
                      </td>
                    </tr>
                  )}
                  {grid.data?.errorTableOutputDtos?.map((item, i) => (
                    <tr key={i} className="pointer">
                      <td className="px-3 pe-0">
                        <input
                          type="checkbox"
                          className="mt-2 mx-1 me-0 pointer"
                          checked={
                            !!selected.find(
                              (x) =>
                                x.partitionKey === item.partitionKey &&
                                x.rowKey === item.rowKey
                            )
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelected((p) => {
                                return [
                                  ...p.filter(
                                    (x) =>
                                      !(
                                        x.partitionKey === item.partitionKey &&
                                        x.rowKey === item.rowKey
                                      )
                                  ),
                                  {
                                    partitionKey: item.partitionKey,
                                    rowKey: item.rowKey,
                                  },
                                ];
                              });
                            } else {
                              setSelected((p) => {
                                return p.filter(
                                  (x) =>
                                    !(
                                      x.partitionKey === item.partitionKey &&
                                      x.rowKey === item.rowKey
                                    )
                                );
                              });
                            }
                            // const found = selected.find(
                            //   (x) =>
                            //     x.partitionKey === item.partitionKey &&
                            //     x.rowKey === item.rowKey
                            // );
                          }}
                        />
                      </td>
                      <td
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setShowDialog(item);
                        }}
                      >
                        <div>
                          <div>
                            <strong>{item.subject}</strong>
                          </div>
                          <div className="pb-2">
                            <span className="me-2">
                              {commonService.toLocalDate(
                                item.dateTime,
                                "MMMM DD, YYYY HH:mm A"
                              )}
                            </span>
                          </div>
                          <div
                            className="alert alert-sm alert-light"
                            style={{
                              backgroundColor: "#efefef",
                              wordBreak: "break-all",
                              maxHeight: "120px",
                              overflow: "hidden",
                            }}
                          >
                            {item.body}
                          </div>
                        </div>
                      </td>
                      <td>
                        <i
                          className="fa fa-trash text-danger pointer mt-2"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setErrorToDelete({
                              partitionKey: item.partitionKey,
                              rowKey: item.rowKey,
                            });
                          }}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="p-3 pt-0">
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="errors"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ManagePortalErrors;
