import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../../components/common/CommonSpinner";
import SysModels from "../../models";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import CustomerLinkComponent from "../common/CustomerLinkComponent";
import DateRangePicker, {
  DatePeriod,
  GetPeriodValues,
} from "../../components/common/DateRangePicker";
import TwoItemBarChart, { BarChar_Colors } from "../common/TwoItemBarChart";
import { reaction } from "mobx";
import systemStore from "../../stores/SystemStore";

function SalesRepProjectionWidget(props: any) {
  const navigate = useNavigate();
  const [order, setOrder] = useState(SysModels.OrderByEnum.Ascending);
  const [period, setPeriod] = useState<{
    from: string | null;
    to: string | null;
    period?: DatePeriod;
  }>({
    from: GetPeriodValues(DatePeriod.ThisWeek).from,
    to: GetPeriodValues(DatePeriod.ThisWeek).to,
    period: DatePeriod.ThisWeek,
  });

  const getPeriod = () => {
    if (period.period === DatePeriod.ThisMonth) {
      return SysModels.SalesRepProjectionDatePeriodEnum.ThisMonth;
    }
    if (period.period === DatePeriod.ThisQuarter) {
      return SysModels.SalesRepProjectionDatePeriodEnum.ThisQuarter;
    }
    return SysModels.SalesRepProjectionDatePeriodEnum.ThisWeek;
  };

  const projection = useFetchHelper(
    async () =>
      SysServices.http.sales.salesRepProjection({
        page: 1,
        pageSize: 5,
        isSummary: true,
        orderBy: order,
        datePeriod: getPeriod(),
      }),
    "Sales Projection"
  );

  useEffect(() => {
    const tmo = setTimeout(() => {
      projection.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, order]);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="hide-on-print">
        <div className="bg-white mb-4">
          <div
            className={`p-3 py-2 txt-light ${
              order === SysModels.OrderByEnum.Ascending
                ? "bg-danger"
                : "bg-success"
            }`}
          >
            <div className={isSmallScreen ? "" : "flex flex-center"}>
              <div className="flex-1">
                <h5 className={isSmallScreen ? "m-0 pb-2" : "m-0"}>
                  <em className="fa fa-line-chart me-2"></em>
                  Sales Projection
                </h5>
              </div>
              <div className="flex flex-center">
                <div className="me-2 bg-white">
                  <button
                    className="btn btn-outline-primary px-3"
                    disabled={projection.status === FetchStatus.InProgress}
                    type="button"
                    onClick={(e) => {
                      setOrder(
                        order === SysModels.OrderByEnum.Ascending
                          ? SysModels.OrderByEnum.Descending
                          : SysModels.OrderByEnum.Ascending
                      );
                    }}
                  >
                    {order === SysModels.OrderByEnum.Ascending ? (
                      <>
                        <i className="fa fa-long-arrow-down"></i> Under
                        Performing
                      </>
                    ) : (
                      <>
                        <i className="fa fa-long-arrow-up"></i> Tracking Well
                      </>
                    )}
                  </button>
                </div>
                <div>
                  <DateRangePicker
                    align="right"
                    disabled={projection.status === FetchStatus.InProgress}
                    from={period.from}
                    to={period.to}
                    onChanged={(from, to, period) => {
                      setPeriod({ from: from, to: to, period: period });
                    }}
                    defaultPeriod={DatePeriod.ThisWeek}
                    hideCustom={true}
                    periods={[
                      DatePeriod.ThisWeek,
                      DatePeriod.ThisMonth,
                      DatePeriod.ThisQuarter,
                    ]}
                  ></DateRangePicker>
                </div>
              </div>
            </div>
          </div>
          <div className="p-3 overflow-auto min-height-100">
            {projection.status === FetchStatus.InProgress && (
              <CommonSpinner></CommonSpinner>
            )}
            {projection.status === FetchStatus.Complete && (
              <table className="table table-sm">
                <thead>
                  {isSmallScreen && (
                    <tr>
                      <th style={{ width: "25px" }}></th>
                      <th className="flex flex-center">
                        <div className="flex-1">Customer</div>
                        <div>
                          <i
                            className="fa fa-square"
                            style={{
                              color: BarChar_Colors[0],
                            }}
                          ></i>{" "}
                          <span className="me-2">This Period</span>
                          <i
                            className="fa fa-square"
                            style={{
                              color: BarChar_Colors[1],
                            }}
                          ></i>{" "}
                          Last Period
                        </div>
                      </th>
                    </tr>
                  )}
                  {!isSmallScreen && (
                    <tr>
                      <th style={{ width: "25px" }}></th>
                      <th>Customer</th>
                      <th className="text-right">Projected Percentage</th>
                      <th className="text-center px-2">
                        <i
                          className="fa fa-square"
                          style={{
                            color: BarChar_Colors[0],
                          }}
                        ></i>{" "}
                        <span className="me-2">This Period</span>
                        <i
                          className="fa fa-square"
                          style={{
                            color: BarChar_Colors[1],
                          }}
                        ></i>{" "}
                        Last Period
                      </th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {projection?.data?.salesRepProjectionOutputDtos?.map(
                    (customer) => (
                      <tr key={customer.customerNumber}>
                        {isSmallScreen ? (
                          <>
                            <td>
                              <i className="fa fa-user text-warning"></i>
                            </td>
                            <td>
                              <CustomerLinkComponent
                                name={customer.customerName}
                                number={customer.customerNumber}
                              ></CustomerLinkComponent>
                              <div className="flex flex-center">
                                <div className="pe-2">
                                  Projected Percentage:
                                </div>
                                <div className="flex-1">
                                  {!commonService.isNumberNullOrMax(
                                    customer.percentage
                                  ) && (
                                    <strong>
                                      {commonService.toNumberWithComma(
                                        customer.percentage
                                      )}
                                      %
                                    </strong>
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-center">
                                <TwoItemBarChart
                                  data={[
                                    {
                                      value: customer.thisPeriodAmount,
                                      label: "This Period",
                                    },
                                    {
                                      value: customer.lastPeriodAmount,
                                      label: "Last Period",
                                    },
                                  ]}
                                ></TwoItemBarChart>
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            <td>
                              <i className="fa fa-user text-warning"></i>
                            </td>
                            <td>
                              <CustomerLinkComponent
                                name={customer.customerName}
                                number={customer.customerNumber}
                              ></CustomerLinkComponent>
                            </td>
                            <td className="text-right">
                              {!commonService.isNumberNullOrMax(
                                customer.percentage
                              ) && (
                                <>
                                  {commonService.toNumberWithComma(
                                    customer.percentage
                                  )}
                                  %
                                </>
                              )}
                            </td>
                            <td>
                              <TwoItemBarChart
                                data={[
                                  {
                                    value: customer.thisPeriodAmount,
                                    label: "This Period",
                                  },
                                  {
                                    value: customer.lastPeriodAmount,
                                    label: "Last Period",
                                  },
                                ]}
                              ></TwoItemBarChart>
                            </td>
                          </>
                        )}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            )}
            <div className="text-right">
              <small>
                <a
                  className="mail-link"
                  href={`/salesprojection/${order}/${period.period}`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(`/salesprojection/${order}/${period.period}`);
                  }}
                >
                  View All Customers
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SalesRepProjectionWidget;
