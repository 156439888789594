import SysModels from "../../models";
import fetcher from "../Fetcher";

class ConfigAzureService {
  getAllRecords = async () => {
    const url = `/ConfigAzure`;
    return fetcher.get<SysModels.IConfigAzureTableItemOutputDto[]>(url);
  };

  create = async (data: SysModels.IConfigAzureTableItemInsertDto) => {
    const url = `/ConfigAzure`;
    return fetcher.post<SysModels.IConfigAzureTableItemOutputDto>(url, data);
  };

  update = async (
    section: string,
    sectionId: string,
    data: SysModels.IConfigAzureTableItemUpdateDto
  ) => {
    const url = `/ConfigAzure/${section}/${sectionId}`;
    return fetcher.put<SysModels.IConfigAzureTableItemOutputDto>(url, data);
  };

  delete = async (section: string, sectionId: string) => {
    const url = `/ConfigAzure/${section}/${sectionId}`;
    return fetcher.delete<SysModels.IConfigAzureTableItemOutputDto>(url, {});
  };

  createAzureFunctionsOnOffSchedule = async (
    data: SysModels.IAzureFunctionsOnOffScheduleInsertDto
  ) => {
    const url = `/ConfigAzure/CreateAzureFunctionsOnOffSchedule`;
    return fetcher.post<any>(url, data);
  };

  editAzureFunctionsOnOffSchedule = async (
    sectionName: string,
    data: SysModels.IAzureFunctionsOnOffScheduleUpdateDto
  ) => {
    const url = `/ConfigAzure/EditAzureFunctionsOnOffSchedule/${sectionName}`;
    return fetcher.put<any>(url, data);
  };

  deleteAzureFunctionsOnOffSchedule = async (sectionName: string) => {
    const url = `/ConfigAzure/DeleteAzureFunctionsOnOffSchedule/${sectionName}`;
    return fetcher.delete<SysModels.IConfigAzureTableItemOutputDto>(url, {});
  };
}

const configAzure = new ConfigAzureService();
export default configAzure;
