import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../../components/common/CommonSpinner";
import DateRangePicker, {
  DatePeriod,
} from "../../components/common/DateRangePicker";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import toastStore from "../../stores/ToastStore";
import CustomerLinkComponent from "../common/CustomerLinkComponent";
import dayjs from "dayjs";

const DEFAULT_FORMAT = "YYYY-MM-DD";

function ActiveOnLineCustomersReport(props: any) {
  const navigate = useNavigate();
  const [period, setPeriod] = useState<{
    from: string | null;
    to: string | null;
  }>({
    from: dayjs().add(-3, "month").format(DEFAULT_FORMAT),
    to: dayjs().format(DEFAULT_FORMAT),
  });

  const grid = useFetchHelper(
    async () =>
      SysServices.http.reportView.activeOnLineCustomers({
        fromDate: period.from as any,
        toDate: period.to as any,
      }),
    "Active Customers"
  );

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  useEffect(() => {
    if (grid.status === FetchStatus.Failed && grid.error?.code === 403) {
      navigate("/reports");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grid.status]);

  const [downloading, setDownloading] = useState(false);
  const startDownload = async () => {
    setDownloading(true);
    await SysServices.http.reportView
      .activeOnLineCustomersCsv({
        fromDate: period.from as any,
        toDate: period.to as any,
      })
      .then((data) => {
        setDownloading(false);
        const file = commonService.b64toBlob(data.fileContents, "text/csv");
        FileSaver.saveAs(file, data.fileDownloadName);
      })
      .catch((err) => {
        setDownloading(false);
        toastStore.showError("Download Failed", err);
      });
  };

  return (
    <div className="default-page-layout">
      {downloading && (
        <CommonSpinner overlay={true} message="Downloading..."></CommonSpinner>
      )}

      <h4 className="hide-on-print">Active Online Customers</h4>

      <div className="bg-white">
        <div
          className={`print-wrapper hide-on-print flex flex-center flex-wrap gap-15 ${
            grid.status === FetchStatus.InProgress ? "display-none" : ""
          }`}
        >
          <div className="flex">
            <DateRangePicker
              from={period.from}
              to={period.to}
              onChanged={(from, to) => {
                setPeriod({ from: from, to: to });
              }}
              requireBothDates={true}
              periods={[
                DatePeriod.ThisYear,
                DatePeriod.LastYear,
                DatePeriod.ThisMonth,
                DatePeriod.LastMonth,
                DatePeriod.ThisWeek,
                DatePeriod.LastWeek,
                DatePeriod.Custom,
              ]}
              defaultPeriod={DatePeriod.Custom}
            ></DateRangePicker>
          </div>
          <div className="flex-1 now-wrap p-2">
            <a
              href="/"
              className="no-decoration no-wrap me-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.print();
              }}
            >
              <i className="fa fa-print"></i> Print
            </a>{" "}
            <a
              href="/"
              className="no-decoration no-wrap"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                startDownload();
              }}
            >
              <i className="fa fa-download"></i> Download CSV
            </a>
          </div>
        </div>
        {grid.status === FetchStatus.InProgress && (
          <CommonSpinner></CommonSpinner>
        )}
        {grid.status === FetchStatus.Complete && (
          <div className="overflow-auto">
            <table className="table table-hover billing-table">
              <thead>
                <tr>
                  <th className="no-wrap">Customer Name</th>
                  <th className="no-wrap">Customer Number</th>
                  <th className="no-wrap">Last Ordered On</th>
                  <th className="text-center no-wrap">Total # of Orders</th>
                  <th className="text-right no-wrap">Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {grid?.data?.map((item, idx) => (
                  <tr key={`${item.customerNumber}-${idx}`}>
                    <td>
                      <span className="show-on-print">{item.customerName}</span>
                      <span className="hide-on-print">
                        <CustomerLinkComponent
                          name={item.customerName}
                          number={item.customerNumber}
                          numberPartOfName={true}
                        ></CustomerLinkComponent>
                      </span>
                    </td>
                    <td>
                      <span className="show-on-print">
                        {item.customerNumber}
                      </span>
                      <span className="hide-on-print">
                        <CustomerLinkComponent
                          name={item.customerNumber}
                          number={item.customerNumber}
                          numberPartOfName={true}
                        ></CustomerLinkComponent>
                      </span>
                    </td>
                    <td className="text-left no-wrap">
                      {commonService.toDateString(item.lastDateOrderedOn)}
                    </td>
                    <td className="text-center no-wrap">
                      {commonService.toNumberWithComma(
                        item.totalNumberOfOrders
                      )}
                    </td>
                    <td className="text-right no-wrap">
                      {commonService.toMoney(item.totalAmount)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default ActiveOnLineCustomersReport;
