import React, { useEffect, useMemo, useRef, useState } from "react";
import Pagination, { usePaging } from "../components/common/Pagination";
import commonService from "../services/CommonService";
import CommonSpinner from "../components/common/CommonSpinner";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import SysModels from "../models";
import CustomerLinkComponent from "./common/CustomerLinkComponent";
import DateRangePicker, {
  DatePeriod,
  GetPeriodValues,
} from "../components/common/DateRangePicker";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import FormModal from "../components/common/FormModal";

interface IOption {
  id: string;
  name: string;
}

const getInvoicePeriod = (period?: DatePeriod) => {
  if (period === DatePeriod.ThisWeek) {
    return SysModels.ExecutiveSummaryDatePeriodEnum.ThisWeek;
  }
  if (period === DatePeriod.ThisMonth) {
    return SysModels.ExecutiveSummaryDatePeriodEnum.ThisMonth;
  }
  if (period === DatePeriod.LastMonth) {
    return SysModels.ExecutiveSummaryDatePeriodEnum.LastMonth;
  }
  if (period === DatePeriod.ThisQuarter) {
    return SysModels.ExecutiveSummaryDatePeriodEnum.ThisQuarter;
  }
  if (period === DatePeriod.LastQuarter) {
    return SysModels.ExecutiveSummaryDatePeriodEnum.LastQuarter;
  }
  if (period === DatePeriod.ThisYear) {
    return SysModels.ExecutiveSummaryDatePeriodEnum.ThisYear;
  }
  if (period === DatePeriod.LastYear) {
    return SysModels.ExecutiveSummaryDatePeriodEnum.LastYear;
  }
  return SysModels.ExecutiveSummaryDatePeriodEnum.DateRange;
};

function PaidInvoices(props: any) {
  const [period, setPeriod] = useState<{
    from: string | null;
    to: string | null;
    period?: DatePeriod;
  }>({
    from: GetPeriodValues(DatePeriod.ThisQuarter).from,
    to: GetPeriodValues(DatePeriod.ThisQuarter).to,
    period: DatePeriod.ThisQuarter,
  });
  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [filter, setFilter] = useState<{ name: string; id: string }>();

  const grid = useFetchHelper(
    async () =>
      SysServices.http.customerInvoicePayment.listForExecutive(
        paging.page,
        paging.pageSize,
        {
          customerNumber: filter?.id || (null as any),
          datePeriod: getInvoicePeriod(period.period),
          startDate:
            period.period === DatePeriod.Custom ? period.from : (null as any),
          endDate:
            period.period === DatePeriod.Custom ? period.to : (null as any),
        }
      ),
    "Invoices"
  );

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, period, filter]);

  const [users, setUsers] = useState([] as IOption[]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (query: string) => {
    if ((query || "").trim() === "") {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    await SysServices.http.customer
      .typeAhead({ search: query })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: i.label,
        }));

        setUsers(options);
        setIsLoading(false);
      })
      .catch((err) => {
        setUsers([]);
        setIsLoading(false);
      });
  };

  const getOnlinePaymentClass = (message: string) => {
    if (message === "Denied") {
      return "table-danger";
    }
    return "";
  };

  const [onlinePaymentToView, setOnlinePaymentToView] =
    useState<SysModels.IPaymentInformationDto>();

  const chartDataSummary = useMemo(() => {
    const total = Math.max(
      //THIS TO ENSURE TOTAL ALWAYS HAS A VALUE IN CASE THERE ARE DUMMY DATA
      grid.data?.executiveSummaryAmountOutputDto?.totalInvoice || 0,
      grid.data?.executiveSummaryAmountOutputDto?.totalInvoicesPaid || 0
    );

    return {
      hasPayments:
        !grid.data?.executiveSummaryPaymentsOutputDto
          ?.totalSuccessfulPayments &&
        !grid.data?.executiveSummaryPaymentsOutputDto?.totalDeclinedInvoices,
      hasInvoices:
        !grid.data?.executiveSummaryAmountOutputDto?.totalInvoicesPaid &&
        !grid.data?.executiveSummaryAmountOutputDto?.totalInvoice,
      forInvoice: {
        total: total,
        paid:
          grid.data?.executiveSummaryAmountOutputDto?.totalInvoicesPaid || 0,
        unpaid:
          total -
          (grid.data?.executiveSummaryAmountOutputDto?.totalInvoicesPaid || 0),
      },
    };
  }, [grid.data]);

  const typeaheadRef = useRef(null);

  return (
    <>
      {!!onlinePaymentToView && (
        <FormModal
          title="Payment Detail"
          isOpen={true}
          close={() => {
            setOnlinePaymentToView(undefined);
          }}
          submit={(data) => {
            setOnlinePaymentToView(undefined);
          }}
          size="md"
          primaryButtonOnly={true}
          submitButtonLabel="Done"
        >
          <div>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th className="text-right">Invoice Amount</th>
                  <th className="text-right">Amount Paid</th>
                </tr>
              </thead>
              <tbody>
                {onlinePaymentToView.invoicesPayments.map((row) => {
                  return (
                    <tr key={row.invoiceNumber}>
                      <td>{row.invoiceNumber}</td>
                      <td className="text-right">
                        {commonService.toMoney(row.invoiceAmount)}
                      </td>
                      <td className="text-right">
                        {commonService.toMoney(row.amountPaid)}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td
                    className="pt-2"
                    style={{
                      border: "none",
                    }}
                  >
                    <strong>Totals:</strong>
                  </td>
                  <td
                    className="pt-2 text-right"
                    style={{
                      border: "none",
                    }}
                  >
                    <strong>
                      {commonService.toMoney(
                        onlinePaymentToView.invoicesPayments
                          .map((x) => x.invoiceAmount)
                          .reduce((accumulator, currentValue) => {
                            return accumulator + currentValue;
                          }, 0)
                      )}
                    </strong>
                  </td>
                  <td
                    className="pt-2 text-right"
                    style={{
                      border: "none",
                    }}
                  >
                    <strong>
                      {commonService.toMoney(
                        onlinePaymentToView.invoicesPayments
                          .map((x) => x.amountPaid)
                          .reduce((accumulator, currentValue) => {
                            return accumulator + currentValue;
                          }, 0)
                      )}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </FormModal>
      )}

      <div className="default-page-layout">
        <h4 className="hide-on-print">Online Paid Invoices</h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}

          <div
            className={`${
              grid.status === FetchStatus.InProgress ? "display-none" : ""
            }`}
          >
            <div className="p-3 flex flex-wrap gap-10">
              <div>
                <DateRangePicker
                  from={period.from}
                  to={period.to}
                  onChanged={(from, to, period) => {
                    setPeriod({ from, to, period });
                  }}
                  defaultPeriod={DatePeriod.ThisQuarter}
                  periods={[
                    DatePeriod.ThisWeek,
                    DatePeriod.ThisMonth,
                    DatePeriod.LastMonth,
                    DatePeriod.ThisQuarter,
                    DatePeriod.LastQuarter,
                    DatePeriod.ThisYear,
                    DatePeriod.LastYear,
                    DatePeriod.Custom,
                  ]}
                ></DateRangePicker>
              </div>
              <div className="flex-1">
                <div className="input-group">
                  <div>
                    <AsyncTypeahead
                      id="customerTypeAhead"
                      labelKey="name"
                      onSearch={handleSearch}
                      onChange={(data: any) => {
                        if (data[0]) {
                          setFilter({
                            id: data[0].id,
                            name: data[0].name,
                          });
                          (typeaheadRef?.current as any)?.clear();
                        }
                      }}
                      searchText={"Searching..."}
                      isLoading={isLoading}
                      options={users}
                      placeholder="Search Customer..."
                      minLength={1}
                      delay={500}
                      useCache={false}
                      filterBy={() => true}
                      style={{ width: "280px" }}
                      ref={typeaheadRef}
                    />
                  </div>
                  {!!filter && (
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        style={{ borderRadius: 0 }}
                      >
                        <div
                          style={{
                            maxWidth: "230px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            marginRight: "10px",
                          }}
                        >
                          {filter?.name}
                        </div>
                        <i
                          className="fa fa-times text-danger pointer"
                          onClick={(e) => {
                            setFilter(undefined);
                          }}
                        ></i>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {grid.status === FetchStatus.Complete && (
              <>
                <div className="row mb-4">
                  {!!grid.data?.executiveSummaryAmountOutputDto && (
                    <div className="col col-sm-12 col-md-3">
                      <div
                        className="chart-doughnut pb-3"
                        style={{ maxWidth: "400px" }}
                      >
                        <div className="text-center">
                          <strong>Invoices</strong>
                        </div>
                        <Pie
                          data={{
                            labels: [
                              `Online Paid: ${commonService.toNumberWithComma(
                                chartDataSummary.forInvoice.paid
                              )}`,
                              `Total: ${commonService.toNumberWithComma(
                                chartDataSummary.forInvoice.unpaid
                              )}`,
                            ],
                            datasets: [
                              {
                                label: "Orders",
                                data: [
                                  chartDataSummary.forInvoice.paid,
                                  chartDataSummary.forInvoice.unpaid,
                                  chartDataSummary.hasInvoices ? 1 : 0,
                                ],
                                backgroundColor: ["#2ecd99", "#4e9de6", "#ddd"],
                                borderWidth: 0,
                              },
                            ],
                          }}
                          plugins={[ChartDataLabels as any]}
                          options={{
                            responsive: true,
                            maintainAspectRatio: true,
                            plugins: {
                              datalabels: {
                                display: true,
                                color: "white",
                                formatter: (val, context) => {
                                  if (!Number(val || 0)) {
                                    return "";
                                  }
                                  const totals =
                                    chartDataSummary.forInvoice.total;
                                  if (!totals) {
                                    return "";
                                  }
                                  const perc = Number(val || 0) / totals;
                                  return `${commonService.toNumberWithComma(
                                    perc * 100
                                  )}%`;
                                },
                              },
                              tooltip: {
                                callbacks: {
                                  label: (context) => {
                                    if (!context.label) {
                                      return "No Data";
                                    }
                                    return ` ${commonService.toNumberWithComma(
                                      context.raw || 0
                                    )}`;
                                  },
                                },
                              },
                            },
                          }}
                        ></Pie>
                      </div>
                    </div>
                  )}
                  {!!grid.data?.executiveSummaryPaymentsOutputDto && (
                    <div className="col col-sm-12 col-md-3">
                      <div
                        className="chart-doughnut pb-3"
                        style={{ maxWidth: "400px" }}
                      >
                        <div className="text-center">
                          <strong>Payments</strong>
                        </div>
                        <Pie
                          data={{
                            labels: [
                              `Successful: ${commonService.toNumberWithComma(
                                grid.data?.executiveSummaryPaymentsOutputDto
                                  ?.totalSuccessfulPayments || 0
                              )}`,
                              `Declined: ${commonService.toNumberWithComma(
                                grid.data?.executiveSummaryPaymentsOutputDto
                                  ?.totalDeclinedInvoices || 0
                              )}`,
                            ],
                            datasets: [
                              {
                                label: "Orders",
                                data: [
                                  grid.data?.executiveSummaryPaymentsOutputDto
                                    ?.totalSuccessfulPayments || 0,
                                  grid.data?.executiveSummaryPaymentsOutputDto
                                    ?.totalDeclinedInvoices || 0,
                                  chartDataSummary.hasPayments ? 1 : 0,
                                ],
                                backgroundColor: ["#2ecd99", "#ed6f56", "#ddd"],
                                borderWidth: 0,
                              },
                            ],
                          }}
                          plugins={[ChartDataLabels as any]}
                          options={{
                            responsive: true,
                            maintainAspectRatio: true,
                            plugins: {
                              datalabels: {
                                display: true,
                                color: "white",
                                formatter: (val, context) => {
                                  if (!Number(val || 0)) {
                                    return "";
                                  }
                                  const totals =
                                    (grid.data
                                      ?.executiveSummaryPaymentsOutputDto
                                      ?.totalSuccessfulPayments || 0) +
                                    (grid.data
                                      ?.executiveSummaryPaymentsOutputDto
                                      ?.totalDeclinedInvoices || 0);
                                  if (!totals) {
                                    return "";
                                  }
                                  const perc = Number(val || 0) / totals;
                                  return `${commonService.toNumberWithComma(
                                    perc * 100
                                  )}%`;
                                },
                              },
                              tooltip: {
                                callbacks: {
                                  label: (context) => {
                                    if (!context.label) {
                                      return "No Data";
                                    }
                                    return ` ${commonService.toNumberWithComma(
                                      context.raw || 0
                                    )}`;
                                  },
                                },
                              },
                            },
                          }}
                        ></Pie>
                      </div>
                    </div>
                  )}
                </div>

                <div style={{ overflow: "auto" }}>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Customer Name</th>
                        <th>Confirmation Number</th>
                        <th>Transaction Date</th>
                        <th>Message</th>
                        <th className="text-right">Total Paid</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!grid.data?.executiveSummaryDetailOutputDtos?.length && (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No Record(s) Found
                          </td>
                        </tr>
                      )}
                      {grid.data?.executiveSummaryDetailOutputDtos?.map(
                        (detail) => (
                          <tr
                            key={detail.sqlId}
                            className={`pointer ${getOnlinePaymentClass(
                              detail.paymentInformation.returnMessage || ""
                            )}`}
                            onClick={(e) => {
                              setOnlinePaymentToView({
                                ...detail.paymentInformation,
                              });
                            }}
                          >
                            <td className="pointer">
                              <CustomerLinkComponent
                                name={detail.customerName}
                                number={detail.customerNumber}
                              ></CustomerLinkComponent>
                            </td>
                            <td>
                              <a
                                href="/"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setOnlinePaymentToView({
                                    ...detail.paymentInformation,
                                  });
                                }}
                              >
                                {detail.paymentInformation.confirmationNumber}
                              </a>
                            </td>
                            <td>
                              {commonService.toLocalDate(
                                detail.transactionDate
                              )}
                            </td>
                            <td>{detail.paymentInformation.returnMessage}</td>
                            <td className="text-right">
                              {commonService.toMoney(
                                detail.paymentInformation?.totalPaid || 0
                              )}
                            </td>
                            <td className="text-right">
                              <i className="fa fa-eye text-primary"></i>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="p-3 pt-0">
                  <Pagination
                    length={grid.data?.totalRecords || 0}
                    page={paging.page}
                    pageSize={paging.pageSize}
                    pageChange={pageChange}
                    showingOfWhatLabel="entries"
                    sizes={[10, 25, 50, 100]}
                  ></Pagination>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PaidInvoices;
