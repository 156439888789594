import React, { useEffect, useMemo, useState } from "react";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import SysServices from "../../services";
import SysModels from "../../models";
import CommonSpinner from "../../components/common/CommonSpinner";
import DateRangePicker, {
  DatePeriod,
} from "../../components/common/DateRangePicker";
import commonService from "../../services/CommonService";
import dayjs from "dayjs";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useWindowSize } from "../../stores/SystemStore";
import { useNavigate } from "react-router-dom";

function PurchaseOrderChartWidget(props: any) {
  const navigate = useNavigate();
  const [period, setPeriod] = useState<{
    from: string | null;
    to: string | null;
    period?: SysModels.PurchaseOrderSummaryDateRangeEnum;
  }>({
    from: null as any,
    to: null as any,
    period: SysModels.PurchaseOrderSummaryDateRangeEnum.ThisWeek,
  });

  const chart = useFetchHelper(async () => {
    if (period.period !== SysModels.PurchaseOrderSummaryDateRangeEnum.Custom) {
      return SysServices.http.purchaseOrder.summary({
        start: null as any,
        end: null as any,
        dateRange: period.period as any,
      });
    }
    return SysServices.http.purchaseOrder.summary({
      start: dayjs(period.from).format("YYYY-MM-DD") as any,
      end: dayjs(period.to).format("YYYY-MM-DD") as any,
      dateRange: period.period as any,
    });
  }, "Purchase Orders");
  const dateRange = useFetchHelper(
    async () =>
      SysServices.http.genericEnumLookup.get(
        "PurchaseOrderSummaryDateRangeEnum"
      ),
    "Date Ranges"
  );

  useEffect(() => {
    dateRange.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dateRange.status === FetchStatus.Complete) {
      chart.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange.status, period]);

  const [colors, setColors] = useState<
    {
      name: string;
      color: string;
    }[]
  >([]);

  const getColor = (cnt: number) => {
    let x = 360 * Math.random(); // (((cnt % 10) + 1) / 10) * 360;
    if (cnt <= 10) {
      x = 36 * (11 - cnt);
    }
    return `hsla(${~~x}, 70%,  72%, 0.8)`;
  };

  const [top20, setTop20] = useState<
    SysModels.IPurchaseOrderSummaryByVendorOutputDto[]
  >([]);

  useEffect(() => {
    if (chart.data) {
      let cnt = 0;
      chart.data &&
        chart.data.purchaseOrderSummaryByVendorOutputDtos?.forEach((r) => {
          cnt = cnt + r.numberPurchaseOrders;
        });

      const list = [
        ...(chart.data.purchaseOrderSummaryByVendorOutputDtos || []),
      ].sort(commonService.sortByNumericProperty("numberPurchaseOrders", true));

      const newTop20 = list.filter((r, i) => i <= 19);
      const others = list.filter((r, i) => i > 19);

      setTop20([
        ...newTop20,
        ...(others.length
          ? [
              {
                vendorId: `Other Vendors: ${others.length}`,
                numberPurchaseOrders: others
                  .map((x) => x.numberPurchaseOrders)
                  .reduce((partialSum, a) => partialSum + a, 0),
                erpVendorInternalAccountNo: "Other Vendors",
              },
            ]
          : []),
      ]);

      setColors((prev) => {
        const list = [...prev];
        if (newTop20) {
          [...newTop20].forEach((vendor) => {
            if (!list.find((color) => color.name === vendor.vendorId)) {
              list.push({
                name: vendor.vendorId,
                color: getColor(list.length + 1),
              });
            }
          });
        }
        return list;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart.data]);

  const totals = useMemo(() => {
    let cnt = 0;
    chart.data &&
      chart.data.purchaseOrderSummaryByVendorOutputDtos?.forEach((r) => {
        cnt = cnt + r.numberPurchaseOrders;
      });
    return cnt;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart.data]);

  const windowSize = useWindowSize();

  return (
    <>
      <div className="hide-on-print">
        <div className="bg-white mb-4">
          <div className="p-3 py-2 bg-warning txt-light">
            <h5 className="m-0 flex flex-center gap-10">
              <span className="py-2 flex-1">
                <span className="flex flex-center">
                  <em className="fa fa-pie-chart me-2"></em>
                  <span>Purchase Orders by Vendors</span>
                </span>
              </span>
              <div className="flex-0">
                {dateRange.status === FetchStatus.Complete && (
                  <DateRangePicker
                    disabled={chart.status === FetchStatus.InProgress}
                    from={period.from}
                    to={period.to}
                    align="right"
                    onChanged={(from, to, period) => {
                      const dr = dateRange.data?.find(
                        (x) => x.value === period
                      );
                      setPeriod({
                        from: from,
                        to: to,
                        period: dr
                          ? dr.value
                          : SysModels.PurchaseOrderSummaryDateRangeEnum.Custom,
                      });
                    }}
                    defaultPeriod={
                      SysModels.PurchaseOrderSummaryDateRangeEnum
                        .ThisWeek as any
                    }
                    periods={[DatePeriod.Custom]}
                    customPeriods={[
                      ...(dateRange.data || [])
                        .sort(commonService.sortByNumericProperty("value"))
                        .filter(
                          (x) => x.label !== "Custom" && x.label !== "All"
                        ),
                    ]}
                  ></DateRangePicker>
                )}
              </div>
            </h5>
          </div>
          <div className="p-3 overflow-auto min-height-100">
            {chart.status === FetchStatus.InProgress && (
              <CommonSpinner></CommonSpinner>
            )}
            {chart.status === FetchStatus.Failed && (
              <div className="text-center pt-3 text-danger">
                <i className="fa fa-warning me-1"></i> Failed Loading Data
              </div>
            )}
            {chart.status === FetchStatus.Complete && (
              <>
                {top20.length === 0 ? (
                  <>
                    <div className="text-center pt-3 text-secondary">
                      No Data
                    </div>
                  </>
                ) : (
                  <>
                    <div className="container">
                      <div
                        className="chart-doughnut text-center"
                        style={{
                          maxWidth: "500px",
                          height: `${(400 / 21) * top20.length + 100}px`,
                          margin: "0 auto",
                        }}
                      >
                        <Bar
                          title=""
                          data={{
                            labels: top20?.map((x) => x.vendorId) || [],
                            datasets: [
                              {
                                label: "",
                                data: [
                                  ...(top20?.map(
                                    (x) => x.numberPurchaseOrders
                                  ) || []),
                                ],
                                backgroundColor: [
                                  ...(top20?.map(
                                    (x) =>
                                      colors.find((c) => x.vendorId === c.name)
                                        ?.color || "#ccc"
                                  ) || []),
                                  "#ddd",
                                ],
                                borderWidth: 0,
                              },
                            ],
                          }}
                          plugins={[ChartDataLabels as any]}
                          options={{
                            indexAxis: "y",
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                              x: {
                                ticks: {
                                  stepSize: 1,
                                },
                              },
                            },
                            onClick: (e: any, activeEls) => {
                              try {
                                const dataIndex = activeEls[0].index;
                                const match = top20[dataIndex];
                                if (
                                  match &&
                                  match.erpVendorInternalAccountNo !==
                                    "Other Vendors"
                                ) {
                                  navigate(
                                    `/vendors/${top20[dataIndex].erpVendorInternalAccountNo}`
                                  );
                                }
                              } catch (error) {}
                            },
                            plugins: {
                              legend: {
                                display: false,
                                position: windowSize.isSmall ? "top" : "left",
                                fullSize: true,
                              },
                              title: {
                                display: false,
                                text: "",
                                color: "#222",
                                position: "top",
                                align: "center",
                                font: {
                                  weight: "bold",
                                },
                                padding: 10,
                              },
                              datalabels: {
                                display: true,
                                color: "#222",
                                formatter: (val, context) => {
                                  return "";
                                },
                              },
                              tooltip: {
                                callbacks: {
                                  label: (context) => {
                                    if (
                                      !context.label ||
                                      (context.raw as any)
                                        .numberPurchaseOrders === 0
                                    ) {
                                      return "No Data";
                                    }
                                    const perc =
                                      (Number(context.raw as any) / totals) *
                                      100;

                                    return ` ${context.label}: ${
                                      context.raw as any
                                    } = ${commonService.toNumberWithComma(
                                      perc
                                    )}%`;
                                  },
                                },
                              },
                            },
                          }}
                        ></Bar>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PurchaseOrderChartWidget;
