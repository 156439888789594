import FileSaver from "file-saver";
import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import ConfirmDialog from "../../components/common/ConfirmDialog";
import SysModels from "../../models";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import ftpStore from "../../stores/FtpStore";
import systemStore from "../../stores/SystemStore";
import toastStore from "../../stores/ToastStore";

export enum FTPFetchStatus {
  Default,
  Loading,
  Complete,
  Failed,
}

export const useFtpFileAction = () => {
  const [fileAction, setFileAction] = useState(ftpStore.action);
  useEffect(() => {
    const disposer = reaction(
      () => ftpStore.action,
      (n, p, i) => {
        setFileAction(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return fileAction;
};

export const useFtpUploadToFolder = () => {
  const [uploadToFolder, setUploadToFolder] = useState(ftpStore.uploadToFolder);
  useEffect(() => {
    const disposer = reaction(
      () => ftpStore.uploadToFolder,
      (n, p, i) => {
        setUploadToFolder(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return uploadToFolder;
};

function FTPObjectComponent(props: {
  ftpSource: SysModels.FtpNameEnum;
  folder: string;
  open?: boolean;
  onStatusChanged?: (stat: FTPFetchStatus) => void;
  isRoot?: boolean;
}) {
  const getParentFolder = () => {
    return props.folder ? `${props.folder}` : "";
  };
  const grid = useFetchHelper(async () => {
    if (props.isRoot) {
      return Promise.resolve([
        {
          ftpObjectTypeEnum: SysModels.FtpObjectTypeEnum.Directory,
          name: "",
        } as SysModels.IFtpContentOutputDto,
      ]);
    }
    return await SysServices.http.ftpManagement.list({
      ftpNameEnum: props.ftpSource,
      folderName: getParentFolder(),
    });
  }, "Files and Folders");

  const [status, setStatus] = useState<{ [key: string]: FTPFetchStatus }>({});
  const getStatus = (name: string) => {
    if (!status[name]) {
      return FTPFetchStatus.Default;
    }
    return status[name];
  };

  const fileAction = useFtpFileAction();
  const [mwfRole, setMwfRole] = useState(systemStore.extension_MWFRole);
  useEffect(() => {
    grid.getData();
    const disposer2 = reaction(
      () => ftpStore.refreshFolder,
      (n, p, i) => {
        if (!!n && getParentFolder() === n) {
          grid.getData();
          ftpStore.setRefreshFolder(undefined);
        }
      }
    );
    const disposer3 = reaction(
      () => systemStore.extension_MWFRole,
      (n, p, r) => {
        setMwfRole(n);
      }
    );
    return () => {
      disposer2();
      disposer3();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (grid.status === FetchStatus.Complete) {
      props.onStatusChanged && props.onStatusChanged(FTPFetchStatus.Complete);
      if (props.isRoot) {
        setStatus((p) => {
          const copy = { ...p };
          copy[""] = FTPFetchStatus.Loading;
          return copy;
        });
      }
    } else if (grid.status === FetchStatus.Failed) {
      props.onStatusChanged && props.onStatusChanged(FTPFetchStatus.Failed);
    } else if (grid.status === FetchStatus.InProgress) {
      props.onStatusChanged && props.onStatusChanged(FTPFetchStatus.Loading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grid.status]);

  const getFileActionClass = (file: SysModels.IFtpContentOutputDto) => {
    if (fileAction) {
      if (
        fileAction.fileName === file.name &&
        fileAction.folderName === props.folder
      ) {
        return "current-file";
      } else {
        return "other-file";
      }
    }
    return "";
  };

  const getFolderActionClass = (file: SysModels.IFtpContentOutputDto) => {
    if (fileAction) {
      if (fileAction.folderName === `${getParentFolder()}/${file.name}`) {
        return "current-folder";
      } else {
        return "other-folder";
      }
    }
    return "";
  };

  const [confirmAction, setConfirmAction] = useState({
    show: false,
    name: "",
    toPath: "",
  });
  const [confirmDelete, setConfirmDelete] = useState({
    show: false,
    name: "",
    folder: "",
  });

  return (
    <>
      {confirmDelete.show && (
        <ConfirmDialog
          show={true}
          title="Delete File"
          message={`
          Do you want delete '${confirmDelete.name}' from '${(
            confirmDelete.folder || "/"
          ).replace("//", "/")}'?`}
          buttons="yesno"
          done={(rtn) => {
            if (rtn === "yes") {
              SysServices.http.ftpManagement
                .deleteFile({
                  fileName: `${confirmDelete.folder}/${confirmDelete.name}`,
                  ftpNameEnum: props.ftpSource,
                })
                .then((data) => {
                  ftpStore.clearAction();
                  grid.getData();
                })
                .catch((error) => {
                  toastStore.showError("Failed Completing Action", error);
                })
                .finally(() => {
                  setConfirmDelete({ show: false, name: "", folder: "" });
                });
            } else {
              setConfirmDelete({ show: false, name: "", folder: "" });
            }
          }}
        ></ConfirmDialog>
      )}
      {confirmAction.show && fileAction && (
        <ConfirmDialog
          show={true}
          title={`${fileAction.action === 0 ? "Copy File" : "Move File"}`}
          message={`
          Do you want ${fileAction.action === 0 ? "copy" : "move"} '${
            fileAction.fileName
          }' from 
          '${(fileAction.folderName || "/").replace(
            "//",
            "/"
          )}' to '${confirmAction.toPath.replace("//", "/")}'?
          `}
          buttons="yesno"
          done={(rtn) => {
            setConfirmAction((p) => {
              return {
                ...p,
                show: false,
              };
            });
            if (rtn === "yes") {
              (fileAction.action === 0
                ? SysServices.http.ftpManagement.copyFile({
                    fromFile: `${fileAction.folderName}/${fileAction.fileName}`,
                    toFile: `${confirmAction.toPath}/${fileAction.fileName}`,
                    ftpNameEnum: props.ftpSource,
                  })
                : SysServices.http.ftpManagement.moveFile({
                    fromFileName: `${fileAction.folderName}/${fileAction.fileName}`,
                    toFileName: `${confirmAction.toPath}/${fileAction.fileName}`,
                    ftpNameEnum: props.ftpSource,
                  })
              )
                .then((data) => {
                  const folder1 = fileAction.folderName;
                  const folder2 = confirmAction.toPath;
                  ftpStore.clearAction();
                  ftpStore.setRefreshFolder(folder1);
                  setTimeout(() => {
                    ftpStore.setRefreshFolder(folder2);
                  }, 200);
                })
                .catch((error) => {
                  toastStore.showError("Failed Completing Action", error);
                })
                .finally(() => {
                  setConfirmAction({ show: false, name: "", toPath: "" });
                });
            } else {
              setConfirmAction({ show: false, name: "", toPath: "" });
            }
          }}
        ></ConfirmDialog>
      )}
      <div className="ftp-objects">
        {grid.data
          ?.sort(commonService.sortByNumericProperty("ftpObjectTypeEnum"))
          ?.map((o) => (
            <div key={o.name}>
              {o.ftpObjectTypeEnum === SysModels.FtpObjectTypeEnum.Directory ? (
                <div>
                  <div
                    title={o.name}
                    className={`ftp-folder ${getFolderActionClass(o)}`}
                    onClick={(e) => {
                      if (getStatus(o.name) === FTPFetchStatus.Failed) {
                        ftpStore.setRefreshFolder(
                          `${getParentFolder()}/${o.name}`
                        );
                        return;
                      }

                      setStatus((p) => {
                        const copy = { ...p };
                        copy[o.name] =
                          status[o.name] === FTPFetchStatus.Complete
                            ? FTPFetchStatus.Default
                            : FTPFetchStatus.Loading;
                        return copy;
                      });
                    }}
                  >
                    {getStatus(o.name) === FTPFetchStatus.Default && (
                      <i className="fa fa-folder text-warning"></i>
                    )}
                    {getStatus(o.name) === FTPFetchStatus.Failed && (
                      <i className="fa fa-warning text-danger"></i>
                    )}
                    {getStatus(o.name) === FTPFetchStatus.Complete && (
                      <i className="fa fa-folder-open text-warning"></i>
                    )}
                    {getStatus(o.name) === FTPFetchStatus.Loading && (
                      <i>
                        <Spinner
                          animation="border"
                          size="sm"
                          variant="secondary"
                        ></Spinner>
                      </i>
                    )}

                    <span>
                      {o.name}
                      {props.isRoot ? ". . /" : ""}

                      {getStatus(o.name) === FTPFetchStatus.Failed && (
                        <span className="m-2 text-primary">
                          Click to Try Again
                        </span>
                      )}
                    </span>

                    {!!fileAction && (
                      <div
                        className="ftp-action pe-2"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          if (fileAction.action === 2) {
                            ftpStore.setUploadToFolder(
                              `${getParentFolder()}/${o.name}`
                            );
                            return;
                          }
                          setConfirmAction({
                            show: true,
                            name: o.name,
                            toPath: `${getParentFolder()}/${o.name}`,
                          });
                        }}
                      >
                        {fileAction.action === 0 && (
                          <span className="text-primary">Copy Here</span>
                        )}
                        {fileAction.action === 1 && (
                          <span className="text-danger">Move Here</span>
                        )}
                        {fileAction.action === 2 && (
                          <span className="text-success">Upload Here</span>
                        )}
                      </div>
                    )}
                  </div>
                  {getStatus(o.name) !== FTPFetchStatus.Default && (
                    <div
                      className={`ftp-sub-folder ${
                        getStatus(o.name) === FTPFetchStatus.Complete
                          ? ""
                          : "display-none"
                      }`}
                    >
                      <div>
                        <FTPObjectComponent
                          ftpSource={props.ftpSource}
                          folder={`${props.folder}/${o.name}`}
                          onStatusChanged={(status) => {
                            setStatus((p) => {
                              const copy = { ...p };
                              copy[o.name] = status;
                              return copy;
                            });
                          }}
                        ></FTPObjectComponent>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <div className={`ftp-file ${getFileActionClass(o)}`}>
                    <i className="fa fa-file text-primary"></i>
                    <span title={o.name}>{o.name}</span>
                    <div className="no-wrap text-secondary">
                      <small>
                        {commonService.toLocalDate(
                          o.date,
                          "MMM DD, YYYY hh:mm: A"
                        )}
                      </small>
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="light"
                        size="sm"
                        disabled={getFileActionClass(o) === "other-file"}
                      ></Dropdown.Toggle>
                      <Dropdown.Menu align="right">
                        {!!(
                          mwfRole & SysModels.UserRolesEnum.ApplicationAdmin
                        ) && (
                          <>
                            <Dropdown.Item
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                ftpStore.setAction({
                                  folderName: props.folder,
                                  fileName: o.name,
                                  action: 0,
                                });
                              }}
                            >
                              <i className="fa fa-copy me-2"></i> Copy
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                ftpStore.setAction({
                                  folderName: props.folder,
                                  fileName: o.name,
                                  action: 1,
                                });
                              }}
                            >
                              <i className="fa fa-external-link me-2"></i> Move
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setConfirmDelete({
                                  show: true,
                                  name: o.name,
                                  folder: props.folder,
                                });
                              }}
                            >
                              <i className="fa fa-trash me-2"></i> Delete
                            </Dropdown.Item>
                          </>
                        )}
                        <Dropdown.Item
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            SysServices.http.ftpManagement
                              .downloadFile({
                                fileName: `${props.folder}/${o.name}`,
                                ftpNameEnum: props.ftpSource,
                              })
                              .then((data) => {
                                const file = commonService.b64toBlob(
                                  data.fileContents,
                                  "text/plain"
                                );
                                FileSaver.saveAs(file, data.fileDownloadName);
                              })
                              .catch((error) => {
                                toastStore.showError(
                                  "Failed to Download File",
                                  error
                                );
                              })
                              .finally(() => {});
                          }}
                        >
                          <i className="fa fa-download me-2"></i> Download
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </>
              )}
            </div>
          ))}
      </div>
    </>
  );
}

export default FTPObjectComponent;
