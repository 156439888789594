import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import { Dropdown } from "react-bootstrap";
import SysServices from "../services";
import toastStore from "../stores/ToastStore";
import SkuMappingDialog from "./SkuMappingDialog";
import ConfirmDialog from "../components/common/ConfirmDialog";
import SkuDetailDialog from "./SkuDetailDialog";
import SysModels from "../models";

function SkuMapping(props: any) {
  const [search, setSearch] = useState({
    typed: "",
    used: "",
    trigger: "",
  });
  const [bgId, setBgId] = useState(0);
  const buyingGroups = useFetchHelper(
    async () =>
      SysServices.http.buyingGroupMapping.buyingGroupsWithMapping(1, 100),
    "Buying Groups"
  );

  const grid = useFetchHelper(
    async () =>
      SysServices.http.buyingGroupMapping.mappings(
        paging.page,
        paging.pageSize,
        bgId
      ),
    "Item Mapping"
  );

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  useEffect(() => {
    const tmo = setTimeout(() => {
      bgId && grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, bgId]);

  const [item, setItem] = useState<SysModels.IErpInventoryItemOutputDto>();
  const [searching, setSearching] = useState(false);
  const getItem = async (itemCode: string) => {
    setSearching(true);
    await SysServices.http.siteManagement
      .getInventoryItem(itemCode, "null")
      .then((data) => {
        setItem(data);
        setShowDetail({
          itemId: data.itemId,
          internalId: data.erpInternalId,
          fromSearch: data,
        });
      })
      .catch((error) => {
        if (commonService.getErrorStatusCode(error) === 404) {
          toastStore.showToast("Item Not Found", "error");
        } else {
          toastStore.showError("Failed Getting Item", error);
        }
      })
      .finally(() => {
        setSearching(false);
      });
  };

  useEffect(() => {
    const tmo = setTimeout(() => {
      if (!commonService.isNullOrEmpty(search.used)) {
        getItem(search.used);
      }
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.used, search.trigger]);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );

    buyingGroups.getData();

    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showDialog, setShowDialog] = useState<
    | {
        id?: number;
        buyingGroup: {
          id: number;
          name: string;
        };
        fromSearch?: SysModels.IErpInventoryItemOutputDto;
      }
    | undefined
  >();

  const [idToDel, setIdToDel] = useState<number>();
  const [deleting, setDeleting] = useState(false);
  const onRowClick = (
    sqlId: number,
    item?: SysModels.IErpInventoryItemOutputDto
  ) => {
    const bg = buyingGroups?.data?.buyingGroupWithMappingsGridItemDtos?.find(
      (x) => x.buyingGroupId === bgId
    );
    if (bg) {
      setShowDialog({
        id: sqlId,
        buyingGroup: {
          id: bg.buyingGroupId,
          name: bg.buyingGroupName,
        },
        fromSearch: item,
      });
    }
  };

  const [showDetail, setShowDetail] = useState<{
    itemId: string;
    internalId: string;
    fromSearch?: SysModels.IErpInventoryItemOutputDto;
  }>();

  return (
    <>
      {showDialog && (
        <SkuMappingDialog
          id={showDialog.id}
          buyingGroup={showDialog.buyingGroup}
          fromSearch={showDialog.fromSearch}
          onClose={(done) => {
            if (done) {
              grid.getData();
            }
            setShowDialog(undefined);
          }}
        ></SkuMappingDialog>
      )}
      {showDetail && (
        <SkuDetailDialog
          internalId={showDetail.internalId}
          itemId={showDetail.itemId}
          fromSearch={showDetail.fromSearch}
          onClose={(add) => {
            if (add) {
              onRowClick(0, showDetail.fromSearch);
            }
            setShowDetail(undefined);
          }}
        ></SkuDetailDialog>
      )}
      <ConfirmDialog
        title="Delete Item Mapping"
        message="Do you really want to delete this Item Mapping?"
        buttons="yesno"
        show={!!idToDel}
        done={(val) => {
          if (val === "yes" && idToDel) {
            setDeleting(true);
            SysServices.http.buyingGroupMapping
              .delete(idToDel)
              .then((data) => {
                toastStore.showToast("Item Mapping Deleted", "success");
                grid.getData();
              })
              .catch((err) => {
                toastStore.showError("Failed Deleting Item Mapping", err);
              })
              .finally(() => {
                setDeleting(false);
              });
          }
          setIdToDel(undefined);
        }}
      ></ConfirmDialog>

      {deleting && (
        <CommonSpinner overlay={true} message="Deleting..."></CommonSpinner>
      )}

      <div className="default-page-layout">
        <h4 className="hide-on-print">Buying Group / NetSuite Item Mapping</h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          <div
            className={
              grid.status === FetchStatus.InProgress ? "display-none" : ""
            }
          >
            <div className="p-3 flex flex-wrap">
              <div className="flex-0 pe-2 mb-2">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    disabled={buyingGroups.status !== FetchStatus.Complete}
                  >
                    {buyingGroups.status === FetchStatus.InProgress ? (
                      "Loading..."
                    ) : (
                      <>
                        {buyingGroups?.data?.buyingGroupWithMappingsGridItemDtos?.find(
                          (x) => x.buyingGroupId === bgId
                        )?.buyingGroupName || "Buying Group"}
                      </>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="left">
                    {buyingGroups.data?.buyingGroupWithMappingsGridItemDtos?.map(
                      (opt) => (
                        <Dropdown.Item
                          key={opt.buyingGroupId}
                          onClick={() => {
                            setBgId(opt.buyingGroupId);
                            setPaging({ ...paging, page: 1 });
                          }}
                        >
                          <span>{opt.buyingGroupName}</span>
                        </Dropdown.Item>
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="flex-0 me-2 mb-2">
                <div
                  className="input-group search-box"
                  style={{ maxWidth: "250px" }}
                >
                  <input
                    autoFocus={true}
                    className="form-control"
                    type="text"
                    placeholder="Search Item Code"
                    value={search.typed}
                    onChange={(e) => {
                      setSearch((data) => {
                        return {
                          ...data,
                          typed: e.target.value,
                        };
                      });
                    }}
                    readOnly={!bgId}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        e.stopPropagation();
                        setSearch((data) => {
                          if (data.used === data.typed) {
                            return {
                              ...data,
                              trigger: commonService.getUniqueId(),
                            };
                          }
                          return {
                            ...data,
                            used: data.typed,
                          };
                        });
                      }
                    }}
                  ></input>
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="button"
                      disabled={!bgId}
                      onClick={(e) => {
                        setSearch((data) => {
                          if (data.used === data.typed) {
                            return {
                              ...data,
                              trigger: commonService.getUniqueId(),
                            };
                          }
                          return {
                            ...data,
                            used: data.typed,
                          };
                        });
                      }}
                    >
                      {searching ? (
                        <i className="fa fa-spin fa-spinner"></i>
                      ) : (
                        <i className="fa fa-search"></i>
                      )}
                    </button>
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={(e) => {
                        setSearch({ typed: "", used: "", trigger: "" });
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex-1 mb-2">
                <button
                  type="button"
                  className="btn btn-success"
                  //disabled={!bgId}
                  onClick={(e) => {
                    if (!bgId) {
                      toastStore.showToast(
                        "Please pick a Buying Group",
                        "warning"
                      );
                      return;
                    }
                    const bg =
                      buyingGroups?.data?.buyingGroupWithMappingsGridItemDtos?.find(
                        (x) => x.buyingGroupId === bgId
                      );
                    if (bg) {
                      setShowDialog({
                        buyingGroup: {
                          id: bg.buyingGroupId,
                          name: bg.buyingGroupName,
                        },
                      });
                    }
                  }}
                >
                  <i className="fa fa-plus"></i> Add Mapping
                </button>
              </div>
            </div>

            <table
              className={`table ${bgId ? "table-striped table-hover" : ""}`}
            >
              <thead>
                {isSmallScreen && (
                  <tr>
                    <th>
                      NetSuite Item ID / Byuing Group Item ID / Mapping Status
                    </th>
                    <td style={{ width: "50px" }}></td>
                  </tr>
                )}
                {!isSmallScreen && (
                  <tr>
                    <th>NetSuite Item ID</th>
                    <th>Buying Group Item ID</th>
                    <th>Mapping Status</th>
                    <td style={{ width: "50px" }}></td>
                  </tr>
                )}
              </thead>
              <tbody>
                {grid.data?.buyingGroupMappingOutputDtos?.map((item) => (
                  <tr key={item.sqlId} className="pointer">
                    {isSmallScreen && (
                      <>
                        <td onClick={(e) => onRowClick(item.sqlId)}>
                          <div>
                            <strong>{item.netSuiteItemId}</strong>
                          </div>
                          <div>{item.buyingGroupItemId}</div>
                          <div>{item.isMappingActive}</div>
                          {item.isMappingActive ? (
                            <span className="chip-green">Active</span>
                          ) : (
                            <span className="chip-red">Inactive</span>
                          )}
                        </td>
                      </>
                    )}
                    {!isSmallScreen && (
                      <>
                        <td onClick={(e) => onRowClick(item.sqlId)}>
                          {item.netSuiteItemId}
                        </td>
                        <td onClick={(e) => onRowClick(item.sqlId)}>
                          {item.buyingGroupItemId}
                        </td>
                        <td onClick={(e) => onRowClick(item.sqlId)}>
                          {item.isMappingActive ? (
                            <span className="chip-green">Active</span>
                          ) : (
                            <span className="chip-red">Inactive</span>
                          )}
                        </td>
                      </>
                    )}
                    <td className="text-right">
                      <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary" size="sm">
                          <i className="fa fa-list"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="left">
                          <Dropdown.Item
                            onClick={() => {
                              setShowDetail({
                                itemId: item.netSuiteItemId,
                                internalId: "null",
                              });
                            }}
                          >
                            View Item Detail
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setIdToDel(item.sqlId);
                            }}
                          >
                            Delete Mapping
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
                {!bgId && (
                  <tr>
                    <td colSpan={3} className="text-center">
                      <div className="p-2">Please pick a Buying Group</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className={`p-3 pt-0 ${bgId ? "" : "display-none"}`}>
              <Pagination
                length={grid.data?.totalRecords || 0}
                page={paging.page}
                pageSize={paging.pageSize}
                pageChange={pageChange}
                showingOfWhatLabel="entries"
                sizes={[10, 25, 50, 100]}
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SkuMapping;
