import dayjs from "dayjs";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import ConfirmDialog from "../components/common/ConfirmDialog";
import SwitchButton from "../components/common/SwitchButton";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import toastStore from "../stores/ToastStore";

function InventoryItems(props: any) {
  const [itemCode, setItemCode] = useState("");
  const [internalItemId, setInternalItemId] = useState("");

  const getSearchValues = (val: string) => {
    return (val || "").trim() === "" ? "null" : (val || "").trim();
  };

  const canSubmit = () => {
    return !(
      (itemCode || "").trim() === "" && (internalItemId || "").trim() === ""
    );
  };

  const [item, setItem] = useState<SysModels.IErpInventoryItemOutputDto>();
  const [status, setStatus] = useState(0); //0 default, 1 searching, 2 not found, 3 found.
  const getItem = async () => {
    if (!canSubmit() || status === 1) {
      return;
    }
    setStatus(1);
    await SysServices.http.siteManagement
      .getInventoryItem(
        getSearchValues(itemCode),
        getSearchValues(internalItemId)
      )
      .then((data) => {
        setItem(data);
        setStatus(3);
      })
      .catch((error) => {
        if (commonService.getErrorStatusCode(error) === 404) {
          toastStore.showToast("Item Not Found", "error");
          setStatus(2);
        } else {
          toastStore.showError("Failed Getting Item", error);
          setStatus(0);
        }
      });
  };

  const [saving, setSaving] = useState(false);
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);

  const toggleStatus = async (activate: boolean) => {
    if (item) {
      setSaving(true);
      await (activate
        ? SysServices.http.siteManagement.activateInventoryItem(item.sqlId)
        : SysServices.http.siteManagement.deActivateInventoryItem(item.sqlId)
      )
        .then((data) => {
          toastStore.showToast("Item updated successfully.", "success");
          setItem(data);
          setSaving(false);
        })
        .catch((error) => {
          toastStore.showError("Failed to update Item Status", error);
          setSaving(false);
        });
    }
  };

  return (
    <>
      <ConfirmDialog
        show={confirmDeactivate}
        buttons="yesno"
        title="De-Activate Item"
        message="Are you sure you want to De-Activate this Item?"
        done={(rtn) => {
          if (rtn === "yes") {
            toggleStatus(false);
          } else {
            setItem((d) => {
              if (!d) return d;
              return {
                ...d,
                activeYn: true,
              };
            });
          }
          setConfirmDeactivate(false);
        }}
      ></ConfirmDialog>
      <div className="default-page-layout">
        <h4 className="hide-on-print">Activate/Deactivate Inventory Items</h4>
        <div className="col-sm-12 col-md-9 col-lg-6">
          <div className="bg-white mb-4 p-3 flex flex-end gap-10">
            <div className="flex-1">
              <strong>Item Code</strong>
              <input
                autoFocus={true}
                className="form-control mt-1"
                type="text"
                placeholder="Item Code"
                onChange={(e) => setItemCode(e.target.value)}
                disabled={saving}
                onKeyDown={(e) => {
                  if (e.key === "Enter") getItem();
                }}
              ></input>
            </div>
            <div className="flex-1">
              <strong>Internal Item Id</strong>
              <input
                className="form-control mt-1"
                type="text"
                placeholder="Internal Item Id"
                onChange={(e) => setInternalItemId(e.target.value)}
                disabled={saving}
                onKeyDown={(e) => {
                  if (e.key === "Enter") getItem();
                }}
              ></input>
            </div>
            <div className="flex-0">
              <Button
                variant="primary"
                className="no-wrap"
                type="button"
                onClick={() => getItem()}
                disabled={saving || !canSubmit()}
              >
                <i className="fa fa-search"></i> Find
              </Button>
            </div>
          </div>

          <div className="bg-white mb-2 p-3">
            {status === 0 && (
              <div className="txt-primary">
                <i className="fa fa-search me-2"></i> Search item using Item
                Code or Internal Item Id
              </div>
            )}
            {status === 1 && (
              <CommonSpinner message="Searching Item..."></CommonSpinner>
            )}
            {status === 2 && (
              <div className="txt-danger">
                <i className="fa fa-warning"></i> Item Not Found
              </div>
            )}
            {status === 3 && !!item && (
              <div>
                <table className="table table-sm">
                  <tbody>
                    <tr>
                      <td>
                        {saving ? (
                          <h5 className="txt-success">Saving...</h5>
                        ) : (
                          <h5>Item Detail</h5>
                        )}
                      </td>
                      <td className="pb-3 text-right">
                        <SwitchButton
                          checked={item.activeYn}
                          checkedLabel="Active"
                          uncheckedLabel="Inactive"
                          onChange={(data) => {
                            setItem((d) => {
                              if (!d) return d;
                              return {
                                ...d,
                                activeYn: data,
                              };
                            });
                            if (data) {
                              toggleStatus(true);
                            } else {
                              setConfirmDeactivate(true);
                            }
                          }}
                          disabled={saving}
                        ></SwitchButton>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Item Id:</strong>
                      </td>
                      <td>{item.itemId}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Internal Item Id:</strong>
                      </td>
                      <td>{item.erpInternalId}</td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Display Name:</strong>
                      </td>
                      <td>{item.displayName}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Description:</strong>
                      </td>
                      <td>{item.description}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Brand:</strong>
                      </td>
                      <td>{item.purchaseLabel}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Category:</strong>
                      </td>
                      <td>{item.category}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Category Id:</strong>
                      </td>
                      <td>{item.categoryId}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Mnf Name:</strong>
                      </td>
                      <td>{item.manufacturedName}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Mnf Id:</strong>
                      </td>
                      <td>{item.manufacturerId}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Mnf Item Number:</strong>
                      </td>
                      <td>{item.manufactureItemNumber}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Last Portal Update:</strong>
                      </td>
                      <td>
                        {dayjs
                          .utc(item.lastUpdatedDate)
                          .local()
                          .format("MMM DD, YYYY hh:mm:ss A")}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Last ERP Update:</strong>
                      </td>
                      <td>
                        {dayjs
                          .utc(item.erpLastUpdatedDate)
                          .local()
                          .format("MMM DD, YYYY hh:mm:ss A")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default InventoryItems;
