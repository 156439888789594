import React, { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import CommonSpinner from "../../../components/common/CommonSpinner";
import ConfirmDialog from "../../../components/common/ConfirmDialog";
import Pagination, { usePaging } from "../../../components/common/Pagination";
import SysModels from "../../../models";
import SysServices from "../../../services";
import { FetchStatus, useFetchHelper } from "../../../services/FetchHelper";
import toastStore from "../../../stores/ToastStore";

export interface ICustomerSearchModel {
  typed: string;
  used: string;
}

export interface ISelectedCustomerModel {
  id: string;
  name: string;
}
export interface IQueryModel {
  id: any;
  name: string;
  items: ISelectedCustomerModel[];
}

function CustomerQueryFilters(props: {
  reportId: string;
  reportName: string;
  selectedCustomers: ISelectedCustomerModel[];
  onSelectedCustomersChanged: (list: ISelectedCustomerModel[]) => void;
  search: ICustomerSearchModel;
}) {
  const [showQueries, setShowQueries] = useState(false);
  const [queries, setQueries] = useState<IQueryModel[]>([]);
  const [query, setQuery] = useState<IQueryModel>({
    id: -1,
    name: "",
    items: [],
  });
  const [queryToDel, setQueryToDel] = useState<IQueryModel>();
  const [selectedCustomers, setSelectedCustomers] = useState<
    ISelectedCustomerModel[]
  >([]);
  const hasChanges = useMemo(() => {
    const match = queries.find((q) => query.id === q.id);
    if (!match) {
      return true;
    }
    return (
      JSON.stringify({
        ...query,
        items: selectedCustomers,
      }) !== JSON.stringify(match)
    );
  }, [query, queries, selectedCustomers]);

  const toggleCustomer = (id: string, name: string) => {
    setSelectedCustomers((list) => {
      if (list.find((c) => c.id === id)) {
        return list.filter((c) => c.id !== id);
      }
      return [...list, { id, name }];
    });
  };

  useEffect(() => {
    const list = props.selectedCustomers || [];
    setSelectedCustomers(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedCustomers]);

  useEffect(() => {
    const list = props.selectedCustomers || [];
    if (JSON.stringify(list) === JSON.stringify(selectedCustomers)) {
      return; //do nothing
    }
    props.onSelectedCustomersChanged(selectedCustomers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomers]);

  useEffect(() => {
    if (query.items.length) {
      props.onSelectedCustomersChanged(query.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.items]);

  const [loading, setLoading] = useState(false);
  const loadQueries = async (afterSave = false) => {
    setLoading(true);
    await SysServices.http.reportQuery
      .list(1, 10, props.reportId)
      .then((rtn) => {
        const data: IQueryModel[] = [];
        if (rtn && rtn.reportQueryGridItemDtos) {
          rtn.reportQueryGridItemDtos.forEach((item) => {
            data.push({
              id: item.id,
              name: item.name,
              items: JSON.parse(item.json || "[]"),
            });
          });
        }

        setQueries(data);
        setLoading(false);

        if (afterSave && query.name.trim()) {
          const match = data.find((q) => q.name.trim() === query.name.trim());
          if (match) {
            setQuery(match);
          }
        }
      })
      .catch((err) => {
        toastStore.showError("Failed getting Queries.", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadQueries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [saving, setSaving] = useState(false);
  const saveQuery = async () => {
    if (
      queries.find(
        (q) => q.name.trim() === query.name.trim() && q.id !== query.id
      )
    ) {
      toastStore.showToast("Query name already exists.", "warning");
      return;
    }
    if (queries.length >= 10) {
      toastStore.showToast(
        "Only a maximum of 10 queries can saved.",
        "warning"
      );
      return;
    }
    if (selectedCustomers.length < 2) {
      toastStore.showToast(
        "Please select at least two (2) customers",
        "warning"
      );
      return;
    }

    const data = [...queries];
    let isNew = false;
    if (data.find((d) => d.id === query.id)) {
      data.forEach((d) => {
        if (d.id === query.id) {
          d.name = query.name;
          d.items = selectedCustomers;
        }
      });
    } else {
      isNew = true;
      data.push({
        ...query,
        items: selectedCustomers,
      });
    }

    setSaving(true);

    const dto = {
      reportId: props.reportId,
      name: query.name.trim(),
      json: JSON.stringify(selectedCustomers),
    };

    if (isNew) {
      await SysServices.http.reportQuery
        .create(dto)
        .then(() => {
          toastStore.showToast("Query saved.", "success");
          localStorage.setItem(props.reportName, JSON.stringify(data));
          setQueries(data);
          setSaving(false);
          loadQueries(true);
        })
        .catch((err) => {
          toastStore.showError("Failed saving Query.", err);
          setSaving(false);
        });
    } else {
      await SysServices.http.reportQuery
        .update(query.id, dto)
        .then(() => {
          toastStore.showToast("Query saved.", "success");
          localStorage.setItem(props.reportName, JSON.stringify(data));
          setQueries(data);
          setSaving(false);
        })
        .catch((err) => {
          toastStore.showError("Failed saving Query.", err);
          setSaving(false);
        });
    }
  };

  const [idsToDel, setIdsToDel] = useState<string[]>([]);
  const delQuery = async (id: string) => {
    if (id === query.id) {
      removeFilter();
    }

    setIdsToDel((list) => [...list, id]);
    await SysServices.http.reportQuery
      .delete(id, props.reportId)
      .then(() => {
        toastStore.showToast("Query deleted.", "success");
        const data = queries.filter((d) => d.id !== id);
        localStorage.setItem(props.reportName, JSON.stringify(data));
        setQueries(data);
        setIdsToDel((list) => list.filter((i) => i !== id));
      })
      .catch((err) => {
        toastStore.showError("Failed deleting Query.", err);
        setIdsToDel((list) => list.filter((i) => i !== id));
      });
  };

  const removeFilter = () => {
    setQuery({
      id: -1,
      name: "",
      items: [],
    });
    props.onSelectedCustomersChanged([]);
  };

  const customers = useFetchHelper(
    async () =>
      SysServices.http.customer.list(paging.page, paging.pageSize, {
        search: props.search.used,
        orderByEnum: SysModels.OrderByEnum.Ascending,
      }),
    "Customers"
  );

  const [paging, setPaging] = usePaging(1, 100);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  useEffect(() => {
    customers.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, props.search.used]);

  //0-none, 1-some, 2-all
  const pageSelection = () => {
    if (customers.data && customers.data.erpCustomerGridItemDtos?.length > 0) {
      const cnt = customers.data.erpCustomerGridItemDtos.filter((i) =>
        selectedCustomers.find((c) => c.id === i.erpCustomerNumber)
      ).length;
      if (cnt === 0) {
        return 0;
      }
      return customers.data.erpCustomerGridItemDtos.length === cnt ? 2 : 1;
    }
    return 0;
  };

  return (
    <>
      {!!queryToDel && (
        <ConfirmDialog
          show={true}
          title="Delete Query Filter"
          message="Do you really want to delete this query filter?"
          buttons="yesno"
          done={(rtn) => {
            if (rtn === "yes") {
              delQuery(queryToDel.id);
            }
            setQueryToDel(undefined);
          }}
        ></ConfirmDialog>
      )}

      {customers.status === FetchStatus.InProgress && (
        <div className="py-4">
          <CommonSpinner></CommonSpinner>
        </div>
      )}

      <table
        className={`table table-hover bg-white ${
          customers.status === FetchStatus.Complete ? "" : "display-none"
        }`}
      >
        <thead>
          <tr>
            <th className="no-wrap">
              <div className="flex flex-center gap-10">
                <span className="flex-0">Customer</span>
                <div className="flex-1">
                  <div className="flex flex-1 flex-center gap-10">
                    <div
                      className="flex-1 px-2"
                      style={{ position: "relative" }}
                    >
                      {!!query.name.trim() && (
                        <div
                          className="flex flex-center alert alert-sm alert-danger p-0 m-0 no-wrap"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            maxWidth: "100%",
                            transform: "translateY(-50%)",
                          }}
                          title={query.name}
                        >
                          <div
                            className="no-wrap px-2"
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {query.name}
                          </div>
                          <i
                            className="fa fa-times text-danger pointer p-1 px-2"
                            style={{
                              backgroundColor: "#f5c2c7",
                            }}
                            title="Remove Filter"
                            onClick={(e) => {
                              removeFilter();
                            }}
                          ></i>
                        </div>
                      )}
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="p-0 border-none text-primary"
                        variant="default"
                        size="sm"
                        style={{ fontSize: "12px" }}
                      >
                        <strong>{selectedCustomers.length} Selected</strong>
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ minWidth: "280px" }}>
                        <div className="p-2 mb-2">
                          {showQueries && (
                            <div className="flex flex-center gap-10">
                              <button
                                type="button"
                                className="btn btn-sm btn-danger"
                                onClick={(e) => setShowQueries(false)}
                              >
                                <i className="fa fa-angle-left"></i> Back
                              </button>
                              <div className="flex-1">
                                <strong>Query List</strong>
                              </div>
                            </div>
                          )}
                          {!showQueries && (
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="new-password"
                                value={query.name || ""}
                                onChange={(e) => {
                                  setQuery((p) => {
                                    return {
                                      ...p,
                                      name: e.target.value || "",
                                    };
                                  });
                                }}
                                placeholder="Enter Query Name"
                                maxLength={50}
                              ></input>
                              <div className="input-group-append">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary"
                                  title="Save"
                                  onClick={(e) => {
                                    saveQuery();
                                  }}
                                  disabled={
                                    saving || !query.name.trim() || !hasChanges
                                  }
                                >
                                  {saving ? (
                                    <i className="fa fa-spin fa-spinner"></i>
                                  ) : (
                                    <i className="fa fa-save"></i>
                                  )}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-secondary"
                                  title="Query List"
                                  onClick={(e) => setShowQueries(true)}
                                >
                                  List <i className="fa fa-angle-right"></i>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                        {showQueries && !loading && queries.length === 0 && (
                          <Dropdown.Header>No Queries Saved</Dropdown.Header>
                        )}
                        {showQueries && loading && (
                          <Dropdown.Header>Loading...</Dropdown.Header>
                        )}
                        {showQueries && !loading && queries.length > 0 && (
                          <>
                            {queries.map((q) => (
                              <Dropdown.Item key={q.id}>
                                <div
                                  className="flex gap-5 flex-center"
                                  style={{
                                    maxWidth: "350px",
                                    whiteSpace: "normal",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setQuery(q);
                                    setSelectedCustomers([...q.items]);
                                    setShowQueries(false);
                                  }}
                                >
                                  <small className="flex-1">{q.name}</small>
                                  {idsToDel.find((i) => i === q.id) ? (
                                    <i className="fa fa-spin fa-spinner text-danger"></i>
                                  ) : (
                                    <i
                                      className="fa fa-trash text-danger pointer"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setQueryToDel(q);
                                      }}
                                    ></i>
                                  )}
                                </div>
                              </Dropdown.Item>
                            ))}
                          </>
                        )}
                        {!showQueries && selectedCustomers.length > 0 && (
                          <>
                            <Dropdown.Item
                              className="text-danger"
                              onClick={(e) => {
                                //setSelectedCustomers([]);
                                removeFilter();
                              }}
                            >
                              <div className="flex gap-5 flex-center">
                                <span className="flex-1">Clear All</span>
                                <i className="fa fa-trash"></i>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                          </>
                        )}
                        {!showQueries && selectedCustomers.length === 0 && (
                          <Dropdown.Header>
                            No Customer Selected
                          </Dropdown.Header>
                        )}
                        {!showQueries && (
                          <div
                            style={{
                              minWidth: "300px",
                              maxHeight: "500px",
                              overflow: "auto",
                            }}
                          >
                            {selectedCustomers.map((c) => (
                              <Dropdown.Item key={c.id}>
                                <div
                                  className="flex gap-5 flex-center"
                                  style={{
                                    maxWidth: "350px",
                                    whiteSpace: "normal",
                                  }}
                                >
                                  <small className="flex-1">
                                    {c.name} ({c.id})
                                  </small>
                                  <i
                                    className="fa fa-trash text-danger pointer"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      toggleCustomer(c.id, c.name);
                                    }}
                                  ></i>
                                </div>
                              </Dropdown.Item>
                            ))}
                          </div>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <input
                type="checkbox"
                checked={pageSelection() === 2}
                onChange={(e) => {
                  if (
                    customers.data &&
                    customers.data.erpCustomerGridItemDtos
                  ) {
                    if (pageSelection() !== 2) {
                      setSelectedCustomers((list) => {
                        const newList = [
                          ...list.filter(
                            (s) =>
                              !customers.data?.erpCustomerGridItemDtos?.find(
                                (e) => e.erpCustomerNumber === s.id
                              )
                          ),
                          ...(
                            customers.data?.erpCustomerGridItemDtos || []
                          ).map((e) => {
                            return {
                              id: e.erpCustomerNumber,
                              name: e.customerName,
                            };
                          }),
                        ];
                        return newList;
                      });
                    } else {
                      setSelectedCustomers((list) => {
                        const newList = [
                          ...list.filter(
                            (s) =>
                              !customers.data?.erpCustomerGridItemDtos?.find(
                                (e) => e.erpCustomerNumber === s.id
                              )
                          ),
                        ];
                        return newList;
                      });
                    }
                  }
                }}
              ></input>
            </th>
          </tr>
        </thead>
        <tbody>
          {customers?.data?.erpCustomerGridItemDtos?.map((item, idx) => (
            <tr
              key={`${item.erpCustomerNumber}-${idx}`}
              className="pointer"
              onClick={(e) => {
                toggleCustomer(item.erpCustomerNumber, item.customerName);
              }}
            >
              <td>
                <small>
                  {item.customerName} ({item.erpCustomerNumber})
                </small>
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={
                    !!selectedCustomers.find(
                      (c) => c.id === item.erpCustomerNumber
                    )
                  }
                  onChange={(e) => {
                    //do nothing...
                  }}
                ></input>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className={`p-3 px-0 pt-0 ${
          customers.data &&
          customers.data?.totalRecords > 0 &&
          customers.status === FetchStatus.Complete
            ? ""
            : "display-none"
        }`}
      >
        <Pagination
          length={customers.data?.totalRecords || 0}
          page={paging.page}
          pageSize={paging.pageSize}
          pageChange={pageChange}
          sizes={[10, 25, 50, 100]}
        ></Pagination>
      </div>

      {customers.data &&
        !customers.data?.totalRecords &&
        customers.status === FetchStatus.Complete && (
          <div className="py-4 text-center">No Customer(s) Found</div>
        )}
    </>
  );
}

export default CustomerQueryFilters;
