import React, { useEffect, useState } from "react";
import { Button, ModalBody, ModalFooter } from "react-bootstrap";
import CommonSpinner from "../../components/common/CommonSpinner";
import ConfirmDialog from "../../components/common/ConfirmDialog";
import FormModal from "../../components/common/FormModal";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import toastStore from "../../stores/ToastStore";

function InvoiceDetailDialog(props: {
  invoiceNumber: number;
  onClose: (refresh?: boolean) => void;
}) {
  const invoiceDetail = useFetchHelper(async () => {
    return SysServices.http.siteManagement.viewInvoiceDetail(
      props.invoiceNumber
    );
  }, "Invoice Details");

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    invoiceDetail.getData();
  }, [calledOnce, invoiceDetail]);

  const [resubmitting, setResubmitting] = useState(false);
  const resubmit = async () => {
    setResubmitting(true);
    await SysServices.http.siteManagement
      .resubmitInvoice({
        invoiceId: props.invoiceNumber,
      })
      .then(() => {
        toastStore.showToast("Invoice Resubmission was requested", "success");
        setResubmitting(false);
        setResubmitted(true);
      })
      .catch((error) => {
        toastStore.showError("Invoice Resubmission Request Failed", error);
        setResubmitting(false);
      });
  };

  const [askResubmit, setAskResubmit] = useState(false);
  const [resubmitted, setResubmitted] = useState(false);

  return (
    <>
      <ConfirmDialog
        show={!!askResubmit}
        title="Resubmit Invoice"
        message="Are you sure you want to Resubmit this invoice?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            resubmit();
          }
          setAskResubmit(false);
        }}
      ></ConfirmDialog>

      <FormModal
        title="Outgoing Invoice Details"
        isOpen={true}
        close={() => {
          props.onClose(resubmitted);
        }}
        size="lg"
        customBodyFooter={true}
        moveBehind={askResubmit}
      >
        <ModalBody>
          {invoiceDetail.status === FetchStatus.InProgress && (
            <CommonSpinner message="Getting Invoice Detail"></CommonSpinner>
          )}
          {invoiceDetail.status === FetchStatus.Failed && (
            <div className="text-center p-4">
              <span className="text-danger">Failed Getting Invoice Detail</span>
              <br />
              <span
                className="text-primary pointer"
                onClick={() => invoiceDetail.getData()}
              >
                Try Again
              </span>
            </div>
          )}
          {invoiceDetail.status === FetchStatus.Complete &&
            invoiceDetail.data && (
              <>
                <div className="row pb-3">
                  <div className="col-sm-12 col-md-6">
                    <table className="no-format">
                      <tbody>
                        <tr>
                          <td className="pe-2 no-wrap">
                            <strong>Customer Number:</strong>
                          </td>
                          <td>
                            <span>{invoiceDetail.data?.customerAccountNo}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2 no-wrap">
                            {invoiceDetail.data?.isCreditMemo ? (
                              <strong>Credit Memo Number:</strong>
                            ) : (
                              <strong>Invoice Number:</strong>
                            )}
                          </td>
                          <td>
                            <span>{invoiceDetail.data?.invoiceNumber}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2">
                            <strong>Invoice Date:</strong>
                          </td>
                          <td>
                            {invoiceDetail.data?.invoiceDate &&
                              commonService.toLocalDate(
                                invoiceDetail.data?.invoiceDate,
                                "date"
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2"></td>
                          <td>
                            {invoiceDetail.data?.invoiceDate &&
                              commonService.toLocalDate(
                                invoiceDetail.data?.invoiceDate,
                                "time"
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <table className="no-format">
                      <tbody>
                        <tr>
                          <td className="pe-2">
                            <strong>Total:</strong>
                          </td>
                          <td>
                            {commonService.toMoney(
                              invoiceDetail.data?.invoiceAmount
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2 no-wrap">
                            <strong>Web Order Number:</strong>
                          </td>
                          <td>
                            <span>{invoiceDetail.data?.webOrderNumber}</span>
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2">
                            <strong>Submitted:</strong>
                          </td>
                          <td>
                            {invoiceDetail.data?.submittedToBuyingGroupOn &&
                              commonService.toLocalDate(
                                invoiceDetail.data?.submittedToBuyingGroupOn,
                                "date"
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td className="pe-2"></td>
                          <td>
                            {invoiceDetail.data?.submittedToBuyingGroupOn &&
                              commonService.toLocalDate(
                                invoiceDetail.data?.submittedToBuyingGroupOn,
                                "time"
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <table className="table table-striped table-sm w-100 bg-white">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Description</th>
                      <th className="text-center">Quantity</th>
                      <th className="text-right">Unit Price</th>
                      <th>UOM</th>
                      <th className="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(
                      invoiceDetail.data?.portalInvoiceLineItemOutputDtos || []
                    ).map((item) => (
                      <tr key={item.itemCode}>
                        <td>{item.itemCode}</td>
                        <td>{item.description}</td>
                        <td className="text-center">
                          {commonService.toNumberWithComma(
                            item.quantityOrdered
                          )}
                        </td>
                        <td className="text-right">
                          {commonService.toMoney(item.unitPrice)}
                        </td>
                        <td>{item.uom}</td>
                        <td className="text-right">
                          {commonService.toMoney(item.total)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={() => {
              setAskResubmit(true);
            }}
            variant="primary"
            size="sm"
            disabled={
              invoiceDetail.status === FetchStatus.InProgress || resubmitting
            }
          >
            {resubmitting ? "Resubmitting..." : "Resubmit to Buying Group"}
          </Button>
          <Button
            type="button"
            onClick={() => {
              props.onClose(resubmitted);
            }}
            variant="secondary"
            size="sm"
            disabled={invoiceDetail.status === FetchStatus.InProgress}
          >
            Close
          </Button>
        </ModalFooter>
      </FormModal>
    </>
  );
}
export default InvoiceDetailDialog;
