import React, { useEffect } from "react";
import CommonSpinner from "../../components/common/CommonSpinner";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";

function BuyingGroupOrderSummaryWidget(props: any) {
  const summaries = useFetchHelper(
    async () => SysServices.http.order.getBuyingGroupOrderSummaries(),
    "Buying Group Order Summaries"
  );

  useEffect(() => {
    summaries.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="hide-on-print">
      <div className="bg-white mb-4">
        <div className="p-3 bg-success txt-light">
          <h5 className="m-0">
            <em className="zmdi zmdi-file-text me-2"></em>
            Buying Group Order Summary
          </h5>
        </div>
        <div className="p-3 overflow-auto min-height-100">
          <div className="pb-3 text-danger">
            Based on incoming orders. Amounts might change after OE processes
            the orders.
          </div>
          {summaries.status === FetchStatus.InProgress && (
            <CommonSpinner></CommonSpinner>
          )}
          {summaries.status === FetchStatus.Complete && (
            <table className="table table-sm">
              <thead>
                <tr>
                  <th></th>
                  <th colSpan={2} className="text-center">
                    <strong>This Month</strong>
                  </th>
                  <th colSpan={2} className="text-center">
                    <strong>Last Month</strong>
                  </th>
                  <th></th>
                </tr>
                <tr>
                  <th>Buying Group</th>
                  <th className="text-right bg-light-grey">#</th>
                  <th className="text-right bg-light-grey">Amt</th>
                  <th className="text-right">#</th>
                  <th className="text-right">Amt</th>
                  <th className="text-right bg-light-grey no-wrap">
                    No. Of <br />
                    Accts
                  </th>
                </tr>
              </thead>
              <tbody>
                {summaries?.data?.map((summary) => (
                  <tr key={summary.sqlId}>
                    <td>{summary.buyingGroupName}</td>
                    <td className="text-right bg-light-grey">
                      {summary.thisMonthTotalNo}
                    </td>
                    <td className="text-right bg-light-grey">
                      {commonService.toMoney(summary.thisMonthTotalAmount)}
                    </td>
                    <td className="text-right">{summary.lastMonthTotalNo}</td>
                    <td className="text-right">
                      {commonService.toMoney(summary.lastMonthTotalAmount)}
                    </td>
                    <td className="text-right bg-light-grey">
                      {summary.noOfAccounts}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}

export default BuyingGroupOrderSummaryWidget;
