import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import SysServices from "../services";
import commonService from "../services/CommonService";
import toastStore from "../stores/ToastStore";

function MWFSettings(props: any) {
  const [emailString, setEmailString] = useState("");
  const [list, setList] = useState<string[]>([]);
  const updateEmailList = (invoiceString: string) => {
    const newEmails: string[] = [];
    invoiceString.split(",").forEach((email) => {
      if (email.trim() !== "") {
        newEmails.push(email.trim());
      }
    });
    setList(newEmails);
  };

  const [saving, setSaving] = useState(false);
  const save = async () => {
    setSaving(true);
    await SysServices.http.siteManagement
      .updateMwfGlobalSettings({
        ordersEmailAddresses: emailString,
      })
      .then((data) => {
        setSaving(false);
        toastStore.showToast("Settings Saved.", "success");
      })
      .catch((error) => {
        setSaving(false);
        toastStore.showError("Failed saving Settings", error);
      });
  };

  const cannotSubmit = () => {
    return (
      saving ||
      list.length === 0 ||
      !!list.find((email) => !commonService.regexPatterns.email.test(email)) ||
      !!list.find((email) => list.filter((i) => i === email).length > 1)
    );
  };

  const [gettingSettings, setGettingSettings] = useState(false);
  useEffect(() => {
    setGettingSettings(true);
    SysServices.http.siteManagement
      .getMwfGlobalSettings()
      .then((data) => {
        setGettingSettings(false);
        setEmailString(data.ordersEmailAddresses || "");
        updateEmailList(data.ordersEmailAddresses || "");
      })
      .catch((error) => {
        setGettingSettings(false);
        toastStore.showError("Failed getting Settings", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="default-page-layout">
        <h4>MWF Settings</h4>
      </div>

      <div className="row">
        <div className="cols-sm-12 col-md-12 col-lg-6">
          <div>
            <div className="bg-white mb-4">
              <div className="p-3 bg-success txt-light">
                <h5 className="m-0">
                  <em className="zmdi zmdi-email me-2"></em>
                  Orders Notification
                </h5>
              </div>

              {gettingSettings && (
                <div className="p-4">
                  <CommonSpinner></CommonSpinner>
                </div>
              )}
              {!gettingSettings && (
                <div className="p-3 overflow-auto min-height-100">
                  <div className="mb-4">
                    <label className="mb-2 text-primary">
                      <strong>Email Address(es) - Separated by commas</strong>
                    </label>
                    <textarea
                      autoFocus={true}
                      className="form-control"
                      placeholder="Emails"
                      value={emailString}
                      onChange={(e) => {
                        updateEmailList(e.target.value || "");
                        setEmailString(e.target.value || "");
                      }}
                      disabled={saving}
                      rows={5}
                      maxLength={2048}
                    ></textarea>
                    <div className="mt-2 flex">
                      <div className="flex-1">
                        {list
                          .filter(
                            (email) =>
                              !commonService.regexPatterns.email.test(email) ||
                              list.filter((i) => i === email).length > 1
                          )
                          .filter((value, index, self) => {
                            return self.indexOf(value) === index;
                          })
                          .map((email) => {
                            if (
                              !commonService.regexPatterns.email.test(email)
                            ) {
                              return (
                                <span
                                  key={email}
                                  className="chip-red me-1"
                                  title="Invalid Email Format"
                                >
                                  {email}
                                </span>
                              );
                            }
                            if (list.filter((i) => i === email).length > 1) {
                              return (
                                <span
                                  key={email}
                                  className="chip-orange me-1"
                                  title={`Entered ${
                                    list.filter((i) => i === email).length
                                  } times`}
                                >
                                  {email}
                                </span>
                              );
                            }
                            return "";
                          })}
                      </div>
                      <div className="flex-0">{emailString.length}/2048</div>
                    </div>
                  </div>
                  <div className="text-right">
                    <Button
                      variant="success"
                      type="button"
                      disabled={cannotSubmit()}
                      onClick={() => save()}
                    >
                      {saving ? "Saving Settings..." : "Save Settings"} (
                      {list.length})
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="cols-sm-12 col-md-12 col-lg-6"></div>
      </div>
    </>
  );
}

export default MWFSettings;
