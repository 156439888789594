import SysModels from "../../../models";
import { CustomerInvoicePaymentService } from "../CustomerInvoicePaymentService";
import { fetcher } from "../_services";

class ExtendedCustomerInvoicePaymentService extends CustomerInvoicePaymentService {
  listForExecutive = async (
    page: number,
    pageSize: number,
    data: SysModels.IExecutiveSummaryFilterDto
  ) => {
    let url = `/CustomerInvoicePayment/ExecutiveSummary/${page}/${pageSize}`;
    return fetcher.post<SysModels.IExecutiveSummaryOutputDto>(url, data);
  };
}

const extendedCustomerInvoicePaymentService =
  new ExtendedCustomerInvoicePaymentService();
export default extendedCustomerInvoicePaymentService;
