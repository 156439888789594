import SysModels from "../../../models";
import fetcher from "../../Fetcher";
import { SurveyService } from "../SurveyService";
class ExtendedSurveyService extends SurveyService {
  list = async (
    page: number,
    pageSize: number,
    filter: SysModels.ISurveyFilterInputDto
  ) => {
    const url = `/Survey/${page}/${pageSize}`;
    return fetcher.post<SysModels.ISurveyGridDto>(url, filter);
  };

  get = async (surveyToken: string) => {
    const url = `/Survey/${surveyToken}`;
    return fetcher.get<SysModels.ISurveyItemOutputDto>(url);
  };

  surveySummary = async (data: SysModels.ISurveyFilterInputDto) => {
    const url = `/Survey/SurveySummary`;
    return fetcher.post<SysModels.ISurveySummaryOutputDto>(url, data);
  };
}

const extendedSurvey = new ExtendedSurveyService();
export default extendedSurvey;
