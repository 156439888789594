import SysModels from "../../../models";
import systemStore from "../../../stores/SystemStore";
import { CustomerService } from "../CustomerService";
import { fetcher } from "../_services";

class ExtendedCustomerService extends CustomerService {
  listRecentlyViewed = async (pageSize: number) => {
    const url = `/Customer/RecentlyViewed/${pageSize}`;
    return fetcher.get<SysModels.IRecentlyViewedCustomerOutputDto[]>(url);
  };

  listTop = async (
    page: number,
    pageSize: number,
    filterBy: SysModels.DatePeriodEnum
  ) => {
    const url = `/Customer/Top/${page}/${pageSize}/${filterBy}`;
    return fetcher.get<SysModels.ITopCustomerGridOutputDto>(url);
  };

  list = async (
    page: number,
    pageSize: number,
    data: SysModels.ISearchInputBaseDto
  ) => {
    const url = `/Customer/${page}/${pageSize}`;
    return fetcher.post<SysModels.IErpCustomerGridDto>(url, data);
  };

  typeAhead = async (data: SysModels.ITypeAheadInputDto) => {
    const url = `/Customer/TypeAhead`;
    return fetcher.post<SysModels.ITypeAheadOutputDto[]>(url, data);
  };

  createBidSheetOrderItem = async (
    data: SysModels.ICustomerBidSheetOrderCreateDto
  ) => {
    const url = `/Customer/CreateBidSheetOrderItem`;
    return fetcher.post<any>(url, data);
  };

  updateBidSheetOrderItem = async (
    id: number,
    data: SysModels.ICustomerBidSheetOrderCreateDto
  ) => {
    const url = `/Customer/UpdateBidSheetOrderItem/${id}/${
      encodeURIComponent(systemStore.currentCustomer?.number || "")
    }`;
    return fetcher.put<any>(url, data);
  };

  deleteBidSheetOrderItem = async (id: number) => {
    const url = `/Customer/DeleteBidSheetOrderItem/${id}/${
      encodeURIComponent(systemStore.currentCustomer?.number || "")
    }`;
    return fetcher.delete<any>(url, {});
  };

  listBidSheetOrderItems = async (page: number, pageSize: number) => {
    const url = `/Customer/ListBidSheetOrderItems/${page}/${pageSize}/${
      encodeURIComponent(systemStore.currentCustomer?.number || "")
    }`;
    return fetcher.get<SysModels.ICustomerBidSheetOrderGridDto>(url);
  };

  executiveCustomerOrderingSummary = async (
    data: SysModels.ICustomerOrderingSummaryFilterDto
  ) => {
    let url = `/Customer/ExecutiveCustomerOrderingSummary`;
    if (systemStore.currentCustomer) {
      url = `${url}/${systemStore.currentCustomer.number}`;
    }
    return fetcher.post<SysModels.ICustomerOrderingSummaryOutputDto>(url, data);
  };
}

const extendedCustomerService = new ExtendedCustomerService();
export default extendedCustomerService;
