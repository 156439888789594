import dayjs from "dayjs";
import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonSpinner from "../../components/common/CommonSpinner";
import DateRangePicker, {
  DatePeriod,
  GetPeriodValues,
} from "../../components/common/DateRangePicker";
import SysModels from "../../models";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import toastStore from "../../stores/ToastStore";
import CategoryQueryFilters, {
  ISelectedCategoryModel,
} from "./filters/CategoryQueryFilters";

const DEFAULT_FORMAT = "YYYY-MM-DD";

function OpenOrdersReport(props: any) {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [period, setPeriod] = useState<{
    mainCategory: number;
    categories: string[];
    from: string | null;
    to: string | null;
  }>({
    mainCategory: -1,
    categories: [],
    from: GetPeriodValues(DatePeriod.Today).from,
    to: dayjs().add(7, "day").format(DEFAULT_FORMAT),
  });

  const grid = useFetchHelper(
    async () =>
      SysServices.http.reportView.openOrders({
        fromDate: period.from as any,
        toDate: period.to as any,
        categoryDescriptions: selections.map((c) => c.displayDescription) || [],
      }),
    "Open Orders"
  );

  const [selections, setSelections] = useState<
    SysModels.ICatalogCategoryOutputDto[]
  >([]);

  const canProceed = () => {
    if (dayjs().startOf("day").isAfter(period.from)) {
      toastStore.showToast("From date must be today or greater", "warning");
      return false;
    }
    if (dayjs().startOf("day").isAfter(period.to)) {
      toastStore.showToast("To date must be today or greater", "warning");
      return false;
    }
    if (dayjs(period.to).isBefore(period.from)) {
      toastStore.showToast(
        "To date must be equal or greater than From date",
        "warning"
      );
      return false;
    }
    if (selectedCategories.length === 0) {
      toastStore.showToast("Please select at least one category.", "warning");
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (selections && selections?.length > 0) {
      if (!canProceed()) {
        return;
      }
      grid.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, selections]); //selections

  useEffect(() => {
    if (grid.status === FetchStatus.Failed && grid.error?.code === 403) {
      navigate("/reports");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grid.status]);

  const [downloading, setDownloading] = useState(false);
  const startDownload = async () => {
    if (!canProceed()) {
      return;
    }
    setDownloading(true);
    await SysServices.http.reportView
      .openOrdersCsv({
        fromDate: period.from as any,
        toDate: period.to as any,
        categoryDescriptions: selections.map((c) => c.displayDescription) || [],
      })
      .then((data) => {
        setDownloading(false);
        const file = commonService.b64toBlob(data.fileContents, "text/csv");
        FileSaver.saveAs(file, data.fileDownloadName);
      })
      .catch((err) => {
        setDownloading(false);
        toastStore.showError("Download Failed", err);
      });
  };

  const [selectedCategories, setSelectedCategories] = useState<
    ISelectedCategoryModel[]
  >([]);

  return (
    <div className="default-page-layout">
      {downloading && (
        <CommonSpinner overlay={true} message="Downloading..."></CommonSpinner>
      )}

      <h4 className="hide-on-print">Open Orders</h4>

      <div className="bg-white">
        <div
          className={`print-wrapper hide-on-print flex flex-center flex-wrap gap-15 mb-0 ${
            grid.status === FetchStatus.InProgress ? "display-none" : ""
          }`}
        >
          <div className="flex">
            <DateRangePicker
              from={period.from}
              to={period.to}
              onChanged={(from, to) => {
                setPeriod((prev) => {
                  return { ...prev, from: from, to: to };
                });
              }}
              requireBothDates={true}
              periods={[DatePeriod.Custom]}
            ></DateRangePicker>
          </div>
          <div className="flex-1 now-wrap p-2">
            <a
              href="/"
              className="no-decoration no-wrap me-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.print();
              }}
            >
              <i className="fa fa-print"></i> Print
            </a>{" "}
            <a
              href="/"
              className="no-decoration no-wrap"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                startDownload();
              }}
            >
              <i className="fa fa-download"></i> Download CSV
            </a>
          </div>

          <div className="flex-0 w-100 text-right">
            <button
              type="button"
              className="btn btn-success"
              onClick={(e) => {
                if (selectedCategories.length > 0) {
                  setSelections([
                    ...selectedCategories.map((c) => {
                      return {
                        id: c.id,
                        displayId: c.id,
                        displayDescription: c.name,
                      };
                    }),
                  ]);
                } else {
                  toastStore.showToast(
                    "Please select at least one category.",
                    "warning"
                  );
                }
              }}
            >
              Submit
            </button>
          </div>
        </div>
        {grid.status === FetchStatus.InProgress && (
          <CommonSpinner></CommonSpinner>
        )}

        <div
          className={`flex overflow-auto ${
            grid.status === FetchStatus.InProgress ? "display-none" : ""
          }`}
          style={{ minHeight: "100px" }}
        >
          <div className="flex-1">
            <table className="table table-hover billing-table">
              <thead>
                <tr>
                  <th className="no-wrap">Sub Item Category</th>
                  <th className="no-wrap">Item</th>
                  <th className="no-wrap">Description</th>
                  {/* <th className="no-wrap">Item: Purchase Label</th>*/}
                  <th className="no-wrap">Unit</th>
                  <th className="text-right no-wrap">Qty. Sold</th>
                  {/* <th className="text-right no-wrap">Amount</th> */}
                </tr>
              </thead>
              <tbody>
                {grid?.data?.map((item, idx) => (
                  <tr key={`${item.itemId}-${idx}`}>
                    <td>{item.category}</td>
                    <td>{item.itemId}</td>
                    <td>{item.description}</td>
                    <td>{item.saleUnit}</td>
                    <td className="text-right no-wrap">
                      {commonService.toNumberWithComma(item.quantity)}
                    </td>
                    {/* <td className="text-right no-wrap">
                      {commonService.toMoney(item.saleUnit)}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>

            {selectedCategories.length === 0 && !grid?.data?.length && (
              <div className="text-center py-4 w-100">
                Please select at least one category
              </div>
            )}
          </div>
          <div
            className="bg-light-grey px-3 hide-on-print"
            style={{ width: "380px" }}
          >
            <CategoryQueryFilters
              reportId={id || ""}
              reportName="OpenOrdersReport"
              selectedCategory={selectedCategories}
              onSelectedCategoryChanged={(list) => {
                setSelectedCategories(list);
              }}
            ></CategoryQueryFilters>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenOrdersReport;
