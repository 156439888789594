import React from "react";
import LastSystemActivitiesWidget from "../widgets/LastSystemActivitiesWidget";
import SystemActivityWidget from "../widgets/SystemActivityWidget";
import BatchJobScheduleWidget from "../widgets/BatchJobScheduleWidget";

function AdminDashboard(props: any) {
  return (
    <>
      <div className="default-page-layout hide-on-print">
        <h4>Admin Dashboard</h4>
      </div>

      <div className="row">
        <div className="cols-sm-12 col-md-12 col-lg-6">
          <SystemActivityWidget></SystemActivityWidget>
          <BatchJobScheduleWidget></BatchJobScheduleWidget>
        </div>
        <div className="cols-sm-12 col-md-12 col-lg-6">
          <LastSystemActivitiesWidget></LastSystemActivitiesWidget>
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
