import { Button, ModalBody, ModalFooter } from "react-bootstrap";
import FormModal from "../../components/common/FormModal";
import { useEffect, useState } from "react";
import ConfirmDialog from "../../components/common/ConfirmDialog";
import SysServices from "../../services";
import toastStore from "../../stores/ToastStore";
import CommonSpinner from "../../components/common/CommonSpinner";
import DocEditor from "../../components/common/DocEditor";
import commonService from "../../services/CommonService";

function DocumentationPreviewDialog(props: {
  id: string;
  content: string;
  title: string;
  subject: string;
  print?: boolean;
  published?: boolean;
  onClose: () => void;
}) {
  const [confirmTitle, setConfirmTitle] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [content, setContent] = useState(props.content || "");
  const [subject, setSubject] = useState(props.subject || "");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!`${props.content || ""}`.trim()) {
      setLoading(true);

      if (props.published) {
        SysServices.http.generalDocumentation
          .getPublished(props.id)
          .then((data) => {
            setLoading(false);
            setContent(data.content);
            setSubject(data.subject);
            setConfirmTitle(props.print || false);
          })
          .catch((error) => {
            toastStore.showError("Failed to getting document content", error);
            setLoading(false);
          });
      } else {
        SysServices.http.generalDocumentation
          .getContent(props.id)
          .then((data) => {
            setLoading(false);
            setContent(data);
            setConfirmTitle(props.print || false);
          })
          .catch((error) => {
            toastStore.showError("Failed to getting document content", error);
            setLoading(false);
          });
      }
    } else {
      setConfirmTitle(props.print || false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ConfirmDialog
        show={confirmTitle}
        buttons="yesno"
        title="Print Document"
        message={`Do you want to display Title and Short Description?`}
        done={(rtn) => {
          setShowTitle(rtn === "yes");
          setConfirmTitle(false);
          setTimeout(() => {
            window.print();
          }, 500);
        }}
      ></ConfirmDialog>

      <FormModal
        title={`${props.title} (${props.published ? "Published" : "Draft"})`}
        isOpen={true}
        close={() => {
          props.onClose();
        }}
        customBodyFooter={true}
        moveBehind={confirmTitle}
        size="xl"
        dialogClassName="dialog-width-90perc"
      >
        {showTitle && (
          <div className="show-on-print">
            <strong>{props.title}</strong>
            <div className="py-2" style={{ borderBottom: "solid 1px #ddd" }}>
              {subject}
            </div>
          </div>
        )}
        {!loading && !!`${subject || ""}`.trim() && (
          <div
            className="hide-on-print px-4 py-2"
            style={{ background: "#eee", borderBottom: "solid 1px #ddd" }}
          >
            {subject}
          </div>
        )}
        <ModalBody>
          {loading && (
            <div className="mt-4">
              <CommonSpinner message="loading..."></CommonSpinner>
            </div>
          )}
          {!loading && !commonService.isNullOrEmpty(content) && (
            <div className="px-4 py-2">
              <DocEditor
                content={content}
                readonly={true}
                className="rich-editor-view"
              ></DocEditor>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={() => {
              props.onClose();
            }}
            variant="secondary"
            size="sm"
          >
            Close
          </Button>

          <Button
            type="button"
            onClick={() => {
              setConfirmTitle(true);
            }}
            variant="primary"
            size="sm"
            className="me-3"
          >
            <i className="fa fa-print me-2"></i>Print
          </Button>
        </ModalFooter>
      </FormModal>
    </>
  );
}

export default DocumentationPreviewDialog;
