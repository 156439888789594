import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Button, ModalBody, ModalFooter } from "react-bootstrap";
import CommonSpinner from "../../components/common/CommonSpinner";
import FormModal from "../../components/common/FormModal";
import Pagination, { usePaging } from "../../components/common/Pagination";
import SysModels from "../../models";
import OrderDetailDialog from "../../pages/orders/OrderDetailDialog";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import CustomerLinkComponent from "../common/CustomerLinkComponent";

function CSROrderSummaryDialog(props: {
  userId: string;
  name: string;
  email: string;
  onClose: () => void;
}) {
  const [paging, setPaging] = usePaging(1, 25);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const grid = useFetchHelper(
    async () =>
      SysServices.http.order.listOrderGuideOrdersForCsr(
        paging.page,
        paging.pageSize,
        props.userId
      ),
    "Orders"
  );

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  // const ActionsCellComponent = (data: any) => {
  //   return (
  //     <>
  //       <Dropdown>
  //         <Dropdown.Toggle size="sm" variant="primary">
  //           Action
  //         </Dropdown.Toggle>
  //         <Dropdown.Menu align="right">
  //           <Dropdown.Item onClick={() => {}}>
  //             <i className="fa fa-eye me-3"></i>
  //             <span>View Details</span>
  //           </Dropdown.Item>
  //         </Dropdown.Menu>
  //       </Dropdown>
  //     </>
  //   );
  // };

  const [selectedOrder, setSelectedOrder] =
    useState<SysModels.IOrderGuideOrderItemForCsrDto>();

  return (
    <>
      {selectedOrder && (
        <OrderDetailDialog
          order={{} as any}
          csrOrder={{
            orderId: selectedOrder.sqlId,
            customerId: selectedOrder.customerNumber,
          }}
          onClose={() => setSelectedOrder(undefined)}
          reportProblem={false}
        ></OrderDetailDialog>
      )}

      <FormModal
        title={`Order Details for: <a class="mail-link" href="mailto:${props.email}">${props.name}</a>`}
        isOpen={true}
        close={() => {}}
        customBodyFooter={true}
        moveBehind={!!selectedOrder}
      >
        <ModalBody>
          <div className="p-1">
            {grid.status === FetchStatus.InProgress && (
              <div>
                <CommonSpinner></CommonSpinner>
              </div>
            )}
            {grid.data && grid.status === FetchStatus.Complete && (
              <div className="overflow-auto">
                <div className="col-sm-12">
                  <table className="table table-sm m-0">
                    <thead>
                      <tr>
                        <th>Customer</th>
                        <th className="no-wrap text-right pe-2">Order #</th>
                        <th className="no-wrap text-right pe-2">Placed On</th>
                        <th className="no-wrap text-right pe-2">
                          Delivery Date
                        </th>
                        <th className="no-wrap text-right">Order Amount</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {grid.data?.orderGuideOrderItemForCsrDtos?.map(
                        (order) => (
                          <tr key={order.sqlId}>
                            <td>
                              <CustomerLinkComponent
                                name={order.customerName}
                                number={order.customerNumber}
                              ></CustomerLinkComponent>
                            </td>
                            <td className="text-right pe-2">{order.sqlId}</td>
                            <td className="no-wrap text-right pe-2">
                              {dayjs(order.placedOn).format("MMM DD, YYYY")}
                            </td>
                            <td className="no-wrap text-right pe-2">
                              {dayjs(order.deliveryDate).format("MMM DD, YYYY")}
                            </td>
                            <td className="no-wrap text-right">
                              {commonService.toMoney(order.orderAmount)}
                            </td>
                            <td className="px-3 py-2 pe-0">
                              {/* <ActionsCellComponent
                            {...order}
                          ></ActionsCellComponent> */}
                              <a
                                href="/"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectedOrder(order);
                                }}
                                className="mail-link no-wrap"
                              >
                                View Detail
                              </a>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                  <div>
                    <Pagination
                      length={grid.data?.totalRecords || 0}
                      page={paging.page}
                      pageSize={paging.pageSize}
                      pageChange={pageChange}
                      showingOfWhatLabel="entries"
                      sizes={[10, 25, 50, 100]}
                    ></Pagination>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={() => props.onClose()}
            variant="secondary"
          >
            Close
          </Button>
        </ModalFooter>
      </FormModal>
    </>
  );
}

export default CSROrderSummaryDialog;
