import React, { useRef } from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import commonService from "../../services/CommonService";

export const BarChar_Colors = [
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
];

function TwoItemBarChart(props: {
  data: {
    value: any;
    label: string;
  }[];
}) {
  const ref = useRef();

  return (
    <div
      style={{
        position: "relative",
        height: "50px",
      }}
    >
      <Bar
        ref={ref}
        data={{
          labels: props.data.map((x) => x.label),
          datasets: [
            {
              data: props.data.map((x) => x.value || 0),
              categoryPercentage: 0.3,
              barThickness: 20,
              barPercentage: 0.5,
              backgroundColor: BarChar_Colors,
              borderWidth: 0,
            },
          ],
        }}
        plugins={[ChartDataLabels as any]}
        options={{
          indexAxis: "y",
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: true,
              color: "white",
              formatter: (val, context) => {
                return commonService.toMoney(val);
              },
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  if (!context.label) {
                    return "No Data";
                  }
                  return ` ${commonService.toMoney(context.raw)}`;
                },
              },
            },
          },
        }}
      ></Bar>
    </div>
  );
}

export default TwoItemBarChart;
