import React, { useEffect, useMemo, useRef, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import FormModal from "../components/common/FormModal";
import toastStore from "../stores/ToastStore";
import SwitchButton from "../components/common/SwitchButton";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Tab, Tabs } from "react-bootstrap";
import ConfirmDialog from "../components/common/ConfirmDialog";
import commonService from "../services/CommonService";

function ApiIntegrationPage(props: any) {
  const scopes = useFetchHelper(
    () => SysServices.http.genericEnumLookup.get("ApiIntegrationScopesEnum"),
    "API Integration Scopes"
  );
  useEffect(() => {
    scopes.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const grid = useFetchHelper(
    async () =>
      SysServices.http.apiIntegration.list(paging.page, paging.pageSize, {
        search: search.used,
        orderByEnum: SysModels.OrderByEnum.Ascending,
      }),
    "API Integrations"
  );

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used]);

  const [showDialog, setShowDialog] = useState<{
    id?: string;
  }>();

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [model, setModel] = useState<SysModels.IApiIntegrationInputDto>({
    name: "",
    active: true,
    apiIntegrationDetailsDto: {
      description: "",
      allCustomers: false,
      apiCustomerDetails: [],
      apiIntegrationScopes: [],
    },
  });
  const modelDto = () => {
    return model as SysModels.IApiIntegrationOutputDto;
  };
  const [origModel, setOrigModel] =
    useState<SysModels.IApiIntegrationInputDto>();

  const hasChanges = useMemo(() => {
    const same =
      JSON.stringify(model || {}) === JSON.stringify(origModel || {});
    return !same;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, origModel]);

  useEffect(() => {
    if (showDialog?.id && !modelDto().id) {
      setLoading(true);
      SysServices.http.apiIntegration
        .get(showDialog.id)
        .then((data) => {
          setModel(data);
          setOrigModel(data);
        })
        .catch((err: any) => {
          setShowDialog(undefined);
          toastStore.showError("Failed Getting API Integration", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDialog]);

  const toggleScope = (scope: SysModels.ApiIntegrationScopesEnum) => {
    setModel((data) => {
      const match =
        data.apiIntegrationDetailsDto.apiIntegrationScopes.filter(
          (s) => s.apiIntegrationScope === scope
        ).length === 0;

      return {
        ...data,
        apiIntegrationDetailsDto: {
          ...data.apiIntegrationDetailsDto,
          apiIntegrationScopes: match
            ? [
                ...data.apiIntegrationDetailsDto.apiIntegrationScopes,
                {
                  apiIntegrationScope: scope,
                },
              ]
            : data.apiIntegrationDetailsDto.apiIntegrationScopes.filter(
                (s) => s.apiIntegrationScope !== scope
              ),
        },
      };
    });
  };

  const ref = useRef(null);
  const [users, setUsers] = useState([] as { name: string; id: string }[]);
  const [isLoading, setIsLoading] = useState(false);
  const handleSearch = async (query: string) => {
    if ((query || "").trim() === "") {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    await SysServices.http.apiIntegration
      .customerTypeAhead({ search: query })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: i.label,
        }));
        setUsers(options);
        setIsLoading(false);
      })
      .catch((err) => {
        setUsers([]);
        setIsLoading(false);
      });
  };

  const [delApi, setDelApi] = useState<string>();
  const [deleting, setDeleting] = useState(false);
  const [gettingNewKey, setGettingNewKey] = useState(false);

  const [email, setEmail] = useState<SysModels.IApiIntegrationEmailInputDto>();
  const [sending, setSending] = useState(false);
  const sendEmail = () => {
    if (email) {
      setSending(true);
      SysServices.http.apiIntegration
        .email(email)
        .then((data) => {
          toastStore.showToast("Email Sent", "success");
          setEmail(undefined);
        })
        .catch((err) => {
          toastStore.showError("Failed Sending Mail", err);
        })
        .finally(() => {
          setSending(false);
        });
    }
  };

  const getDeleteMessage = () => {
    if (showDialog) {
      return "Do you really want to delete this API Integration?";
    }
    return `Do you really want to delete this API Integration (${
      grid.data?.apiIntegrationGridItemDtos?.find((x) => x.id === delApi)
        ?.name || ""
    })?`;
  };

  return (
    <>
      {!!delApi && (
        <ConfirmDialog
          show={true}
          title="Delete API Integration"
          message={getDeleteMessage()}
          done={(rtn) => {
            setDelApi(undefined);
            if (rtn === "yes" && delApi) {
              setDeleting(true);
              setShowDialog(undefined);
              SysServices.http.apiIntegration
                .delete(delApi)
                .then((data) => {
                  toastStore.showToast("API Integration Deleted", "success");
                  grid.getData();
                })
                .catch((err: any) => {
                  toastStore.showError("Failed Deleting API Integration", err);
                })
                .finally(() => {
                  setDeleting(false);
                });
            }
          }}
          buttons="yesno"
        ></ConfirmDialog>
      )}
      {deleting && (
        <CommonSpinner
          overlay={true}
          message="Deleting API Integration"
        ></CommonSpinner>
      )}

      {!!email && (
        <FormModal
          isOpen={true}
          title="Send Email"
          size="md"
          close={() => {
            setEmail(undefined);
          }}
          submitButtonLabel={sending ? "Sending..." : "Send"}
          disableSubmit={sending}
          submit={(data) => {
            if (commonService.isNullOrWhitespace(email.email)) {
              toastStore.showToast("Email is required", "warning");
              return;
            }
            if (!commonService.regexPatterns.email.test(email.email)) {
              toastStore.showToast("Invalid Email format", "warning");
              return;
            }
            sendEmail();
          }}
        >
          <div>
            <div className="mb-2">Enter Email Address</div>
            <input
              type="text"
              id="txtEmail"
              placeholder="Email Address"
              className="form-control"
              value={email.email || ""}
              autoComplete="new-password"
              disabled={sending}
              autoFocus={true}
              onChange={(e) => {
                setEmail((prev) => {
                  if (!prev) return prev;
                  return {
                    ...prev,
                    email: e.target.value,
                  };
                });
              }}
            />
          </div>
        </FormModal>
      )}

      {!!showDialog && (
        <FormModal
          isOpen={true}
          title={
            showDialog?.id ? "Edit API Integration" : "Add API Integration"
          }
          close={() => {
            setShowDialog(undefined);
          }}
          submit={(data) => {
            setSaving(true);

            const modelToSend = {
              ...model,
              apiIntegrationDetailsDto: {
                ...model.apiIntegrationDetailsDto,
                apiCustomerDetails: model.apiIntegrationDetailsDto.allCustomers
                  ? []
                  : model.apiIntegrationDetailsDto.apiCustomerDetails,
              },
            };

            if (showDialog?.id) {
              SysServices.http.apiIntegration
                .update(showDialog?.id, modelToSend)
                .then((data) => {
                  setModel({ ...data });
                  setOrigModel({ ...data });
                  grid.getData();
                  toastStore.showToast("API Integration Saved", "success");
                })
                .catch((err: any) => {
                  toastStore.showError("Failed Saving API Integration", err);
                })
                .finally(() => {
                  setSaving(false);
                });
            } else {
              SysServices.http.apiIntegration
                .create(modelToSend)
                .then((data) => {
                  setModel({ ...data });
                  setOrigModel({ ...data });
                  setShowDialog({ id: data.id });
                  grid.getData();
                  toastStore.showToast("API Integration Saved", "success");
                })
                .catch((err: any) => {
                  toastStore.showError("Failed Saving API Integration", err);
                })
                .finally(() => {
                  setSaving(false);
                });
            }
          }}
          size="lg"
          submitButtonLabel={saving ? "Saving..." : "Save"}
          disableSubmit={saving || loading}
          showDeleteButton={!!modelDto().id}
          deleteAction={() => {
            setDelApi(showDialog.id);
          }}
          moveBehind={!!delApi || !!email}
        >
          <div>
            {loading && (
              <div className="p-4">
                <CommonSpinner></CommonSpinner>
              </div>
            )}
            {!loading && (
              <>
                <Tabs>
                  <Tab eventKey="Details" title="Details">
                    <div className="row pt-4">
                      <div className="col-sm-12 col-md-6">
                        {!!modelDto().id && (
                          <>
                            <div className="mt-1 mb-3">
                              <label className="mb-2">ID</label>
                              <input
                                type="text"
                                className="form-control"
                                disabled={true}
                                value={modelDto().id}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="mb-2">API Key</label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled={true}
                                  value={modelDto().apiKey}
                                />
                                <div className="input-group-append">
                                  <button
                                    type="button"
                                    className="btn btn-outline-success"
                                    title="Generate New Key"
                                    disabled={gettingNewKey}
                                    onClick={(e) => {
                                      setGettingNewKey(true);
                                      SysServices.http.apiIntegration
                                        .regenerateKey(modelDto().id)
                                        .then((data) => {
                                          setModel((prev) => {
                                            return {
                                              ...prev,
                                              apiKey: data.apiKey,
                                            };
                                          });
                                          setOrigModel((prev) => {
                                            if (!prev) return prev;
                                            return {
                                              ...prev,
                                              apiKey: data.apiKey,
                                            };
                                          });
                                          toastStore.showToast(
                                            "New API Key Generated",
                                            "success"
                                          );
                                        })
                                        .catch((err) => {
                                          toastStore.showError(
                                            "Failed Generating New Key",
                                            err
                                          );
                                        })
                                        .finally(() => {
                                          setGettingNewKey(false);
                                        });
                                    }}
                                  >
                                    <i
                                      className={`fa fa-refresh me-1 ${
                                        gettingNewKey ? "fa-spin" : ""
                                      }`}
                                    ></i>{" "}
                                    {gettingNewKey ? "Generating..." : "New"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="mb-3">
                          <label className="mb-2">Name</label>
                          <input
                            id="inputName"
                            value={model.name}
                            className="form-control"
                            placeholder="Name"
                            autoComplete="new-password"
                            disabled={saving}
                            onChange={(e) => {
                              setModel((data) => {
                                return {
                                  ...data,
                                  name: e.target.value,
                                };
                              });
                            }}
                          ></input>
                        </div>
                        <div className="mb-3">
                          <label className="mb-2">Description</label>
                          <input
                            value={model.apiIntegrationDetailsDto.description}
                            className="form-control"
                            placeholder="Description"
                            disabled={saving}
                            onChange={(e) => {
                              setModel((data) => {
                                return {
                                  ...data,
                                  apiIntegrationDetailsDto: {
                                    ...data.apiIntegrationDetailsDto,
                                    description: e.target.value,
                                  },
                                };
                              });
                            }}
                          ></input>
                        </div>
                        <div className="mb-3">
                          <div className="mb-2">Scopes</div>
                          <div className="alert alert-sm alert-secondary pb-1">
                            {scopes.data?.map((scope) => (
                              <div
                                key={scope.value}
                                className="flex flex-center pb-2"
                              >
                                <input
                                  type="checkbox"
                                  id={`chkRead${scope.value}`}
                                  checked={
                                    !!model.apiIntegrationDetailsDto.apiIntegrationScopes.find(
                                      (s) =>
                                        s.apiIntegrationScope === scope.value
                                    )
                                  }
                                  onChange={(e) => {
                                    toggleScope(scope.value);
                                  }}
                                />
                                <label
                                  className="pointer mx-2"
                                  htmlFor={`chkRead${scope.value}`}
                                >
                                  {scope.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="flex flex-center">
                            <div className="flex-1">
                              <div className="mb-2">Active</div>
                              <div className="flex flex-center">
                                <div>
                                  <SwitchButton
                                    checked={model.active}
                                    disabled={saving}
                                    onChange={(val) => {
                                      setModel((data) => {
                                        return {
                                          ...data,
                                          active: val,
                                        };
                                      });
                                    }}
                                  ></SwitchButton>
                                </div>
                              </div>
                            </div>
                            <div className="text-right">
                              {!!modelDto().id && (
                                <div>
                                  {hasChanges ? (
                                    <div className="mb-2 text-danger">
                                      Save Changes First
                                    </div>
                                  ) : (
                                    <div className="mb-2 opacity-0">-</div>
                                  )}
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary"
                                    disabled={hasChanges}
                                    onClick={(e) => {
                                      setEmail({
                                        id: modelDto().id,
                                        email: "",
                                      });
                                      commonService.focusInput("txtEmail", 500);
                                    }}
                                  >
                                    Send Email
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="mb-3">
                          <div className="flex flex-center mb-2">
                            <div className="flex-1">
                              <label>Customers</label>
                            </div>
                            <div className="flex flex-center">
                              <div className="me-2">ALL</div>
                              <div>
                                <SwitchButton
                                  checked={
                                    model.apiIntegrationDetailsDto?.allCustomers
                                  }
                                  disabled={saving}
                                  onChange={(val) => {
                                    setModel((data) => {
                                      return {
                                        ...data,
                                        apiIntegrationDetailsDto: {
                                          ...data.apiIntegrationDetailsDto,
                                          allCustomers: val,
                                        },
                                      };
                                    });
                                  }}
                                ></SwitchButton>
                              </div>
                            </div>
                          </div>

                          <div className="pb-4">
                            <AsyncTypeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              onSearch={handleSearch}
                              disabled={
                                model.apiIntegrationDetailsDto?.allCustomers
                              }
                              onChange={(data: any) => {
                                if (
                                  data[0] &&
                                  !model.apiIntegrationDetailsDto.apiCustomerDetails.find(
                                    (c) => c.customerNumber === data[0].id
                                  )
                                ) {
                                  setModel((prev) => {
                                    return {
                                      ...prev,
                                      apiIntegrationDetailsDto: {
                                        ...prev.apiIntegrationDetailsDto,
                                        apiCustomerDetails: [
                                          ...prev.apiIntegrationDetailsDto
                                            .apiCustomerDetails,
                                          {
                                            customerNumber: data[0].id,
                                            customerName: data[0].name,
                                          },
                                        ],
                                      },
                                    };
                                  });
                                }
                                (ref?.current as any)?.clear();
                              }}
                              searchText={"Searching..."}
                              isLoading={isLoading}
                              options={users}
                              placeholder="Search Customer..."
                              minLength={1}
                              delay={500}
                              useCache={false}
                              filterBy={() => true}
                              ref={ref}
                              //style={{ width: "300px" }}
                            />
                          </div>

                          {!model.apiIntegrationDetailsDto.apiCustomerDetails
                            .length &&
                            !model.apiIntegrationDetailsDto.allCustomers && (
                              <div className="alert alert-sm alert-secondary">
                                No Customer(s)
                              </div>
                            )}

                          {model.apiIntegrationDetailsDto.allCustomers && (
                            <div className="alert alert-sm alert-secondary">
                              <strong>All Customers</strong>
                            </div>
                          )}

                          {!model.apiIntegrationDetailsDto.allCustomers && (
                            <table className="table table-sm table-bordered">
                              <tbody>
                                {model.apiIntegrationDetailsDto.apiCustomerDetails.map(
                                  (customer) => (
                                    <tr key={customer.customerNumber}>
                                      <td>{customer.customerName}</td>
                                      <td>
                                        <i
                                          className="fa fa-times text-danger pointer px-2"
                                          onClick={(e) => {
                                            setModel((prev) => {
                                              return {
                                                ...prev,
                                                apiIntegrationDetailsDto: {
                                                  ...prev.apiIntegrationDetailsDto,
                                                  apiCustomerDetails: [
                                                    ...prev.apiIntegrationDetailsDto.apiCustomerDetails.filter(
                                                      (c) =>
                                                        c.customerNumber !==
                                                        customer.customerNumber
                                                    ),
                                                  ],
                                                },
                                              };
                                            });
                                          }}
                                        ></i>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="LegalInfo" title="Legal Information">
                    <div className="py-4">
                      {modelDto().id ? (
                        <>
                          <table className="table table-sm table-bordered">
                            <tbody>
                              <tr>
                                <td style={{ width: "120px" }}>Name</td>
                                <td>
                                  {modelDto().apiTermsOfUsageOutputDto.name}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "120px" }}>
                                  Email Address
                                </td>
                                <td>
                                  {
                                    modelDto().apiTermsOfUsageOutputDto
                                      .emailAddress
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "120px" }}>
                                  Date Accepted
                                </td>
                                <td>
                                  {modelDto().apiTermsOfUsageOutputDto
                                    .dateAccepted &&
                                    commonService.toLocalDate(
                                      modelDto().apiTermsOfUsageOutputDto
                                        .dateAccepted,
                                      "MMMM DD, YYYY hh:mm A"
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "120px" }}>IP Address</td>
                                <td>
                                  {
                                    modelDto().apiTermsOfUsageOutputDto
                                      .ipAddress
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </>
            )}
          </div>
        </FormModal>
      )}
      <div className="default-page-layout">
        <h4 className="hide-on-print">API Integrations</h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div>
              <div className="p-3">
                <div className="flex-0">
                  <div className="input-group search-box">
                    <input
                      autoFocus={true}
                      className="form-control"
                      type="text"
                      placeholder="Search"
                      value={search.typed}
                      onChange={(e) => {
                        setSearch((data) => {
                          return {
                            ...data,
                            typed: e.target.value,
                          };
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          e.stopPropagation();
                          setSearch((data) => {
                            pageChange(1, paging.pageSize);
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }
                      }}
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            return { typed: "", used: "" };
                          });
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={(e) => {
                          const data = {
                            name: "",
                            active: true,
                            apiIntegrationDetailsDto: {
                              description: "",
                              allCustomers: false,
                              apiCustomerDetails: [],
                              apiIntegrationScopes: [],
                            },
                          } as SysModels.IApiIntegrationInputDto;
                          setModel({ ...data });
                          setOrigModel({ ...data });
                          setShowDialog({});
                          commonService.focusInput("inputName", 500);
                        }}
                      >
                        <i className="fa fa-plus"></i> New
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {grid.data?.apiIntegrationGridItemDtos?.map((api) => (
                    <tr
                      key={api.id}
                      className="pointer"
                      onClick={(e) => {
                        setShowDialog({
                          id: api.id,
                        });
                        commonService.focusInput("inputName", 500);
                      }}
                    >
                      <td>{api.name}</td>
                      <td>{api.description}</td>
                      <td className="text-right">
                        <i
                          className="pointer fa fa-trash text-danger"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setDelApi(api.id);
                          }}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="p-3 pt-0">
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="entries"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ApiIntegrationPage;
