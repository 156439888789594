import SysModels from "../../../models";
import { DashboardAnnouncementService } from "../DashboardAnnouncementService";
import { fetcher } from "../_services";

class ExtendedDashboardAnnouncementService extends DashboardAnnouncementService {
  get = async (id: string) => {
    const url = `/DashboardAnnouncement/${id}`;
    return fetcher.get<SysModels.IDashboardAnnouncementAdminOutputDto>(url);
  };

  update = async (
    id: string,
    data: SysModels.IDashboardAnnouncementUpdateDto
  ) => {
    const url = `/DashboardAnnouncement/${id}`;
    return fetcher.put<SysModels.IDashboardAnnouncementAdminOutputDto>(
      url,
      data
    );
  };

  delete = async (id: string) => {
    const url = `/DashboardAnnouncement/${id}`;
    return fetcher.delete<SysModels.IDashboardAnnouncementAdminOutputDto>(url);
  };

  create = async (data: SysModels.IDashboardAnnouncementCreateDto) => {
    const url = `/DashboardAnnouncement`;
    return fetcher.post<SysModels.IDashboardAnnouncementAdminOutputDto>(
      url,
      data
    );
  };

  list = async (
    page: number,
    pageSize: number,
    data: SysModels.IDashboardAnnouncementSearchDto
  ) => {
    const url = `/DashboardAnnouncement/${page}/${pageSize}`;
    return fetcher.post<SysModels.IDashboardAnnouncementDisplayAdminGridDto>(
      url,
      data
    );
  };

  getInventoryItems = async (search: string) => {
    const url = `/DashboardAnnouncement/InventoryItems/${search}`;
    return fetcher.get<SysModels.IErpInventoryItemOutputDto[]>(url);
  };
}

const extendedDashboardAnnouncement =
  new ExtendedDashboardAnnouncementService();
export default extendedDashboardAnnouncement;
