import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import { useWindowSize } from "../stores/SystemStore";
import { useNavigate } from "react-router-dom";

function VendorsPage(props: any) {
  const navigate = useNavigate();

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const grid = useFetchHelper(
    () =>
      SysServices.http.vendor.list(paging.page, paging.pageSize, {
        search: search.used,
      }),
    "Vendors"
  );

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used]);

  const windowSize = useWindowSize();

  return (
    <div className="default-page-layout">
      <h4 className="hide-on-print">Vendors</h4>
      <div className="bg-white">
        {grid.status === FetchStatus.InProgress && (
          <div className="p-3">
            <CommonSpinner></CommonSpinner>
          </div>
        )}

        <div
          className={grid.status === FetchStatus.Complete ? "" : "display-none"}
        >
          <div className="p-3 flex flex-wrap gap-10 hide-on-print">
            <div className="flex-0">
              <div className="input-group search-box">
                <input
                  autoFocus={true}
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={search.typed}
                  onChange={(e) => {
                    setSearch((data) => {
                      return {
                        ...data,
                        typed: e.target.value,
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearch((data) => {
                        pageChange(1, paging.pageSize);
                        if (data.used === data.typed) {
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                    }
                  }}
                ></input>
                <div className="input-group-append">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      pageChange(1, paging.pageSize);
                      setSearch((data) => {
                        if (data.used === data.typed) {
                          return data;
                        }
                        return {
                          ...data,
                          used: data.typed,
                        };
                      });
                    }}
                  >
                    <i className="fa fa-search"></i>
                  </button>
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={(e) => {
                      pageChange(1, paging.pageSize);
                      setSearch((data) => {
                        return { typed: "", used: "" };
                      });
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <table className="table table-hover">
            <thead>
              {windowSize.isSmall && (
                <tr>
                  <td>Vendor / Phone / Email</td>
                </tr>
              )}
              {!windowSize.isSmall && (
                <tr>
                  <th>Vendor</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Address</th>
                </tr>
              )}
            </thead>
            <tbody>
              {grid.data?.vendorOutputDtos?.map((vendor) => (
                <tr
                  key={vendor.vendorId}
                  className="pointer"
                  onClick={(e) => {
                    navigate(`/vendors/${vendor.id}`);
                  }}
                >
                  {windowSize.isSmall && (
                    <>
                      <td>
                        <div>{vendor.vendorName}</div>
                        <div>
                          {vendor.telephone && (
                            <a href={`tel:${vendor.telephone}`}>
                              {vendor.telephone}
                            </a>
                          )}
                        </div>
                        <div>
                          {vendor.email && (
                            <a href={`mailto:${vendor.email}`}>
                              {vendor.email}
                            </a>
                          )}
                        </div>
                      </td>
                    </>
                  )}
                  {!windowSize.isSmall && (
                    <>
                      <td>{vendor.vendorName}</td>
                      <td>
                        {vendor.telephone && (
                          <a href={`tel:${vendor.telephone}`}>
                            {vendor.telephone}
                          </a>
                        )}
                      </td>
                      <td>
                        {vendor.email && (
                          <a href={`mailto:${vendor.email}`}>{vendor.email}</a>
                        )}
                      </td>
                      <td>
                        {[
                          vendor.address1,
                          vendor.address2,
                          vendor.address3,
                          vendor.city,
                          vendor.state,
                          vendor.postalCode,
                        ]
                          .filter((x) => !commonService.isNullOrEmpty(x))
                          .join(", ")}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="p-3 pt-0 hide-on-print">
            <Pagination
              length={grid.data?.totalRecords || 0}
              page={paging.page}
              pageSize={paging.pageSize}
              pageChange={pageChange}
              showingOfWhatLabel="entries"
              sizes={[10, 25, 50, 100]}
            ></Pagination>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorsPage;
