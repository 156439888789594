import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  ModalBody,
  ModalFooter,
  Spinner,
} from "react-bootstrap";
import CommonSpinner from "../../components/common/CommonSpinner";
import ConfirmDialog from "../../components/common/ConfirmDialog";
import FormModal from "../../components/common/FormModal";
import SwitchButton from "../../components/common/SwitchButton";
import SysModels from "../../models";
import SysServices from "../../services";
import { useUserRole, useWindowSize } from "../../services/CommonHooks";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import toastStore from "../../stores/ToastStore";

function BatchJobScheduleWidget(props: any) {
  const windowSize = useWindowSize();
  const grid = useFetchHelper(
    async () => SysServices.http.batchScheduleManager.getAllJobs(),
    "Job Schedules"
  );

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [delJob, setDelJob] =
    useState<SysModels.IBatchJobsScheduleEntityOutputDto>();
  const [jobsBeingDeleted, setDatesBeingDeleted] = useState<
    SysModels.IBatchJobsScheduleEntityOutputDto[]
  >([]);

  const deleteJob = async (type: number, rowKey: string) => {
    await SysServices.http.batchScheduleManager
      .delete(type, rowKey)
      .then((data) => {
        toastStore.showToast("Job Schedule deleted.", "success");
        grid.getData();
      })
      .catch((error) => {
        toastStore.showError("Failed to Delete Job Schedule", error);
      })
      .finally(() => {
        setDatesBeingDeleted((list) => {
          return list.filter((d) => d.rowKey !== rowKey);
        });
      });
  };

  const [showAdd, setShowAdd] = useState(false);
  const [jobToEdit, setJobToEdit] =
    useState<SysModels.IBatchJobsScheduleEntityOutputDto>();
  const [saving, setSaving] = useState(false);

  const modelDefaults = {
    batchJobScheduleTypeEnum: SysModels.BatchJobScheduleTypeEnum.Scheduled,
    functionKey: "",
    functionName: "",
    url: "",
    triggerName: "",
  } as SysModels.IBatchJobScheduleInsertDto;
  const [model, setModel] = useState<SysModels.IBatchJobScheduleInsertDto>({
    ...modelDefaults,
  });

  const getTypeName = (type: SysModels.BatchJobScheduleTypeEnum) => {
    if (type === SysModels.BatchJobScheduleTypeEnum.Scheduled)
      return "Scheduled";
    if (type === SysModels.BatchJobScheduleTypeEnum.Triggered)
      return "Triggered";
    return "Unknown";
  };

  const [selectedJob, setSelectedJob] =
    useState<SysModels.IBatchJobsScheduleEntityOutputDto>();
  const occurrences = useFetchHelper(
    async () =>
      SysServices.http.batchScheduleManager.getOccurrences(
        selectedJob?.rowKey || ""
      ),
    "Occurrences"
  );
  useEffect(() => {
    if (selectedJob) {
      occurrences.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob]);

  const [showExp, setShowExp] = useState(false);
  const [expression, setExpression] = useState("");
  const occurrencesFromExpression = useFetchHelper(
    async () =>
      SysServices.http.batchScheduleManager.getOccurrencesFromExpression({
        expression: expression,
      }),
    "Occurrences from Expression"
  );

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("inputFunctionName")?.focus();
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAdd]);

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("inputExpression")?.focus();
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExp]);

  const userRole = useUserRole();
  const displayExpression = () => {
    return (userRole & SysModels.UserRolesEnum.ApplicationAdmin) > 0;
  };

  const [startFunction, setStartFunctions] = useState({
    rowKey: null as string | null,
    jobName: "",
    showConfirm: false,
    started: false,
  });

  const doStartFunction = async () => {
    if (startFunction.rowKey) {
      await SysServices.http.batchScheduleManager
        .startFunction(startFunction.rowKey)
        .then((data) => {
          toastStore.showToast("Job Started", "success");
        })
        .catch((error) => {
          toastStore.showError("Failed to Start Job", error);
        })
        .finally(() => {
          setStartFunctions({
            rowKey: null,
            jobName: "",
            showConfirm: false,
            started: false,
          });
        });
    }
  };

  useEffect(() => {
    if (startFunction.started && !startFunction.showConfirm) {
      doStartFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startFunction]);

  useEffect(() => {
    if (jobToEdit) {
      SysServices.http.batchScheduleManager
        .get(jobToEdit.batchJobScheduleTypeEnum, jobToEdit.rowKey)
        .then((data) => {
          setModel({ ...modelDefaults, ...jobToEdit, ...data });
          setShowAdd(true);
        })
        .catch((error) => {
          toastStore.showError("Failed to Get Job", error);
          setJobToEdit(undefined);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobToEdit]);

  const [showAllStatusOfDurable, setShowAllStatusOfDurable] = useState({
    show: false,
    days: 1,
    rowKey: "",
    jobName: "",
  });

  const allStatuses = useFetchHelper(
    async () =>
      SysServices.http.batchScheduleManager.allStatusOfDurable(
        showAllStatusOfDurable.rowKey,
        showAllStatusOfDurable.days || 0
      ),
    "All Statuses"
  );

  useEffect(() => {
    if (showAllStatusOfDurable.show) {
      allStatuses.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllStatusOfDurable.show]);

  const [showSingle, setShowSingle] = useState({
    show: false,
    rowKey: "",
    instanceId: "",
  });

  const singleInstance = useFetchHelper(
    async () =>
      SysServices.http.batchScheduleManager.singleStatusOfDurable(
        showSingle.rowKey,
        showSingle.instanceId
      ),
    "Instance"
  );
  useEffect(() => {
    if (showSingle.show) {
      singleInstance.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSingle.show]);

  const [terminating, setTerminating] = useState(false);
  const [confirmTerminate, setConfirmTerminate] = useState({
    show: false,
    code: "",
    confirmCode: "",
    reason: "",
  });

  const generateCode = () => {
    setConfirmTerminate((p) => {
      return {
        ...p,
        show: true,
        reason: "",
        confirmCode: "",
        code: [1, 2, 3, 4]
          .map((x) => `${(Math.random() * 9).toFixed(0)}`)
          .join(""),
      };
    });
  };

  return (
    <>
      {startFunction.showConfirm && (
        <ConfirmDialog
          show={true}
          title={`Start Job '${startFunction.jobName}'`}
          message="Are you sure you want to start this Job?"
          buttons={"yesno"}
          done={(rtn) => {
            if (rtn === "yes") {
              setStartFunctions((p) => {
                return {
                  ...p,
                  showConfirm: false,
                  started: true,
                };
              });
            } else {
              setStartFunctions({
                rowKey: null,
                jobName: "",
                showConfirm: false,
                started: false,
              });
            }
          }}
        ></ConfirmDialog>
      )}

      {showAdd && (
        <FormModal
          title={jobToEdit ? "Edit Job Schedule" : "Add Job Schedule"}
          isOpen={true}
          close={() => {
            setShowAdd(false);
            setJobToEdit(undefined);
          }}
          disableSubmit={
            commonService.isNullOrWhitespace(model.functionName) ||
            commonService.isNullOrWhitespace(model.url) ||
            (!jobToEdit &&
              commonService.isNullOrWhitespace(model.functionKey)) ||
            (model.batchJobScheduleTypeEnum ===
              SysModels.BatchJobScheduleTypeEnum.Scheduled &&
              commonService.isNullOrWhitespace(model.triggerName))
          }
          submitButtonLabel={saving ? "Saving..." : "Save"}
          submit={() => {
            setSaving(true);

            if (jobToEdit) {
              SysServices.http.batchScheduleManager
                .update(
                  jobToEdit.batchJobScheduleTypeEnum,
                  jobToEdit.rowKey,
                  model
                )
                .then((data) => {
                  setSaving(false);
                  setShowAdd(false);
                  setJobToEdit(undefined);
                  toastStore.showToast("Job Schedule Updated", "success");
                  grid.getData();
                })
                .catch((error) => {
                  setSaving(false);
                  toastStore.showError("Failed to Update Job Schedule", error);
                });
              return;
            }

            SysServices.http.batchScheduleManager
              .create(model)
              .then((data) => {
                setSaving(false);
                setShowAdd(false);
                toastStore.showToast("Job Schedule Saved", "success");
                grid.getData();
              })
              .catch((error) => {
                setSaving(false);
                toastStore.showError("Failed to Save Job Schedule", error);
              });
          }}
        >
          {saving && (
            <CommonSpinner overlay={true} message="Saving..."></CommonSpinner>
          )}
          <div>
            <label className="mb-2">Type</label>
            <div className="mb-3">
              <Dropdown>
                <Dropdown.Toggle variant="outline-primary">
                  {getTypeName(model.batchJobScheduleTypeEnum)}
                </Dropdown.Toggle>

                <Dropdown.Menu align="left">
                  <Dropdown.Item
                    onClick={() => {
                      setModel((p) => {
                        return {
                          ...p,
                          batchJobScheduleTypeEnum:
                            SysModels.BatchJobScheduleTypeEnum.Scheduled,
                        };
                      });
                    }}
                  >
                    <span>Scheduled</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setModel((p) => {
                        return {
                          ...p,
                          batchJobScheduleTypeEnum:
                            SysModels.BatchJobScheduleTypeEnum.Triggered,
                        };
                      });
                    }}
                  >
                    <span>Triggered</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <label className="required-label mb-2">Function Name</label>
            <input
              id="inputFunctionName"
              className="form-control mb-3"
              defaultValue={model.functionName}
              onChange={(e) => {
                setModel((p) => {
                  return {
                    ...p,
                    functionName: e.target.value,
                  };
                });
              }}
            ></input>

            <label className={`mb-2 ${jobToEdit ? "" : "required-label"}`}>
              Function Key
            </label>
            <input
              className="form-control mb-3"
              defaultValue={model.functionKey}
              onChange={(e) => {
                setModel((p) => {
                  return {
                    ...p,
                    functionKey: e.target.value,
                  };
                });
              }}
              placeholder={
                jobToEdit
                  ? "Cannot show existing key, but you can enter a new one"
                  : ""
              }
            ></input>
            <label className="required-label mb-2">URL</label>
            <input
              className="form-control mb-3"
              defaultValue={model.url}
              onChange={(e) => {
                setModel((p) => {
                  return {
                    ...p,
                    url: e.target.value,
                  };
                });
              }}
            ></input>
            <label
              className={`mb-2 ${
                model.batchJobScheduleTypeEnum ===
                SysModels.BatchJobScheduleTypeEnum.Scheduled
                  ? "required-label"
                  : ""
              }`}
            >
              Trigger Name
            </label>
            <input
              id="inputTriggerName"
              className="form-control mb-3"
              defaultValue={model.triggerName}
              onChange={(e) => {
                setModel((p) => {
                  return {
                    ...p,
                    triggerName: e.target.value,
                  };
                });
              }}
            ></input>

            <div className="flex flex-center">
              <div className="me-2">Is Durable</div>
              <div>
                <SwitchButton
                  checked={model.isDurable}
                  onChange={(val) => {
                    setModel((data) => {
                      return {
                        ...data,
                        isDurable: val,
                      };
                    });
                  }}
                ></SwitchButton>
              </div>
            </div>
          </div>
        </FormModal>
      )}

      {showAllStatusOfDurable.show && (
        <FormModal
          title={`All Status for '${showAllStatusOfDurable.jobName}'`}
          isOpen={true}
          close={() => {
            setShowAllStatusOfDurable((p) => {
              return {
                ...p,
                show: false,
              };
            });
          }}
          submitButtonLabel="Done"
          submit={() => {
            setShowAllStatusOfDurable((p) => {
              return {
                ...p,
                show: false,
              };
            });
          }}
          size="xl"
          primaryButtonOnly={true}
          moveBehind={showSingle.show}
        >
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <label className="mb-2">Enter Number of Days</label>
              <div className="input-group">
                <input
                  id="numberOfDaysFilter"
                  className="form-control mb-3"
                  defaultValue={showAllStatusOfDurable.days}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      allStatuses.getData();
                    }
                  }}
                  onChange={(e) => {
                    setShowAllStatusOfDurable((p) => {
                      return {
                        ...p,
                        days: e.target.value as any,
                      };
                    });
                  }}
                  type="number"
                  step={1}
                ></input>
                <div className="input-group-append" id="button-addon4">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={(e) => {
                      //---
                      allStatuses.getData();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            {allStatuses.status === FetchStatus.InProgress && (
              <CommonSpinner message="Loading..."></CommonSpinner>
            )}
            {allStatuses.status !== FetchStatus.InProgress && (
              <table className="table table-sm table-hover">
                <thead>
                  <tr>
                    {windowSize.isSmallScreen && (
                      <>
                        <th></th>
                      </>
                    )}
                    {!windowSize.isSmallScreen && (
                      <>
                        <th>Status</th>
                        <th>Custom Status</th>
                        <th>Created On</th>
                        <th>Updated On</th>
                        <th>Output</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {windowSize.isSmallScreen &&
                    allStatuses.data?.map((row) => (
                      <React.Fragment key={row.instanceId}>
                        <tr>
                          <td>Status</td>
                          <td>{row.runtimeStatus}</td>
                        </tr>
                        <tr>
                          <td>Custom Status</td>
                          <td>{row.customStatus}</td>
                        </tr>
                        <tr>
                          <td>Created On</td>
                          <td>
                            {dayjs(row.createdTime).format(
                              "MMM DD, YYYY hh:mm A"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Updated On</td>
                          <td>
                            {dayjs(row.lastUpdatedTime).format(
                              "MMM DD, YYYY hh:mm A"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Output</td>
                          <td
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: "100%",
                                position: "absolute",
                                left: 0,
                                right: 0,
                              }}
                            >
                              {row.output}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="text-center pb-4">
                            <span
                              className="text-primary pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setConfirmTerminate((p) => {
                                  return {
                                    ...p,
                                    show: false,
                                  };
                                });
                                setShowSingle((p) => {
                                  return {
                                    ...p,
                                    show: true,
                                    instanceId: row.instanceId,
                                    rowKey: showAllStatusOfDurable.rowKey,
                                  };
                                });
                              }}
                            >
                              <i className="fa fa-eye me-2"></i> View Instance
                            </span>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  {!windowSize.isSmallScreen &&
                    allStatuses.data?.map((row) => (
                      <tr
                        key={row.instanceId}
                        className="pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setConfirmTerminate((p) => {
                            return {
                              ...p,
                              show: false,
                            };
                          });
                          setShowSingle((p) => {
                            return {
                              ...p,
                              show: true,
                              instanceId: row.instanceId,
                              rowKey: showAllStatusOfDurable.rowKey,
                            };
                          });
                        }}
                      >
                        <td>{row.runtimeStatus}</td>
                        <td>{row.customStatus}</td>
                        <td>
                          {dayjs(row.createdTime).format(
                            "MMM DD, YYYY hh:mm A"
                          )}
                        </td>
                        <td>
                          {dayjs(row.lastUpdatedTime).format(
                            "MMM DD, YYYY hh:mm A"
                          )}
                        </td>
                        <td
                          style={{
                            position: "relative",
                            minWidth: "150px",
                          }}
                        >
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              maxWidth: "100%",
                              position: "absolute",
                              left: 0,
                              right: 0,
                            }}
                          >
                            {row.output}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </FormModal>
      )}

      {showSingle.show && (
        <FormModal
          title={`Instance '${showSingle.instanceId}'`}
          isOpen={true}
          close={() => {
            setShowSingle((p) => {
              return {
                ...p,
                show: false,
              };
            });
          }}
          submitButtonLabel="Done"
          submit={() => {
            setShowSingle((p) => {
              return {
                ...p,
                show: false,
              };
            });
          }}
          size="lg"
          primaryButtonOnly={true}
        >
          <div>
            {singleInstance.status === FetchStatus.InProgress && (
              <CommonSpinner message="Loading..."></CommonSpinner>
            )}
            {singleInstance.status !== FetchStatus.InProgress && (
              <div>
                <table className="table table-sm">
                  <tbody>
                    <tr>
                      <td>Status</td>
                      <td>{singleInstance.data?.runtimeStatus}</td>
                    </tr>
                    <tr>
                      <td>Custom Status</td>
                      <td>{singleInstance.data?.customStatus}</td>
                    </tr>
                    <tr>
                      <td>Created On</td>
                      <td>
                        {dayjs(singleInstance.data?.createdTime).format(
                          "MMM DD, YYYY hh:mm A"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Updated On</td>
                      <td>
                        {dayjs(singleInstance.data?.lastUpdatedTime).format(
                          "MMM DD, YYYY hh:mm A"
                        )}
                      </td>
                    </tr>
                    {singleInstance.data?.output && (
                      <tr>
                        <td colSpan={2}>
                          <div className="pb-2">Output</div>
                          <div
                            style={{
                              wordBreak: "break-word",
                            }}
                          >
                            {singleInstance.data?.output}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div>
                  {!confirmTerminate.show && (
                    <>
                      <button
                        className="btn btn-sm me-3"
                        title="Refresh"
                        onClick={(e) => {
                          singleInstance.getData();
                        }}
                      >
                        <i className="fa fa-refresh text-primary"></i>
                      </button>
                      {(singleInstance.data?.runtimeStatus === "Pending" ||
                        singleInstance.data?.runtimeStatus === "Running") && (
                        <button
                          className="btn btn-sm me-3"
                          title="Terminate"
                          onClick={(e) => {
                            generateCode();
                          }}
                        >
                          <i className="fa fa-stop text-danger"></i>
                        </button>
                      )}
                    </>
                  )}
                </div>
                {confirmTerminate.show && (
                  <div className="alert alert-sm alert-secondary">
                    Please enter the code below to confirm Termination of
                    Process
                    <h4 className="my-2">{confirmTerminate.code}</h4>
                    <div className="input-group">
                      <input
                        style={{
                          maxWidth: "300px",
                        }}
                        id="inputConfirmCode"
                        className="form-control mb-3"
                        defaultValue={confirmTerminate.confirmCode}
                        onChange={(e) => {
                          setConfirmTerminate((p) => {
                            return {
                              ...p,
                              show: true,
                              confirmCode: e.target.value,
                            };
                          });
                        }}
                        disabled={terminating}
                      ></input>
                    </div>
                    <div>
                      <label className="required-label">Reason</label>
                      <textarea
                        className="form-control mt-2"
                        rows={3}
                        onChange={(e) => {
                          setConfirmTerminate((p) => {
                            return {
                              ...p,
                              show: true,
                              reason: e.target.value,
                            };
                          });
                        }}
                        disabled={terminating}
                      ></textarea>
                    </div>
                    <div className="pt-2">
                      <button
                        className="btn btn-danger"
                        type="button"
                        disabled={
                          terminating ||
                          confirmTerminate.code !==
                            confirmTerminate.confirmCode ||
                          commonService.isNullOrWhitespace(
                            confirmTerminate.reason
                          )
                        }
                        onClick={(e) => {
                          setTerminating(true);
                          SysServices.http.batchScheduleManager
                            .terminateInstanceOfDurable(
                              showAllStatusOfDurable.rowKey,
                              showSingle.instanceId,
                              confirmTerminate.reason
                            )
                            .then((data) => {
                              setTimeout(() => {
                                allStatuses.getData();
                                singleInstance.getData();
                                setConfirmTerminate((p) => {
                                  return {
                                    ...p,
                                    show: false,
                                  };
                                });
                                setTerminating(false);
                              }, 2000);
                            })
                            .catch((err) => {
                              setTerminating(false);
                              toastStore.showError(
                                "Failed to Terminate Job",
                                err
                              );
                            });
                        }}
                      >
                        {terminating ? "Terminating..." : "Terminate"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </FormModal>
      )}

      {/* OCCURRENCES */}
      {!!selectedJob && (
        <FormModal
          title={`Occurrences of '${selectedJob.functionName.trim()}'`}
          isOpen={true}
          close={() => {
            setSelectedJob(undefined);
          }}
          customBodyFooter={true}
        >
          <ModalBody>
            {occurrences.status === FetchStatus.Failed && (
              <div className="txt-danger text-center my-2">
                Failed Getting Occurrences
              </div>
            )}
            {occurrences.status === FetchStatus.InProgress && (
              <CommonSpinner></CommonSpinner>
            )}
            {occurrences.status === FetchStatus.Complete && (
              <>
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th>Decription</th>
                      {displayExpression() && <th>Expression</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {occurrences.data?.map((job) => (
                      <React.Fragment key={job.expression}>
                        <tr>
                          <td>
                            <div>
                              <strong className="txt-primary me-2">
                                {job.description}
                              </strong>
                            </div>
                          </td>
                          {displayExpression() && (
                            <td className="no-wrap">
                              <strong>{job.expression}</strong>
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td
                            colSpan={displayExpression() ? 2 : 1}
                            className="p-0"
                            style={{ borderWidth: 0 }}
                          >
                            {job.nextExecutions.length > 0 && (
                              <div style={{ borderTop: "solid 1px #ddd" }}>
                                <table className="table table-sm">
                                  <tbody>
                                    {job.nextExecutions?.map((date) => (
                                      <tr
                                        key={commonService.toLocalDate(
                                          date,
                                          "full"
                                        )}
                                      >
                                        <td>
                                          <div>
                                            {commonService.toLocalDate(
                                              date,
                                              "full"
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              onClick={(e) => {
                setSelectedJob(undefined);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </FormModal>
      )}

      {/* OCCURRENCES FROM EXPRESSION */}
      {showExp && (
        <FormModal
          title={`Occurrences from Expression`}
          isOpen={true}
          close={() => {
            setShowExp(false);
            setExpression("");
            occurrencesFromExpression.setData(undefined);
          }}
          customBodyFooter={true}
        >
          <ModalBody>
            <div className="mb-4">
              <label className="mb-2">Expression</label>
              <div className="input-group">
                <input
                  id="inputExpression"
                  className="form-control"
                  onChange={(e) => {
                    setExpression(e.target.value || "");
                  }}
                ></input>
                <div className="input-group-append" id="button-addon4">
                  <button
                    className="btn btn-primary"
                    type="button"
                    disabled={
                      occurrencesFromExpression.status ===
                      FetchStatus.InProgress
                    }
                    onClick={(e) => {
                      occurrencesFromExpression.getData();
                    }}
                  >
                    Run Expression
                  </button>
                </div>
              </div>
              <small>
                {"{second} {minute} {hour} {day} {month} {day-of-week}"}
              </small>
            </div>
            {occurrencesFromExpression.status === FetchStatus.Failed && (
              <div className="txt-danger text-center my-2">
                Failed Getting Occurrences from Expression
              </div>
            )}
            {occurrencesFromExpression.status === FetchStatus.InProgress && (
              <CommonSpinner></CommonSpinner>
            )}
            {occurrencesFromExpression.status === FetchStatus.Complete && (
              <>
                {(occurrencesFromExpression.data
                  ? [occurrencesFromExpression.data]
                  : []
                )?.map((job) => (
                  <div key={job.expression}>
                    <div>
                      <strong className="txt-primary">{job.description}</strong>
                    </div>
                    {job.nextExecutions.length > 0 && (
                      <div style={{ borderTop: "solid 1px #ddd" }}>
                        <table className="table table-sm">
                          <tbody>
                            {job.nextExecutions?.map((date) => (
                              <tr key={commonService.toLocalDate(date, "full")}>
                                <td>
                                  <div>
                                    {commonService.toLocalDate(date, "full")}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              onClick={(e) => {
                setShowExp(false);
                setExpression("");
                occurrencesFromExpression.setData(undefined);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </FormModal>
      )}

      <ConfirmDialog
        show={!!delJob}
        buttons="yesno"
        title="Delete Job Schedule"
        message={`Are you sure you want to delete '${delJob?.functionName}' from Job Schedules?`}
        done={(rtn) => {
          if (rtn === "yes") {
            if (delJob) {
              setDatesBeingDeleted((list) => {
                return [...list, { ...delJob }];
              });
              deleteJob(delJob.batchJobScheduleTypeEnum, delJob.rowKey);
            }
          }
          setDelJob(undefined);
        }}
      ></ConfirmDialog>

      <div className="hide-on-print position-relative">
        {!!jobToEdit && !showAdd && (
          <CommonSpinner
            overlay={true}
            message="Getting Job..."
          ></CommonSpinner>
        )}
        {startFunction.started && (
          <CommonSpinner
            overlay={true}
            message="Starting Job..."
          ></CommonSpinner>
        )}
        <div className="bg-white mb-4">
          <div className="p-3 bg-blue txt-light">
            <h5 className="m-0 flex flex-center">
              <em className="fa fa-tasks me-2"></em>
              <span className="flex-1">Batch Job Schedules</span>
              {!!grid.data && (
                <>
                  {grid.status !== FetchStatus.InProgress && (
                    <i
                      className="fa fa-refresh pointer"
                      onClick={(e) => {
                        grid.getData();
                      }}
                    ></i>
                  )}
                  {grid.status === FetchStatus.InProgress && (
                    <Spinner
                      animation="border"
                      size="sm"
                      variant="light"
                    ></Spinner>
                  )}
                </>
              )}
            </h5>
          </div>
          <div className="p-3 pb-4 overflow-auto min-height-100 position-relative">
            <div>
              {grid.status === FetchStatus.InProgress && (
                <CommonSpinner overlay={true}></CommonSpinner>
              )}
              {grid.status !== FetchStatus.Failed && (
                <div>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        {windowSize.isSmallScreen && (
                          <>
                            <th>Type / Name</th>
                          </>
                        )}
                        {!windowSize.isSmallScreen && (
                          <>
                            <th>Type</th>
                            <th>Name</th>
                          </>
                        )}

                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {grid.data?.map((job) => (
                        <tr key={job.rowKey}>
                          {windowSize.isSmallScreen && (
                            <>
                              <td className="pt-2">
                                {getTypeName(job.batchJobScheduleTypeEnum)}
                                {(userRole &
                                  SysModels.UserRolesEnum.ApplicationAdmin) >
                                0 ? (
                                  <div
                                    className="text-primary pointer"
                                    title="Edit"
                                    onClick={(e) => {
                                      setJobToEdit(job);
                                    }}
                                  >
                                    {job.functionName}
                                  </div>
                                ) : (
                                  <div>{job.functionName}</div>
                                )}
                              </td>
                            </>
                          )}
                          {!windowSize.isSmallScreen && (
                            <>
                              <td className="pt-2">
                                {getTypeName(job.batchJobScheduleTypeEnum)}
                              </td>
                              <td className="pt-2">
                                {(userRole &
                                  SysModels.UserRolesEnum.ApplicationAdmin) >
                                0 ? (
                                  <div
                                    className="text-primary pointer"
                                    title="Edit"
                                    onClick={(e) => {
                                      setJobToEdit(job);
                                    }}
                                  >
                                    {job.functionName}
                                  </div>
                                ) : (
                                  <div>{job.functionName}</div>
                                )}
                              </td>
                            </>
                          )}

                          <th className="text-right no-wrap">
                            {displayExpression() && job.isDurable && (
                              <Button
                                variant="default"
                                type="button"
                                title="View All Status"
                                onClick={(e) => {
                                  if (
                                    !jobsBeingDeleted.find(
                                      (d) => job.rowKey === d.rowKey
                                    )
                                  ) {
                                    allStatuses.setData(undefined);
                                    setShowAllStatusOfDurable((p) => {
                                      return {
                                        ...p,
                                        show: true,
                                        days: 1,
                                        rowKey: job.rowKey,
                                        jobName: job.functionName,
                                      };
                                    });
                                    setTimeout(() => {
                                      document
                                        .getElementById("numberOfDaysFilter")
                                        ?.focus();
                                    }, 500);
                                  }
                                }}
                              >
                                <i className="fa fa-list-alt txt-warning"></i>
                              </Button>
                            )}

                            {job.batchJobScheduleTypeEnum ===
                              SysModels.BatchJobScheduleTypeEnum.Scheduled && (
                              <Button
                                variant="default"
                                type="button"
                                title="View"
                                onClick={(e) => {
                                  if (
                                    !jobsBeingDeleted.find(
                                      (d) => job.rowKey === d.rowKey
                                    )
                                  ) {
                                    setSelectedJob(job);
                                  }
                                }}
                              >
                                <i className="fa fa-eye txt-primary"></i>
                              </Button>
                            )}

                            {(userRole &
                              SysModels.UserRolesEnum.ApplicationAdmin) >
                              0 && (
                              <>
                                {job.batchJobScheduleTypeEnum ===
                                  SysModels.BatchJobScheduleTypeEnum
                                    .Scheduled && (
                                  <Button
                                    variant="default"
                                    type="button"
                                    title="Start"
                                    onClick={(e) => {
                                      setStartFunctions({
                                        rowKey: job.rowKey,
                                        jobName: job.functionName,
                                        showConfirm: true,
                                        started: false,
                                      });
                                    }}
                                  >
                                    <i className="fa fa-play txt-success"></i>
                                  </Button>
                                )}

                                {!jobsBeingDeleted.find(
                                  (d) => job.rowKey === d.rowKey
                                ) && (
                                  <Button
                                    variant="default"
                                    type="button"
                                    title="Delete"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setDelJob(job);
                                    }}
                                  >
                                    <i className="fa fa-trash txt-danger"></i>
                                  </Button>
                                )}
                                {!!jobsBeingDeleted.find(
                                  (d) => job.rowKey === d.rowKey
                                ) && (
                                  <Button variant="default" type="button">
                                    <Spinner
                                      animation="border"
                                      variant={"danger"}
                                      size="sm"
                                    ></Spinner>
                                  </Button>
                                )}
                              </>
                            )}
                          </th>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="text-right">
              {(userRole & SysModels.UserRolesEnum.ApplicationAdmin) > 0 && (
                <div className="mt-4 flex gap-10">
                  <Button
                    size="sm"
                    variant="success"
                    type="button"
                    onClick={() => setShowExp(true)}
                  >
                    Occurrences from Expression
                  </Button>
                  <Button
                    size="sm"
                    variant="primary"
                    type="button"
                    onClick={() => {
                      setModel({ ...modelDefaults });
                      setShowAdd(true);
                    }}
                    disabled={saving}
                  >
                    {!saving && (
                      <span>
                        <i className="fa fa-plus"></i> Add Job Schedule
                      </span>
                    )}
                    {saving && (
                      <span>
                        <i className="fa fa-save"></i> Saving...
                      </span>
                    )}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BatchJobScheduleWidget;
