import SysModels from "../../../models";
import { OrderService } from "../OrderService";
import { fetcher } from "../_services";

class ExtendedOrderService extends OrderService {
  getCsrOrderSummary = async (fromDate: string, toDate: string) => {
    const url = `/Ordering/CsrOrderSummary/${fromDate}/${toDate}`;
    return fetcher.post<SysModels.ICsrTotalSummaryOutputDto[]>(url, {});
  };

  getBuyingGroupOrderSummaries = async () => {
    const url = `/Ordering/BuyingGroupOrderSummaries`;
    return fetcher.get<SysModels.IBuyingGroupTotalSummaryOutputDto[]>(url);
  };

  listOrderGuideOrdersForCsr = async (
    page: number,
    pageSize: number,
    userId: string
  ) => {
    const url = `/Ordering/ListOrderGuideOrdersForCsr/${page}/${pageSize}/${userId}`;
    return fetcher.get<SysModels.IGridOrderGuideOrdersForCsrDto>(url);
  };
}

const extendedOrderService = new ExtendedOrderService();

export default extendedOrderService;
