import React, { useEffect } from "react";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import SysServices from "../services";
import FormModal from "../components/common/FormModal";
import CommonSpinner from "../components/common/CommonSpinner";
import commonService from "../services/CommonService";

function PurchaseOrderDialog(props: { tranId: string; onClose: () => void }) {
  const detail = useFetchHelper(
    async () => SysServices.http.purchaseOrder.detail(props.tranId),
    "Purchase Order"
  );

  useEffect(() => {
    detail.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      title={`Purchase Order: ${props.tranId}`}
      size="lg"
      isOpen={true}
      close={() => props.onClose()}
      submit={(data) => props.onClose()}
      primaryButtonOnly={true}
      submitButtonLabel="Done"
    >
      <div>
        {detail.status === FetchStatus.InProgress && (
          <CommonSpinner></CommonSpinner>
        )}
        {detail.status === FetchStatus.Complete && (
          <div>
            <h5>{detail.data?.vendorAccountNo}</h5>
            <div>
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Trans. Date</th>
                    <th>Expected Date</th>
                    <th>Prepared By</th>
                    <th>Delivery Truck</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {commonService.toLocalDate(
                        detail.data?.tranDate,
                        "MMM DD, YYYY"
                      )}
                    </td>
                    <td>
                      {commonService.toLocalDate(
                        detail.data?.expectedDate,
                        "MMM DD, YYYY"
                      )}
                    </td>
                    <td>{detail.data?.preparedBy || ""}</td>
                    <td>{detail.data?.deliveryTruck || ""}</td>
                  </tr>
                </tbody>
              </table>
              <h5 className="mt-2">Items</h5>
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th></th>
                    <th>MWF Item No.</th>
                    <th>Vendor Item No.</th>
                    <th className="text-right pe-3">Quantity</th>
                    <th>Unit</th>
                  </tr>
                </thead>
                <tbody>
                  {detail.data?.purchaseOrderLineOutputDtos?.map((row) => (
                    <tr key={row.lineNumber}>
                      <td>{row.lineNumber}</td>
                      <td>{row.mwfItemNumber}</td>
                      <td>{row.vendorItemNumber}</td>
                      <td className="text-right pe-3">
                        {commonService.toNumberWithComma(row.quantity)}
                      </td>
                      <td>{row.uom}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </FormModal>
  );
}

export default PurchaseOrderDialog;
