import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../components/common/CommonSpinner";
import ConfirmDialog from "../components/common/ConfirmDialog";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import toastStore from "../stores/ToastStore";
import UserRolesDisplayComponent from "./common/UserRolesDisplayComponent";

function InitialUsers(props: any) {
  const navigate = useNavigate();

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [filter, setFilter] = useState<SysModels.IUserInitialSearchDto>({
    search: "",
    userListSearchActiveEnum: SysModels.UserListSearchActiveEnum.Inactive,
    orderByEnum: SysModels.OrderByEnum.Ascending,
  });
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const grid = useFetchHelper(async () => {
    return SysServices.http.user.listInitial(paging.page, paging.pageSize, {
      ...filter,
      search: search.used,
    });
  }, "Users");

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, filter]);

  const statusFilter = [
    {
      label: "All",
      value: SysModels.UserListSearchActiveEnum.All,
    },
    {
      label: "Added",
      value: SysModels.UserListSearchActiveEnum.Active,
    },
    {
      label: "Not Added",
      value: SysModels.UserListSearchActiveEnum.Inactive,
    },
  ];

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [deleting, setDeleting] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState<string>();
  const deleteUser = async (email: string) => {
    setDeleting(true);
    await SysServices.http.user
      .deleteInitial(email)
      .then((data) => {
        toastStore.showToast("User deleted successfully.", "success");
        setDeleting(false);
        grid.getData();
      })
      .catch((error) => {
        toastStore.showError("Failed Deleting User", error);
        setDeleting(false);
      });
  };

  return (
    <>
      {deleting && (
        <CommonSpinner overlay={true} message="Deleting..."></CommonSpinner>
      )}
      {!!emailToDelete && (
        <ConfirmDialog
          show={true}
          title="Delete User"
          message={`Do you really want to delete this user (${emailToDelete})?`}
          buttons="yesno"
          done={(rtn) => {
            if (rtn === "yes") {
              deleteUser(emailToDelete);
            }
            setEmailToDelete(undefined);
          }}
        ></ConfirmDialog>
      )}
      <div className="default-page-layout">
        <h4 className="hide-on-print">Manage Initial Users</h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div>
              <div className="p-3 flex flex-wrap gap-10">
                <div className="flex-0" style={{ maxWidth: "100%" }}>
                  <div className="input-group search-box">
                    <input
                      autoFocus={true}
                      className="form-control"
                      type="text"
                      placeholder="Search"
                      value={search.typed}
                      onChange={(e) => {
                        setSearch((data) => {
                          return {
                            ...data,
                            typed: e.target.value,
                          };
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          e.stopPropagation();
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }
                      }}
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            return { typed: "", used: "" };
                          });
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex-0">
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-primary">
                      <i className="fa fa-filter me-2"></i>{" "}
                      {
                        statusFilter.find(
                          (opt) => opt.value === filter.userListSearchActiveEnum
                        )?.label
                      }
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="left">
                      {statusFilter.map((opt) => (
                        <Dropdown.Item
                          key={opt.value}
                          onClick={(e) => {
                            setFilter((prev) => {
                              return {
                                ...prev,
                                userListSearchActiveEnum: opt.value,
                              };
                            });
                          }}
                          active={opt.value === filter.userListSearchActiveEnum}
                        >
                          {opt.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="flex-1">
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      navigate(`/initialusers/new`);
                    }}
                  >
                    <i className="fa fa-user-plus me-2"></i>Add
                  </Button>
                </div>
              </div>
              <table className="table table-hover pointer">
                <thead>
                  {isSmallScreen && (
                    <tr>
                      <th>Name / Email / Roles</th>
                      <th></th>
                    </tr>
                  )}
                  {!isSmallScreen && (
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Roles</th>
                      <th>Added On</th>
                      <th></th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {grid.data?.userInitialOutputDto?.map((item) => (
                    <tr
                      key={item.email}
                      className="break-word"
                      onClick={(e) => {
                        navigate(`/initialusers/${item.email}`);
                      }}
                    >
                      {isSmallScreen && (
                        <td>
                          <div>
                            {item.firstName} {item.lastName}
                          </div>
                          <div>
                            <a
                              href={`mailto:${item.email}`}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open(`mailto:${item.email}`);
                              }}
                            >
                              {item.email}
                            </a>
                          </div>
                          <div>
                            <small>
                              <UserRolesDisplayComponent
                                roles={item.userRoleDto}
                              ></UserRolesDisplayComponent>
                            </small>
                          </div>
                          <div className="no-wrap">
                            {!!item.addedToAadOn && (
                              <span className="chip-green">Added</span>
                            )}
                            {!item.addedToAadOn && (
                              <span className="chip-red">Not Added</span>
                            )}
                          </div>
                        </td>
                      )}
                      {!isSmallScreen && (
                        <>
                          <td>
                            {item.firstName} {item.lastName}
                          </td>
                          <td>
                            <a
                              href={`mailto:${item.email}`}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open(`mailto:${item.email}`);
                              }}
                            >
                              {item.email}
                            </a>
                          </td>
                          <td>
                            <UserRolesDisplayComponent
                              roles={item.userRoleDto}
                            ></UserRolesDisplayComponent>
                          </td>
                          <td>
                            {!!item.addedToAadOn && (
                              <span>
                                Added on{" "}
                                {commonService.toDateString(item.addedToAadOn)}
                              </span>
                            )}
                            {!item.addedToAadOn && (
                              <span className="chip-red">Not Added</span>
                            )}
                          </td>
                        </>
                      )}
                      <td className="text-right no-wrap">
                        {!item.addedToAadOn && (
                          <>
                            <i className="fa fa-pencil text-primary pointer me-3"></i>
                            <i
                              className="fa fa-trash text-danger pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setEmailToDelete(item.email);
                              }}
                            ></i>
                          </>
                        )}
                        {item.addedToAadOn && (
                          <>
                            <i className="fa fa-eye text-primary pointer"></i>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="p-3 pt-0">
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="users"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default InitialUsers;
