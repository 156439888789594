import SysModels from "../../models";
import fetcher from "../Fetcher";

class FtpManagementService {
  list = async (data: SysModels.IFtpListRequestInputDto) => {
    const url = `/FtpManagement/List`;
    return fetcher.post<SysModels.IFtpContentOutputDto[]>(url, data);
  };

  moveFile = async (data: SysModels.IFtpMoveFileRequestInputDto) => {
    const url = `/FtpManagement/MoveFile`;
    return fetcher.post<any>(url, data);
  };

  copyFile = async (data: SysModels.IFtpCopyFileRequestInputDto) => {
    const url = `/FtpManagement/CopyFile`;
    return fetcher.post<any>(url, data);
  };

  deleteFile = async (data: SysModels.IFtpDeleteFileRequestInputDto) => {
    const url = `/FtpManagement/DeleteFile`;
    return fetcher.post<any>(url, data);
  };

  downloadFile = async (data: SysModels.IFtpDownloadFileRequestInputDto) => {
    const url = `/FtpManagement/DownloadFile`;
    return fetcher.post<any>(url, data, { responseType: "blob" });
  };

  uploadFile = async (data: FormData) => {
    const url = `/FtpManagement/UploadFile`;
    return fetcher.postFormData<any>(url, data);
  };
}

const ftpManagement = new FtpManagementService();
export default ftpManagement;
