import dayjs from "dayjs";
import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import ConfirmDialog from "../components/common/ConfirmDialog";
import DateTimePicker from "../components/common/DateTimePicker";
import FormModal from "../components/common/FormModal";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import toastStore from "../stores/ToastStore";
import ColumnHeaderSortComponent from "./common/ColumnHeaderSortComponent";

function ConfigureAzureTable(props: any) {
  const grid = useFetchHelper(
    async () => SysServices.http.configAzure.getAllRecords(),
    "Azure Config"
  );

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    grid.getData();
  }, [calledOnce, grid]);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [delConfig, setDelConfig] =
    useState<SysModels.IConfigAzureTableItemOutputDto>();
  const [configsBeingDeleted, setConfigsBeingDeleted] = useState<
    SysModels.IConfigAzureTableItemOutputDto[]
  >([]);

  const deleteConfig = async (section: string, sectionId: string) => {
    await SysServices.http.configAzure
      .delete(section, sectionId)
      .then((data) => {
        toastStore.showToast("Azure Configuration deleted.", "success");
        grid.getData();
      })
      .catch((error) => {
        toastStore.showError("Failed to Delete Azure Configuration", error);
      })
      .finally(() => {
        setConfigsBeingDeleted((list) => {
          return list.filter((d) => d.section !== section);
        });
      });
  };

  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [model, setModel] = useState({ section: "", item: "", value: "" });

  const canSave = () => {
    return (
      !!`${model.section}`.trim() &&
      !!`${model.item}`.trim() &&
      !!`${model.value}`.trim()
    );
  };

  const canMaintain = () => {
    return (
      (systemStore.extension_MWFRole &
        SysModels.UserRolesEnum.ApplicationAdmin) >
      0
    );
  };

  const [orderBy, setOrderBy] = useState(SysModels.OrderByEnum.Ascending);
  const [sortBy, setSortBy] = useState("section");

  const canIRun = (row: SysModels.IConfigAzureTableItemOutputDto) => {
    return row.item.toLowerCase().trim() === "canirun";
  };

  const getRowClass = (row: SysModels.IConfigAzureTableItemOutputDto) => {
    const clsName = canMaintain() ? "pointer" : "";
    if (canIRun(row) && row.value.toLowerCase().trim() === "false") {
      return `${clsName} row-red`;
    }
    return clsName;
  };

  const getSortedData = () => {
    if (orderBy === 0) {
      return grid.data?.sort(commonService.sortByStringProperty(sortBy));
    }
    return grid.data
      ?.sort(commonService.sortByStringProperty(sortBy))
      ?.reverse();
  };

  const focusInput = (edit?: boolean) => {
    setTimeout(() => {
      document.getElementById(edit ? "inputValue" : "inputSection")?.focus();
    }, 200);
  };

  const Actions = ({
    row,
  }: {
    row: SysModels.IConfigAzureTableItemOutputDto;
  }) => {
    return (
      <>
        {!configsBeingDeleted.find(
          (d) => row.section === d.section && row.item === d.item
        ) && (
          <>
            {canIRun(row) && (
              <Button
                title="Set Schedule"
                variant="default"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setConfigSchedFor(row);
                  setHasExistingSched(!!row.fromDateTimeString);
                }}
              >
                {!!row.fromDateTimeString && (
                  <i className="fa fa-calendar txt-primary"></i>
                )}
                {!row.fromDateTimeString && (
                  <i className="fa fa-calendar-plus-o txt-primary"></i>
                )}
              </Button>
            )}
            <Button variant="default" type="button">
              <i className="fa fa-pencil txt-primary"></i>
            </Button>
            <Button
              variant="default"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setDelConfig(row);
              }}
            >
              <i className="fa fa-trash txt-danger"></i>
            </Button>
          </>
        )}
        {!!configsBeingDeleted.find(
          (d) => row.section === d.section && row.item === d.item
        ) && (
          <Button variant="default" type="button">
            <Spinner animation="border" variant={"danger"} size="sm"></Spinner>
          </Button>
        )}
      </>
    );
  };

  const [configSchedFor, setConfigSchedFor] =
    useState<SysModels.IConfigAzureTableItemOutputDto>();
  const [hasExistingSched, setHasExistingSched] = useState(false);
  const [delSched, setDelSched] = useState<"hide" | "show" | "deleting">(
    "hide"
  );

  const invalidDates = () => {
    return (
      configSchedFor &&
      !!configSchedFor.fromDateTimeString &&
      !!configSchedFor.toDateTimeString &&
      (dayjs(configSchedFor.fromDateTimeString).isSame(
        configSchedFor.toDateTimeString
      ) ||
        dayjs(configSchedFor.fromDateTimeString).isAfter(
          configSchedFor.toDateTimeString
        ))
    );
  };

  return (
    <>
      {(showAdd || showEdit) && (
        <FormModal
          title={`${showEdit ? "Edit" : "Add"} Azure Configuration`}
          isOpen={true}
          close={() => {
            setShowAdd(false);
            setShowEdit(false);
          }}
          size="md"
          submitButtonLabel={saving ? "Saving..." : "Save"}
          disableSubmit={!canSave() || saving}
          submit={() => {
            setSaving(true);
            (showAdd
              ? SysServices.http.configAzure.create(model)
              : SysServices.http.configAzure.update(
                  model.section,
                  model.item,
                  model
                )
            )
              .then((data) => {
                setSaving(false);
                toastStore.showToast("Azure Configuration Saved.", "success");
                grid.getData();
              })
              .catch((error) => {
                setSaving(false);
                toastStore.showError(
                  "Failed to Save Azure Configuration",
                  error
                );
              });

            setShowAdd(false);
            setShowEdit(false);
          }}
        >
          <div>
            <div className="mb-3">
              <label className="mb-2">Section</label>
              <input
                id="inputSection"
                value={model.section}
                disabled={showEdit}
                className="form-control"
                placeholder="Section"
                onChange={(e) => {
                  setModel((data) => {
                    return {
                      ...data,
                      section: e.target.value,
                    };
                  });
                }}
              ></input>
            </div>
            <div className="mb-3">
              <label className="mb-2">Item</label>
              <input
                value={model.item}
                disabled={showEdit}
                className="form-control"
                placeholder="Item"
                onChange={(e) => {
                  setModel((data) => {
                    return {
                      ...data,
                      item: e.target.value,
                    };
                  });
                }}
              ></input>
            </div>
            <div className="mb-3">
              <label className="mb-2">Value</label>
              <input
                id="inputValue"
                value={model.value}
                className="form-control"
                placeholder="Value"
                onChange={(e) => {
                  setModel((data) => {
                    return {
                      ...data,
                      value: e.target.value,
                    };
                  });
                }}
              ></input>
            </div>
          </div>
        </FormModal>
      )}

      {!!configSchedFor && (
        <FormModal
          title={`Set Schedule when not to run Function`}
          isOpen={true}
          close={() => {
            setConfigSchedFor(undefined);
          }}
          size="md"
          submitButtonLabel={saving ? "Saving..." : "Save"}
          disableSubmit={
            saving ||
            delSched === "deleting" ||
            !configSchedFor.fromDateTimeString ||
            !configSchedFor.toDateTimeString ||
            invalidDates()
          }
          disabledDeleteButton={saving || delSched === "deleting"}
          showDeleteButton={hasExistingSched}
          deleteButtonLabel={delSched === "deleting" ? "Deleting..." : "Delete"}
          moveBehind={delSched === "show"}
          submit={() => {
            setSaving(true);
            (!hasExistingSched
              ? SysServices.http.configAzure.createAzureFunctionsOnOffSchedule({
                  sectionName: configSchedFor.section,
                  fromDateTimeString: dayjs(
                    configSchedFor.fromDateTimeString
                  ).format("YYYY-MM-DDTHH:mm"),
                  toDateTimeString: dayjs(
                    configSchedFor.toDateTimeString
                  ).format("YYYY-MM-DDTHH:mm"),
                })
              : SysServices.http.configAzure.editAzureFunctionsOnOffSchedule(
                  configSchedFor.section,
                  {
                    fromDateTimeString: dayjs(
                      configSchedFor.fromDateTimeString
                    ).format("YYYY-MM-DDTHH:mm"),
                    toDateTimeString: dayjs(
                      configSchedFor.toDateTimeString
                    ).format("YYYY-MM-DDTHH:mm"),
                  }
                )
            )
              .then((data) => {
                setConfigSchedFor(undefined);
                setSaving(false);
                toastStore.showToast("Schedule Saved.", "success");
                grid.getData();
              })
              .catch((error) => {
                setSaving(false);
                toastStore.showError("Failed to Save Schedule", error);
              });
          }}
          deleteAction={() => {
            setDelSched("show");
          }}
        >
          <div>
            <div className="mb-3">
              <label className="mb-2">Section</label>
              <input
                id="inputSection"
                value={configSchedFor.section}
                disabled={true}
                className="form-control"
                placeholder="Section"
                onChange={(e) => {}}
              ></input>
            </div>
            <div className="mb-3">
              <label className="mb-2">From Date/Time</label>
              <DateTimePicker
                data={configSchedFor.fromDateTimeString}
                showAllMinuteOptions={true}
                onChange={(data) => {
                  setConfigSchedFor((p) => {
                    if (!p) return p;
                    return {
                      ...p,
                      fromDateTimeString: data,
                    };
                  });
                }}
              ></DateTimePicker>
            </div>
            <div className="mb-3">
              <label className="mb-2">To Date/Time</label>
              <DateTimePicker
                data={configSchedFor.toDateTimeString}
                showAllMinuteOptions={true}
                onChange={(data) => {
                  setConfigSchedFor((p) => {
                    if (!p) return p;
                    return {
                      ...p,
                      toDateTimeString: data,
                    };
                  });
                }}
              ></DateTimePicker>
            </div>
            {invalidDates() && (
              <div className="text-danger">
                From Date/Time must be before To Date/Time
              </div>
            )}
          </div>
        </FormModal>
      )}

      <ConfirmDialog
        show={!!delConfig}
        buttons="yesno"
        title="Delete Azure Configuration"
        message={`Are you sure you want to delete this azure configuration?`}
        done={(rtn) => {
          if (rtn === "yes") {
            if (delConfig) {
              setConfigsBeingDeleted((list) => {
                return [...list, { ...delConfig }];
              });
              deleteConfig(delConfig.section, delConfig.item);
            }
          }
          setDelConfig(undefined);
        }}
      ></ConfirmDialog>

      <ConfirmDialog
        show={delSched === "show"}
        buttons="yesno"
        title="Delete Schedule"
        message={`Are you sure you want to delete this schedule?`}
        done={(rtn) => {
          if (configSchedFor && rtn === "yes") {
            setDelSched("deleting");
            SysServices.http.configAzure
              .deleteAzureFunctionsOnOffSchedule(configSchedFor.section)
              .then((data) => {
                toastStore.showToast("Schedule deleted.", "success");
                grid.getData();
                setConfigSchedFor(undefined);
                setDelSched("hide");
              })
              .catch((error) => {
                toastStore.showError("Failed to Delete Schedule", error);
              });
          } else {
            setDelSched("hide");
          }
        }}
      ></ConfirmDialog>

      <div className="default-page-layout">
        <h4 className="hide-on-print">Azure Configuration</h4>
        <div className="bg-white col-sm-12">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div className="overflow-auto">
              <table className={`table ${canMaintain() ? "table-hover" : ""}`}>
                <thead>
                  <tr>
                    <th>
                      <ColumnHeaderSortComponent
                        name="Section"
                        mode={sortBy === "section" ? orderBy : null}
                        onClick={(mode) => {
                          if (mode === null) {
                            setSortBy("section");
                          } else {
                            setOrderBy(mode ? 0 : 1);
                          }
                        }}
                      ></ColumnHeaderSortComponent>
                      {isSmallScreen && (
                        <>
                          <span className="mx-4">|</span>
                          <ColumnHeaderSortComponent
                            name="Item"
                            mode={sortBy === "item" ? orderBy : null}
                            onClick={(mode) => {
                              if (mode === null) {
                                setSortBy("item");
                              } else {
                                setOrderBy(mode ? 0 : 1);
                              }
                            }}
                          ></ColumnHeaderSortComponent>
                        </>
                      )}
                    </th>

                    {!isSmallScreen && (
                      <>
                        <th>
                          <ColumnHeaderSortComponent
                            name="Item"
                            mode={sortBy === "item" ? orderBy : null}
                            onClick={(mode) => {
                              if (mode === null) {
                                setSortBy("item");
                              } else {
                                setOrderBy(mode ? 0 : 1);
                              }
                            }}
                          ></ColumnHeaderSortComponent>
                        </th>
                        <th>Value</th>
                        {canMaintain() && <th></th>}
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {getSortedData()?.map((row) => (
                    <React.Fragment key={`${row.section}-${row.item}`}>
                      <tr
                        className={getRowClass(row)}
                        onClick={() => {
                          if (canMaintain()) {
                            setShowEdit(true);
                            setModel(row);
                            focusInput(true);
                          }
                        }}
                      >
                        {!isSmallScreen && (
                          <>
                            <td>{row.section}</td>
                            <td className="break-all">{row.item}</td>
                            <td className="break-all">
                              <div className="mb-2">{row.value}</div>
                              {row.fromDateTimeString && row.toDateTimeString && (
                                <small className="mb-2">
                                  Do not run from{" "}
                                  <strong>
                                    {dayjs(row.fromDateTimeString).format(
                                      "MMM DD, YYYY hh:mm A"
                                    )}
                                  </strong>{" "}
                                  to{" "}
                                  <strong>
                                    {dayjs(row.toDateTimeString).format(
                                      "MMM DD, YYYY hh:mm A"
                                    )}
                                  </strong>
                                </small>
                              )}
                            </td>
                            {canMaintain() && (
                              <td className="no-wrap text-right">
                                <Actions row={row}></Actions>
                              </td>
                            )}
                          </>
                        )}
                        {isSmallScreen && (
                          <td colSpan={2} className="break-all">
                            <div className="flex flex-center">
                              <strong className="flex-1">{row.section}</strong>
                              <Actions row={row}></Actions>
                            </div>
                            <div>{row.item}</div>
                            <div className="bg-light-grey p-2 mt-2">
                              <small className="text-secondary">
                                {row.value}
                              </small>
                              <div>
                                {row.fromDateTimeString &&
                                  row.toDateTimeString && (
                                    <small className="mb-2">
                                      Do not run from{" "}
                                      <strong>
                                        {dayjs(row.fromDateTimeString).format(
                                          "MMM DD, YYYY hh:mm A"
                                        )}
                                      </strong>{" "}
                                      to{" "}
                                      <strong>
                                        {dayjs(row.toDateTimeString).format(
                                          "MMM DD, YYYY hh:mm A"
                                        )}
                                      </strong>
                                    </small>
                                  )}
                              </div>
                            </div>
                          </td>
                        )}
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {canMaintain() && (
          <div className="mt-4">
            <Button
              variant="primary"
              type="button"
              onClick={() => {
                setShowAdd(true);
                setModel({ section: "", item: "", value: "" });
                focusInput();
              }}
              disabled={saving}
            >
              {!saving && (
                <span>
                  <i className="fa fa-plus"></i> Add
                </span>
              )}
              {saving && (
                <span>
                  <i className="fa fa-save"></i> Saving...
                </span>
              )}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default ConfigureAzureTable;
