import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import DateTimePicker from "../components/common/DateTimePicker";
import SysModels from "../models";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import toastStore from "../stores/ToastStore";

function SiteAnnouncements(props: any) {
  const [site, setSite] = useState<"Employee" | "MyOrders">("Employee");
  const [siteMessage, setSiteMessage] =
    useState<SysModels.ISiteMessageOutputDto>({} as any);

  const [saving, setSaving] = useState(false);
  const save = async () => {
    if (dayjs(siteMessage.startDate).isAfter(siteMessage.endDate)) {
      toastStore.showToast(
        "End Time must be greater than Start Time",
        "warning"
      );
      return;
    }

    setSaving(true);
    await SysServices.http.siteManagement
      .updateSiteMessage({
        siteId: site,
        ...siteMessage,
      })
      .then((data) => {
        setSaving(false);
        toastStore.showToast("Site Message Saved.", "success");
        systemStore.setTmoSystemMessage(systemStore.tmoSystemMessage + 1);
      })
      .catch((error) => {
        setSaving(false);
        toastStore.showError("Failed saving Site Message", error);
      });
  };

  const cannotSubmit = () => {
    return (
      saving ||
      !siteMessage ||
      !siteMessage.startDate ||
      !siteMessage.endDate ||
      !siteMessage.globalMessage ||
      !siteMessage.globalMessage.trim()
    );
  };

  const siteMsgFetcher = useFetchHelper(
    async () => SysServices.http.siteManagement.getSiteMessage(site),
    "Site Message"
  );

  useEffect(() => {
    siteMsgFetcher.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [site]);

  const [getCounter, setGetCounter] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (siteMsgFetcher.data && siteMsgFetcher.status === FetchStatus.Complete) {
      setGetCounter(getCounter + 1);
      setStartDate(
        new Date(
          dayjs(siteMsgFetcher.data.startDate).format("YYYY-MM-DDTHH:mm:ss")
        )
      );
      setEndDate(
        new Date(
          dayjs(siteMsgFetcher.data.endDate).format("YYYY-MM-DDTHH:mm:ss")
        )
      );
      setSiteMessage({
        ...siteMsgFetcher.data,
        startDate: new Date(
          dayjs(siteMsgFetcher.data.startDate).format("YYYY-MM-DDTHH:mm:ss")
        ),
        endDate: new Date(
          dayjs(siteMsgFetcher.data.endDate).format("YYYY-MM-DDTHH:mm:ss")
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteMsgFetcher.status]);

  return (
    <>
      <div className="default-page-layout">
        <h4>Site Announcements</h4>
      </div>

      <div className="row">
        <div className="cols-sm-12 col-md-12 col-lg-6">
          <div>
            <div className="bg-white mb-4">
              <div className="p-3 bg-success txt-light">
                <h5 className="m-0">
                  <em className="fa fa-bullhorn me-2"></em>
                  Site Message Update
                </h5>
              </div>

              {siteMsgFetcher.status === FetchStatus.InProgress && (
                <div className="p-4">
                  <CommonSpinner></CommonSpinner>
                </div>
              )}
              {siteMsgFetcher.status === FetchStatus.Complete && (
                <div className="p-3 overflow-auto min-height-100">
                  <div className="mb-1">
                    <label className="mb-2">
                      <strong>Please Select a Site :</strong>
                    </label>
                    <select
                      autoFocus={true}
                      className="form-control mb-3"
                      value={site}
                      onChange={(e) => {
                        setSite(e.target.value as any);
                      }}
                      disabled={saving}
                    >
                      <option value="Employee">Employee</option>
                      <option value="MyOrders">My Orders</option>
                    </select>

                    {[getCounter].map((cnt) => (
                      <React.Fragment key={cnt}>
                        <label className="mb-2">
                          <strong>Start Date/Time:</strong>
                        </label>
                        <div className="mb-3">
                          <DateTimePicker
                            data={startDate}
                            showAllMinuteOptions={true}
                            onChange={(date) => {
                              setSiteMessage((rtn) => {
                                return {
                                  ...rtn,
                                  startDate: date,
                                };
                              });
                            }}
                            disabled={saving}
                          ></DateTimePicker>
                        </div>

                        <label className="mb-2">
                          <strong>End Date/Time:</strong>
                        </label>
                        <div className="mb-3">
                          <DateTimePicker
                            data={endDate}
                            showAllMinuteOptions={true}
                            onChange={(date) => {
                              setSiteMessage((rtn) => {
                                return {
                                  ...rtn,
                                  endDate: date,
                                };
                              });
                            }}
                            disabled={saving}
                          ></DateTimePicker>
                        </div>
                      </React.Fragment>
                    ))}

                    <label className="mb-2">
                      <strong>Message:</strong>
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Emails"
                      value={siteMessage?.globalMessage}
                      onChange={(e) => {
                        setSiteMessage((rtn) => {
                          return {
                            ...rtn,
                            globalMessage: e.target.value || "",
                          };
                        });
                      }}
                      disabled={saving}
                      rows={5}
                      maxLength={2048}
                    ></textarea>
                    <div className="mt-2">
                      <div className="text-right">
                        {siteMessage?.globalMessage?.length || 0}/2048
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <label className="mb-2">
                      <strong>Message Type:</strong>
                    </label>

                    <div className="flex flex-center">
                      <input
                        type="radio"
                        name="msgType"
                        value="a"
                        id="msgTypeA"
                        checked={siteMessage.announcement || false}
                        onChange={(e) => {
                          setSiteMessage((data) => {
                            return {
                              ...data,
                              announcement: e.target.checked,
                              ...(e.target.checked
                                ? {
                                    warning: false,
                                    error: false,
                                  }
                                : {}),
                            };
                          });
                        }}
                        disabled={saving}
                      ></input>
                      <label className="mx-2" htmlFor="msgTypeA">
                        Announcement
                      </label>
                    </div>
                    <div className="flex flex-center">
                      <input
                        type="radio"
                        name="msgType"
                        value="w"
                        id="msgTypeW"
                        checked={siteMessage.warning || false}
                        onChange={(e) => {
                          setSiteMessage((data) => {
                            return {
                              ...data,
                              warning: e.target.checked,
                              ...(e.target.checked
                                ? {
                                    announcement: false,
                                    error: false,
                                  }
                                : {}),
                            };
                          });
                        }}
                        disabled={saving}
                      ></input>
                      <label className="mx-2" htmlFor="msgTypeW">
                        Warning
                      </label>
                    </div>
                    <div className="flex flex-center">
                      <input
                        type="radio"
                        name="msgType"
                        value="e"
                        id="msgTypeE"
                        checked={siteMessage.error || false}
                        onChange={(e) => {
                          setSiteMessage((data) => {
                            return {
                              ...data,
                              error: e.target.checked,
                              ...(e.target.checked
                                ? {
                                    warning: false,
                                    announcement: false,
                                  }
                                : {}),
                            };
                          });
                        }}
                        disabled={saving}
                      ></input>
                      <label className="mx-2" htmlFor="msgTypeE">
                        Error
                      </label>
                    </div>
                  </div>

                  <div className="text-right">
                    <Button
                      variant="success"
                      type="button"
                      disabled={cannotSubmit()}
                      onClick={() => save()}
                    >
                      {saving ? "Saving..." : "Save"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="cols-sm-12 col-md-12 col-lg-6"></div>
      </div>
    </>
  );
}

export default SiteAnnouncements;
