import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import commonService from "../services/CommonService";
import CustomerInfoDialog from "../pages/CustomerInfoDialog";

function CustomerLastLogins(props: any) {
  const grid = useFetchHelper(
    async () => SysServices.http.userAccess.list(paging.page, paging.pageSize),
    "Customers"
  );

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging]);

  const [selId, setSelId] = useState<string>();

  return (
    <>
      {!!selId && (
        <CustomerInfoDialog
          id={selId}
          onClose={() => {
            setSelId(undefined);
          }}
        ></CustomerInfoDialog>
      )}
      <div className="default-page-layout">
        <h4 className="hide-on-print">Customers Logins</h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Customer Name(s)</th>
                    <th className="">Email</th>
                    <th className="">Last Logged In</th>
                  </tr>
                </thead>
                <tbody>
                  {grid.data?.userAccessOutputDtos?.map((customer) => (
                    <tr key={customer.emailAddress}>
                      <td>
                        {customer.customerName.split("|").map((name) => (
                          <div key={name}>
                            <a
                              href="/"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                const idx = name.lastIndexOf("(");
                                const id = name.substring(
                                  idx + 1,
                                  name.length - 1
                                );
                                setSelId(id);
                              }}
                            >
                              {name}
                            </a>
                          </div>
                        ))}
                      </td>
                      <td className="pointer">
                        <a href={"mailto:" + customer.emailAddress}>
                          {customer.emailAddress}
                        </a>
                      </td>
                      <td>
                        {commonService.toLocalDate(
                          customer.lastLoggedIn,
                          "full"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="p-3 pt-0">
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="entries"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CustomerLastLogins;
