import RecentIncomingOrdersWidget from "../widgets/RecentIncomingOrdersWidget";
import RecentlyViewedCustomersWidget from "../widgets/RecentlyViewedCustomersWidget";
import SalesRepProjectionWidget from "../widgets/SalesRepProjectionWidget";
import TopCustomersWidget from "../widgets/TopCustomersWidget";

function SalesRepDashboard(props: any) {
  return (
    <>
      <div className="default-page-layout hide-on-print">
        <h4>Sales Rep Dashboard</h4>
      </div>

      <div className="row">
        <div className="cols-sm-12 col-md-12 col-lg-6">
          <SalesRepProjectionWidget></SalesRepProjectionWidget>
          <TopCustomersWidget></TopCustomersWidget>
        </div>
        <div className="cols-sm-12 col-md-12 col-lg-6">
          <RecentlyViewedCustomersWidget></RecentlyViewedCustomersWidget>
          <RecentIncomingOrdersWidget></RecentIncomingOrdersWidget>
        </div>
      </div>
    </>
  );
}

export default SalesRepDashboard;
