import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CommonSpinner from "../components/common/CommonSpinner";
import CategoriesMenu from "../components/layout-common/CategoriesMenu";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore, {
  useSalesAccountViewing,
  useSavedCartItems,
} from "../stores/SystemStore";
import menuActions from "./MenuActions";
import SysModels from "../models";

function SideNav(props: { toggle: () => void }) {
  const navigate = useNavigate();
  const [curMenuActions, setCurMenuActions] = useState({ ...menuActions });
  const [actions, setActions] = useState([...menuActions.actions]);

  const [mwfRole, setMwfRole] = useState(systemStore.extension_MWFRole);
  const [activeRole, setActiveRole] = useState(systemStore.activeRole);
  const [currentCustomer, setCurrentCustomer] = useState(
    systemStore.currentCustomer
  );

  const salesAccountViewing = useSalesAccountViewing();

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.extension_MWFRole,
      (n, p, r) => {
        setMwfRole(n);
      }
    );
    const disposer2 = reaction(
      () => systemStore.activeRole,
      (n, p, r) => {
        if (n !== activeRole) {
          const ma = curMenuActions.actions.find(
            (ma) => ma.dashboard && ma.role === n
          );
          if (ma) {
            saveLastDashboard(ma.label);
          }
        }
        setActiveRole(n);
      }
    );
    const disposer3 = reaction(
      () => systemStore.currentCustomer,
      (n, p, r) => {
        setCurrentCustomer(n);
        if (!n) {
          gotoDefaultDashboard();
        } else {
          navigate("/customerdashboard");
        }
      }
    );
    return () => {
      disposer();
      disposer2();
      disposer3();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roles = useFetchHelper(
    async () => SysServices.http.genericEnumLookup.get("UserRolesEnum"),
    "Roles"
  );

  const lastDashboard = useFetchHelper(
    async () => SysServices.http.myAccount.getLastDashboard(),
    "Last Dashboard"
  );

  const allowInvoicePayment = useFetchHelper(
    SysServices.http.customerInvoicePayment.allowInvoicePayment,
    "Allow Invoice Payment"
  );

  useEffect(() => {
    systemStore.setAllowInvoicePayment(allowInvoicePayment.data || false);
    const tmo = setTimeout(() => {
      systemStore.setSideMenuReady(
        allowInvoicePayment.status === FetchStatus.Complete
      );
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowInvoicePayment.status]);

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (!calledOnce) {
      roles.getData();
      lastDashboard.getData();
      allowInvoicePayment.getData();
      setCalledOnce(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calledOnce]);

  const gotoDefaultDashboard = () => {
    //go to active role's tab
    actions
      .filter((ma) => ma.dashboard && !!(mwfRole & ma.role))
      .forEach((ma) => {
        if (ma.role === activeRole) {
          navigate(ma.url);
        }
      });
  };

  const saveLastDashboard = async (dashboardName: string) => {
    await SysServices.http.myAccount
      .saveDashboardUsed({
        dashboardName: dashboardName,
      })
      .then(() => {})
      .catch((error) => {});
  };

  const [isInitialDashboardSet, setIsInitialDashboardSet] = useState(false);
  const [dashboards, setDashboards] = useState<
    { role: number; label: string }[]
  >([]);
  useEffect(() => {
    if (
      roles.status === FetchStatus.Complete &&
      lastDashboard.status === FetchStatus.Complete &&
      roles.data
    ) {
      let actRole = activeRole;

      if (!isInitialDashboardSet) {
        setIsInitialDashboardSet(true);
        const ma = curMenuActions.actions.find(
          (ma) => ma.dashboard && ma.label === lastDashboard.data
        );
        if (ma && systemStore.extension_MWFRole & ma.role) {
          actRole = ma.role;
          setActiveRole(ma.role);
          systemStore.setActiveRole(ma.role);
        }
      }

      //Identify Dashboard based on URL
      curMenuActions.actions
        .filter((ma) => !!(mwfRole & ma.role) && ma.dashboard)
        .forEach((ma) => {
          if (!actRole && window.location.href.indexOf(ma.url) > -1) {
            actRole = ma.role;
            setActiveRole(actRole);
            saveLastDashboard(ma.label);
          }
        });

      const dbs = roles.data.filter((r) => !!(mwfRole & r.value));

      dbs.forEach((r) => {
        //If Dashboard not identified yet from above, use first one
        if (!actRole) {
          actRole = r.value;
          setActiveRole(actRole);
          const ma = curMenuActions.actions.find(
            (ma) => ma.dashboard && ma.role === actRole
          );
          if (ma) {
            saveLastDashboard(ma.label);
            systemStore.setActiveRole(ma.role);
          }
        }
      });

      setDashboards(
        dbs.map((r) => {
          return {
            role: r.value,
            label: r.label,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles.status, lastDashboard.status, mwfRole]);

  const getRootUrl = (url: string) => {
    return url.split("/:")[0];
  };

  const getCurrentDashboardMenu = () => {
    return curMenuActions.actions.filter(
      (ma) =>
        !!(ma.role & activeRole) &&
        !ma.hidden &&
        !ma.dashboard &&
        !ma.asCustomer &&
        (!ma.canAccess || (ma.canAccess && ma.canAccess()))
    );
  };

  const isLoading = () =>
    roles.status === FetchStatus.InProgress ||
    lastDashboard.status === FetchStatus.InProgress ||
    allowInvoicePayment.status === FetchStatus.InProgress;

  const savedCartItems = useSavedCartItems();

  useEffect(() => {
    if (salesAccountViewing) {
      const axnList = [
        ...menuActions.actions.map((axn) => {
          if (
            mwfRole & SysModels.UserRolesEnum.LoginAsSalesRep &&
            !(axn.role & SysModels.UserRolesEnum.LoginAsSalesRep) &&
            !(mwfRole & SysModels.UserRolesEnum.Sales) &&
            axn.role & SysModels.UserRolesEnum.Sales
          ) {
            return {
              ...axn,
              role: axn.role + SysModels.UserRolesEnum.LoginAsSalesRep,
            };
          }
          if (axn.role & SysModels.UserRolesEnum.LoginAsSalesRep) {
            return {
              ...axn,
              role: 0,
            };
          }
          return axn;
        }),
      ];
      setCurMenuActions({
        ...menuActions,
        actions: [...axnList],
      });
      setActions([...axnList]);
    } else {
      setCurMenuActions({ ...menuActions });
      setActions([...menuActions.actions]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesAccountViewing]);

  const isActiveRoleAllowed = () => {
    if (
      salesAccountViewing &&
      mwfRole & SysModels.UserRolesEnum.LoginAsSalesRep
    ) {
      return true;
    }
    return !!dashboards.find((d) => d.role === activeRole);
  };

  //This gives time to the redirects on first load/refresh
  const [ready, setReady] = useState(false);
  useEffect(() => {
    const tmo = setTimeout(() => {
      setReady(true);
    }, 1500);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading() && (
        <div className="side-nav-spinner-wrapper">
          <CommonSpinner color="light"></CommonSpinner>
        </div>
      )}
      <div className={`side-nav-panel ${isLoading() ? "display-none" : ""}`}>
        <div>
          {!currentCustomer && <strong>Main Menu</strong>}
          {/* {systemStore.extension_MWFRole} */}
          {!!currentCustomer && (
            <strong
              style={{
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {currentCustomer.number} | {currentCustomer.name}
            </strong>
          )}
        </div>
        {!!salesAccountViewing && (
          <div className="pt-0 flex">
            <strong>
              <i className="fa fa-user me-3"></i>
            </strong>
            <div
              style={{
                textTransform: "none",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <strong title={salesAccountViewing.email}>
                {salesAccountViewing.email}
              </strong>
            </div>
          </div>
        )}
        <ul className="navbar-nav">
          {!!currentCustomer && (
            <>
              <li className="navbar-nav">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  to={"/"}
                  onClick={(e) => {
                    props.toggle();
                    e.preventDefault();
                    e.stopPropagation();
                    systemStore.setCurrentCustomer();
                    systemStore.setSavedCartItems([]);

                    if (salesAccountViewing) {
                      navigate(
                        menuActions.getDashboardUrl(
                          SysModels.UserRolesEnum.Sales
                        )
                      );
                    } else {
                      dashboards.forEach((d) => {
                        if (d.role === activeRole) {
                          navigate(
                            actions.find(
                              (a) => a.dashboard && a.role === d.role
                            )?.url || "/"
                          );
                        }
                      });
                    }
                  }}
                >
                  <i className="fa fa-sign-out"></i>
                  {salesAccountViewing ? (
                    <span>Back to {salesAccountViewing.role}</span>
                  ) : (
                    <span>Back to Employee Site</span>
                  )}
                </NavLink>
              </li>
              <li className="navbar-nav">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  to={"/customerdashboard"}
                  onClick={props.toggle}
                >
                  <i className="zmdi zmdi-home"></i>
                  <span>Dashboard</span>
                </NavLink>
              </li>
            </>
          )}
          {!!salesAccountViewing && !currentCustomer && (
            <>
              <li className="navbar-nav">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  to={"/"}
                  onClick={(e) => {
                    props.toggle();
                    e.preventDefault();
                    e.stopPropagation();
                    systemStore.setSalesAccountViewing(undefined);
                    navigate("/loginassales");
                  }}
                >
                  <i className="fa fa-sign-out"></i>
                  <span>Exit {salesAccountViewing.role} View</span>
                </NavLink>
              </li>
              <li className="navbar-nav">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  to={"/salesdashboard"}
                  onClick={props.toggle}
                >
                  <i className="zmdi zmdi-home"></i>
                  <span>Dashboard</span>
                </NavLink>
              </li>
            </>
          )}
          {!currentCustomer && !salesAccountViewing && (
            <>
              <li className="navbar-nav">
                {dashboards.length === 1 && (
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link active" : "nav-link"
                    }
                    to={
                      actions.find(
                        (ma) => ma.dashboard && ma.role === dashboards[0].role
                      )?.url || "/dashboard"
                    }
                    onClick={props.toggle}
                  >
                    <i className="zmdi zmdi-home"></i>
                    <span>Dashboard</span>
                  </NavLink>
                )}
                {dashboards.length > 1 && (
                  <>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        gotoDefaultDashboard();
                      }}
                    >
                      <i className="zmdi zmdi-home"></i>
                      <span>Dashboard</span>
                    </a>

                    <ul>
                      {actions
                        .filter((ma) => ma.dashboard && !!(mwfRole & ma.role))
                        .map((ma, idx) => (
                          <li key={ma.role}>
                            <NavLink
                              className={({ isActive }) => {
                                if (!ready) return "nav-link";
                                return isActive || activeRole & ma.role
                                  ? "nav-link active"
                                  : "nav-link";
                              }}
                              to={ma.url}
                              onClick={(e) => {
                                props.toggle();
                                systemStore.setActiveRole(ma.role);
                              }}
                            >
                              <i
                                className={`${
                                  ma.icon?.split("-")[0] || "zmdi"
                                } ${ma.icon || "zmdi-tab"}`}
                              ></i>
                              <span>{ma.label}</span>
                            </NavLink>
                          </li>
                        ))}
                    </ul>
                  </>
                )}
              </li>
            </>
          )}
          <li className="navbar-nav mt-2">
            <NavLink
              className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
              }
              to={"/search"}
              onClick={props.toggle}
            >
              <i className="zmdi zmdi-search"></i>
              <span>Global Search</span>
            </NavLink>
          </li>
        </ul>

        {!!currentCustomer && (
          <>
            <hr className="light-grey-hr mb-10" />
            <div>
              <strong>CUSTOMER</strong>
            </div>
            <ul className="navbar-nav">
              {curMenuActions.actions
                .filter(
                  (ma) =>
                    !!(ma.role & mwfRole) &&
                    !ma.hidden &&
                    !ma.dashboard &&
                    ma.asCustomer &&
                    (!ma.canAccess || (ma.canAccess && ma.canAccess()))
                )
                .sort(commonService.sortByStringProperty("label"))
                .map((ma) => (
                  <li className="navbar-nav" key={`${ma.role}-${ma.url}`}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                      to={ma.url}
                      onClick={props.toggle}
                    >
                      <i
                        className={`${ma.icon?.split("-")[0] || "zmdi"} ${
                          ma.icon || "zmdi-folder"
                        }`}
                      ></i>
                      <span>
                        {ma.label}{" "}
                        {ma.label === "Place an Order" &&
                          savedCartItems &&
                          savedCartItems?.items?.length > 0 && (
                            <strong
                              className="alert alert-primary p-0 m-0 px-2 pull-right"
                              style={{ border: "none" }}
                            >
                              <small>{savedCartItems?.items?.length}</small>
                            </strong>
                          )}
                      </span>
                    </NavLink>
                  </li>
                ))}
            </ul>
            <hr className="light-grey-hr mb-10" />
            <div>
              <strong>POPULAR CATEGORIES</strong>
            </div>
            <CategoriesMenu></CategoriesMenu>
          </>
        )}

        {!currentCustomer &&
          isActiveRoleAllowed() &&
          getCurrentDashboardMenu().length > 0 && (
            <>
              <hr className="light-grey-hr mb-10" />
              <div>
                <strong>
                  {salesAccountViewing
                    ? `Viewing as ${salesAccountViewing.role}`
                    : dashboards.find((d) => d.role === activeRole)?.label}
                </strong>
              </div>
              <ul className="navbar-nav">
                {getCurrentDashboardMenu()
                  .sort(commonService.sortByStringProperty("label"))
                  .map((ma) => (
                    <li className="navbar-nav" key={`${ma.role}-${ma.url}`}>
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? "nav-link active" : "nav-link"
                        }
                        to={getRootUrl(ma.url)}
                        onClick={props.toggle}
                      >
                        <i
                          className={`${ma.icon?.split("-")[0] || "zmdi"} ${
                            ma.icon || "zmdi-folder"
                          }`}
                        ></i>
                        <span>{ma.label}</span>
                      </NavLink>
                    </li>
                  ))}
              </ul>
            </>
          )}
      </div>
    </>
  );
}

export default SideNav;
