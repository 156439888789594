import SysModels from "../../models";
import fetcher from "../Fetcher";

class AsSalesRepRoleService {
  list = async (
    page: number,
    pageSize: number,
    data: SysModels.IAsSalesRepSearchInputDto
  ) => {
    const url = `/AsSalesRepRole/ListSalesReps/${page}/${pageSize}`;
    return fetcher.post<SysModels.IAsSalesRepGridDto>(url, data);
  };
}

const asSalesRepRole = new AsSalesRepRoleService();
export default asSalesRepRole;
