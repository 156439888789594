import RecentlyViewedCustomersWidget from "../widgets/RecentlyViewedCustomersWidget";
import RecentIncomingOrdersWidget from "../widgets/RecentIncomingOrdersWidget";
import CSROrderSummaryWidget from "../widgets/CSROrderSummaryWidget";
import IncomingOrdersInErrorWdiget from "../widgets/IncomingOrdersInErrorWdiget";
import LastSystemActivitiesWidget from "../widgets/LastSystemActivitiesWidget";
import SystemActivityWidget from "../widgets/SystemActivityWidget";

function OeManagerDashboard(props: any) {
  return (
    <>
      <div className="default-page-layout hide-on-print">
        <h4>OE Manager Dashboard</h4>
      </div>

      <div className="row">
        <div className="cols-sm-12 col-md-12 col-lg-6">
          <IncomingOrdersInErrorWdiget></IncomingOrdersInErrorWdiget>
          <CSROrderSummaryWidget></CSROrderSummaryWidget>
          <LastSystemActivitiesWidget></LastSystemActivitiesWidget>
        </div>
        <div className="cols-sm-12 col-md-12 col-lg-6">
          <RecentlyViewedCustomersWidget></RecentlyViewedCustomersWidget>
          <RecentIncomingOrdersWidget></RecentIncomingOrdersWidget>
          <SystemActivityWidget></SystemActivityWidget>
        </div>
        <div className="cols-sm-12 col-md-12 col-lg-6"></div>
      </div>
    </>
  );
}

export default OeManagerDashboard;
