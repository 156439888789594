import React, { useEffect, useState } from "react";
import FormModal from "../components/common/FormModal";
import SysServices from "../services";
import SysModels from "../models";
import toastStore from "../stores/ToastStore";
import { useFetchHelper } from "../services/FetchHelper";
import SwitchButton from "../components/common/SwitchButton";
import CommonSpinner from "../components/common/CommonSpinner";
import commonService from "../services/CommonService";

function MonitorRuleDialog(props: {
  ruleRegimeId?: number;
  systemActivityId?: number;
  onClose: (done?: boolean) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [model, setModel] = useState<SysModels.IPortalMonitorRuleInsertDto>({
    ruleRegimeId: SysModels.RuleRegimeEnum.Any,
    active: true,
  } as any);

  const ruleRegimeList = useFetchHelper(async () => {
    return SysServices.http.genericEnumLookup.get("RuleRegimeEnum");
  }, "Rule Regime");

  const batchList = useFetchHelper(async () => {
    return SysServices.http.portalMonitor.getAllSystemBatchActivities();
  }, "System Batch Activities");

  useEffect(() => {
    ruleRegimeList.getData();
    batchList.getData();

    if (
      props.ruleRegimeId !== undefined &&
      props.systemActivityId !== undefined
    ) {
      setLoading(true);
      SysServices.http.portalMonitor
        .getRule(props.ruleRegimeId, props.systemActivityId)
        .then((data) => {
          setModel(data);
          setLoading(false);
        })
        .catch((error) => {
          toastStore.showError("Failed to Get Monitor Rule", error);
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isBatchSelected, setIsBatchSelected] = useState(false);

  return (
    <FormModal
      title={
        props.systemActivityId !== undefined
          ? "Edit Monitor Rule"
          : "Add Monitor Rule"
      }
      isOpen={true}
      close={() => {
        props.onClose();
      }}
      size="md"
      submitButtonLabel={saving ? "Saving..." : "Save"}
      disableSubmit={loading || saving}
      submit={() => {
        if (
          props.systemActivityId === undefined &&
          !isBatchSelected &&
          (model.systemActivityId === undefined ||
            model.systemActivityId < 10000)
        ) {
          commonService.focusInput("inputSystemActivityId");
          toastStore.showToast(
            "System Activity ID must be equal or greatar than 10000",
            "warning"
          );
          return;
        }

        if (commonService.isNullOrWhitespace(model.processName)) {
          commonService.focusInput("inputProcessName");
          toastStore.showToast("Process Name is required", "warning");
          return;
        }

        if (model.problemMinutes === undefined || model.problemMinutes <= 0) {
          commonService.focusInput("inputProblemMinutes");
          toastStore.showToast(
            "Process Minutes must be greater than 0",
            "warning"
          );
          return;
        }

        if (model.recheckMinutes === undefined || model.recheckMinutes <= 0) {
          commonService.focusInput("inputRecheckMinutes");
          toastStore.showToast(
            "Recheck Minutes must be greater than 0",
            "warning"
          );
          return;
        }

        setSaving(true);
        if (
          props.ruleRegimeId !== undefined &&
          props.systemActivityId !== undefined
        ) {
          SysServices.http.portalMonitor
            .updateRule(props.ruleRegimeId, props.systemActivityId, model)
            .then((data) => {
              setSaving(false);
              toastStore.showToast("Monitor Rule saved.", "success");
              props.onClose(true);
            })
            .catch((error) => {
              setSaving(false);
              toastStore.showError("Failed to Save Monitor Rule", error);
            });
        } else {
          SysServices.http.portalMonitor
            .createRule(model)
            .then((data) => {
              setSaving(false);
              toastStore.showToast("Monitor Rule saved.", "success");
              props.onClose(true);
            })
            .catch((error) => {
              setSaving(false);
              toastStore.showError("Failed to Save Monitor Rule", error);
            });
        }
      }}
    >
      {loading && <CommonSpinner overlay={true}></CommonSpinner>}
      <div>
        <label className="mb-2 required-label">System Activity ID</label>

        {props.systemActivityId === undefined && (
          <select
            className="form-control mb-3"
            value={isBatchSelected ? model.systemActivityId : undefined}
            disabled={props.systemActivityId !== undefined}
            onChange={(e) => {
              const valid = !isNaN(Number(e.target.value));
              setIsBatchSelected(valid);
              const val = Number(e.target.value);
              setModel((p) => {
                return {
                  ...p,
                  systemActivityId: valid ? val : (undefined as any),
                  processName:
                    batchList.data?.find((x) => x.id === val)?.activity || "",
                };
              });
            }}
          >
            <option>- Enter Manually -</option>
            {batchList.data?.map((r) => (
              <option key={r.id} value={r.id}>
                {r.id} - {r.activity}
              </option>
            ))}
          </select>
        )}

        {!isBatchSelected && (
          <>
            <small>
              Enter ID manually. Must be equal or greatar than 10000
            </small>
            <input
              id="inputSystemActivityId"
              className="form-control mb-3"
              placeholder="System Activity ID"
              value={model.systemActivityId}
              disabled={props.systemActivityId !== undefined}
              type="number"
              onChange={(e) => {
                const valid =
                  !commonService.isNullOrWhitespace(e.target.value) &&
                  !isNaN(Number(e.target.value));
                setModel((p) => {
                  return {
                    ...p,
                    systemActivityId: valid
                      ? Number(e.target.value)
                      : (undefined as any),
                  };
                });
              }}
            ></input>
          </>
        )}

        <label className="mb-2 required-label">Rules Regime</label>
        <select
          className="form-control mb-3"
          value={model.ruleRegimeId}
          disabled={props.ruleRegimeId !== undefined}
          onChange={(e) => {
            const val = Number(e.target.value) || 0;
            setModel((p) => {
              return {
                ...p,
                ruleRegimeId: val,
              };
            });
          }}
        >
          {ruleRegimeList.data?.map((r) => (
            <option key={r.value} value={r.value}>
              {r.label}
            </option>
          ))}
        </select>

        <label className="mb-2 required-label">Process Name</label>
        <input
          id="inputProcessName"
          className="form-control mb-3"
          placeholder="Process Name"
          value={model.processName}
          disabled={isBatchSelected}
          onChange={(e) => {
            setModel((p) => {
              return {
                ...p,
                processName: e.target.value,
              };
            });
          }}
        ></input>

        <div className="row">
          <div className="col-sm-6 col-md-6">
            <div className="mb-1">
              Problem Minutes <span className="required-label">*</span>
            </div>
            <input
              id="inputProblemMinutes"
              className="form-control mb-3"
              placeholder="Problem Minutes"
              type="number"
              defaultValue={model.problemMinutes}
              onChange={(e) => {
                setModel((data) => {
                  return {
                    ...data,
                    problemMinutes:
                      Number(e.target.value) || (undefined as any),
                  };
                });
              }}
            ></input>
          </div>
          <div className="col-sm-6 col-md-6">
            <div className="mb-1">
              Recheck Minutes <span className="required-label">*</span>
            </div>
            <input
              id="inputRecheckMinutes"
              className="form-control mb-3"
              placeholder="Recheck Minutes"
              type="number"
              defaultValue={model.recheckMinutes}
              onChange={(e) => {
                setModel((data) => {
                  return {
                    ...data,
                    recheckMinutes:
                      Number(e.target.value) || (undefined as any),
                  };
                });
              }}
            ></input>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-6">
            <div className="mb-1">Start Time</div>
            <select
              className="form-control mb-3"
              value={model.startTime === null ? -1 : model.startTime}
              onChange={(e) => {
                const val = Number(e.target.value) || 0;
                setModel((p) => {
                  return {
                    ...p,
                    startTime: val === -1 ? (null as any) : val,
                  };
                });
              }}
            >
              <option value={-1}>None</option>
              {Array.from({ length: 24 }, (_, i) => i).map((i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-6 col-md-6">
            <div className="mb-1">End Time</div>
            <select
              className="form-control mb-3"
              value={model.endTime === null ? -1 : model.endTime}
              onChange={(e) => {
                const val = Number(e.target.value) || 0;
                setModel((p) => {
                  return {
                    ...p,
                    endTime: val === -1 ? (null as any) : val,
                  };
                });
              }}
            >
              <option value={-1}>None</option>
              {Array.from({ length: 24 }, (_, i) => i).map((i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <div className="mb-2">
            <strong>Active</strong>
          </div>
          <div className="flex flex-center">
            <SwitchButton
              checked={model.active}
              disabled={saving}
              onChange={(val) => {
                setModel((data) => {
                  return {
                    ...data,
                    active: val,
                  };
                });
              }}
            ></SwitchButton>
          </div>
        </div>
      </div>
    </FormModal>
  );
}

export default MonitorRuleDialog;
