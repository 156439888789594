import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import CommonSpinner from "../components/common/CommonSpinner";
import ConfirmDialog from "../components/common/ConfirmDialog";
import DateRangePicker, {
  DatePeriod,
} from "../components/common/DateRangePicker";
import DocEditor from "../components/common/DocEditor";
import FormModal from "../components/common/FormModal";
import SwitchButton from "../components/common/SwitchButton";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import toastStore from "../stores/ToastStore";

const TEMPLATES = [
  {
    label: "Announcement",
    value: "AnnouncementTpl.html",
    type: SysModels.DashboardAnnouncementTypeEnum.Announcement,
  },
  // {
  //   label: "Featured Items",
  //   value: "FeaturedItemsTpl.html",
  //   type: SysModels.DashboardAnnouncementTypeEnum.FeaturedItems,
  // },
  {
    label: "Featured Items",
    value: "JustItemsTpl.html",
    type: SysModels.DashboardAnnouncementTypeEnum.FeaturedItems,
  },
  {
    label: "Recipe",
    value: "RecipeTpl.html",
    type: SysModels.DashboardAnnouncementTypeEnum.Recipe,
  },
];

function CustomerDashboardEdit(props: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const isNew = id === "new";

  const [loading, setLoading] = useState(false);
  const [gettingTemplate, setGettingTemplate] = useState(false);
  const [html, setHtml] = useState("");

  const updateModel = (
    data: SysModels.IDashboardAnnouncementAdminOutputDto
  ) => {
    setModel({
      ...data,
      _from: dayjs(data.showFromDate).local().format("YYYY-MM-DD"),
      _to: dayjs(data.showToDate).local().format("YYYY-MM-DD"),
    } as any);
    setHtml(data.content);
  };

  const [tpl, setTpl] = useState("RecipeTpl.html");
  const getTemplate = async () => {
    if (isNew) {
      setGettingTemplate(true);
      const req = await fetch(`/templates/${tpl}`, {});
      const rtn = await req.text();
      setTimeout(() => {
        setHtml(rtn);
        setGettingTemplate(false);
      }, 200);
    } else {
      setLoading(true);
      await SysServices.http.dashboardAnnouncement
        .get(id || "")
        .then((data) => {
          updateModel(data);
          setLoading(false);
        })
        .catch((error) => {
          toastStore.showError("Failed Getting Dashboard Announcement", error);
          navigate("/managedashboard");
        });
    }
  };

  useEffect(() => {
    getTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tpl]);

  const [activeImgEl, setActiveImgEl] = useState<Element>();
  useEffect(() => {
    if (html) {
      setTimeout(() => {
        document
          .querySelectorAll("#dashboard-template-body [data-text]")
          .forEach((data) => {
            data.setAttribute("contenteditable", "true");
          });
        document
          .querySelectorAll("#dashboard-template-body [data-image]")
          .forEach((data) => {
            data.addEventListener("click", (e) => {
              setActiveImgEl(undefined);
              setTimeout(() => {
                setActiveImgEl(data);
              }, 200);
            });
          });
      }, 500);
    }
  }, [html]);

  const getHtmlBody = () => {
    if ((html || "").trim() === "JUST ITEMS") {
      return "JUST ITEMS";
    }
    return (
      document.getElementById("dashboard-template-body")?.innerHTML || ""
    ).replaceAll(`contenteditable="true"`, ``);
  };

  const [model, setModel] = useState({
    title: "",
    content: "",
    activeYn: true,
    announcementType: SysModels.DashboardAnnouncementTypeEnum.Recipe,
  } as SysModels.IDashboardAnnouncementAdminOutputDto);
  const [itemsToAdd, setItemsToAdd] = useState<
    SysModels.IErpInventoryItemOutputDto[]
  >([]);
  const [itemsToDelete, setItemsToDelete] = useState<
    SysModels.IErpInventoryItemOutputDto[]
  >([]);

  const [saving, setSaving] = useState(false);
  const submit = async () => {
    setSaving(true);
    if (isNew) {
      await SysServices.http.dashboardAnnouncement
        .create({
          ...model,
          content: getHtmlBody(),
          itemsToAdd: itemsToAdd.map((i) => i.itemId),
        })
        .then((data) => {
          toastStore.showToast("Dashboard Announcement Saved.", "success");
          navigate("/managedashboard");
        })
        .catch((error) => {
          toastStore.showError("Failed Saving Dashboard Announcement", error);
          setSaving(false);
        });
    } else {
      await SysServices.http.dashboardAnnouncement
        .update(id || "", {
          ...model,
          content: getHtmlBody(),
          itemsToAdd: itemsToAdd.map((i) => i.itemId),
          itemsToDelete: itemsToDelete.map((i) => i.itemId),
        })
        .then((data) => {
          toastStore.showToast("Dashboard Announcement Updated.", "success");
          navigate("/managedashboard");
        })
        .catch((error) => {
          toastStore.showError("Failed Saving Dashboard Announcement", error);
          setSaving(false);
        });
    }
  };

  const canSave = useMemo(() => {
    return (
      model &&
      !commonService.isNullOrWhitespace(model.title) &&
      !!model.showFromDate &&
      !!model.showToDate
    );
  }, [model]);

  const [showDel, setShowDel] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const deleteItem = async () => {
    setDeleting(true);
    await SysServices.http.dashboardAnnouncement
      .delete(id || "")
      .then((data) => {
        toastStore.showToast("Dashboard Announcement Deleted.", "success");
        navigate("/managedashboard");
      })
      .catch((error) => {
        toastStore.showError("Failed Deleting Dashboard Announcement", error);
        setDeleting(false);
      });
  };

  useEffect(() => {
    if (activeImgEl) {
      document.getElementById("tplFileImageInput")?.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeImgEl]);

  const loadImage = async (file: File) => {
    const filePromise = new Promise<string>((resolve, reject) => {
      var reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result as any);
      };
      reader.readAsDataURL(file);
    });
    const url = await filePromise;
    activeImgEl?.querySelector("img")?.setAttribute("src", url);
  };

  const [htmlPreview, setHtmlPreview] = useState<string>();
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });
  const itemSearch = useFetchHelper(
    async () =>
      SysServices.http.dashboardAnnouncement.getInventoryItems(search.used),
    "Inventory Items"
  );

  useEffect(() => {
    if (search.used) {
      itemSearch.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.used]);

  return (
    <>
      {showDel && (
        <ConfirmDialog
          show={true}
          title="Confirm Delete"
          message="Do you really want to delete this Dashboard Announcement?"
          done={(rtn) => {
            if (rtn === "yes") {
              deleteItem();
            }
            setShowDel(false);
          }}
          buttons="yesno"
        ></ConfirmDialog>
      )}
      {!!htmlPreview && (
        <FormModal
          size="xl"
          isOpen={true}
          title="Preview"
          close={() => setHtmlPreview(undefined)}
          submit={() => setHtmlPreview(undefined)}
          primaryButtonOnly={true}
          submitButtonLabel="Done"
        >
          <div dangerouslySetInnerHTML={{ __html: htmlPreview }}></div>
        </FormModal>
      )}
      <div className="default-page-layout">
        <h4 className="hide-on-print">
          {isNew ? "Add" : "Edit"} Customer Dashboard
        </h4>
        <div className="mb-2 position-relative">
          {loading && <CommonSpinner message="Loading..."></CommonSpinner>}
          {!loading && (
            <>
              <div className="flex flex-wrap" style={{ gap: "20px" }}>
                <div className="flex-0" style={{ minWidth: "350px" }}>
                  <div className="bg-white p-3">
                    <input
                      key={tpl}
                      type="file"
                      accept="image/*"
                      hidden
                      id="tplFileImageInput"
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          loadImage(e.target.files[0]);
                        }
                      }}
                    />

                    {isNew && (
                      <>
                        <label className="mb-2 required-label">Template</label>
                        <div className="form-group mb-3">
                          <select
                            className="form-control"
                            value={tpl}
                            onChange={(e) => {
                              setTpl(e.target.value);
                              setModel((prev) => {
                                return {
                                  ...prev,
                                  announcementType: TEMPLATES.find(
                                    (t) => t.value === e.target.value
                                  )?.type as any,
                                };
                              });
                            }}
                          >
                            {TEMPLATES.map((tpl) => (
                              <option key={tpl.value} value={tpl.value}>
                                {tpl.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    )}

                    <label className="mb-2 required-label">Title</label>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Title"
                        value={model.title}
                        onChange={(e) => {
                          setModel((rtn) => {
                            return {
                              ...rtn,
                              title: e.target.value,
                            };
                          });
                        }}
                      />
                    </div>
                    {model.announcementType ===
                      SysModels.DashboardAnnouncementTypeEnum.FeaturedItems && (
                      <>
                        <div className="my-3">
                          <SwitchButton
                            checked={model.forLeftHandSideYn}
                            checkedLabel="Display on Left-Hand Side"
                            uncheckedLabel=""
                            onChange={(val) => {
                              setModel((rtn) => {
                                return {
                                  ...rtn,
                                  forLeftHandSideYn: val,
                                };
                              });
                            }}
                          ></SwitchButton>
                        </div>
                      </>
                    )}

                    <label className="mb-2 required-label">Active Dates</label>
                    <div>
                      <DateRangePicker
                        from={(model as any)._from}
                        to={(model as any)._to}
                        periods={[DatePeriod.Custom]}
                        onChanged={(from, to) => {
                          setModel((rtn) => {
                            return {
                              ...rtn,
                              _from: from as any,
                              showFromDate: from as any,
                              _to: to as any,
                              showToDate: to as any,
                            };
                          });
                        }}
                      ></DateRangePicker>
                    </div>

                    <div className="py-4">
                      <SwitchButton
                        checked={model.activeYn}
                        checkedLabel="Active"
                        uncheckedLabel="Inactive"
                        onChange={(val) => {
                          setModel((rtn) => {
                            return {
                              ...rtn,
                              activeYn: val,
                            };
                          });
                        }}
                      ></SwitchButton>
                    </div>

                    {!isNew && model.lastUpdatedBy && model.lastUpdatedOn && (
                      <div className="mt-1">
                        <label>Last Modified:</label>
                        <div>
                          By <strong>{model.lastUpdatedBy}</strong> on{" "}
                          <small>
                            <strong className="txt-grey">
                              {commonService.toDateString(
                                model.lastUpdatedOn,
                                "full"
                              )}
                            </strong>
                          </small>
                        </div>
                      </div>
                    )}

                    <div className="mt-4 text-right">
                      {!isNew && (
                        <Button
                          variant="danger"
                          type="button"
                          className="me-2 pull-left"
                          disabled={saving || deleting}
                          onClick={(e) => setShowDel(true)}
                        >
                          {deleting ? "Deleting..." : "Delete"}
                        </Button>
                      )}

                      <Button
                        variant="secondary"
                        type="button"
                        className="me-2"
                        onClick={(e) => navigate("/managedashboard")}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        type="button"
                        onClick={submit}
                        disabled={saving || deleting || !canSave}
                      >
                        {saving ? "Saving..." : "Save"}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-column flex-1 position-relative">
                  {gettingTemplate && (
                    <div className="flex-0">
                      <div className="position-relative p-1">
                        <CommonSpinner></CommonSpinner>
                      </div>
                    </div>
                  )}
                  {!gettingTemplate && (
                    <>
                      {(html || "").trim() === "JUST ITEMS" && (
                        <div className="flex-0">
                          <div className="flex-1 bg-white p-3">
                            No Contents, this will only display featured
                            item(s).
                          </div>
                        </div>
                      )}
                      {(html || "").trim() !== "JUST ITEMS" && (
                        <>
                          <Button
                            variant="secondary"
                            onClick={(e) => {
                              setHtmlPreview(getHtmlBody());
                            }}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              zIndex: 2,
                            }}
                          >
                            Preview
                          </Button>
                          <div
                            id="dashboard-template-body"
                            className="flex-0"
                            dangerouslySetInnerHTML={{ __html: html }}
                          ></div>
                        </>
                      )}

                      {model.announcementType ===
                        SysModels.DashboardAnnouncementTypeEnum.Recipe && (
                        <>
                          <div className="flex-1 bg-white p-3 mt-3">
                            <h5 className="mb-4">Recipe Instructions</h5>
                            <DocEditor
                              onChange={(data) => {
                                setModel((p) => {
                                  return {
                                    ...p,
                                    recipeInstructions: data,
                                  };
                                });
                              }}
                              content={model.recipeInstructions}
                            ></DocEditor>
                          </div>
                        </>
                      )}
                      <div className="flex-1 bg-white p-3 mt-3">
                        <h5>
                          {model.announcementType ===
                          SysModels.DashboardAnnouncementTypeEnum.Recipe
                            ? "Featured Recipe Items"
                            : "Featured Items"}
                        </h5>

                        <div className="input-group search-box">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Search Items to Add..."
                            value={search.typed}
                            onChange={(e) => {
                              setSearch((data) => {
                                return {
                                  ...data,
                                  typed: e.target.value,
                                };
                              });
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                e.stopPropagation();
                                setSearch((data) => {
                                  if (data.used === data.typed) {
                                    return data;
                                  }
                                  return {
                                    ...data,
                                    used: data.typed,
                                  };
                                });
                              }
                            }}
                          ></input>
                          <div className="input-group-append">
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={(e) => {
                                setSearch((data) => {
                                  if (data.used === data.typed) {
                                    return data;
                                  }
                                  return {
                                    ...data,
                                    used: data.typed,
                                  };
                                });
                              }}
                            >
                              <i className="fa fa-search"></i>
                            </button>
                            <button
                              className="btn btn-secondary"
                              type="button"
                              onClick={(e) => {
                                setSearch((data) => {
                                  return { typed: "", used: "" };
                                });
                              }}
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          </div>
                        </div>
                        <div className="flex flex-wrap gap-10 mt-3">
                          <div className="flex-1">
                            <table className="table table-sm table-bordered">
                              <thead>
                                <tr>
                                  <th>Search Results</th>
                                  <th style={{ width: "50px" }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {itemSearch.status ===
                                  FetchStatus.InProgress && (
                                  <>
                                    <tr>
                                      <td colSpan={2}>
                                        <CommonSpinner></CommonSpinner>
                                      </td>
                                    </tr>
                                  </>
                                )}
                                {itemSearch.status !==
                                  FetchStatus.InProgress && (
                                  <>
                                    {itemSearch?.data?.map((item, i) => (
                                      <tr key={item.itemId + i}>
                                        <td>
                                          <small>{item.itemId}</small>
                                          <div>{item.description}</div>
                                        </td>
                                        <td
                                          className="text-center"
                                          style={{
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          {[
                                            ...(model?.erpInventoryItemOutputDtos ||
                                              []),
                                            ...itemsToAdd,
                                          ].find(
                                            (i) => i.itemId === item.itemId
                                          ) ? (
                                            <>
                                              <i className="fa fa-check text-success"></i>
                                            </>
                                          ) : (
                                            <>
                                              <i
                                                title="Add"
                                                className="fa fa-plus text-primary pointer"
                                                onClick={(e) => {
                                                  setItemsToAdd((list) => {
                                                    return [...list, item];
                                                  });
                                                }}
                                              ></i>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                    {!itemSearch?.data?.length && (
                                      <tr>
                                        <td colSpan={2}>
                                          Search for items to add...
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="flex-1">
                            <table className="table table-sm table-bordered">
                              <thead>
                                <tr>
                                  <th>Existing Items</th>
                                  <th style={{ width: "50px" }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {[
                                  ...(model?.erpInventoryItemOutputDtos || []),
                                  ...itemsToAdd,
                                ]?.map((item) => (
                                  <tr key={item.itemId}>
                                    <td>
                                      {itemsToDelete.find(
                                        (i) => i.itemId === item.itemId
                                      ) ? (
                                        <i
                                          className="text-danger"
                                          style={{
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          <small>{item.itemId}</small>
                                          <div>{item.description}</div>
                                        </i>
                                      ) : (
                                        <>
                                          <small>{item.itemId}</small>
                                          <div>{item.description}</div>
                                        </>
                                      )}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      {itemsToDelete.find(
                                        (i) => i.itemId === item.itemId
                                      ) ? (
                                        <i
                                          className="fa fa-undo pointer text-primary"
                                          title="Undo"
                                          onClick={(e) => {
                                            setItemsToDelete((list) => {
                                              return list.filter(
                                                (i) => i.itemId !== item.itemId
                                              );
                                            });
                                          }}
                                        ></i>
                                      ) : (
                                        <i
                                          className="fa fa-trash pointer text-danger"
                                          title="Remove"
                                          onClick={(e) => {
                                            setItemsToDelete((list) => {
                                              return [...list, item];
                                            });
                                          }}
                                        ></i>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                                {!model?.erpInventoryItemOutputDtos?.length &&
                                  !itemsToAdd.length && (
                                    <tr>
                                      <td colSpan={2}>No Item(s)</td>
                                    </tr>
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CustomerDashboardEdit;
