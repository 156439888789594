import { useEffect, useState } from "react";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import BatchRequestInvoicesWidget from "./widgets/BatchRequestInvoicesWidget";
import CommonSpinner from "../components/common/CommonSpinner";
import BatchRequestInvoiceDialog from "./dialogs/BatchRequestInvoiceDialog";
import BatchRequestCreditMemoDialog from "./dialogs/BatchRequestCreditMemoDialog";

function BatchRequestInvoices(props: any) {
  const buyingGroups = useFetchHelper(
    SysServices.http.siteManagement.getAllBuyingGroups,
    "Buying Grops"
  );

  useEffect(() => {
    buyingGroups.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showInvDialog, setShowInvDialog] = useState(false);
  const [showCredDialog, setShowCredDialog] = useState(false);

  return (
    <>
      {showInvDialog && (
        <BatchRequestInvoiceDialog
          onClose={() => {
            setShowInvDialog(false);
          }}
        ></BatchRequestInvoiceDialog>
      )}
      {showCredDialog && (
        <BatchRequestCreditMemoDialog
          onClose={() => {
            setShowCredDialog(false);
          }}
        ></BatchRequestCreditMemoDialog>
      )}
      <div className="default-page-layout">
        <h4>Batch Request Invoices</h4>
      </div>
      {buyingGroups.status === FetchStatus.InProgress && (
        <div>
          <CommonSpinner></CommonSpinner>
        </div>
      )}
      {buyingGroups.status === FetchStatus.Complete && (
        <div className="row">
          <div className="cols-sm-12 col-md-12 col-lg-6">
            <BatchRequestInvoicesWidget
              title="Resubmit Invoices to Buying Groups"
              object="Invoices"
              action={SysServices.http.siteManagement.batchResubmitInvoices}
              groups={[]}
              autoFocus={true}
            ></BatchRequestInvoicesWidget>

            {/* <BatchRequestInvoicesWidget
              title="Rerequest Sales Orders from NetSuite"
              object="Sales Orders"
              action={
                SysServices.http.siteManagement
                  .batchReRequestSalesOrdersFromNetsuite
              }
              groups={buyingGroups.data || []}
            ></BatchRequestInvoicesWidget> */}

            <BatchRequestInvoicesWidget
              title="Rerequest Credit Memos from NetSuite"
              object="Credit Memos"
              action={
                SysServices.http.siteManagement
                  .batchReRequestCreditMemosFromNetsuite
              }
              groups={buyingGroups.data || []}
              viewStatus={() => {
                setShowCredDialog(true);
              }}
            ></BatchRequestInvoicesWidget>
          </div>
          <div className="cols-sm-12 col-md-12 col-lg-6">
            <BatchRequestInvoicesWidget
              title="Rerequest Invoices from NetSuite"
              object="Invoices"
              action={
                SysServices.http.siteManagement
                  .batchReRequestInvoicesFromNetsuite
              }
              groups={buyingGroups.data || []}
              viewStatus={() => {
                setShowInvDialog(true);
              }}
            ></BatchRequestInvoicesWidget>
          </div>
        </div>
      )}
    </>
  );
}

export default BatchRequestInvoices;
