import React from "react";
import IncomingOrdersInErrorWdiget from "../widgets/IncomingOrdersInErrorWdiget";
import RecentIncomingOrdersWidget from "../widgets/RecentIncomingOrdersWidget";
import RecentlyViewedCustomersWidget from "../widgets/RecentlyViewedCustomersWidget";

function CustomerServiceDashboard(props: any) {
  return (
    <>
      <div className="default-page-layout hide-on-print">
        <h4>Customer Service Dashboard</h4>
      </div>

      <div className="row">
        <div className="cols-sm-12 col-md-12 col-lg-6">
          <IncomingOrdersInErrorWdiget></IncomingOrdersInErrorWdiget>
          <RecentIncomingOrdersWidget></RecentIncomingOrdersWidget>
        </div>
        <div className="cols-sm-12 col-md-12 col-lg-6">
          <RecentlyViewedCustomersWidget></RecentlyViewedCustomersWidget>
        </div>
      </div>
    </>
  );
}

export default CustomerServiceDashboard;
