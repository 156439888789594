import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown, ModalBody, Spinner } from "react-bootstrap";
import FormModal from "../../components/common/FormModal";
import SysServices from "../../services";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import SysModels from "../../models";
import toastStore from "../../stores/ToastStore";
import SwitchButton from "../../components/common/SwitchButton";
import CommonSpinner from "../../components/common/CommonSpinner";
import commonService from "../../services/CommonService";
import ConfirmDialog from "../../components/common/ConfirmDialog";
import ReleaseNotePreviewDialog from "./ReleaseNotePreviewDialog";
import DocEditor from "../../components/common/DocEditor";

function ReleaseNoteDialog(props: {
  id?: string;
  duplicate?: boolean;
  onClose: (saved: boolean) => void;
}) {
  const [docId, setDocId] = useState(props.id);
  const [duplicate, setDuplicate] = useState(props.duplicate || false);

  const [saved, setSaved] = useState(false);
  const docObject = useFetchHelper(async () => {
    return SysServices.http.siteDocumentation.get(docId || "");
  }, "Release Note");

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    if (docId) {
      docObject.getData();
    }
    document.getElementById("inputDocTitle")?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calledOnce, docObject]);

  const [model, setModel] = useState<SysModels.ISiteDocumentationInputDto>({
    activeYn: true,
    siteDocumentationType: SysModels.SiteDocumentationTypeEnum.Customer,
  } as any);

  const [initialFocus, setInitialFocus] = useState(false);
  useEffect(() => {
    if (docObject.status === FetchStatus.Complete && docObject.data) {
      if (duplicate) {
        setModel({
          ...docObject.data,
          sequence: undefined as any,
        });
      } else {
        setModel(docObject.data);
      }
      setContent(docObject.data.contentDraft);

      if (!initialFocus) {
        setTimeout(() => {
          const el = document.getElementById("inputDocTitle");
          if (el) {
            //Focus and Move cursor to end of text
            el.focus();
            (el as any).value = "";
            (el as any).value = docObject.data?.title || "";
            setInitialFocus(true);
          }
        }, 200);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docObject.status, docObject.data]);

  const [saving, setSaving] = useState(false);
  const saveDoc = async () => {
    if (isProcessing() || !allowSave() || !hasChanges()) {
      //do nothing...
      return;
    }

    const saveRequest =
      docId && !duplicate
        ? SysServices.http.siteDocumentation.update(docId, {
            ...model,
            contentDraft: content,
          })
        : SysServices.http.siteDocumentation.create({
            ...model,
            contentDraft: content,
          });

    setSaving(true);
    await saveRequest
      .then((data) => {
        setSaved(true);
        setSaving(false);
        setDuplicate(false);
        if (!docId) {
          setDocId(data.sqlId);
          setModel(data);
        }
        docObject.setData(data);
        toastStore.showToast("Release Note Saved", "success");
      })
      .catch((error) => {
        toastStore.showError("Failed Saving Release Note", error);
        setSaving(false);
      });
  };

  const allowSave = () => {
    return (
      !!`${model.title || ""}`.trim() &&
      !!`${model.sequence || ""}`.trim() &&
      !!`${content || ""}`.trim()
    );
  };

  const [content, setContent] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const deleteDocument = async () => {
    setDeleting(true);
    await SysServices.http.siteDocumentation
      .delete(docId || "")
      .then((data) => {
        toastStore.showToast("Release Note deleted.", "success");
        props.onClose(true);
      })
      .catch((error) => {
        toastStore.showError("Failed to Delete Release Note", error);
        setDeleting(false);
      });
  };

  const [confirmPublish, setConfirmPublish] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const publishReleaseNote = async () => {
    setPublishing(true);
    await SysServices.http.siteDocumentation
      .publish(docId || "", {
        active: model.activeYn,
      })
      .then((data) => {
        toastStore.showToast("Release Note Published.", "success");
        setSaved(true);
        setPublishing(false);
        docObject.getData();
      })
      .catch((error) => {
        toastStore.showError("Failed to Publish Release Note", error);
        setPublishing(false);
      });
  };

  const hasChanges = () => {
    if (docObject.data && model) {
      return !(
        docObject.data.title === model.title &&
        docObject.data.subjectDraft === model.subjectDraft &&
        Number(docObject.data.sequence) === Number(model.sequence) &&
        //document.data.activeYn === model.activeYn &&
        docObject.data.contentDraft === content
      );
    }
    if (!docObject.data && model) {
      return !!(model.title || model.subjectDraft || model.sequence || content);
    }
    return false;
  };

  const isProcessing = (notGetting = false) => {
    return (
      saving ||
      deleting ||
      publishing ||
      (docObject.status === FetchStatus.InProgress && !notGetting)
    );
  };

  const [showPreview, setShowPreview] = useState({
    show: false,
    print: false,
    published: false,
  });
  const [confirmClose, setConfirmClose] = useState(false);

  const [isSavingStatus, setIsSavingStatus] = useState(false);
  const toggleActiveValue = async (active: boolean) => {
    if (docId && !duplicate) {
      setIsSavingStatus(true);
      await SysServices.http.siteDocumentation
        .setActiveStatus(docId || "", { active: active })
        .then((data) => {
          toastStore.showToast("Release Note Status Updated.", "success");
          setIsSavingStatus(false);
        })
        .catch((error) => {
          toastStore.showError("Failed to updating Release Note status", error);
          setIsSavingStatus(false);
        });
    }
  };

  const [expand, setExpand] = useState(false);
  const [docEditorDialogOpen, setDocEditorDialogOpen] = useState(false);

  const getTypeName = useMemo(() => {
    if (
      model.siteDocumentationType ===
      SysModels.SiteDocumentationTypeEnum.Employee
    ) {
      return "Employee";
    }
    if (
      model.siteDocumentationType ===
      SysModels.SiteDocumentationTypeEnum.Customer
    ) {
      return "Customer";
    }
    return "Portal Documentation";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.siteDocumentationType]);

  return (
    <>
      <ConfirmDialog
        show={confirmDelete}
        buttons="yesno"
        title="Delete Release Note"
        message={`Are you sure you want to delete this Release Note?`}
        done={(rtn) => {
          if (rtn === "yes") {
            deleteDocument();
          }
          setConfirmDelete(false);
        }}
      ></ConfirmDialog>

      <ConfirmDialog
        show={confirmPublish}
        buttons="yesno"
        title="Publish Release Note"
        message={`Are you sure you want to publish this Release Note?`}
        done={(rtn) => {
          if (rtn === "yes") {
            publishReleaseNote();
          }
          setConfirmPublish(false);
        }}
      ></ConfirmDialog>

      <ConfirmDialog
        show={confirmClose}
        buttons="yesno"
        title="Discard Unsaved Changes"
        message="You have unsaved changes. Are you sure you want to close?"
        done={(rtn) => {
          if (rtn === "yes") {
            props.onClose(saved);
          }
          setConfirmClose(false);
        }}
      ></ConfirmDialog>

      {showPreview.show && (
        <ReleaseNotePreviewDialog
          id={docId || ""}
          title={model.title}
          subject={model.subjectDraft}
          content={showPreview.published ? "" : content}
          print={showPreview.print}
          published={showPreview.published}
          onClose={() => {
            setShowPreview({ show: false, print: false, published: false });
          }}
        ></ReleaseNotePreviewDialog>
      )}

      <FormModal
        title={
          duplicate
            ? "Duplicate Release Note"
            : docId
            ? "Edit Release Note"
            : "Add Release Note"
        }
        isOpen={true}
        close={() => {
          if (hasChanges()) {
            setConfirmClose(true);
          } else {
            props.onClose(saved);
          }
        }}
        onControlSave={() => {
          saveDoc();
        }}
        customBodyFooter={true}
        moveBehind={
          showPreview.show ||
          confirmDelete ||
          confirmPublish ||
          confirmClose ||
          docEditorDialogOpen
        }
        size="xl"
        dialogClassName="dialog-width-90perc"
        enforceFocus={false}
      >
        <ModalBody className="mb-4">
          <div className="flex flex-wrap gap-10 mb-3">
            {docId && !duplicate && (
              <Dropdown>
                <Dropdown.Toggle
                  disabled={isProcessing()}
                  size="sm"
                  id={`dropdownDocDialog`}
                  variant="outline-primary"
                >
                  <i className="me-2 fa fa-bars"></i>
                  {publishing ? "Publishing..." : "More"}
                </Dropdown.Toggle>
                <Dropdown.Menu align="left">
                  <Dropdown.Item
                    disabled={hasChanges()}
                    onClick={() => {
                      setConfirmPublish(true);
                    }}
                  >
                    <i className="fa fa-newspaper-o me-2"></i>
                    Publish {hasChanges() ? "(unsaved)" : ""}
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={!docObject.data?.publishedOn}
                    onClick={() => {
                      setShowPreview({
                        show: true,
                        print: false,
                        published: true,
                      });
                    }}
                  >
                    <i className="fa fa-television me-2"></i>
                    Preview Published
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setShowPreview({
                        show: true,
                        print: false,
                        published: false,
                      });
                    }}
                  >
                    <i className="fa fa-television me-2"></i>
                    Preview Draft
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={!docObject.data?.publishedOn}
                    onClick={() => {
                      setShowPreview({
                        show: true,
                        print: true,
                        published: true,
                      });
                    }}
                  >
                    <i className="fa fa-print me-2"></i> Print Published
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setShowPreview({
                        show: true,
                        print: true,
                        published: false,
                      });
                    }}
                  >
                    <i className="fa fa-print me-2"></i> Print Draft
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}

            {docId && !duplicate && (
              <Button
                className="no-wrap"
                type="button"
                onClick={() => {
                  setConfirmDelete(true);
                }}
                variant="danger"
                size="sm"
                disabled={isProcessing()}
              >
                <i className="me-2 fa fa-trash"></i>
                {deleting ? "Deleting..." : "Delete"}
              </Button>
            )}

            <div className="flex-1">
              <Button
                className="no-wrap"
                type="button"
                onClick={() => {
                  saveDoc();
                }}
                variant="primary"
                size="sm"
                disabled={isProcessing() || !allowSave() || !hasChanges()}
              >
                <i className="me-2 fa fa-save"></i>
                {saving ? "Saving Document..." : "Save (Ctrl+S)"}
              </Button>
            </div>

            <Button
              type="button"
              onClick={() => {
                if (hasChanges()) {
                  setConfirmClose(true);
                } else {
                  props.onClose(saved);
                }
              }}
              variant="secondary"
              size="sm"
              disabled={docObject.status === FetchStatus.InProgress}
            >
              Close
            </Button>
          </div>

          {docObject.status === FetchStatus.InProgress && (
            <CommonSpinner></CommonSpinner>
          )}
          {isProcessing(true) && <CommonSpinner overlay={true}></CommonSpinner>}
          <div
            className={`row ${
              docObject.status !== FetchStatus.InProgress || !docId
                ? ""
                : "display-none"
            }`}
          >
            <div
              className={`col-sm-12 col-md-4 col-lg-3 ${
                expand ? "display-none" : ""
              }`}
            >
              <div className="mb-1 mt-2">
                <strong>
                  Title <span className="required-label">*</span>
                </strong>
              </div>
              <input
                id="inputDocTitle"
                className="form-control mb-3"
                placeholder="Title"
                type="text"
                defaultValue={model.title}
                onChange={(e) => {
                  setModel((data) => {
                    return {
                      ...data,
                      title: e.target.value,
                    };
                  });
                }}
              ></input>

              <div className="mb-1">
                <strong>Short Description</strong>
              </div>
              <textarea
                className="form-control mb-3"
                placeholder="Short Description"
                rows={3}
                defaultValue={model.subjectDraft}
                onChange={(e) => {
                  setModel((data) => {
                    return {
                      ...data,
                      subjectDraft: e.target.value,
                    };
                  });
                }}
              ></textarea>

              <div className="row">
                <div className="col-sm-12 py-1 pb-4">
                  <div className="pb-2">
                    <strong>Type</strong>
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-secondary">
                      {getTypeName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setModel((data) => {
                            return {
                              ...data,
                              siteDocumentationType:
                                SysModels.SiteDocumentationTypeEnum.Customer,
                            };
                          });
                        }}
                      >
                        <span>Customer</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setModel((data) => {
                            return {
                              ...data,
                              siteDocumentationType:
                                SysModels.SiteDocumentationTypeEnum.Employee,
                            };
                          });
                        }}
                      >
                        <span>Employee</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setModel((data) => {
                            return {
                              ...data,
                              siteDocumentationType:
                                SysModels.SiteDocumentationTypeEnum
                                  .PortalDocumentation,
                            };
                          });
                        }}
                      >
                        <span>Portal Documentation</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 col-md-6">
                  <div className="mb-1">
                    <strong>
                      Sequence <span className="required-label">*</span>
                    </strong>
                  </div>
                  <input
                    className="form-control mb-3"
                    placeholder="Sequence"
                    type="number"
                    defaultValue={model.sequence}
                    onChange={(e) => {
                      setModel((data) => {
                        return {
                          ...data,
                          sequence:
                            Number(e.target.value) || (undefined as any),
                        };
                      });
                    }}
                  ></input>
                </div>

                <div className="col-sm-6 col-md-6">
                  <div className="mb-2">
                    <strong>Active</strong>
                  </div>
                  <div className="flex flex-center">
                    <div>
                      <SwitchButton
                        checked={model.activeYn}
                        disabled={isSavingStatus}
                        onChange={(val) => {
                          setModel((data) => {
                            return {
                              ...data,
                              activeYn: val,
                            };
                          });
                          toggleActiveValue(val);
                        }}
                      ></SwitchButton>
                    </div>
                    <div className="mx-2">
                      {isSavingStatus && (
                        <Spinner
                          animation="grow"
                          variant="success"
                          size="sm"
                        ></Spinner>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {docObject.data && !duplicate && (
                <>
                  <div className="row">
                    <div className="col-sm-12 mt-3">
                      <div className="mb-1">
                        <strong>Last Updated On</strong>
                      </div>
                      {docObject.data.lastUpdatedOn &&
                        commonService.toLocalDate(
                          docObject.data.lastUpdatedOn,
                          "full"
                        )}
                    </div>

                    <div className="col-sm-12 mt-3">
                      <div className="mb-2">
                        <strong>Last Updated By</strong>
                      </div>
                      {docObject.data.lastUpdatedBy}
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-sm-12 mt-3">
                      <div className="mb-1">
                        <strong>Published On</strong>
                      </div>
                      {docObject.data.publishedOn
                        ? commonService.toLocalDate(
                            docObject.data.publishedOn,
                            "full"
                          )
                        : "Unpublished"}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className={`col-sm-12 ${expand ? "" : "col-md-8 col-lg-9"}`}>
              <div className="mb-1 flex flex-center gap-10">
                <Button
                  type="button"
                  variant={expand ? "danger" : "light"}
                  size="sm"
                  onClick={(e) => {
                    setExpand(!expand);
                  }}
                  className="me-1"
                >
                  {!expand && <i className="fa fa-expand text-primary"></i>}
                  {expand && <i className="fa fa-compress"></i>}
                </Button>
                <strong className="flex-1">
                  Content <span className="required-label">*</span>
                </strong>
              </div>
              <div className="document-body mb-3">
                <div>
                  <DocEditor
                    content={model.contentDraft}
                    onChange={(val) => {
                      setContent(val);
                    }}
                    onSave={() => {
                      saveDoc();
                    }}
                    onDialogOpen={(open) => {
                      setDocEditorDialogOpen(open);
                    }}
                  ></DocEditor>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </FormModal>
    </>
  );
}

export default ReleaseNoteDialog;
