import { reaction } from "mobx";
import { useEffect, useState } from "react";
import systemStore from "../stores/SystemStore";
import commonService from "./CommonService";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(systemStore.windowSize);
  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
        setWindowSize(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { windowSize, isSmallScreen };
};

export const useUserRole = () => {
  const [userRole, setUserRole] = useState(systemStore.extension_MWFRole);

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.extension_MWFRole,
      (n, p, i) => {
        //console.log(`USER ROLE: ${n}`);
        setUserRole(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return userRole;
};

export const useTimer = () => {
  const [tmoSystemMessage, setTmoSystemMessage] = useState(
    systemStore.tmoSystemMessage
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.tmoSystemMessage,
      (n, p, i) => {
        setTmoSystemMessage(n);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return tmoSystemMessage;
};
