import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../../components/common/CommonSpinner";
import SysModels from "../../models";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import CustomerLinkComponent from "../common/CustomerLinkComponent";
import IncomingOrderDialog from "../dialogs/IncomingOrderDialog";

function RecentIncomingOrdersWidget(props: any) {
  const navigate = useNavigate();

  const [asOfDate, setAsOfDate] = useState(new Date());
  const recentOrders = useFetchHelper(
    async () =>
      SysServices.http.siteManagement.listIncomingOrders(1, 5, {
        sortBy: SysModels.IncomingOrderSortByEnum.PlacedOn,
        orderBy: SysModels.OrderByEnum.Descending,
        filterBy: SysModels.IncomingOrderFilterByEnum.All,
        startDate: null as any,
        endDate: null as any,
        search: "",
      }),
    "Recent Incoming Orders"
  );

  const statuses = useFetchHelper(
    async () =>
      SysServices.http.genericEnumLookup.get("IncomingOrderFilterByEnum"),
    "Statuses"
  );

  useEffect(() => {
    recentOrders.getData();
    statuses.getData();
    setAsOfDate(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [detail, setDetail] = useState<SysModels.IOrderHeaderOutputDto>();

  return (
    <>
      {!!detail && (
        <IncomingOrderDialog
          detail={detail}
          print={false}
          onClose={() => {
            setDetail(undefined);
          }}
        ></IncomingOrderDialog>
      )}
      <div className="hide-on-print">
        <div className="bg-white mb-4">
          <div className="p-3 bg-blue txt-light">
            <h5 className="m-0 flex flex-center">
              <em className="zmdi zmdi-shopping-cart me-2"></em>
              <span className="flex-1">
                <span className="me-2">Recent Incoming Orders</span>{" "}
                <small className="no-wrap">
                  {dayjs(asOfDate).format("MMM DD YYYY, HH:mm A")}
                </small>
              </span>
              <i
                className="fa fa-refresh pointer"
                onClick={(e) => {
                  if (recentOrders.status !== FetchStatus.InProgress) {
                    setAsOfDate(new Date());
                    recentOrders.getData();
                  }
                }}
              ></i>
            </h5>
          </div>
          <div className="p-3 overflow-auto min-height-100 position-relative">
            {recentOrders.status === FetchStatus.InProgress && (
              <CommonSpinner overlay={true}></CommonSpinner>
            )}
            {recentOrders.status !== FetchStatus.Failed && (
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Customer</th>
                    <th className="no-wrap">BG Order No.</th>
                    <th className="text-right">Total</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {recentOrders?.data?.orderHeaderOutputDtos
                    ?.sort(commonService.sortByNumericProperty("sqlId", true))
                    ?.map((order, i) => (
                      <tr key={`${order.sqlId}-${i}`}>
                        <td>
                          <small>
                            <CustomerLinkComponent
                              name={order.customerName}
                              number={order.customerAccountNo}
                              numberPartOfName={true}
                            ></CustomerLinkComponent>
                          </small>
                        </td>
                        <td>
                          <small>
                            <a
                              href="/"
                              className="mail-link"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setDetail(order);
                              }}
                            >
                              {order.externalOrderNo}
                            </a>
                          </small>
                        </td>
                        <td className="text-right">
                          {commonService.toMoney(order.orderAmount)}
                        </td>
                        <td>
                          <small>
                            {
                              statuses?.data?.find(
                                (s) => s.value === order.recordStatus
                              )?.label
                            }
                          </small>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            <div className="text-right">
              <small>
                <a
                  className="mail-link"
                  href="/incomingorders"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate("/incomingorders");
                  }}
                >
                  View All Orders
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecentIncomingOrdersWidget;
