import React from "react";
import OrderSummaryWidget from "../../pages/widgets/OrderSummaryWidget";
import SysServices from "../../services";

function EmployeeOrderSummaryWidget(props: any) {
  return (
    <>
      <OrderSummaryWidget
        tabs={["percentByQuantity", "percentByAmount"]}
        action={SysServices.http.customer.executiveCustomerOrderingSummary}
        titleAsWidget="Order Summary"
      ></OrderSummaryWidget>
    </>
  );
}

export default EmployeeOrderSummaryWidget;
