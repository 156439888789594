import FileSaver from "file-saver";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonSpinner from "../../components/common/CommonSpinner";
import DateRangePicker, {
  DatePeriod,
  GetPeriodValues,
} from "../../components/common/DateRangePicker";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import toastStore from "../../stores/ToastStore";
import CustomerLinkComponent from "../common/CustomerLinkComponent";
import CustomerQueryFilters, {
  ICustomerSearchModel,
  ISelectedCustomerModel,
} from "./filters/CustomerQueryFilters";

function TotalSpendBySourceSummaryReport(props: any) {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [period, setPeriod] = useState<{
    from: string | null;
    to: string | null;
  }>({
    from: GetPeriodValues(DatePeriod.LastMonth).from,
    to: GetPeriodValues(DatePeriod.LastMonth).to,
  });

  const grid = useFetchHelper(
    async () =>
      SysServices.http.reportView.totalSpendBySourceSummary({
        customers: selectedCustomers.map((c) => c.id),
        fromDate: period.from as any,
        toDate: period.to as any,
      }),
    "Records"
  );

  useEffect(() => {
    if (selectedCustomers.length > 1) {
      grid.getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  useEffect(() => {
    if (grid.status === FetchStatus.Failed && grid.error?.code === 403) {
      navigate("/reports");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grid.status]);

  const [downloading, setDownloading] = useState(false);
  const startDownload = async () => {
    setDownloading(true);
    await SysServices.http.reportView
      .totalSpendBySourceSummaryCsv({
        customers: selectedCustomers.map((c) => c.id),
        fromDate: period.from as any,
        toDate: period.to as any,
      })
      .then((data) => {
        setDownloading(false);
        const file = commonService.b64toBlob(data.fileContents, "text/csv");
        FileSaver.saveAs(file, data.fileDownloadName);
      })
      .catch((err) => {
        setDownloading(false);
        toastStore.showError("Download Failed", err);
      });
  };

  const [search, setSearch] = useState<ICustomerSearchModel>({
    typed: "",
    used: "",
  });

  const [selectedCustomers, setSelectedCustomers] = useState<
    ISelectedCustomerModel[]
  >([]);

  return (
    <div className="default-page-layout">
      {downloading && (
        <CommonSpinner overlay={true} message="Downloading..."></CommonSpinner>
      )}

      <h4 className="hide-on-print">Total Spend by Source Summary</h4>

      <div className="bg-white">
        <div
          className={`print-wrapper hide-on-print flex flex-center flex-wrap gap-15 m-0 ${
            grid.status === FetchStatus.InProgress ? "display-none" : ""
          }`}
        >
          <div className="flex">
            <DateRangePicker
              from={period.from}
              to={period.to}
              onChanged={(from, to) => {
                setPeriod({ from: from, to: to });
              }}
              requireBothDates={true}
              periods={[
                DatePeriod.LastMonth,
                DatePeriod.ThisYear,
                DatePeriod.LastYear,
                DatePeriod.ThisMonth,
                DatePeriod.ThisWeek,
                DatePeriod.LastWeek,
                DatePeriod.Custom,
              ]}
            ></DateRangePicker>
          </div>
          <div className="flex-1 now-wrap p-2">
            <a
              href="/"
              className="no-decoration no-wrap me-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.print();
              }}
            >
              <i className="fa fa-print"></i> Print
            </a>{" "}
            <a
              href="/"
              className="no-decoration no-wrap"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                startDownload();
              }}
            >
              <i className="fa fa-download"></i> Download CSV
            </a>
          </div>

          <div className="flex-0 w-100">
            <div className="input-group search-box">
              <input
                className="form-control"
                type="text"
                placeholder="Search Customer"
                value={search.typed}
                onChange={(e) => {
                  setSearch((data) => {
                    return {
                      ...data,
                      typed: e.target.value,
                    };
                  });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                    setSearch((data) => {
                      if (data.used === data.typed) {
                        return data;
                      }
                      return {
                        ...data,
                        used: data.typed,
                      };
                    });
                  }
                }}
              ></input>
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={(e) => {
                    setSearch((data) => {
                      if (data.used === data.typed) {
                        return data;
                      }
                      return {
                        ...data,
                        used: data.typed,
                      };
                    });
                  }}
                >
                  <i className="fa fa-search"></i>
                </button>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={(e) => {
                    setSearch((data) => {
                      return { typed: "", used: "" };
                    });
                  }}
                >
                  <i className="fa fa-times"></i>
                </button>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={(e) => {
                    if (selectedCustomers.length > 1) {
                      grid.getData();
                    } else {
                      toastStore.showToast(
                        "You must request more than one customer.",
                        "warning"
                      );
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap spendbysource-report">
          <div className="flex-1 overflow-auto" style={{ minWidth: "350px" }}>
            {grid.status === FetchStatus.Default && (
              <div className="flex-1 p-4">
                Please select at least two (2) customers then click 'Submit' to
                load report.
              </div>
            )}

            {grid.status === FetchStatus.InProgress && (
              <div className="py-4">
                <CommonSpinner></CommonSpinner>
              </div>
            )}
            {grid.status === FetchStatus.Complete && (
              <table className="table table-hover billing-table">
                <thead>
                  <tr>
                    <th className="no-wrap">Customer</th>
                    <th className="text-right no-wrap">Qty Sold</th>
                    <th className="text-right no-wrap">Weight</th>
                    <th className="text-right no-wrap">Amount</th>
                    <th className="text-right no-wrap">Avg. Price</th>
                  </tr>
                </thead>
                <tbody>
                  {grid.data?.map((customer, idx) => (
                    <React.Fragment key={customer.customerNumber}>
                      <tr>
                        <td>
                          <span className="show-on-print">
                            {customer.customerName} ({customer.customerNumber})
                          </span>
                          <span className="hide-on-print">
                            <CustomerLinkComponent
                              name={customer.customerName}
                              number={customer.customerNumber}
                            ></CustomerLinkComponent>
                          </span>
                        </td>
                        <td className="text-right no-wrap">
                          {commonService.toNumberWithComma(customer.quantity)}
                        </td>
                        <td className="text-right no-wrap">
                          {commonService.toNumberWithComma(
                            customer.purchaseWeight,
                            2
                          )}
                        </td>
                        <td className="text-right no-wrap">
                          {commonService.toMoney(customer.amount)}
                        </td>
                        <td className="text-right no-wrap">
                          {commonService.toMoney(customer.averagePrice)}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div
            className="bg-light-grey px-3 hide-on-print"
            style={{ width: "380px" }}
          >
            <CustomerQueryFilters
              reportId={id || ""}
              reportName="TotalSpendBySourceSummary"
              selectedCustomers={selectedCustomers}
              onSelectedCustomersChanged={(list) => {
                setSelectedCustomers(list);
              }}
              search={search}
            ></CustomerQueryFilters>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TotalSpendBySourceSummaryReport;
