import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableRow from "../../components/common/TableRow";
import commonService from "../../services/CommonService";
import systemStore from "../../stores/SystemStore";
import CustomerLinkComponent from "./CustomerLinkComponent";

interface ISummaryRow {
  idKey: any;
  email: string;
  thisWeekAmount: number;
  lastWeekAmount: number;
  threeWeekAmount: number;
  fourWeekAmount: number;
  fiveWeekAmount: number;
  sixWeekAmount: number;
  personName?: string;
  customerName?: string;
  erpCustomerNumber?: string;
  erpCustomerId?: number;
}

function SalesWeeklySummaryTableComponent({
  allReps,
  rows,
}: {
  allReps?: boolean;
  rows: ISummaryRow[];
}) {
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GoToDetail = (item: ISummaryRow) => {
    return (
      <a
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          navigate(`/salesrepdetail/${item.email}`);
        }}
        href={`/salesrepdetail/${item.email}`}
      >
        {item.personName}
      </a>
    );
  };

  return (
    <div className="overflow-auto">
      <table className="table">
        <thead>
          <tr>
            <th>{!allReps ? "Customer" : "Rep's Name"}</th>
            {!isSmallScreen && (
              <>
                {allReps && <th>Email</th>}
                <th className="text-right">This Week</th>
                <th className="text-right">Last Week</th>
                <th className="text-right">Three Weeks</th>
                <th className="text-right">Four Weeks</th>
                <th className="text-right">Five Weeks</th>
                <th className="text-right">Six Weeks</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {rows?.map((summary) => (
            <tr key={summary.idKey}>
              {allReps && !isSmallScreen && (
                <td>
                  <GoToDetail {...summary}></GoToDetail>
                </td>
              )}
              <td>
                {!allReps && (
                  <>
                    {!!summary.customerName && !!summary.erpCustomerNumber && (
                      <CustomerLinkComponent
                        name={summary.customerName}
                        number={summary.erpCustomerNumber}
                      ></CustomerLinkComponent>
                    )}
                  </>
                )}
                {!!allReps && (
                  <>
                    {isSmallScreen && (
                      <div>
                        <GoToDetail {...summary}></GoToDetail>
                      </div>
                    )}
                    {!!summary.email && (
                      <a href={`mailto:${summary.email}`}>{summary.email}</a>
                    )}
                  </>
                )}
                {isSmallScreen && (
                  <table className="table-label-desc">
                    <tbody>
                      <TableRow label="This Week">
                        {commonService.toMoney(summary.thisWeekAmount)}
                      </TableRow>
                      <TableRow label="Last Week">
                        {commonService.toMoney(summary.lastWeekAmount)}
                      </TableRow>
                      <TableRow label="Three Weeks">
                        {commonService.toMoney(summary.threeWeekAmount)}
                      </TableRow>
                      <TableRow label="Four Weeks">
                        {commonService.toMoney(summary.fourWeekAmount)}
                      </TableRow>
                      <TableRow label="Five Weeks">
                        {commonService.toMoney(summary.fiveWeekAmount)}
                      </TableRow>
                      <TableRow label="Six Weeks">
                        {commonService.toMoney(summary.sixWeekAmount)}
                      </TableRow>
                    </tbody>
                  </table>
                )}
              </td>
              {!isSmallScreen && (
                <>
                  <td className="text-right">
                    {commonService.toMoney(summary.thisWeekAmount)}
                  </td>
                  <td className="text-right">
                    {commonService.toMoney(summary.lastWeekAmount)}
                  </td>
                  <td className="text-right">
                    {commonService.toMoney(summary.threeWeekAmount)}
                  </td>
                  <td className="text-right">
                    {commonService.toMoney(summary.fourWeekAmount)}
                  </td>
                  <td className="text-right">
                    {commonService.toMoney(summary.fiveWeekAmount)}
                  </td>
                  <td className="text-right">
                    {commonService.toMoney(summary.sixWeekAmount)}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SalesWeeklySummaryTableComponent;
