import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../../components/common/CommonSpinner";
import SysModels from "../../models";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import CustomerLinkComponent from "../common/CustomerLinkComponent";
import IncomingOrderInErrorDialog from "../dialogs/IncomingOrderInErrorDialog";

function IncomingOrdersInErrorWdiget(props: any) {
  const navigate = useNavigate();

  const [inErrorDate, setInErrorDate] = useState(new Date());
  const grid = useFetchHelper(
    async () =>
      SysServices.http.siteManagement.getListOrdersInError(1, 5, {
        search: "",
        filterBy: SysModels.OrdersInErrorFilterByEnum.NotRead,
      }),
    "Incoming Orders In Error"
  );

  useEffect(() => {
    grid.getData();
    setInErrorDate(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIncomingOrderFilter = (status: number) => {
    if (status === 8) {
      return "Not all items sent";
    }
    if (status === 16) {
      return "Rejected by ERP";
    }
    return "Unkown";
  };

  const [detail, setDetail] = useState<SysModels.IOrderInErrorOutputDto>();

  return (
    <>
      {!!detail && (
        <IncomingOrderInErrorDialog
          detail={detail}
          onClose={() => {
            setDetail(undefined);
          }}
          onRefresh={() => {
            grid.getData();
          }}
          print={false}
        ></IncomingOrderInErrorDialog>
      )}

      <div className="hide-on-print">
        <div className="bg-white mb-4">
          <div className="p-3 bg-red txt-light">
            <h5 className="m-0 flex flex-center">
              <em className="zmdi zmdi-alert-triangle me-2"></em>
              <span className="flex-1">
                <span className="me-2">Incoming Orders In Error</span>{" "}
                <small className="no-wrap">
                  {dayjs(inErrorDate).format("MMM DD YYYY, HH:mm A")}
                </small>
              </span>
              <i
                className="fa fa-refresh pointer"
                onClick={(e) => {
                  if (grid.status !== FetchStatus.InProgress) {
                    setInErrorDate(new Date());
                    grid.getData();
                  }
                }}
              ></i>
            </h5>
          </div>
          <div className="p-3 overflow-auto min-height-100 position-relative">
            {grid.status === FetchStatus.InProgress && (
              <CommonSpinner overlay={true}></CommonSpinner>
            )}
            {grid.status !== FetchStatus.Failed && (
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Customer</th>
                    <th className="no-wrap">BG Order No.</th>
                    <th className="text-right">Total</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {grid?.data?.orderHeaderOutputDtos
                    ?.sort(commonService.sortByNumericProperty("sqlId", true))
                    ?.map((order) => (
                      <tr key={order.sqlId}>
                        <td>
                          <small>
                            <CustomerLinkComponent
                              name={order.customerName}
                              number={order.customerAccountNo}
                              numberPartOfName={true}
                            ></CustomerLinkComponent>
                          </small>
                        </td>
                        <td>
                          <small>
                            <a
                              href="/"
                              className="mail-link"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setDetail({
                                  sqlId: order.sqlId,
                                } as any);
                              }}
                            >
                              {order.externalOrderNo}
                            </a>
                          </small>
                        </td>
                        <td className="text-right">
                          {commonService.toMoney(order.orderAmount)}
                        </td>
                        <td>
                          <small>
                            {getIncomingOrderFilter(order.recordStatus)}
                          </small>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
            <div className="text-right">
              <small>
                <a
                  className="mail-link"
                  href="/incomingordersinerror"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate("/incomingordersinerror");
                  }}
                >
                  View All Orders In Error
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IncomingOrdersInErrorWdiget;
