import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import menuActions from "../layout/MenuActions";
import systemStore from "../stores/SystemStore";

function Dashboard(props: any) {
  const navigate = useNavigate();
  const [activeRole, setActiveRole] = useState(systemStore.activeRole);

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.activeRole,
      (n, p, r) => {
        if (n !== activeRole) {
          const ma = menuActions.actions.find(
            (ma) => ma.dashboard && ma.role === n
          );
          if (ma) {
            navigate(ma.url);
          }
        }
        setActiveRole(n);
      }
    );

    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <strong>Identifying Dashboard...</strong>
    </div>
  );
}

export default Dashboard;
