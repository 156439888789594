import SysModels from "../../models";
import fetcher from "../Fetcher";

class GenericEnumLookupService {
  get = async (enumName: string) => {
    const url = `/GenericEnumLookup/${enumName}`;
    return fetcher.get<SysModels.ILookupIntDto[]>(url);
  };
}

const genericEnumLookup = new GenericEnumLookupService();
export default genericEnumLookup;
