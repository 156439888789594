import dayjs from "dayjs";
import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import userStore from "../stores/UserStore";
import UserRolesDisplayComponent from "./common/UserRolesDisplayComponent";
import AddUserDialog from "./dialogs/AddUserDialog";

function Users(props: any) {
  const navigate = useNavigate();

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [filter, setFilter] = useState<SysModels.IUserListSearchInputDto>({
    searchString: "",
    userListSearchActiveEnum: SysModels.UserListSearchActiveEnum.Active,
  });
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const grid = useFetchHelper(async () => {
    return SysServices.http.user.list(paging.page, paging.pageSize, {
      ...filter,
      searchString: search.used,
    });
  }, "Users");

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used, filter]);

  const statusFilter = [
    {
      label: "All",
      value: SysModels.UserListSearchActiveEnum.All,
    },
    {
      label: "Active",
      value: SysModels.UserListSearchActiveEnum.Active,
    },
    {
      label: "Inactive",
      value: SysModels.UserListSearchActiveEnum.Inactive,
    },
  ];
  const [addUser, setAddUser] = useState(false);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {addUser && (
        <AddUserDialog
          onClose={() => {
            setAddUser(false);
          }}
        ></AddUserDialog>
      )}
      <div className="default-page-layout">
        <h4 className="hide-on-print">Manage Users</h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div>
              <div className="p-3 flex flex-wrap gap-10">
                <div className="flex-0" style={{ maxWidth: "100%" }}>
                  <div className="input-group search-box">
                    <input
                      autoFocus={true}
                      className="form-control"
                      type="text"
                      placeholder="Search"
                      value={search.typed}
                      onChange={(e) => {
                        setSearch((data) => {
                          return {
                            ...data,
                            typed: e.target.value,
                          };
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          e.stopPropagation();
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }
                      }}
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            return { typed: "", used: "" };
                          });
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex-0">
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-primary">
                      <i className="fa fa-filter me-2"></i>{" "}
                      {
                        statusFilter.find(
                          (opt) => opt.value === filter.userListSearchActiveEnum
                        )?.label
                      }
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="left">
                      {statusFilter.map((opt) => (
                        <Dropdown.Item
                          key={opt.value}
                          onClick={(e) => {
                            setFilter((prev) => {
                              return {
                                ...prev,
                                userListSearchActiveEnum: opt.value,
                              };
                            });
                          }}
                          active={opt.value === filter.userListSearchActiveEnum}
                        >
                          {opt.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="flex-1">
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setAddUser(true);
                    }}
                  >
                    <i className="fa fa-user-plus me-2"></i>Add
                  </Button>
                </div>
              </div>
              <table className="table table-hover pointer">
                <thead>
                  {isSmallScreen && (
                    <tr>
                      <th>Name / Email / Roles / Last Modified</th>
                      <th></th>
                    </tr>
                  )}
                  {!isSmallScreen && (
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Roles</th>
                      <th>Last Modified</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {grid.data?.userOutputDtos?.map((item) => (
                    <tr
                      key={item.id}
                      className="break-word"
                      onClick={(e) => {
                        userStore.setUserToEdit({ ...item });
                        navigate(`/users/${item.id}`);
                      }}
                    >
                      {isSmallScreen && (
                        <td>
                          <div>
                            {item.firstName} {item.lastName}
                          </div>
                          <div>
                            <a
                              href={`mailto:${item.email}`}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open(`mailto:${item.email}`);
                              }}
                            >
                              {item.email}
                            </a>
                          </div>
                          <div>
                            <small>
                              <UserRolesDisplayComponent
                                roles={item.userRoleDto}
                              ></UserRolesDisplayComponent>
                            </small>
                          </div>
                          <div>
                            <small>
                              {!item.dateUpdated ||
                              dayjs(item.dateUpdated).format("YYYY-MM-DD") ===
                                "1-01-01" ? (
                                ""
                              ) : (
                                <small>
                                  {commonService.toLocalDate(
                                    item.dateUpdated,
                                    "full"
                                  )}
                                </small>
                              )}
                            </small>
                          </div>
                          <div className="no-wrap">
                            {item.isActive && (
                              <span className="chip-green">Active</span>
                            )}
                            {!item.isActive && (
                              <span className="chip-red">Inactive</span>
                            )}
                          </div>
                        </td>
                      )}
                      {!isSmallScreen && (
                        <>
                          <td>
                            {item.firstName} {item.lastName}
                          </td>
                          <td>
                            <a
                              href={`mailto:${item.email}`}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open(`mailto:${item.email}`);
                              }}
                            >
                              {item.email}
                            </a>
                          </td>
                          <td>
                            <UserRolesDisplayComponent
                              roles={item.userRoleDto}
                            ></UserRolesDisplayComponent>
                          </td>
                          <td className="no-wrap">
                            {!item.dateUpdated ||
                            dayjs(item.dateUpdated).format("YYYY-MM-DD") ===
                              "1-01-01" ? (
                              ""
                            ) : (
                              <small>
                                {commonService.toLocalDate(
                                  item.dateUpdated,
                                  "date"
                                )}
                                <br />{" "}
                                {commonService.toLocalDate(
                                  item.dateUpdated,
                                  "time"
                                )}
                              </small>
                            )}
                          </td>
                          <td>
                            <div className="no-wrap">
                              {item.isActive && (
                                <span className="chip-green">Active</span>
                              )}
                              {!item.isActive && (
                                <span className="chip-red">Inactive</span>
                              )}
                            </div>
                          </td>
                        </>
                      )}
                      <td className="text-right">
                        <i className="fa fa-pencil text-primary pointer"></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="p-3 pt-0">
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="users"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Users;
