import SysModels from "../../models";
import fetcher from "../Fetcher";

class VendorService {
  list = async (
    page: number,
    pageSize: number,
    data: SysModels.IVendorSearchInputDto
  ) => {
    let url = `/Vendor/${page}/${pageSize}`;
    return fetcher.post<SysModels.IVendorGridOutputDto>(url, data);
  };

  get = async (id: string) => {
    let url = `/Vendor/${id}`;
    return fetcher.get<SysModels.IVendorOutputDto>(url);
  };

  listRecentlyViewed = async (pageSize: number) => {
    let url = `/Vendor/RecentlyViewed/${pageSize}`;
    return fetcher.get<SysModels.IRecentlyViewedVendorOutputDto[]>(url);
  };
}

const vendor = new VendorService();
export default vendor;
