import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../../components/common/CommonSpinner";
import SysModels from "../../models";
import SysServices from "../../services";
import commonService from "../../services/CommonService";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import CustomerLinkComponent from "../common/CustomerLinkComponent";

function TopCustomersWidget(props: any) {
  const navigate = useNavigate();

  const topCustomers = useFetchHelper(
    async () =>
      SysServices.http.customer.listTop(
        1,
        5,
        SysModels.DatePeriodEnum.ThisMonth
      ),
    "Top Customers"
  );

  useEffect(() => {
    topCustomers.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="hide-on-print">
        <div className="bg-white mb-4">
          <div className="p-3 bg-primary txt-light">
            <h5 className="m-0">
              <em className="zmdi zmdi-account-calendar me-2"></em>
              Top 5 Customers for the Month
            </h5>
          </div>
          <div className="p-3 overflow-auto min-height-100">
            {topCustomers.status === FetchStatus.InProgress && (
              <CommonSpinner></CommonSpinner>
            )}
            {topCustomers.status === FetchStatus.Complete && (
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th style={{ width: "25px" }}></th>
                    <th>Customer</th>
                    <th className="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {topCustomers?.data?.topCustomerOutputDtos?.map(
                    (customer) => (
                      <tr key={customer.customerSqlId}>
                        <td>
                          <i className="fa fa-user text-warning"></i>
                        </td>
                        <td>
                          <CustomerLinkComponent
                            name={customer.customerName}
                            number={customer.erpCustomerNumber}
                            id={customer.customerSqlId}
                          ></CustomerLinkComponent>
                        </td>
                        <td className="text-right">
                          {commonService.toMoney(customer.amount)}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            )}
            <div className="text-right">
              <small>
                <a
                  className="mail-link"
                  href="/topcustomers"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate("/topcustomers");
                  }}
                >
                  View All Top Customers
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopCustomersWidget;
