import React, { useEffect, useState } from "react";
import SysModels from "../models";
import FormModal from "../components/common/FormModal";
import CommonSpinner from "../components/common/CommonSpinner";
import SysServices from "../services";
import commonService from "../services/CommonService";
import toastStore from "../stores/ToastStore";
import SwitchButton from "../components/common/SwitchButton";
import dayjs from "dayjs";

function SkuDetailDialog(props: {
  itemId: string;
  internalId: string;
  fromSearch?: SysModels.IErpInventoryItemOutputDto;
  onClose: (add?: boolean) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<SysModels.IErpInventoryItemOutputDto>();

  const getItem = async () => {
    setLoading(true);
    await SysServices.http.siteManagement
      .getInventoryItem(props.itemId, "null")
      .then((data) => {
        setItem(data);
        setLoading(false);
      })
      .catch((error) => {
        if (commonService.getErrorStatusCode(error) === 404) {
          toastStore.showToast("Item Not Found", "error");
        } else {
          toastStore.showError("Failed Getting Item", error);
        }
        props.onClose();
      });
  };

  useEffect(() => {
    if (props.fromSearch) {
      setItem({ ...props.fromSearch });
    } else {
      getItem();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      title="Item Detail"
      isOpen={true}
      close={() => {
        props.onClose();
      }}
      size="md"
      submitButtonLabel={props.fromSearch ? "Add Mapping" : "Done"}
      primaryButtonOnly={!props.fromSearch}
      disableSubmit={loading}
      submit={() => {
        if (props.fromSearch) {
          props.onClose(true);
        } else {
          props.onClose();
        }
      }}
    >
      {loading && (
        <div className="p-4">
          <CommonSpinner overlay={true}></CommonSpinner>
        </div>
      )}
      {!loading && item && (
        <div>
          <table className="table table-sm">
            <tbody>
              <tr>
                <td>
                  <strong>Item Id:</strong>
                </td>
                <td>{item.itemId}</td>
              </tr>
              <tr>
                <td>
                  <strong>Internal Item Id:</strong>
                </td>
                <td>{item.erpInternalId}</td>
              </tr>

              <tr>
                <td>
                  <strong>Display Name:</strong>
                </td>
                <td>{item.displayName}</td>
              </tr>
              <tr>
                <td>
                  <strong>Description:</strong>
                </td>
                <td>{item.description}</td>
              </tr>
              <tr>
                <td>
                  <strong>Brand:</strong>
                </td>
                <td>{item.purchaseLabel}</td>
              </tr>
              <tr>
                <td>
                  <strong>Category:</strong>
                </td>
                <td>{item.category}</td>
              </tr>
              <tr>
                <td>
                  <strong>Category Id:</strong>
                </td>
                <td>{item.categoryId}</td>
              </tr>
              <tr>
                <td>
                  <strong>Mnf Name:</strong>
                </td>
                <td>{item.manufacturedName}</td>
              </tr>
              <tr>
                <td>
                  <strong>Mnf Id:</strong>
                </td>
                <td>{item.manufacturerId}</td>
              </tr>
              <tr>
                <td>
                  <strong>Mnf Item Number:</strong>
                </td>
                <td>{item.manufactureItemNumber}</td>
              </tr>
              <tr>
                <td>
                  <strong>Last Portal Update:</strong>
                </td>
                <td>
                  {dayjs
                    .utc(item.lastUpdatedDate)
                    .local()
                    .format("MMM DD, YYYY hh:mm:ss A")}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Last ERP Update:</strong>
                </td>
                <td>
                  {dayjs
                    .utc(item.erpLastUpdatedDate)
                    .local()
                    .format("MMM DD, YYYY hh:mm:ss A")}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Status</strong>
                </td>
                <td>
                  {item.activeYn ? (
                    <span className="chip-green">Active</span>
                  ) : (
                    <span className="chip-red">Inactive</span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </FormModal>
  );
}

export default SkuDetailDialog;
