import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonSpinner from "../../components/common/CommonSpinner";
import SysModels from "../../models";
import SysServices from "../../services";
import { FetchStatus, useFetchHelper } from "../../services/FetchHelper";
import systemStore from "../../stores/SystemStore";
import toastStore from "../../stores/ToastStore";
import CustomerLinkComponent from "../common/CustomerLinkComponent";
import { AsyncTypeahead } from "react-bootstrap-typeahead"; // ES2015

interface IOption {
  id: string;
  name: string;
}

function RecentlyViewedCustomersWidget(props: any) {
  const navigate = useNavigate();

  const recentCustomers = useFetchHelper(
    async () => SysServices.http.customer.listRecentlyViewed(5),
    "Recently Viewed Customers"
  );

  useEffect(() => {
    recentCustomers.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isSwitching, setIsSwitching] = useState(false);
  const [selectedCustomer, setSelectedCustomer] =
    useState<SysModels.IRecentlyViewedCustomerOutputDto>();
  useEffect(() => {
    if (selectedCustomer) {
      setIsSwitching(true);
      SysServices.http.customer
        .get(selectedCustomer?.erpCustomerNumber)
        .then((data) => {
          systemStore.setCurrentCustomer({
            name: selectedCustomer.customerName,
            number: selectedCustomer.erpCustomerNumber,
            id: selectedCustomer.sqlId,
            data: data,
          });
          setIsSwitching(false);
        })
        .catch((error) => {
          toastStore.showError("Failed Getting Customer", error);
          setIsSwitching(false);
        });
    }
  }, [selectedCustomer]);

  const [users, setUsers] = useState([] as IOption[]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (query: string) => {
    if ((query || "").trim() === "") {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    await SysServices.http.customer
      .typeAhead({ search: query })
      .then((items) => {
        const options = items.map((i) => ({
          id: i.id,
          name: i.label,
        }));

        setUsers(options);
        setIsLoading(false);
      })
      .catch((err) => {
        setUsers([]);
        setIsLoading(false);
      });
  };

  const [switchTo, setSwitchTo] = useState<IOption>();

  return (
    <>
      {isSwitching && (
        <CommonSpinner
          overlay={true}
          message={`Switching to Customer: ${selectedCustomer?.customerName}`}
        ></CommonSpinner>
      )}

      {!!switchTo && (
        <CustomerLinkComponent
          switchOnLoad={true}
          name={switchTo.name}
          number={switchTo.id}
        ></CustomerLinkComponent>
      )}

      <div className="hide-on-print">
        <div className="bg-white mb-4">
          <div className="px-3 py-2 bg-warning txt-light">
            <h5 className="m-0 flex flex-center gap-10">
              <span className="flex-1">
                <span className="flex flex-center">
                  <em className="zmdi zmdi-accounts-alt me-2"></em>
                  <span>Recent Customers</span>
                </span>
              </span>
              <AsyncTypeahead
                id="basic-typeahead-single"
                labelKey="name"
                onSearch={handleSearch}
                onChange={(data: any) => {
                  if (data[0]) {
                    setSwitchTo({ id: data[0].id, name: data[0].name });
                  }
                }}
                searchText={"Searching..."}
                isLoading={isLoading}
                options={users}
                placeholder="Search..."
                minLength={1}
                delay={500}
                useCache={false}
                filterBy={() => true}
                style={{ width: "300px" }}
              />
            </h5>
          </div>
          <div className="p-3 overflow-auto min-height-100">
            {recentCustomers.status === FetchStatus.InProgress && (
              <CommonSpinner></CommonSpinner>
            )}
            {recentCustomers.status === FetchStatus.Complete && (
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th style={{ width: "25px" }}></th>
                    <th>Customer</th>
                  </tr>
                </thead>
                <tbody>
                  {recentCustomers?.data?.map((customer) => (
                    <tr
                      key={customer.sqlId}
                      className="pointer"
                      onClick={(e) => {
                        setSelectedCustomer(customer);
                      }}
                    >
                      <td>
                        <i className="fa fa-user text-warning"></i>
                      </td>
                      <td>
                        <CustomerLinkComponent
                          name={customer.customerName}
                          number={customer.erpCustomerNumber}
                          id={customer.sqlId}
                        ></CustomerLinkComponent>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <div className="text-right">
              <small>
                <a
                  className="mail-link"
                  href="/customers"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate("/customers");
                  }}
                >
                  View All Customers
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecentlyViewedCustomersWidget;
