import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import CommonSpinner from "../components/common/CommonSpinner";
import Pagination, { usePaging } from "../components/common/Pagination";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import CustomerLinkComponent from "./common/CustomerLinkComponent";

function Customers(props: any) {
  const [search, setSearch] = useState({
    typed: "",
    used: "",
  });

  const grid = useFetchHelper(
    async () =>
      SysServices.http.customer.list(paging.page, paging.pageSize, {
        search: search.used,
        orderByEnum: SysModels.OrderByEnum.Ascending,
      }),
    "Customers"
  );

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, search.used]);

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );

  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="default-page-layout">
        <h4 className="hide-on-print">Customers</h4>
        <div className="bg-white">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div>
              <div className="p-3">
                <div className="flex-0">
                  <div className="input-group search-box">
                    <input
                      autoFocus={true}
                      className="form-control"
                      type="text"
                      placeholder="Search"
                      value={search.typed}
                      onChange={(e) => {
                        setSearch((data) => {
                          return {
                            ...data,
                            typed: e.target.value,
                          };
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          e.stopPropagation();
                          setSearch((data) => {
                            pageChange(1, paging.pageSize);
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }
                      }}
                    ></input>
                    <div className="input-group-append">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            if (data.used === data.typed) {
                              return data;
                            }
                            return {
                              ...data,
                              used: data.typed,
                            };
                          });
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                      <button
                        className="btn btn-secondary"
                        type="button"
                        onClick={(e) => {
                          pageChange(1, paging.pageSize);
                          setSearch((data) => {
                            return { typed: "", used: "" };
                          });
                        }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <table className="table">
                <thead>
                  {isSmallScreen && (
                    <tr>
                      <th>Name / Email / Phone</th>
                    </tr>
                  )}
                  {!isSmallScreen && (
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {grid.data?.erpCustomerGridItemDtos?.map((customer) => (
                    <tr key={customer.sqlId}>
                      {isSmallScreen && (
                        <>
                          <td>
                            <CustomerLinkComponent
                              name={customer.customerName}
                              number={customer.erpCustomerNumber}
                            ></CustomerLinkComponent>
                            <div>
                              <a
                                href={`mailto:${customer.email}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                {customer.email}
                              </a>
                            </div>
                            <div>{customer.phone}</div>
                          </td>
                        </>
                      )}
                      {!isSmallScreen && (
                        <>
                          <td>
                            <CustomerLinkComponent
                              name={customer.customerName}
                              number={customer.erpCustomerNumber}
                            ></CustomerLinkComponent>
                          </td>
                          <td>
                            <a
                              href={`mailto:${customer.email}`}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              {customer.email}
                            </a>
                          </td>
                          <td>{customer.phone}</td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="p-3 pt-0">
                <Pagination
                  length={grid.data?.totalRecords || 0}
                  page={paging.page}
                  pageSize={paging.pageSize}
                  pageChange={pageChange}
                  showingOfWhatLabel="entries"
                  sizes={[10, 25, 50, 100]}
                ></Pagination>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Customers;
