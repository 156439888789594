import SysModels from "../../models";
import fetcher from "../Fetcher";

class UserAccessService {
  list = async (page: number, pageSize: number) => {
    const url = `/UserAccess/${page}/${pageSize}`;
    return fetcher.get<SysModels.IUserAccessGridOutputDto>(url);
  };
}

const userAccess = new UserAccessService();
export default userAccess;
