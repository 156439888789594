import { reaction } from "mobx";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import ConfirmDialog from "../components/common/ConfirmDialog";
import Pagination, { usePaging } from "../components/common/Pagination";
import TableRow from "../components/common/TableRow";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import systemStore from "../stores/SystemStore";
import toastStore from "../stores/ToastStore";
import InvoiceDetailDialog from "./dialogs/InvoiceDetailDialog";

function OutgoingInvoices(props: any) {
  const [filter, setFilter] = useState(SysModels.PortalInvoiceFilterEnum.All);
  const statuses = useFetchHelper(
    async () =>
      SysServices.http.genericEnumLookup.get("PortalInvoiceFilterEnum"),
    "Statuses"
  );

  useEffect(() => {
    statuses.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [paging, setPaging] = usePaging(1, 50);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };
  const [search, setSearch] = useState({ term: "", go: false });
  const grid = useFetchHelper(
    () =>
      SysServices.http.siteManagement.listPortalInvoices(
        paging.page,
        paging.pageSize,
        {
          search: search.term,
          portalInvoiceFilter: filter,
        }
      ),
    "Invoices"
  );

  useEffect(() => {
    const tmo = setTimeout(() => {
      grid.getData();
    }, 200);
    return () => {
      clearTimeout(tmo);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paging, filter]);

  const pageOptions = [10, 25, 50, 100];
  const [selectedInvoice, setSelectedInvoice] =
    useState<SysModels.IPortalInvoiceGridItemOutputDto>();

  const [isSmallScreen, setIsSmallScreen] = useState(
    commonService.isSmallScreen
  );
  useEffect(() => {
    const disposer = reaction(
      () => systemStore.windowSize,
      (n, p, i) => {
        setIsSmallScreen(commonService.isSmallScreen);
      }
    );
    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [showSpinnerMessage, setShowSpinnerMessage] = useState<string>();

  useEffect(() => {
    if (search.go) {
      if (paging.page === 1) {
        grid.getData();
      } else {
        setPaging((p) => {
          return {
            ...p,
            page: 1,
          };
        });
      }
      setSearch((rtn) => {
        return { ...rtn, go: false };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const resubmit = async (invoiceId: number) => {
    setShowSpinnerMessage("Sending Request...");
    await SysServices.http.siteManagement
      .resubmitInvoice({
        invoiceId: invoiceId,
      })
      .then(() => {
        toastStore.showToast("Invoice Resubmission was requested", "success");
        setShowSpinnerMessage(undefined);
        grid.getData();
      })
      .catch((error) => {
        toastStore.showError("Invoice Resubmission Request Failed", error);
        setShowSpinnerMessage(undefined);
      });
  };

  const reRequest = async (invoiceNumber: string) => {
    setShowSpinnerMessage("Sending Request...");
    await SysServices.http.siteManagement
      .reRequestInvoiceFromNetsuite({
        invoiceNumber: invoiceNumber,
      })
      .then(() => {
        toastStore.showToast("Invoice Re-requested from NetSuite", "success");
        setShowSpinnerMessage(undefined);
        grid.getData();
      })
      .catch((error) => {
        toastStore.showError("Invoice Re-request Failed", error);
        setShowSpinnerMessage(undefined);
      });
  };

  const [askResubmit, setAskResubmit] = useState(0);
  const [askRerequest, setAskRerequest] = useState("");

  const Actions = ({
    i,
    item,
  }: {
    i: number;
    item: SysModels.IPortalInvoiceGridItemOutputDto;
  }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          size="sm"
          id={`dropdownInvoiceRow${i}${item.webOrderNumber}`}
          variant="outline-primary"
        >
          <i className="fa fa-bars"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu align="right">
          <Dropdown.Item
            onClick={() => {
              setSelectedInvoice(item);
            }}
          >
            View Detail
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setAskResubmit(item.sqlId);
            }}
          >
            Resubmit
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setAskRerequest(item.invoiceNumber);
            }}
          >
            Re-request
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <>
      {!!showSpinnerMessage && (
        <CommonSpinner
          overlay={true}
          message={showSpinnerMessage}
        ></CommonSpinner>
      )}
      <ConfirmDialog
        show={!!askResubmit}
        title="Resubmit Invoice"
        message="Are you sure you want to Resubmit this invoice?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            resubmit(askResubmit);
          }
          setAskResubmit(0);
        }}
      ></ConfirmDialog>
      <ConfirmDialog
        show={!!askRerequest}
        title="Re-request Invoice"
        message="Are you sure you want to Re-request this invoice?"
        buttons="yesno"
        done={(rtn) => {
          if (rtn === "yes") {
            reRequest(askRerequest);
          }
          setAskRerequest("");
        }}
      ></ConfirmDialog>
      {selectedInvoice && (
        <InvoiceDetailDialog
          invoiceNumber={selectedInvoice.sqlId}
          onClose={(refresh) => {
            setSelectedInvoice(undefined);
            if (refresh) {
              grid.getData();
            }
          }}
        ></InvoiceDetailDialog>
      )}
      <div className="default-page-layout">
        <h4>Outgoing Invoices</h4>
      </div>
      {grid.status === FetchStatus.InProgress && (
        <div>
          <CommonSpinner message="Getting Invoices"></CommonSpinner>
        </div>
      )}
      {grid.status === FetchStatus.Failed && (
        <div className="bg-white p-3 text-danger">
          <i className="fa fa-warning"></i> Failed getting invoices.
        </div>
      )}
      {grid.status === FetchStatus.Complete && (
        <div className="bg-white">
          <div className="p-3 pt-4">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="flex flex-row gap-10">
                  <div className="flex-0">
                    <div className="input-group search-box">
                      <input
                        autoFocus={true}
                        value={search.term}
                        className="form-control form-control-sm"
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          setSearch({
                            term: `${e.target.value || ""}`.trim(),
                            go: false,
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setSearch((rtn) => {
                              return { ...rtn, go: true };
                            });
                          }
                        }}
                      ></input>
                      <div className="input-group-append">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={(e) => {
                            setSearch((rtn) => {
                              return { ...rtn, go: true };
                            });
                          }}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={(e) => {
                            setSearch((rtn) => {
                              return { term: "", go: true };
                            });
                          }}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1">
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-primary">
                        <i className="fa fa-filter me-2"></i>{" "}
                        {statuses?.data?.find((o) => o.value === filter)
                          ?.label || "All"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu align="left">
                        {statuses?.data
                          ?.sort(commonService.sortByNumericProperty("value"))
                          ?.map((opt) => (
                            <Dropdown.Item
                              key={opt.value}
                              onClick={() => {
                                setFilter(opt.value);
                                pageChange(1, paging.pageSize);
                              }}
                              active={opt.value === filter}
                            >
                              <span>{opt.label}</span>
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="table w-100 bg-white my-orders-table">
            <thead>
              <tr>
                {isSmallScreen && (
                  <>
                    <th></th>
                  </>
                )}
                {!isSmallScreen && (
                  <>
                    <th>Customer No</th>
                    <th>Buying Group</th>
                    <th>Invoice No </th>
                    <th>Inv. Date </th>
                    <th className="text-right">Total $</th>
                    <th>Web Order</th>
                    <th>Submitted</th>
                    <th>Resubmitted</th>
                    <th>Processed Not Submitted</th>
                    <th>Error</th>
                    <th></th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {grid.data?.portalInvoiceGridItemOutputDtos &&
                grid.data.portalInvoiceGridItemOutputDtos.map((item, i) => (
                  <tr key={`${i}-${item.webOrderNumber}`}>
                    {isSmallScreen && (
                      <td>
                        <table className="table-label-desc">
                          <tbody>
                            <TableRow label="Customer No:">
                              <div className="flex gap-10">
                                <div className="flex-1">
                                  {item.customerAccountNo}
                                </div>
                                <div>
                                  <Actions i={i} item={item}></Actions>
                                </div>
                              </div>
                            </TableRow>
                            <TableRow label="Buying Group:">
                              {item.buyingGroupName}
                            </TableRow>
                            <TableRow label="Invoice No:">
                              {item.invoiceNumber}
                            </TableRow>
                            <TableRow label="Inv. Date:">
                              {item.invoiceDate &&
                                commonService.toDateString(
                                  item.invoiceDate,
                                  "MMM DD, YYYY"
                                )}
                            </TableRow>
                            <TableRow label="Total:">
                              {commonService.toMoney(item.invoiceAmount)}
                            </TableRow>
                            <TableRow label="Web Order:">
                              {item.webOrderNumber}
                            </TableRow>
                            <TableRow label="Submitted:">
                              {item.submittedToBuyingGroupOn &&
                                commonService.toLocalDate(
                                  item.submittedToBuyingGroupOn,
                                  "MMM DD, YYYY"
                                )}
                            </TableRow>
                            <TableRow label="Resubmitted:">
                              {item.resubmittedYn ? "Yes" : "No"}
                            </TableRow>
                            <TableRow label="Processed Not<br/>Submitted:">
                              {item.processedNotSubmitted &&
                                commonService.toLocalDate(
                                  item.processedNotSubmitted,
                                  "MMM DD, YYYY"
                                )}
                            </TableRow>
                            <TableRow label="Error:">
                              {item.inError ? "Yes" : "No"}
                            </TableRow>
                          </tbody>
                        </table>
                      </td>
                    )}
                    {!isSmallScreen && (
                      <>
                        <td>{item.customerAccountNo}</td>
                        <td>{item.buyingGroupName}</td>
                        <td>{item.invoiceNumber}</td>
                        <td className="no-wrap">
                          {item.invoiceDate &&
                            commonService.toDateString(
                              item.invoiceDate,
                              "MMM DD, YYYY"
                            )}
                        </td>
                        <td className="text-right">
                          $
                          {(Number(item.invoiceAmount) || 0).toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }
                          )}
                        </td>
                        <td>{item.webOrderNumber}</td>
                        <td className="no-wrap">
                          {item.submittedToBuyingGroupOn &&
                            commonService.toLocalDate(
                              item.submittedToBuyingGroupOn,
                              "MMM DD, YYYY"
                            )}
                        </td>
                        <td>{item.resubmittedYn ? "Yes" : "No"}</td>
                        <td>
                          {item.processedNotSubmitted &&
                            commonService.toLocalDate(
                              item.processedNotSubmitted,
                              "MMM DD, YYYY"
                            )}
                        </td>
                        <td>{item.inError ? "Yes" : "No"}</td>
                        <td className="text-center">
                          <div>
                            <Actions i={i} item={item}></Actions>
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>

          <div className="p-3 pt-0">
            <Pagination
              length={grid.data?.totalRecords || 0}
              page={paging.page}
              pageSize={paging.pageSize}
              pageChange={pageChange}
              showingOfWhatLabel="invoices"
              sizes={pageOptions}
            ></Pagination>
          </div>
        </div>
      )}
    </>
  );
}

export default OutgoingInvoices;
