import RecentlyViewedCustomersWidget from "../widgets/RecentlyViewedCustomersWidget";
import RecentIncomingOrdersWidget from "../widgets/RecentIncomingOrdersWidget";
import CSROrderSummaryWidget from "../widgets/CSROrderSummaryWidget";
import TopCustomersWidget from "../widgets/TopCustomersWidget";
import BuyingGroupOrderSummaryWidget from "../widgets/BuyingGroupOrderSummaryWidget";
import EmployeeOrderSummaryWidget from "../widgets/EmployeeOrderSummaryWidget";

function ExecutiveDashboard(props: any) {
  return (
    <>
      <div className="default-page-layout hide-on-print">
        <h4>Executive Dashboard</h4>
      </div>

      <div className="row">
        <div className="cols-sm-12 col-md-12 col-lg-6">
          <EmployeeOrderSummaryWidget></EmployeeOrderSummaryWidget>
          <RecentlyViewedCustomersWidget></RecentlyViewedCustomersWidget>
          <CSROrderSummaryWidget></CSROrderSummaryWidget>
        </div>
        <div className="cols-sm-12 col-md-12 col-lg-6">
          <TopCustomersWidget></TopCustomersWidget>
          <RecentIncomingOrdersWidget></RecentIncomingOrdersWidget>
          <BuyingGroupOrderSummaryWidget></BuyingGroupOrderSummaryWidget>
        </div>
      </div>
    </>
  );
}

export default ExecutiveDashboard;
