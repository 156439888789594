import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import ConfirmDialog from "../components/common/ConfirmDialog";
import DateTimePicker from "../components/common/DateTimePicker";
import FormModal from "../components/common/FormModal";
import SysModels from "../models";
import SysServices from "../services";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import toastStore from "../stores/ToastStore";

function NonDeliveryDates(props: any) {
  const grid = useFetchHelper(
    async () => SysServices.http.siteManagement.getNonDeliveryDates(),
    "Non-Delivery Dates"
  );

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (calledOnce) {
      return; //do nothing...
    }
    setCalledOnce(true);
    grid.getData();
  }, [calledOnce, grid]);

  useEffect(() => {
    grid.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [delDate, setDelDate] = useState<SysModels.INonDeliveryDateOutputDto>();
  const [datesBeingDeleted, setDatesBeingDeleted] = useState<
    SysModels.INonDeliveryDateOutputDto[]
  >([]);

  const deleteData = async (id: number) => {
    await SysServices.http.siteManagement
      .deleteNonDeliveryDate(id)
      .then((data) => {
        toastStore.showToast("Non-Delivery Date deleted.", "success");
        grid.getData();
      })
      .catch((error) => {
        toastStore.showError("Failed to Delete Non-Delivery Date", error);
      })
      .finally(() => {
        setDatesBeingDeleted((list) => {
          return list.filter((d) => d.sqlId !== id);
        });
      });
  };

  const [showAdd, setShowAdd] = useState(false);
  const [saving, setSaving] = useState(false);
  const [newDate, setNewDate] = useState(new Date());

  return (
    <>
      {showAdd && (
        <FormModal
          title="Add Non-Delivery Date"
          isOpen={true}
          close={() => {
            setShowAdd(false);
          }}
          submitButtonLabel="Save Date"
          size="sm"
          disableSubmit={!newDate}
          submit={() => {
            setSaving(true);
            SysServices.http.siteManagement
              .createNonDeliveryDate({
                nonDeliveryDate: dayjs(newDate).format("YYYY-MM-DD") as any,
              })
              .then((data) => {
                setSaving(false);
                toastStore.showToast("Non-Delivery Date Saved.", "success");
                grid.getData();
              })
              .catch((error) => {
                setSaving(false);
                toastStore.showError("Failed to save Non-Delivery Date", error);
              });

            setShowAdd(false);
          }}
        >
          <div>
            <DateTimePicker
              dateOnly={true}
              data={new Date()}
              onChange={(date) => {
                setNewDate(date);
              }}
            ></DateTimePicker>
          </div>
        </FormModal>
      )}

      <ConfirmDialog
        show={!!delDate}
        buttons="yesno"
        title="Delete Date"
        message={`Are you sure you want to delete '${dayjs(
          delDate?.nonDeliveryDate
        ).format("MMMM DD, YYYY")}' from Non-Delivery Dates?`}
        done={(rtn) => {
          if (rtn === "yes") {
            if (delDate) {
              setDatesBeingDeleted((list) => {
                return [...list, { ...delDate }];
              });
              deleteData(delDate.sqlId);
            }
          }
          setDelDate(undefined);
        }}
      ></ConfirmDialog>

      <div className="default-page-layout">
        <h4 className="hide-on-print">Non-Delivery Dates</h4>
        <div className="bg-white col-sm-12 col-md-9 col-lg-6">
          {grid.status === FetchStatus.InProgress && (
            <div className="p-3">
              <CommonSpinner></CommonSpinner>
            </div>
          )}
          {grid.status === FetchStatus.Complete && (
            <div>
              <table className="table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {grid.data?.map((date) => (
                    <tr key={date.sqlId}>
                      <td>
                        {dayjs(date.nonDeliveryDate).format("MMMM DD, YYYY")}
                      </td>
                      <th className="text-right">
                        {!datesBeingDeleted.find(
                          (d) => date.sqlId === d.sqlId
                        ) && (
                          <Button
                            variant="default"
                            type="button"
                            onClick={() => setDelDate(date)}
                          >
                            <i className="fa fa-trash txt-danger"></i>
                          </Button>
                        )}
                        {!!datesBeingDeleted.find(
                          (d) => date.sqlId === d.sqlId
                        ) && (
                          <Button variant="default" type="button">
                            <Spinner
                              animation="border"
                              variant={"danger"}
                              size="sm"
                            ></Spinner>
                          </Button>
                        )}
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="mt-4">
          <Button
            variant="primary"
            type="button"
            onClick={() => setShowAdd(true)}
            disabled={saving}
          >
            {!saving && (
              <span>
                <i className="fa fa-plus"></i> Add Date
              </span>
            )}
            {saving && (
              <span>
                <i className="fa fa-save"></i> Saving...
              </span>
            )}
          </Button>
        </div>
      </div>
    </>
  );
}

export default NonDeliveryDates;
