import SysModels from "../../models";
import fetcher from "../Fetcher";

class BuyingGroupMappingService {
  buyingGroupsWithMapping = async (page: number, pageSize: number) => {
    const url = `/BuyingGroupMapping/BuyingGroupsWithMapping/${page}/${pageSize}`;
    return fetcher.get<SysModels.IBuyingGroupWithMappingsGridOutputDto>(url);
  };

  mappings = async (page: number, pageSize: number, buyingGroupId: number) => {
    const url = `/BuyingGroupMapping/Mappings/${page}/${pageSize}/${buyingGroupId}`;
    return fetcher.get<SysModels.IBuyingGroupMappingGridOutputDto>(url);
  };

  get = async (id: number) => {
    const url = `/BuyingGroupMapping/${id}`;
    return fetcher.get<SysModels.IBuyingGroupMappingOutputDto>(url);
  };

  delete = async (id: number) => {
    const url = `/BuyingGroupMapping/${id}`;
    return fetcher.delete<any>(url);
  };

  update = async (
    id: number,
    data: SysModels.IBuyingGroupMappingUpdatetDto
  ) => {
    const url = `/BuyingGroupMapping/${id}`;
    return fetcher.put<SysModels.IBuyingGroupMappingOutputDto>(url, data);
  };

  create = async (data: SysModels.IBuyingGroupMappingInputDto) => {
    const url = `/BuyingGroupMapping`;
    return fetcher.post<SysModels.IBuyingGroupMappingOutputDto>(url, data);
  };
}

const buyingGroupMapping = new BuyingGroupMappingService();
export default buyingGroupMapping;
