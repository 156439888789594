import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import CommonSpinner from "../components/common/CommonSpinner";
import FileUpload from "../components/common/FileUpload";
import SysModels from "../models";
import SysServices from "../services";
import { useFetchHelper } from "../services/FetchHelper";
import ftpStore from "../stores/FtpStore";
import toastStore from "../stores/ToastStore";
import FTPObjectComponent, {
  FTPFetchStatus,
  useFtpFileAction,
  useFtpUploadToFolder,
} from "./common/FTPObjectComponent";

function FTPManagement(props: any) {
  const ftpSources = useFetchHelper(
    async () => SysServices.http.genericEnumLookup.get("FtpNameEnum"),
    "FTP Sources"
  );

  const [ftp, setFtp] = useState<SysModels.FtpNameEnum>();
  const [status, setStatus] = useState(FTPFetchStatus.Default);
  const [refresh, setRefresh] = useState(new Date());
  const fileAction = useFtpFileAction();
  const uploadToFolder = useFtpUploadToFolder();

  useEffect(() => {
    ftpSources.getData();
    ftpStore.clearAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [deleteFile, setDeleteFile] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [model, setModel] = useState<{ fileName: string }>({} as any);

  const upload = async () => {
    if (!ftp) {
      return;
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file);
    });

    const data: SysModels.IFtpUploadFileRequestInputDto = {
      toFileName: files[0].name,
      toFolder: uploadToFolder || "",
      ftpNameEnum: ftp,
    };
    formData.append("jsonData", JSON.stringify(data));

    await SysServices.http.ftpManagement
      .uploadFile(formData)
      .then((rtn) => {
        toastStore.showToast("File Uploaded.", "success");
        ftpStore.setRefreshFolder(uploadToFolder);
        ftpStore.clearAction();
      })
      .catch((error) => {
        toastStore.showError("Failed Uploading File", error);
      })
      .finally(() => {
        ftpStore.clearUploadToFolder();
      });
  };

  useEffect(() => {
    if (!fileAction) {
      setFiles([]);
      setDeleteFile(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileAction]);

  useEffect(() => {
    if (uploadToFolder) {
      upload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadToFolder]);

  return (
    <>
      <div className="default-page-layout h-100 flex flex-column">
        <h4>FTP Management</h4>

        <div className="ftp-manager bg-white flex flex-row flex-wrap flex-1">
          <div className="flex-0 position-relative">
            {!!uploadToFolder && (
              <div>
                <CommonSpinner
                  overlay={true}
                  message="Uploading File..."
                ></CommonSpinner>
              </div>
            )}

            <div style={{ paddingBottom: "10px" }}>
              <div className="flex flex-center flex-wrap gap-10">
                <Dropdown>
                  <Dropdown.Toggle variant="outline-secondary" size="sm">
                    <i className="fa fa-home py-1 me-2"></i>
                    {ftpSources.data?.find((o) => o.value === ftp)?.label ||
                      "Select FTP Connection"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="left">
                    {ftpSources?.data?.map((opt) => (
                      <Dropdown.Item
                        key={opt.value}
                        onClick={() => {
                          ftpStore.clearAction();
                          setFtp(opt.value);
                          setRefresh(new Date());
                        }}
                        active={opt.value === ftp}
                      >
                        <span>{opt.label}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <div className="flex flex-row flex-1 gap-10">
                  {!!ftp && (
                    <>
                      <div>
                        <FileUpload
                          label="Upload File"
                          onChange={(fileList) => {
                            if (fileList.length > 1) {
                              toastStore.showToast(
                                "Please select one file only.",
                                "warning"
                              );
                              return;
                            }
                            if (fileList.length > 0) {
                              const list = [];
                              let cnt = 0;
                              while (cnt < fileList.length) {
                                const file = fileList[cnt];
                                list.push(file);
                                cnt++;
                              }
                              setFiles(list);
                              if (list[0]) {
                                setModel({
                                  ...model,
                                  fileName: list[0].name,
                                });
                                setDeleteFile(false);
                                ftpStore.setAction({
                                  action: 2,
                                  fileName: list[0].name,
                                  folderName: "",
                                });
                              }
                            }
                          }}
                        />
                      </div>
                      <div className="pe-3">
                        {!deleteFile &&
                          files?.map((f, i) => (
                            <div
                              key={i}
                              className="alert alert-success m-0 p-1 px-2 flex flex-center"
                              style={{
                                borderRadius: 0,
                              }}
                            >
                              <span className="me-2 flex-1">
                                <i className="fa fa-file text-success me-2"></i>
                                {f.name}
                              </span>
                              <i
                                className="fa fa-times pointer"
                                onClick={() => {
                                  ftpStore.clearAction();
                                }}
                              ></i>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {status === FTPFetchStatus.Loading && (
              <div>
                <CommonSpinner></CommonSpinner>
              </div>
            )}
            <div
              className={`${
                status === FTPFetchStatus.Loading ? "display-none" : ""
              }`}
            >
              {ftp !== undefined &&
                [
                  {
                    ftp: ftp,
                    date: refresh,
                  },
                ].map((item) => (
                  <div key={item.date.toString()}>
                    <FTPObjectComponent
                      ftpSource={item.ftp}
                      folder=""
                      onStatusChanged={(s) => setStatus(s)}
                      isRoot={true}
                    ></FTPObjectComponent>
                  </div>
                ))}
            </div>
            {ftp === undefined && (
              <div className="bg-light-grey text-primary p-4 me-3">
                <i className="fa fa-info-circle me-2"></i> Please select FTP
                connection to view
              </div>
            )}
            {status === FTPFetchStatus.Failed && (
              <div className="bg-light-grey text-danger p-4 me-3">
                <i className="fa fa-warning me-2"></i> Failed Getting Directory
                Contents.{" "}
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setRefresh(new Date());
                  }}
                >
                  Try Again
                </a>
              </div>
            )}
          </div>
          <div className="flex-1">
            {!!fileAction && (
              <div className="p-4">
                {fileAction.action === 0 && (
                  <div className="pb-2">
                    <h4 className="text-primary">
                      <i className="fa fa-clone me-2"></i> Copy File
                    </h4>
                    <p className="m-0">
                      Please select a folder where to you want to copy this
                      file:
                    </p>
                  </div>
                )}
                {fileAction.action === 1 && (
                  <div className="pb-3">
                    <h4 className="text-danger">
                      <i className="fa fa-external-link me-2"></i> Move File
                    </h4>
                    <p className="m-0">
                      Please select a folder where to you want to move this
                      file:
                    </p>
                  </div>
                )}
                {fileAction.action === 2 && (
                  <div className="pb-3">
                    <h4 className="text-success">
                      <i className="fa fa-upload me-2"></i> Upload File
                    </h4>
                    <p className="m-0">
                      Please select a folder where to you want to upload this
                      file:
                    </p>
                  </div>
                )}
                <div className="p-3 bg-white">
                  <i className="fa fa-file text-primary me-2"></i>
                  {fileAction.fileName}
                </div>
                <div className="pt-3">
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={(e) => {
                      ftpStore.clearAction();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FTPManagement;
