import SysModels from "../../../models";
import systemStore from "../../../stores/SystemStore";
import { SiteDocumentationService } from "../SiteDocumentationService";
import { fetcher } from "../_services";

class ExtendedSiteDocumentationService extends SiteDocumentationService {
  get = async (id: string) => {
    let url = `/SiteDocumentation/${id}`;
    return fetcher.get<SysModels.ISiteDocumentationAdminOutputDto>(url);
  };

  getContent = async (id: string) => {
    let url = `/SiteDocumentation/GetContent/${id}`;
    return fetcher.get<string>(url);
  };

  getItems = async (data: SysModels.ISiteDocumentationSearchInputDto) => {
    let url = `/SiteDocumentation/Items`;
    return fetcher.post<SysModels.ISiteDocumentationDisplayAdminGridDto>(
      url,
      data
    );
  };

  create = async (data: SysModels.ISiteDocumentationInputDto) => {
    const url = `/SiteDocumentation`;
    return fetcher.post<SysModels.ISiteDocumentationAdminOutputDto>(url, data);
  };

  update = async (id: string, data: SysModels.ISiteDocumentationInputDto) => {
    const url = `/SiteDocumentation/${id}`;
    return fetcher.put<SysModels.ISiteDocumentationAdminOutputDto>(url, data);
  };

  delete = async (id: string) => {
    const url = `/SiteDocumentation/${id}`;
    return fetcher.delete<any>(url, {});
  };

  setActiveStatus = async (
    id: string,
    data: SysModels.ISiteDocumentationActiveStatusDto
  ) => {
    const url = `/SiteDocumentation/SetActiveStatus/${id}`;
    return fetcher.put<SysModels.ISiteDocumentationAdminOutputDto>(url, data);
  };

  publish = async (
    id: string,
    data: SysModels.ISiteDocumentationActiveStatusDto
  ) => {
    const url = `/SiteDocumentation/Publish/${id}`;
    return fetcher.put<any>(url, data);
  };

  // getContentPublished = async (id?: string) => {
  //   let url = `/SiteDocumentation/GetContentPublished/${id}`;
  //   return fetcher.get<string>(url);
  // };

  getPublishedItems = async (
    data: SysModels.ISiteDocumentationSearchInputDto,
    portalDoc?: boolean
  ) => {
    let url = `/SiteDocumentation/PublishedItemsForEmployees`;
    if (systemStore.currentCustomer) {
      url = `/SiteDocumentation/PublishedItemsForCustomers`;
    }
    if (portalDoc) {
      url = `/SiteDocumentation/PublishedPortalDocumentationItems`;
    }
    return fetcher.post<SysModels.ISiteDocumentationDisplayGridDto>(url, data);
  };
}

const extendedSiteDocumentation = new ExtendedSiteDocumentationService();
export default extendedSiteDocumentation;
