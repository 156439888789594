import React from "react";
import LatestPurchaseOrdersWidget from "../widgets/LatestPurchaseOrdersWidget";
import RecentlyViewedVendorsWdiget from "../widgets/RecentlyViewedVendorsWdiget";
import PurchaseOrderChartWidget from "../widgets/PurchaseOrderChartWidget";

function PurchasingDashboard(props: any) {
  return (
    <>
      <div className="default-page-layout hide-on-print">
        <h4>Purchasing Dashboard</h4>
      </div>

      <div className="row">
        <div className="cols-sm-12 col-md-12 col-lg-6">
          <PurchaseOrderChartWidget></PurchaseOrderChartWidget>
          <LatestPurchaseOrdersWidget></LatestPurchaseOrdersWidget>
        </div>
        <div className="cols-sm-12 col-md-12 col-lg-6">
          <RecentlyViewedVendorsWdiget></RecentlyViewedVendorsWdiget>
        </div>
      </div>
    </>
  );
}

export default PurchasingDashboard;
