import { makeAutoObservable } from "mobx";
export interface IFtpFileAction {
  folderName: string;
  fileName: string;
  action: number; //0: Copy, 1: Move, 2: upload
}

class FtpStore {
  private _action?: IFtpFileAction;
  private _refreshFolder?: string;
  private _uploadToFolder?: string;

  get action() {
    return this._action;
  }

  get refreshFolder() {
    return this._refreshFolder;
  }

  get uploadToFolder() {
    return this._uploadToFolder;
  }

  constructor() {
    makeAutoObservable(this);
  }

  clearAction() {
    this.setAction(undefined);
  }

  setAction(action?: IFtpFileAction) {
    this._action = action;
  }

  setRefreshFolder(folder?: string) {
    this._refreshFolder = folder;
  }

  clearUploadToFolder() {
    this.setUploadToFolder(undefined);
  }

  setUploadToFolder(folder?: string) {
    this._uploadToFolder = folder;
  }
}
const ftpStore = new FtpStore();
export default ftpStore;
