import SysModels from "../../../models";
import fetcher from "../../Fetcher";
import { SalesService } from "../SalesService";
class ExtendedSalesService extends SalesService {
  getMyCustomerSalesSummary = async () => {
    let url = `/Sales/MonthlySalesSummary`;
    return fetcher.get<SysModels.ICustomerSalesSummaryBySalesRepGridOutputDto>(
      url
    );
  };

  getWeeklySalesSummary = async () => {
    let url = `/Sales/WeeklySalesSummary`;
    return fetcher.get<SysModels.IWeeklyCustomerSalesSummaryBySalesRepGridOutputDto>(
      url
    );
  };

  getAllSalesRepSummary = async () => {
    let url = `/Sales/AllSalesRepSummary`;
    return fetcher.get<SysModels.ISalesRepSummaryOutputDto[]>(url);
  };

  getWeeklySalesSummaryExecutive = async (email: string) => {
    let url = `/Sales/WeeklySalesSummaryForSalesRep/${email}`;
    return fetcher.get<SysModels.IWeeklyCustomerSalesSummaryBySalesRepGridOutputDto>(
      url
    );
  };

  listOrders = async (
    page: number,
    pageSize: number,
    data: SysModels.ISalesOrderInvoiceSearchInputDto
  ) => {
    let url = `/Sales/Orders/${page}/${pageSize}`;
    return fetcher.post<SysModels.ISalesOrderInvoiceGridOutputDto>(url, data);
  };

  getCustomerSalesSummaryViaSalesRep = async (email: string) => {
    let url = `/Sales/MonthlySalesSummaryForSalesRep/${email}`;
    return fetcher.get<SysModels.ICustomerSalesSummaryBySalesRepGridOutputDto>(
      url
    );
  };

  getSalesRepName = async (email: string) => {
    let url = `/Sales/SalesRepName/${email}`;
    return fetcher.get<string>(url);
  };

  salesRepProjection = async (data: SysModels.ISalesRepProjectionSearchDto) => {
    let url = `/Sales/SalesRepProjection`;
    return fetcher.post<SysModels.ISalesRepProjectionGridOutputDto>(url, data);
  };

  yearlySalesSummaryForSalesRep = async (email: string) => {
    let url = `/Sales/YearlySalesSummaryForSalesRep/${email}`;
    return fetcher.get<SysModels.IYearlyCustomerSalesSummaryBySalesRepGridOutputDto>(
      url
    );
  };

  yearlySalesSummary = async () => {
    let url = `/Sales/YearlySalesSummary`;
    return fetcher.get<SysModels.IYearlyCustomerSalesSummaryBySalesRepGridOutputDto>(
      url
    );
  };

  downloadYearlySalesSummaryCsv = async () => {
    const url = `/Sales/DownloadYearlySalesSummaryCsv`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };

  downloadMonthlySalesSummaryCsv = async () => {
    const url = `/Sales/DownloadMonthlySalesSummaryCsv`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };

  downloadWeeklySalesSummaryCsv = async () => {
    const url = `/Sales/DownloadWeeklySalesSummaryCsv`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };

  downloadYearlySalesSummaryCsvForSalesRep = async (email: string) => {
    const url = `/Sales/DownloadYearlySalesSummaryCsvForSalesRep/${email}`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };

  downloadMonthlySalesSummaryCsvForSalesRep = async (email: string) => {
    const url = `/Sales/DownloadMonthlySalesSummaryCsvForSalesRep/${email}`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };

  downloadWeeklySalesSummaryCsvForSalesRep = async (email: string) => {
    const url = `/Sales/DownloadWeeklySalesSummaryCsvForSalesRep/${email}`;
    return fetcher.get<any>(url, { responseType: "blob" });
  };
}

const extendedSales = new ExtendedSalesService();
export default extendedSales;
