import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import CommonSpinner from "../components/common/CommonSpinner";
import ConfirmDialog from "../components/common/ConfirmDialog";
import SysModels from "../models";
import SysServices from "../services";
import commonService from "../services/CommonService";
import { FetchStatus, useFetchHelper } from "../services/FetchHelper";
import toastStore from "../stores/ToastStore";
import { useParams } from "react-router-dom";

function InitialUserEdit(props: any) {
  const navigate = useNavigate();
  const { id } = useParams(); //id: email

  const [user, setUser] = useState<SysModels.IUserInitialOutputDto>({
    email: "",
    firstName: "",
    lastName: "",
    addedToAadOn: null as any,
    userRoleDto: {
      none: false,
      isAdmin: false,
      isApplicationAdmin: false,
      isCustomerService: false,
      isDocumentManager: false,
      isExecutive: false,
      isOeManager: false,
      isSales: false,
      isSalesManager: false,
      isPurchasing: false,
      isLoginAsSalesRep: false,
    },
  });
  const roles = useFetchHelper(
    async () => SysServices.http.genericEnumLookup.get("UserRolesEnum"),
    "Roles"
  );

  const [calledOnce, setCalledOnce] = useState(false);
  useEffect(() => {
    if (!calledOnce) {
      roles.getData();
      setCalledOnce(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calledOnce, roles, id]);

  const focusInput = () => {
    setTimeout(() => {
      document.getElementById("firstNameInput")?.focus();
    }, 200);
  };

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (id !== "new") {
      setLoading(true);
      SysServices.http.user
        .getInitial(id || "")
        .then((data) => {
          setUser(data);
          setLoading(false);
          focusInput();
        })
        .catch((error) => {
          toastStore.showError("Failed Getting User", error);
          setSaving(false);
          navigate(`/initialusers`);
        });
    } else {
      setLoading(false);
      focusInput();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isRoleSelected = (role: SysModels.UserRolesEnum) => {
    if (!user?.userRoleDto) {
      return false;
    }
    if (role === SysModels.UserRolesEnum.Admin) {
      return user?.userRoleDto?.isAdmin || false;
    }
    if (role === SysModels.UserRolesEnum.ApplicationAdmin) {
      return user?.userRoleDto?.isApplicationAdmin || false;
    }
    if (role === SysModels.UserRolesEnum.CustomerService) {
      return user?.userRoleDto?.isCustomerService || false;
    }
    if (role === SysModels.UserRolesEnum.Executive) {
      return user?.userRoleDto?.isExecutive || false;
    }
    if (role === SysModels.UserRolesEnum.OeManager) {
      return user?.userRoleDto?.isOeManager || false;
    }
    if (role === SysModels.UserRolesEnum.Sales) {
      return user?.userRoleDto?.isSales || false;
    }
    if (role === SysModels.UserRolesEnum.SalesManager) {
      return user?.userRoleDto?.isSalesManager || false;
    }
    if (role === SysModels.UserRolesEnum.DocumentManager) {
      return user?.userRoleDto?.isDocumentManager || false;
    }
    if (role === SysModels.UserRolesEnum.Purchasing) {
      return user?.userRoleDto?.isPurchasing || false;
    }
    if (role === SysModels.UserRolesEnum.LoginAsSalesRep) {
      return user?.userRoleDto?.isLoginAsSalesRep || false;
    }
    return true;
  };

  const checkSelected = (role: SysModels.UserRolesEnum) => {
    return roleList.find((r) => r.value === role)?.checked || false;
  };

  const [saving, setSaving] = useState(false);

  const submit = async () => {
    const userRoleDto = {
      isAdmin: checkSelected(SysModels.UserRolesEnum.Admin),
      isApplicationAdmin: checkSelected(
        SysModels.UserRolesEnum.ApplicationAdmin
      ),
      isCustomerService: checkSelected(SysModels.UserRolesEnum.CustomerService),
      isExecutive: checkSelected(SysModels.UserRolesEnum.Executive),
      isOeManager: checkSelected(SysModels.UserRolesEnum.OeManager),
      isSales: checkSelected(SysModels.UserRolesEnum.Sales),
      isSalesManager: checkSelected(SysModels.UserRolesEnum.SalesManager),
      isDocumentManager: checkSelected(SysModels.UserRolesEnum.DocumentManager),
      isPurchasing: checkSelected(SysModels.UserRolesEnum.Purchasing),
      isLoginAsSalesRep: checkSelected(SysModels.UserRolesEnum.LoginAsSalesRep),
      none: checkSelected(-1 as any),
    };
    setSaving(true);
    if (id === "new") {
      await SysServices.http.user
        .createInitial({
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          userRoleDto: {
            ...userRoleDto,
          },
        })
        .then((data) => {
          toastStore.showToast("User saved successfully.", "success");
          navigate(`/initialusers`);
        })
        .catch((error) => {
          toastStore.showError("Failed Saving User", error);
          setSaving(false);
        });
    } else {
      await SysServices.http.user
        .updateInitial(id || "", {
          ...user,
          firstName: user.firstName,
          lastName: user.lastName,
          userRoleDto: {
            ...userRoleDto,
          },
        })
        .then((data) => {
          toastStore.showToast("User updated successfully.", "success");
          navigate(`/initialusers`);
        })
        .catch((error) => {
          toastStore.showError("Failed Updating User", error);
          setSaving(false);
        });
    }
  };

  const [roleList, setRoleList] = useState(
    [] as {
      label: string;
      value: number;
      checked?: boolean;
    }[]
  );

  useEffect(() => {
    if (roles.status === FetchStatus.Complete) {
      const list = [
        ...(roles.data || []),
        {
          value: -1,
          label: "None",
        },
      ];
      list.forEach((role) => {
        (role as any).checked = isRoleSelected(role.value);
      });
      list.forEach((role) => {
        if (role.value === -1) {
          (role as any).checked = !list.find(
            (r) => r.value !== -1 && (r as any).checked
          );
        }
      });
      setRoleList(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles.status, user?.userRoleDto]);

  const canEdit = () => {
    return user && !user.addedToAadOn;
  };

  const canSubmit = () => {
    return (
      user.firstName.trim() &&
      user.lastName.trim() &&
      user.email.trim() &&
      user.email.match(commonService.regexPatterns.email)
    );
  };

  const [deleting, setDeleting] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState<string>();
  const deleteUser = async (email: string) => {
    setDeleting(true);
    await SysServices.http.user
      .deleteInitial(email)
      .then((data) => {
        toastStore.showToast("User deleted successfully.", "success");
        navigate(`/initialusers`);
      })
      .catch((error) => {
        toastStore.showError("Failed Deleting User", error);
        setDeleting(false);
      });
  };

  return (
    <>
      {loading && <CommonSpinner message="Loading..."></CommonSpinner>}
      {saving && (
        <CommonSpinner overlay={true} message="Saving..."></CommonSpinner>
      )}
      {deleting && (
        <CommonSpinner overlay={true} message="Deleting..."></CommonSpinner>
      )}
      {!!emailToDelete && (
        <ConfirmDialog
          show={true}
          title="Delete User"
          message={`Do you really want to delete this user (${emailToDelete})?`}
          buttons="yesno"
          done={(rtn) => {
            if (rtn === "yes") {
              deleteUser(emailToDelete);
            }
            setEmailToDelete(undefined);
          }}
        ></ConfirmDialog>
      )}
      {!loading && (
        <div className="default-page-layout">
          <h4 className="flex flex-wrap flex-center mb-2">
            <span className="flex-1 mb-2">
              {id === "new" ? "Add Initial User" : "Edit Initial User"}
            </span>
          </h4>
          <div className="row">
            {/* style={{ background: "#fff" }} */}
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4 mb-4">
                  <h6>
                    <strong>User Information</strong>
                  </h6>

                  <div className="pt-3">
                    <label className="mb-2">First Name</label>
                    <input
                      id="firstNameInput"
                      className="form-control"
                      placeholder="First Name"
                      value={user?.firstName || ""}
                      readOnly={!canEdit()}
                      onChange={(e) => {
                        setUser((p) => {
                          if (!p) return p;
                          return {
                            ...p,
                            firstName: e.target.value,
                          };
                        });
                      }}
                    ></input>
                  </div>

                  <div className="pt-3">
                    <label className="mb-2">Last Name</label>
                    <input
                      className="form-control"
                      placeholder="Last Name"
                      value={user?.lastName || ""}
                      readOnly={!canEdit()}
                      onChange={(e) => {
                        setUser((p) => {
                          if (!p) return p;
                          return {
                            ...p,
                            lastName: e.target.value,
                          };
                        });
                      }}
                    ></input>
                  </div>

                  <div className="pt-3">
                    <label className="mb-2">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={user?.email || ""}
                      readOnly={id !== "new" || !canEdit()}
                      onChange={(e) => {
                        setUser((p) => {
                          if (!p) return p;
                          return {
                            ...p,
                            email: e.target.value,
                          };
                        });
                      }}
                    ></input>
                    {user.email.trim() &&
                      !user.email.match(commonService.regexPatterns.email) && (
                        <label className="text-danger mt-2">
                          Invalid Email
                        </label>
                      )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <h6>
                    <strong>Access Permissions</strong>
                  </h6>
                  {calledOnce && user && (
                    <div className="pt-3">
                      <label className="mb-2">Roles</label>
                      <div className="role-list">
                        {roleList
                          .filter((r) => {
                            return (
                              r.value !==
                              SysModels.UserRolesEnum.ApplicationAdmin
                            );
                          })
                          .map((role) => (
                            <div key={role.value} className="flex flex-center">
                              <input
                                className="me-2 pointer"
                                type="checkbox"
                                id={`roleId${role.value}`}
                                name="role"
                                value={role.label}
                                checked={role.checked}
                                onChange={(e) => {
                                  setRoleList((prev) => {
                                    return prev.map((p) => {
                                      if (
                                        e.target.checked &&
                                        role.value === -1
                                      ) {
                                        if (p.value === role.value) {
                                          return { ...p, checked: true };
                                        } else {
                                          return { ...p, checked: false };
                                        }
                                      }

                                      if (
                                        e.target.checked &&
                                        role.value !== -1
                                      ) {
                                        if (p.value === -1) {
                                          return { ...p, checked: false };
                                        }
                                      }

                                      if (p.value !== role.value) {
                                        return p;
                                      }
                                      return {
                                        ...p,
                                        checked: e.target.checked,
                                      };
                                    });
                                  });
                                }}
                              ></input>
                              <label
                                htmlFor={`roleId${role.value}`}
                                className="pointer"
                              >
                                {role.label}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-sm-12 mt-4">
              <span>
                {!user.addedToAadOn && (
                  <Button
                    variant="danger"
                    className="mb-2 me-4"
                    onClick={() => {
                      setEmailToDelete(id);
                    }}
                    disabled={saving}
                  >
                    Delete
                  </Button>
                )}

                <Button
                  variant="outline-secondary"
                  className="mb-2 me-2"
                  onClick={() => {
                    navigate(`/initialusers`);
                  }}
                  disabled={saving}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="mb-2"
                  onClick={submit}
                  disabled={saving || !canSubmit()}
                >
                  Submit
                </Button>
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default InitialUserEdit;
